import { format, fromUnixTime, getUnixTime, sub } from "date-fns";
import { Dropdown } from "../../components/Dropdown";
import { H3 } from "../../components/Heading";
import React, { useEffect, useRef, useState } from "react";

import { CanvasRenderer } from "echarts/renderers";
import { init, getInstanceByDom, use } from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  LegendComponent,
  GridComponent,
  TooltipComponent,
} from "echarts/components";
import type { ComposeOption, SetOptionOpts } from "echarts/core";
import type { GridComponentOption } from "echarts/components";
import { CSSProperties } from "styled-components";

import { useTheme } from "../../hooks/useTheme";
import { BarSeriesOption } from "echarts";
import { Card2 } from "../../components/Card";
import {
  DataRoomActivityFragmentFragment,
  DataRoomActivityMetrics,
  FileType,
  useDataRoomActivityMetricsQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import Loading from "../../components/Loading";
import { useHistory, useLocation } from "react-router-dom";
import { ActivityItem } from "./DataRoom";
import { Text } from "../../components/Text";
import {
  DocumentArrowUpIcon,
  EyeIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/solid";
import { FileIcon } from "../../components/FileIcon";
import { classNames } from "../../utils/cn";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Activity() {
  const client = useGqlClient();

  const [startDate, setStartDate] = useState<number>(
    getUnixTime(sub(new Date(), { days: 120 }))
  );
  const [endDate, setEndDate] = useState<number>(getUnixTime(new Date()));

  const [accountId, setAccountId] = useState<string | null>(null);
  const [fileId, setFileId] = useState<string | null>(null);

  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const accountId = query.get("accountId");

    if (accountId) {
      setAccountId(accountId);
    }

    const fileId = query.get("fileId");
    if (fileId) {
      setFileId(fileId);
    }
  }, [query]);

  const { data, isLoading, error } = useDataRoomActivityMetricsQuery(client, {
    input: {
      startDate,
      endDate,
      accountID: accountId,
      dataRoomFileID: fileId,
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className="flex-1">
      <div className="bg-white px-8 py-6 w-full shadow-md">
        <H3>Activity</H3>
        <Text size="s" colorPreset="secondary">
          Admin and staff can create new files, upload new versions and remove
          files from the data room. Buyers can only see live versions of files.
        </Text>
      </div>
      <div className="p-8">
        <div className="flex items-center">
          <div className="flex">
            <Dropdown
              dropdownType="text"
              defaultValue={
                accountId
                  ? data.company.companyAccounts.filter(
                      (ca) => ca.account.id === accountId
                    ).length > 0
                    ? data.company.companyAccounts.filter(
                        (ca) => ca.account.id === accountId
                      )[0].account.name
                    : undefined
                  : undefined
              }
              options={[
                { label: "Everyone", value: null, description: "" },
                ...data.company.companyAccounts.map((ca) => {
                  return {
                    label: ca.account.name,
                    value: ca.account.id,
                    description: "",
                  };
                }),
              ]}
              onSelect={(option) => {
                if (option.value === null) {
                  const params = query;
                  params.delete("accountId");

                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });

                  setAccountId(null);

                  return;
                }

                const params = query;
                params.set("accountId", option.value);
                history.replace({
                  pathname: location.pathname,
                  search: params.toString(),
                });
              }}
            />
            <Dropdown
              dropdownType="text"
              defaultValue={
                fileId
                  ? data.company.dataRoom.allFiles.filter(
                      (f) => f.id === fileId
                    ).length > 0
                    ? data.company.dataRoom.allFiles.filter(
                        (f) => f.id === fileId
                      )[0].name
                    : undefined
                  : undefined
              }
              options={[
                { label: "All files", value: null, description: "" },
                ...data.company.dataRoom.allFiles.map((f) => {
                  return {
                    label: f.name,
                    value: f.id,
                    description: "",
                  };
                }),
              ]}
              onSelect={(option) => {
                if (option.value === null) {
                  const params = query;
                  params.delete("fileId");

                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });

                  setFileId(null);

                  return;
                }

                const params = query;
                params.set("fileId", option.value);
                history.replace({
                  pathname: location.pathname,
                  search: params.toString(),
                });
              }}
            />
          </div>
        </div>

        <Card2 margin="l 0 0 0">
          <Chart
            data={[
              { key: "15 Feb", views: 24, downloads: 3 },
              { key: "16 Feb", views: 19, downloads: 1 },
              { key: "17 Feb", views: 4, downloads: 5 },
              { key: "18 Feb", views: 6, downloads: 8 },
              { key: "19 Feb", views: 9, downloads: 3 },
              { key: "20 Feb", views: 16, downloads: 5 },
              { key: "21 Feb", views: 29, downloads: 4 },
              { key: "22 Feb", views: 19, downloads: 2 },
              { key: "23 Feb", views: 16, downloads: 2 },
              { key: "24 Feb", views: 22, downloads: 9 },
              { key: "25 Feb", views: 21, downloads: 1 },
              { key: "26 Feb", views: 20, downloads: 6 },
              { key: "27 Feb", views: 29, downloads: 3 },
              { key: "28 Feb", views: 25, downloads: 6 },
              { key: "29 Feb", views: 13, downloads: 10 },
              { key: "1 Mar", views: 2, downloads: 6 },
              { key: "2 Mar", views: 18, downloads: 2 },
              { key: "3 Mar", views: 1, downloads: 4 },
              { key: "4 Mar", views: 13, downloads: 8 },
              { key: "5 Mar", views: 8, downloads: 6 },
              { key: "6 Mar", views: 5, downloads: 7 },
            ]}
          />
        </Card2>

        <H3 margin="xxl 0 0 0">Recent</H3>
        <Activity2 />
      </div>
    </div>
  );
}

export interface ChartData {
  label: string;
  lineColor: "primary" | "secondary";
  data: number[][];
}

interface Props {
  data: DataRoomActivityMetrics[];
  name?: string;
}

const WrappedCharts = (props: Props) => {
  const theme = useTheme();

  if (props.data.length === 0) {
    return null;
  }

  const option: ReactEChartsProps["option"] = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "1%",
      top: "15%",
      right: "5%",
      bottom: "5%",
      containLabel: true,
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      data: props.data.map((d) => d.key),
      axisTick: {
        show: false,
      },
      axisLabel: {
        fontSize: 12,
        color: theme.color.typography.secondary,
        fontWeight: "bold",
        padding: 4,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#788AA5",
      },
      splitLine: {
        show: false,
      },
    },
    legend: {
      data: ["Views", "Downloads"],
      top: 0,
      icon: "circle",
    },
    series: [
      {
        data: props.data.map((d) => d.views),
        label: {
          show: false,
        },
        name: "Views",
        type: "bar",
      },
      {
        data: props.data.map((d) => d.downloads),
        label: {
          show: false,
        },
        name: "Downloads",
        type: "bar",
      },
    ],
  };
  return <ReactECharts option={option} />;
};

const Chart = React.memo(WrappedCharts);

// Register the required components
use([
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  CanvasRenderer, // If you only need to use the canvas rendering mode, the bundle will not include the SVGRenderer module, which is not needed.
]);

// Combine an Option type with only required components and charts via ComposeOption
export type EChartsOption = ComposeOption<
  BarSeriesOption | GridComponentOption
>;

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
}

export const ReactECharts = ({
  option,
  style,
  settings,
  theme,
}: ReactEChartsProps): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize chart
    if (chartRef.current !== null) {
      init(chartRef.current, theme);
    }
  }, [theme]);

  useEffect(() => {
    console.log(option);
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  return (
    <div>
      <div
        ref={chartRef}
        style={{ width: "100%", height: "220px", ...style }}
      />
    </div>
  );
};

interface ActivityItemsProps {
  activity: DataRoomActivityFragmentFragment[];
}

export function ActivityItems(props: ActivityItemsProps) {
  const grouped: { [key: string]: DataRoomActivityFragmentFragment[] } = {};

  props.activity.forEach((activity) => {
    const key = format(fromUnixTime(activity.createdAt), "EEEE do MMMM ");

    if (grouped[key]) {
      grouped[key].push(activity);
    } else {
      grouped[key] = [activity];
    }
  });

  return (
    <div>
      {Object.keys(grouped).map((key) => {
        return (
          <div key={key}>
            <Text size="l" isInline weight="bold" margin="l 0 0 0">
              {key}
            </Text>

            {grouped[key].map((activity) => {
              return <ActivityItem key={activity.id} activity={activity} />;
            })}
          </div>
        );
      })}
    </div>
  );
}

const activity = [
  {
    id: 2,
    type: "viewed",
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    person: { name: "Henry", avatarColor: "bg-indigo-400" },
    date: "1 day ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 4,
    type: "downloaded",
    person: {
      name: "Henry",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    comment: "",
    date: "1 day ago",
    dateTime: "2023-01-23T15:56",
  },
];

function Activity2() {
  return (
    <>
      <ul role="list" className="space-y-6 mt-4 w-1/2">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ||
            activityItem.type === "questioned" ? (
              <>
                {activityItem.person.imageUrl ? (
                  <img
                    src={activityItem.person.imageUrl}
                    alt=""
                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  />
                ) : (
                  <div
                    className={` relative w-6 h-6 ring-2 ring-white flex-none ${activityItem.person.avatarColor} rounded-full items-center justify-center flex`}
                  >
                    <p className="text-sm text-white">
                      {activityItem.person.name[0]}
                    </p>
                  </div>
                )}

                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      {activityItem.type === "questioned"
                        ? "asked a question"
                        : activityItem.type}
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.comment}
                  </p>
                </div>
              </>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} a new version of
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : activityItem.type === "viewed" ||
              activityItem.type === "downloaded" ? (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <DocumentArrowDownIcon className="w-4 h-4 text-gray-500" />
                  )}
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type}
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            ) : null}
          </li>
        ))}
      </ul>
    </>
  );
}

import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { H3 } from "../../../components/Heading";
import { Fragment, useEffect, useState } from "react";
import { animated, useTransition } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import { demoSelectors } from "../../../store/demo/selector";
import { PnLData, actions } from "../../../store/demo/slice";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  PointElement,
  LineElement,
  LineController,
} from "chart.js";
import { Tab } from "@headlessui/react";
import { Bar } from "react-chartjs-2";
import Loading from "../../../components/Loading";
import { formatCurrency } from "../../../utils/formatCurrency";
import { ColumnIndicatorProps } from "react-spreadsheet";
import React from "react";
import { PlusIcon } from "@heroicons/react/20/solid";

// Register the components you need for the chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController
);

export function Analysis() {
  const isLoading = useSelector(demoSelectors.playgroundLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.initialize());
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white px-8 py-6 w-full shadow-md z-50">
        <div className="flex items-center justify-between">
          <H3>Playground</H3>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex-1 w-full">
          <PanelGroup
            direction="horizontal"
            style={{
              height: "100%",
            }}
          >
            <Panel defaultSize={75}>
              <PanelGroup direction="vertical">
                <Panel
                  minSize={20}
                  defaultSize={50}
                  style={{ overflow: "scroll" }}
                >
                  <Visualization />
                </Panel>
                <PanelResizeHandle
                  style={{ height: 0.5, backgroundColor: "#d1d5db" }}
                />
                <Panel
                  style={{ overflow: "auto" }}
                  minSize={20}
                  defaultSize={50}
                  maxSize={50}
                >
                  <Data />
                </Panel>
              </PanelGroup>
            </Panel>
            <PanelResizeHandle
              style={{ width: 1, backgroundColor: "#d1d5db" }}
            />

            <Panel defaultSize={25} minSize={20} maxSize={30}>
              <PanelGroup direction="vertical">
                <Panel minSize={32}>
                  <div className="px-4 ">
                    <Config />
                  </div>
                </Panel>
                <PanelResizeHandle
                  style={{ height: 1, backgroundColor: "#d1d5db" }}
                />
                <Panel minSize={68}>
                  <Chat />
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </div>
      </div>
    </div>
  );
}

function Config() {
  return (
    <Tab.Group>
      <Tab.List className="mt-2 py-1.5 rounded-lg flex space-x-1">
        <Tab as={Fragment}>
          {({ selected }) => {
            return (
              <button
                className={`${
                  selected
                    ? "bg-gray-500 text-white"
                    : "text-gray-500 bg-gray-100"
                } px-4 py-1 font-semibold text-sm rounded-md w-1/2 focus:border-none focus:ring-0`}
              >
                Forecast
              </button>
            );
          }}
        </Tab>
        <Tab as={Fragment}>
          {({ selected }) => {
            return (
              <button
                className={`${
                  selected
                    ? "bg-gray-500 text-white"
                    : "text-gray-500 bg-gray-100"
                } px-4 py-1 rounded-md text-sm w-1/2 active:border-none font-semibold`}
              >
                Returns
              </button>
            );
          }}
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <ForecastConfig />
        </Tab.Panel>
        <Tab.Panel>
          <ReturnsConfig />
        </Tab.Panel>
        <Tab.Panel>Content 3</Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}

function ReturnsConfig() {
  return (
    <div className="py-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="font-semibold">Purchase price</p>
          <p className="text-sm">£12,100,000</p>
        </div>
        <div>
          <p className="font-semibold">LTV</p>
          <p className="text-sm">50%</p>
        </div>
        <div>
          <p className="font-semibold">Entry date</p>
          <p className="text-sm">2024</p>
        </div>
        <div>
          <p className="font-semibold">Exit date</p>
          <p className="text-sm">2029</p>
        </div>
        <div>
          <p className="font-semibold">Entry multiple</p>
          <p className="text-sm">3x</p>
        </div>

        <div>
          <p className="font-semibold">Exit multiple</p>
          <p className="text-sm">4.5x</p>
        </div>
      </div>
      <button className="mt-2">
        <div className="flex items-center space-x-2">
          <PlusIcon className="text-indigo-500 w-4 h-4" />
          <p className="font-semibold text-indigo-500 text-sm">More filters</p>
        </div>
      </button>
    </div>
  );
}

function ForecastConfig() {
  const dispatch = useDispatch();

  const inputs = useSelector(demoSelectors.playgroundInputs);

  return (
    <div className="py-4">
      <div className="grid grid-cols-2 3xl:grid-cols-3 gap-4">
        <div className="mb-4">
          <label
            htmlFor="annualGrowth"
            className="block text-sm font-medium text-gray-700"
          >
            Annual Revenue Growth %
          </label>
          <div className="mt-1 relative rounded-md ">
            <input
              type="number"
              name="annualGrowth"
              id="annualGrowth"
              className="focus:ring-indigo-500 px-2 py-1 focus:border-indigo-500 border-gray-300 border w-full 3xl:w-1/3 sm:text-sm  rounded-md"
              placeholder="5"
              defaultValue={inputs.growth["Revenue"]["default"]}
              onChange={(e) => {
                const v = Number(e.target.value);
                dispatch(
                  actions.updateAnnualGrowth({
                    key: "Revenue",
                    annualGrowth: v,
                  })
                );
              }}
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="annualGrowth"
            className="block text-sm font-medium text-gray-700"
          >
            Annual COGS Growth %
          </label>
          <div className="mt-1 relative rounded-md ">
            <input
              type="number"
              name="annualCogsGrowth"
              id="annualCogsGrowth"
              className="focus:ring-indigo-500 px-2 py-1 focus:border-indigo-500 border-gray-300 border w-full 3xl:w-1/3 sm:text-sm  rounded-md"
              placeholder="5"
              defaultValue={inputs.growth.COGS["default"]}
              onChange={(e) => {
                const v = Number(e.target.value);
                dispatch(
                  actions.updateAnnualGrowth({
                    key: "COGS",
                    annualGrowth: v,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="annualGrowth"
            className="block text-sm font-medium text-gray-700"
          >
            Annual Opex Growth %
          </label>
          <div className="mt-1 relative rounded-md ">
            <input
              type="number"
              name="annualOpexGrowth"
              id="annualOpexGrowth"
              className="focus:ring-indigo-500 px-2 py-1 focus:border-indigo-500 border-gray-300 border w-full 3xl:w-1/3 sm:text-sm  rounded-md"
              placeholder="5"
              defaultValue={inputs.growth["Opex"]["default"]}
              onChange={(e) => {
                const v = Number(e.target.value);
                dispatch(
                  actions.updateAnnualGrowth({
                    key: "Opex",
                    annualGrowth: v,
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Chat() {
  const dispatch = useDispatch();
  const [newMessage, setNewMessage] = useState("");

  const messages = useSelector(demoSelectors.messages);

  const sendNewMessage = async () => {
    if (!newMessage.trim()) return;
    let id = Math.random() * 1000;
    dispatch(
      actions.addMessage({
        message: newMessage,
        from: "user",
        id,
      })
    );
    setNewMessage("");

    id = id + 1;
    dispatch(
      actions.addMessage({
        message: "Liquid is thinking...",
        from: "ai",
        id,
      })
    );

    setTimeout(() => {
      dispatch(
        actions.updatePeriodGrowth({
          key: "Opex",
          period: "2025",
          growth: -0.2,
        })
      );
      dispatch(actions.initialize());
      dispatch(
        actions.updateMessage({
          text: "Sure, I've adjusted the forecast to reflect a 20% drop in Opex in 2025",
          id,
        })
      );
    }, 1500);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if either Ctrl or Cmd is pressed along with Enter
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
      e.preventDefault(); // Prevent default to avoid adding a new line in textarea
      sendNewMessage();
    }
  };

  console.log("messages", messages);

  const transitions = useTransition(messages, {
    from: { transform: "translate3d(0,40px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-40px,0)", opacity: 0 },
    keys: (message) => message.id,
  });

  return (
    <div className="flex h-full flex-col">
      <div className="p-4">
        <H3>Chat</H3>
      </div>
      <div className="flex-1 overflow-y-auto px-4">
        {transitions((style, message) => {
          if (message.from === "user") {
            return (
              <animated.div
                style={style}
                key={message.id + message.text}
                className="flex items-center justify-end space-x-2"
              >
                <div className="bg-indigo-50 shadow-sm rounded-md p-2 my-2">
                  <p className="text-sm text-gray-600">{message.text}</p>
                </div>
              </animated.div>
            );
          }

          return (
            <animated.div
              style={style}
              key={message.id + message.text}
              className="flex items-center space-x-1"
            >
              <div className="w-4 h-4 rounded-full bg-teal-600 opacity-50 mt-3"></div>
              <div className="bg-gray-100 shadow-sm rounded-md p-2 my-2">
                <p className="text-sm text-gray-600">{message.text}</p>
              </div>
            </animated.div>
          );
        })}
      </div>

      <div className="p-4 border-t bg-gray-50">
        <div className="relative flex-auto">
          <div className="bg-white overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={2}
              name="comment"
              value={newMessage}
              onKeyDown={handleKeyDown}
              id="comment"
              onChange={(e) => setNewMessage(e.target.value)}
              className="p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Ask a question..."
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              {/* <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button>
              </div> */}
            </div>
            <button
              onClick={() => sendNewMessage()}
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Visualization() {
  const pnlData = useSelector(demoSelectors.paygroundPnl);
  return (
    <div className="w-full h-full px-8 py-4">
      <H3>Visualization</H3>
      <div className="h-full py-4">
        <RevenueBarChart pnlData={pnlData.data} />
      </div>
    </div>
  );
}

function Data() {
  const pnlData = useSelector(demoSelectors.paygroundPnl);
  return (
    <div className="relative flex-grow w-full py-4">
      <div className="px-8">
        <H3>Data</H3>
      </div>
      <div className="overflow-x-auto py-4">
        <FinancialTable
          pnlData={{
            pnlData: pnlData.data,
          }}
        />
      </div>
    </div>
  );
}

interface FinancialTableProps {
  pnlData: PnLData;
}

// const CustomCell: React.FC<CellComponentProps> = ({
//   row,
//   column,
//   data,
//   select,
//   activate,
//   ...props
// }) => {
//   // You can add custom styling or logic here based on the cell's data, row, or column
//   const style = {
//     // Example of custom styling
//     backgroundColor: data?.readOnly ? "lightgrey" : "white",
//     // More styles...
//   };

//   // Handle cell selection or activation if needed
//   const handleClick = () => {
//     select({ row, column });
//     activate({ row, column });
//   };

//   return (
//     <td style={style} onClick={handleClick}>
//       {/* Render the cell's value or a custom component */}
//       {data?.value}
//     </td>
//   );
// };

function CustomColumnIndicator(props: ColumnIndicatorProps) {
  return (
    <th>
      <p className="text-gray-500 text-sm">{props.label}</p>
    </th>
  );
}

const FinancialTable = ({ pnlData }: { pnlData: FinancialTableProps }) => {
  // Extract all unique quarters from the "Revenue" section (or any other with complete date range)
  const quarters = Object.keys(pnlData.pnlData["Revenue"].values);

  const columnLabels = quarters;
  const rowLabels = [...Object.keys(pnlData.pnlData)];
  const data = rowLabels
    .filter((rl) => pnlData.pnlData[rl].hidden !== true)
    .map((rowLabel) => {
      const values = quarters.map((quarter) => {
        return {
          value: pnlData.pnlData[rowLabel].values[quarter],
          readOnly: pnlData.pnlData[rowLabel].type === "TOTAL",
        };
      });
      return values;
    });

  return (
    <div className="px-8">
      <table className={`  `}>
        <thead>
          <tr>
            <th
              style={{ width: "100%" }}
              scope="col"
              className="pr-8 text-left"
            >
              <p className="text-gray-500 font-normal mt-2">$</p>
            </th>
            {columnLabels.map((cl) => {
              return (
                <th scope="col" className=" text-sm  text-gray-600 text-right">
                  {cl}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => {
            return (
              <tr
                key={rowLabels[i]}
                className={`
          ${i % 2 === 0 ? "bg-gray-100" : ""} 
          `}
              >
                <td className={` text-sm font-semibold truncate`}>
                  {rowLabels[i]}
                </td>
                {d.map((column, i) => (
                  <td
                    className={`border-0 ring-0 p-0 text-sm  pl-12`}
                    key={`${column.value}-${i}`}
                  >
                    <div
                      className={` text-right w-full  py-1 focus:ring-0 focus:outline-none`}
                    >
                      <p>{column.value.toLocaleString()}</p>
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const RevenueBarChart = ({ pnlData }: { pnlData: PnLData }) => {
  // Extract the quarters and revenue values
  const quarters = Object.keys(pnlData.Revenue.values);
  const revenueValues = quarters.map(
    (quarter) => pnlData.Revenue.values[quarter]
  );

  const netProfitValues = quarters.map(
    (quarter) =>
      pnlData.Revenue.values[quarter] -
      Object.values(pnlData)
        .filter((v, i) => v.type === "EXPENSE")
        .reduce((acc, cur) => acc + cur.values[quarter], 0)
  );

  const marginValues = quarters.map(
    (quarter) =>
      (netProfitValues[quarters.indexOf(quarter)] /
        revenueValues[quarters.indexOf(quarter)]) *
      100
  );

  // Prepare the data for the chart
  const data = {
    labels: quarters,
    datasets: [
      {
        yAxisID: "y",
        label: "Net Profit",
        data: netProfitValues,
        backgroundColor: "#fb7185cc",
        hoverBackgroundColor: "#fb7185",
        borderWidth: 0,
        borderRadius: 4,
      },
      {
        yAxisID: "y",
        label: "Revenue",
        data: revenueValues,
        backgroundColor: "#60a5facc",
        hoverBackgroundColor: "#60a5fa",
        borderWidth: 0,
        borderRadius: 4,
      },
      {
        yAxisID: "margin",
        type: "line",
        label: "Margin (%)",
        data: marginValues,
        backgroundColor: "#34d399cc",
        hoverBackgroundColor: "#34d399",
        borderWidth: 1,
        borderColor: "#34d399",
        pointRadius: 0,
        tension: 0.4,
      },
    ],
  };

  // Chart options
  const options: ChartOptions<"bar"> = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },

      margin: {
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false, // Only show grid for the primary Y-axis
        },
        ticks: {
          callback: function (value) {
            return value + "%"; // Format the Margin ticks with a percentage sign
          },
        },
      },
      y: {
        beginAtZero: true,
        border: {
          color: "#d1d5db",
          width: 0.5,
        },
        grid: {
          display: true,
          color: "#d1d5db",
          lineWidth: 0.5,
        },
        ticks: {
          // Include a dollar sign in the ticks and format numbers
          color: "#9CA3AF",
          font: {
            weight: "bold",
          },
          callback(tickValue, index, ticks) {
            return `$${tickValue.toLocaleString()}`;
          },
          // callback: function (value: number, index: number, values: number[]) {
          //   return `$${value.toLocaleString()}`;
          // },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.parsed.y;
            return `${label}: ${formatCurrency(value)}`;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          borderRadius: 8,
          boxWidth: 24,
          font: {
            weight: "bold",
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  // ignore next line in ts
  // @ts-ignore
  return <Bar data={data} options={options} />;
};

import { useParams } from "react-router-dom";
import {
  FileType,
  QuestionQuery,
  useQuestionQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  DocumentArrowUpIcon,
  EyeIcon,
  PaperClipIcon,
  SparklesIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Card2 } from "../../../components/Card";
import { classNames } from "../../../utils/cn";
import { FileIcon } from "../../../components/FileIcon";
import { useState } from "react";
import { Dropdown } from "../../../components/Dropdown";

export function Question() {
  const { id } = useParams<{ id: string }>();

  const client = useGqlClient();
  const { data, isLoading, error } = useQuestionQuery(client, { id });

  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 py-2 w-full shadow-md">
        <div className="flex justify-between">
          <div>
            <div className="flex items-center">
              <div className="flex items-center">
                <p className="font-semibold text-gray-400 text-sm">Questions</p>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div>
                  <H3>{data ? data.question.title : `Loading...`}</H3>
                  <p className="text-gray-500 text-xs">Created 2 hours ago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionInner isLoading={isLoading} error={error} data={data} />
    </div>
  );
}

function QuestionInner(props: {
  isLoading: boolean;
  data?: QuestionQuery;
  error: any;
}) {
  if (props.error) {
    return (
      <div className="flex-1 justify-center items-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.data) {
    return (
      <div className="flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  return <QuestionContent question={props.data.question} />;
}

function QuestionContent(props: { question: QuestionQuery["question"] }) {
  const [answer, setAnswer] = useState("");
  const [showFiles, setShowFiles] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(true);
  return (
    <div className="p-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <H3>Question</H3>
          <div className="flex space-x-2 mt-4">
            <div className="relative w-7 h-7 ring-2 ring-white flex-none bg-indigo-400 rounded-full items-center justify-center flex">
              <p className="text-sm text-white">H</p>
            </div>
            <div className="flex-1">
              <Card2 padding="m">
                <p className="text-xl text-gray-600 font-semibold">
                  {props.question.title}
                </p>
                <p className="whitespace-pre-wrap text-sm text-gray-500 mt-2">
                  {props.question.body}
                </p>
              </Card2>
            </div>
          </div>
          <div className="mt-6">
            <H3>Answer</H3>

            <div className="mt-6 flex gap-x-3 ">
              <img
                src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
                alt=""
                className="h-6 w-6 flex-none rounded-full bg-gray-50"
              />
              <form action="#" className="relative flex-auto">
                <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
                  <label htmlFor="comment" className="sr-only">
                    Add your comment
                  </label>
                  <textarea
                    rows={3}
                    name="comment"
                    id="comment"
                    className="p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Write your answer..."
                    defaultValue={""}
                    value={answer}
                  />
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      {showFiles ? (
                        <div className="flex mt-3 flex-wrap items-center gap-2">
                          <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                            <FileIcon size="s" fileType={FileType.Xlsx} />
                            <p className="text-xs ml-1 truncate">
                              Customer breakdown
                            </p>
                          </div>
                          <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                            <FileIcon size="s" fileType={FileType.Docx} />
                            <p className="text-xs ml-1 truncate">
                              Sample contracts
                            </p>
                          </div>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                        >
                          <PaperClipIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Attach a file</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Answer
                  </button>
                </div>
              </form>
            </div>
            {showSuggestion ? (
              <>
                <div className="flex justify-center my-2">
                  <div className="w-px h-8 bg-gray-200"></div>
                </div>
                <div className="ml-9 mt-2 opacity-50 hover:opacity-100 p-3 rounded-md shadow bg-white">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <SparklesIcon className="w-4 h-4 text-indigo-500" />
                      <p className="text-indigo-500 font-semibold text-sm">
                        Suggested Answer
                      </p>
                    </div>
                    <XCircleIcon className="w-5 h-5 text-gray-400" />
                  </div>
                  <div className="w-full bg-gray-200 h-px my-3"></div>
                  <div>
                    <div className="flex space-x-2">
                      <img
                        src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
                        alt=""
                        className="h-6 w-6 mt-1 flex-none rounded-full bg-gray-50"
                      />
                      <div>
                        <p className="text-sm ml-1 text-gray-600">
                          Hey Henry! Yes, you can see customer concentration and
                          example contracts in the Data Room, I have attached
                          those files. We don't have anything on geographic
                          distrbution right now but I'll look into getting that
                          for you.
                        </p>
                        <div className="flex mt-3 flex-wrap items-center gap-2">
                          <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                            <FileIcon size="s" fileType={FileType.Xlsx} />
                            <p className="text-xs ml-1 truncate">
                              Customer breakdown
                            </p>
                          </div>
                          <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                            <FileIcon size="s" fileType={FileType.Docx} />
                            <p className="text-xs ml-1 truncate">
                              Sample contracts
                            </p>
                          </div>
                        </div>
                        <div className="mt-3 flex justify-end">
                          <button
                            type="submit"
                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => {
                              setAnswer(
                                `Hey Henry! Yes, you can see customer concentration and example contracts in the Data Room, I have attached those files. We don't have anything on geographic distrbution right now but I'll look into getting that for you.`
                              );
                              setShowFiles(true);
                              setShowSuggestion(false);
                            }}
                          >
                            Use answer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div>
          <H3>Activity</H3>
          <Activity />
        </div>
      </div>
    </div>
  );
}

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Henry" },
    date: "2 hours ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "assigned",
    person: { name: "Dan" },
    assignee: "Matt",
    date: "1 hour ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 5,
    type: "viewed",
    person: { name: "Henry" },
    date: "1 hour ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 4,
    type: "commented",
    person: {
      name: "Dan",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    comment: "",
    date: "1 hour ago",
    dateTime: "2023-01-23T15:56",
  },
  //   {
  //     id: 6,
  //     type: "answered",
  //     person: { name: "Matt" },
  //     date: "Just now",
  //     dateTime: "2023-01-24T09:20",
  //   },
];

function Activity() {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <>
      <ul role="list" className="space-y-6 mt-2 ">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ? (
              <>
                <img
                  src={activityItem.person.imageUrl}
                  alt=""
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-orange-50">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      commented
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date} {`\u2022`} Visible to sell side
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    <span className="font-bold">Matt</span> can you please get
                    back to Henry today on this.
                  </p>
                </div>
              </>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} a new version of
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Pdf} />
                    <p className="text-xs ml-1">Profit & Loss</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "answered" ? (
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "assigned" ? (
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                      alt=""
                      className="relative h-4 w-4 flex-none rounded-full bg-gray-50"
                    />
                  ) : activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "uploaded" ? (
                    <DocumentArrowUpIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  )}
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {activityItem.person.name}
                  </span>{" "}
                  {activityItem.type} the question
                  <span className="font-bold">
                    {activityItem.assignee
                      ? ` to ${activityItem.assignee}`
                      : ""}
                  </span>
                  .
                </p>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            )}
          </li>
        ))}
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3 ">
        <img
          src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
          alt=""
          className="h-6 w-6 flex-none rounded-full bg-gray-50"
        />
        <form action="#" className="relative flex-auto">
          <div
            className={`overflow-hidden rounded-lg ${
              isFocused ? "pb-12" : "pb-1"
            } shadow-sm ring-1 ring-inset transition-all ease-in-out duration-300 ring-gray-300 focus-within:ring-indigo-500 focus:ring-2`}
          >
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={isFocused ? 2 : 1}
              name="comment"
              id="comment"
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              defaultValue={""}
            />
          </div>

          <div
            className={`ring-0  absolute top-0 right-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Dropdown
              options={[{ value: 1, label: "Visible to sell side" }]}
              defaultValue="Visible to sell side"
              dropdownType="text"
              onSelect={() => {}}
            />
          </div>

          <div
            className={`ring-0  absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button>
              </div>
            </div>
            <button
              type="submit"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Comment
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

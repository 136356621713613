import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGqlClient from "../../hooks/useGqlClient";
import { useQueryClient } from "react-query";
import {
  DataRoomFileQuery,
  useDataRoomFileQuery,
  useUpdateDataRoomFileMutation,
} from "../../graphql/generated";
import { DocumentIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { getUnixTime } from "date-fns";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface DeleteFileProps {
  onClose: () => void;
  open: boolean;
}

export function DeleteFile(props: DeleteFileProps) {
  const id = useQuery().get("deleteFile");

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(e) => {
          props.onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-50"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-50"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <DeleteFileContent
                  id={id}
                  onCancel={() => props.onClose()}
                  onDelete={() => props.onClose()}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface DeleteFileContentProps {
  id: string | null;
  onCancel: () => void;
  onDelete: () => void;
}

function DeleteFileContent(props: DeleteFileContentProps) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const fileQuery = useDataRoomFileQuery(client, {
    id: props.id || "",
  });

  const updateDataRoomFile = useUpdateDataRoomFileMutation(client);

  const [data, setData] = useState<DataRoomFileQuery | null>(null);

  useEffect(() => {
    if (fileQuery.data) {
      setData(fileQuery.data);
    }
  }, [fileQuery.data]);

  if (fileQuery.isLoading && !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <DocumentIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Loading...
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Please wait</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (fileQuery.error || !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Something went wrong
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                We couldn't load the file. Please try again.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <DocumentIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {`Delete ${data.dataRoomFile.name}`}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete the {data.dataRoomFile.name} file?
              This action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            console.log(data.dataRoomFile.parentFolders);
            updateDataRoomFile.mutate(
              {
                input: {
                  id: data.dataRoomFile.id,
                  deletedAt: getUnixTime(new Date()),
                },
              },
              {
                onSuccess: () => {
                  queryClient
                    .invalidateQueries([
                      "DataRoomFolder",
                      {
                        id: data.dataRoomFile.parentFolders[0].id,
                      },
                    ])
                    .then(() => {
                      props.onDelete();
                    });
                },
              }
            );
          }}
        >
          {updateDataRoomFile.isLoading ? "Deleting..." : "Delete"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Login } from "./Login";
import { Signup } from "./Signup";
import ProtectedRoute from "../components/ProtectedRoute";
import { Navigation } from "../components/Navigation";
import styled from "../styles";
import { MOBILE_NAV_BREAKPOINT } from "../config";
import { DataRoom } from "./DataRoom/DataRoom";
import { DataRoom as BuyerDataRoom } from "./buyer/DataRoom/DataRoom";
import { DataRoomFile as BuyerDataRoomFile } from "./buyer/DataRoom/DataRoomFile";
import { CreateCompany } from "./CreateCompany";
import { DataRoomFile } from "./DataRoom/DataRoomFile";
import { Access } from "./DataRoom/Access";
import { Invite } from "./Invite";
import {
  CompanyAccountAccessType,
  useCompanyQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { Activity } from "./DataRoom/Activity";
import { Valuation } from "./seller/Valuation";
import { Questions } from "./seller/questions2";
import { Questions as BuyerQuestions } from "./buyer/Questions";
import { ProfitLoss as BuyerPnl } from "./buyer/ProfitLoss";
import { Statements } from "./seller/statements2";
import { Dashboard } from "./seller/Dashboard";
import { Bidders } from "./seller/bidders";
import { Bidder } from "./seller/bidders/Bidder";
import { Issues } from "./seller/issues";
import { Issue } from "./seller/issues/Issue";
import { Playgrounds } from "./seller/playground";
import { Analysis } from "./seller/playground/Analysis";
import { Question } from "./seller/questions2/Question";
import { QualityOfEarnings } from "./seller/quality_of_earnings";
import { Home } from "./Home";
import { DueDiligence } from "./seller/due-diligence";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/invite/:token">
          <Invite />
        </Route>
        <ProtectedRoute path="/create-company">
          <CreateCompany />
        </ProtectedRoute>
        <ProtectedRoute path="/">
          <AuthedRoot />
        </ProtectedRoute>
      </Switch>
    </>
  );
};

const AuthedRoot = () => {
  return (
    <NavWrap>
      <Navigation />
      <PageContent>
        <Route path="/home" exact>
          <Home />
        </Route>
        <SellerOnly>
          <SellerRoot />
        </SellerOnly>
        <BuyerOnly>
          <BuyerRoot />
        </BuyerOnly>
      </PageContent>
    </NavWrap>
  );
};

const BuyerRoot = () => {
  return (
    <Switch>
      <Route path="/b/finances" exact>
        <Redirect to="/b/finances/statements" />
      </Route>

      <Route path="/b/finances/pnl">
        <BuyerPnl />
      </Route>

      <Route path="/b/questions">
        <BuyerQuestions />
      </Route>

      <Route path="/b/dataroom/folder/:folderId/file/:id" exact>
        <BuyerDataRoomFile />
      </Route>
      <Route path="/b/dataroom/folder/:folderId">
        <BuyerDataRoom />
      </Route>
      <Route path="/b/dataroom">
        <BuyerDataRoom />
      </Route>
    </Switch>
  );
};

const SellerRoot = () => {
  return (
    <Switch>
      <Route path="/s" exact>
        <Redirect to="/s/dashboard" />
      </Route>

      <Route path="/s/dashboard">
        <Dashboard />
      </Route>

      <Route path="/s/due-diligence">
        <DueDiligence />
      </Route>

      <Route path="/s/finances/playground/1">
        <Analysis />
      </Route>

      <Route path="/s/finances/playground">
        <Playgrounds />
      </Route>

      <Route path="/s/finances/valuation">
        <Valuation />
      </Route>

      <Route path="/s/finances/quality-of-earnings">
        <QualityOfEarnings />
      </Route>

      <Route path="/s/finances/statements">
        <Statements />
      </Route>

      <Route path="/s/finances">
        <Redirect to="/s/finances/statements/profit-loss" />
      </Route>

      <Route path="/s/bidders" exact>
        <Redirect to="/s/bidders/active" />
      </Route>

      <Route path="/s/bidders">
        <Bidders />
      </Route>

      <Route path="/s/bidder">
        <Bidder />
      </Route>

      <Route path="/s/errors" exact>
        <Issues />
      </Route>

      <Route path="/s/errors/details">
        <Issue />
      </Route>

      <Route path="/s/questions/:id">
        <Question />
      </Route>

      <Route path="/s/questions">
        <Questions />
      </Route>

      <Route path="/s/dataroom/folder/:folderId/file/:id/versions">
        <DataRoomFile />
      </Route>
      <Route path="/s/dataroom/folder/:folderId/file/:id" exact>
        <DataRoomFile />
      </Route>
      <Route path="/s/dataroom/folder/:folderId">
        <DataRoom />
      </Route>

      <Route path="/s/access" exact>
        <Access />
      </Route>

      <Route path="/s/dataroom/activity" exact>
        <Activity />
      </Route>

      <Route path="/s/dataroom/files" exact>
        <DataRoom />
      </Route>

      <Route path="/s/dataroom">
        <Redirect to="/s/dataroom/files" />
      </Route>
    </Switch>
  );
};

function BuyerOnly(props: { children: React.ReactNode }) {
  const client = useGqlClient();
  const companyQuery = useCompanyQuery(client);
  const location = useLocation();

  if (companyQuery.isLoading || companyQuery.error || !companyQuery.data) {
    return null;
  }

  if (
    companyQuery.data.company.activeCompanyAccount.accessType !==
      CompanyAccountAccessType.Buyer &&
    location.pathname.startsWith("/b")
  ) {
    return <Redirect to="/s" />;
  }

  return <>{props.children}</>;
}

function SellerOnly(props: { children: React.ReactNode }) {
  const client = useGqlClient();
  const companyQuery = useCompanyQuery(client);
  const location = useLocation();

  if (companyQuery.isLoading || companyQuery.error || !companyQuery.data) {
    return null;
  }

  if (
    companyQuery.data.company.activeCompanyAccount.accessType ===
      CompanyAccountAccessType.Buyer &&
    location.pathname.startsWith("/s")
  ) {
    return <Redirect to="/b" />;
  }

  return <>{props.children}</>;
}

const PageContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 100%;
  background-color: ${(p) => p.theme.color.card.background};
  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    border-top-left-radius: ${(p) => p.theme.misc.borderRadius};
    border-bottom-left-radius: ${(p) => p.theme.misc.borderRadius};
    border-left: 1px solid ${(p) => p.theme.color.card.divider};
    border-top: 1px solid ${(p) => p.theme.color.card.divider};
    box-shadow: ${(p) => p.theme.shadow.card};
  }
`;

const NavWrap = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${MOBILE_NAV_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export default Routes;

import { PlusIcon } from "@heroicons/react/20/solid";
import { H3 } from "../../../components/Heading";
import { Card2 } from "../../../components/Card";
import { Link } from "react-router-dom";
import { useState } from "react";
import { AnimatedModal } from "../../../components/AnimatedModal";
import { FileIcon } from "../../../components/FileIcon";
import { FileType } from "../../../graphql/generated";

export function Playgrounds() {
  const [showChoosePlaygroundAnalysis, setShowChoosePlaygroundAnalysis] =
    useState(false);

  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 py-6 w-full shadow-md">
        <div className="flex items-center justify-between">
          <H3>Playground</H3>
          <div className="space-x-2">
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setShowChoosePlaygroundAnalysis(true);
              }}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" />
              New
            </button>
          </div>
        </div>
      </div>
      <div className="p-8">
        <div className=" grid grid-cols-3 3xl:grid-cols-6 gap-4">
          <Link to="/s/finances/playground/1">
            <Card2>
              <div className="p-4">
                <img
                  className="w-full h-40 object-cover rounded-md"
                  src="https://archive.smashing.media/assets/344dbf88-fdf9-42bb-adb4-46f01eedd629/01310190-0d42-4b7a-b985-a70c65c56466/8-combined-multi-series-bar-and-line-chart-for-large-data-set-large-opt.png"
                />
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm mt-2 font-semibold text-gray-700">
                      Margin adjustments with new staffing plan
                    </p>
                    <p className="text-sm text-gray-500">Created 3 days ago</p>
                  </div>
                </div>
              </div>
            </Card2>
          </Link>
        </div>
      </div>
      <AnimatedModal
        size="xl"
        position="top"
        onClose={() => setShowChoosePlaygroundAnalysis(false)}
        open={showChoosePlaygroundAnalysis}
      >
        <ChoosePlaygroundAnalysis />
      </AnimatedModal>
    </div>
  );
}

function ChoosePlaygroundAnalysis() {
  return (
    <div className="w-full">
      <p className="font-bold text-lg">
        Choose the type of analysis you want to do
      </p>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="border rounded-md px-4 py-2 cursor-pointer hover:shadow relative">
          <p className="font-bold text-gray-600">Revenue, margins, growth</p>
          <p className="text-sm text-gray-500">
            Play out different financial scenarios
          </p>
          <div className="flex items-center mt-8 absolute bottom-2">
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Pdf} />
              <p className="text-xs ml-1">Profit & Loss</p>
            </div>
          </div>
        </div>
        <div className="border rounded-md px-4 py-2 relative h-32 cursor-pointer hover:shadow">
          <p className="font-bold text-gray-600">
            Headcount, staffing, succession
          </p>
          <p className="text-sm text-gray-500 truncate">
            Play out different staffing and succession scenarios
          </p>
          <div className="flex items-center mt-8 space-x-1   absolute bottom-2">
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Pdf} />
              <p className="text-xs ml-1">Profit & Loss</p>
            </div>
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Xlsx} />
              <p className="text-xs ml-1">Staff sheet</p>
            </div>
          </div>
        </div>

        <div className="border rounded-md px-4 py-2 relative h-32 cursor-pointer hover:shadow">
          <p className="font-bold text-gray-600">IRR</p>
          <p className="text-sm text-gray-500 truncate">
            See when you will get your money back
          </p>
          <div className="flex items-center mt-8 space-x-1 absolute bottom-2">
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Pdf} />
              <p className="text-xs ml-1">Profit & Loss</p>
            </div>
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Docx} />
              <p className="text-xs ml-1">Deal structure</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

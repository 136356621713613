import { Card2 } from "../../../components/Card";
import { Flex } from "../../../components/Flex";
import { H1, H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Text } from "../../../components/Text";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import {
  DataRoomFileFragmentFragment,
  DataRoomFolderFragmentFragment,
  useDataRoomFolderQuery,
  useDataRoomQuery,
  useFileDownloadUrlQuery,
  useTrackActivityMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import styled, { css } from "../../../styles";
import withMargin from "../../../styles/withMargin";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BuyerOnly } from "../../../components/BuyerOnly";
import {
  FolderIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/solid";
import { Breadcrumbs } from "../../DataRoom/DataRoom";
import { FileIcon } from "../../../components/FileIcon";

const Wrap = styled.div`
  flex: 1;
`;

const FilesWrapper = styled(Card2)`
  height: 400px;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  ${withMargin};
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableData = styled.td`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
`;

const TableHeading = styled.th`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.typography.secondary};
`;

export const TableRow = styled.tr`
  width: 100%;
  display: table-row;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}33;
  }
`;

export const TableLabel = styled((props) => <Text as="th" {...props} />)`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};
  display: flex;
  white-space: pre;
  ${({ theme }) => css`
    padding: 1px ${theme.spacing.xl} 0 0;
  `}
`;

export const TableValue = styled((props) => <Text as="td" {...props} />)`
  display: table-cell;
  padding: 1px 0;
`;

export function DataRoom() {
  const client = useGqlClient();
  const dataRoomQuery = useDataRoomQuery(client);

  const { folderId } = useParams<{ folderId: string }>();

  if (!dataRoomQuery.data || dataRoomQuery.isLoading) {
    return <Loading />;
  }

  return (
    <Wrap>
      <H1>Data Room</H1>
      <BuyerOnly>
        <Folder
          id={
            folderId ? folderId : dataRoomQuery.data.company.dataRoom.folder.id
          }
        />
      </BuyerOnly>
    </Wrap>
  );
}

interface FolderProps {
  id: string;
}

function Folder(props: FolderProps) {
  const client = useGqlClient();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id,
  });

  if (!folderQuery.data || folderQuery.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Flex justify="space-between" align="center" margin="xl 0 s 0">
        <H3 margin="0">Files</H3>
        <Flex align="center" justify="space-between">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              const params = new URLSearchParams({ search: "" });
              history.replace({
                pathname: location.pathname,
                search: params.toString(),
              });
            }}
          >
            <MagnifyingGlassCircleIcon className="w-8 h-8 text-gray-500 hover:text-gray-700" />
          </div>
        </Flex>
      </Flex>

      <Breadcrumbs
        viewerType="b"
        folders={folderQuery.data.dataRoomFolder.parentFolders}
      />

      <FilesWrapper>
        <Files folder={folderQuery.data.dataRoomFolder} />
      </FilesWrapper>
    </>
  );
}

interface FilesProps {
  folder: DataRoomFolderFragmentFragment;
}

const CenterWrap = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function Files(props: FilesProps) {
  const theme = useTheme();
  const history = useHistory();

  if (props.folder.files.length === 0 && props.folder.folders.length === 0) {
    return (
      <CenterWrap>
        <Text margin="0" size="xl" weight="bold">
          You haven't uploaded any files yet
        </Text>
        <Text margin="0" size="s">
          Click & drag files into this box or use the upload button
        </Text>
      </CenterWrap>
    );
  }

  return (
    <Table>
      <TableBody>
        <tr>
          <TableHeading style={{ width: "100%" }}>Name</TableHeading>
          <TableHeading style={{ minWidth: 130 }}>Last updated</TableHeading>
        </tr>
        {props.folder.folders.map((folder) => {
          return (
            <TableRow
              key={folder.id}
              onClick={() => {
                history.push("/b/dataroom/folder/" + folder.id);
              }}
            >
              <TableData>
                <Flex>
                  <FolderIcon
                    width={20}
                    height={20}
                    fill={theme.color.secondary}
                  />
                  <Text margin="0 0 0 s" weight="bold">
                    {folder.name}
                  </Text>
                </Flex>
              </TableData>

              <TableData>
                <Text margin="0">
                  {formatDistanceToNowStrict(fromUnixTime(folder.createdAt), {
                    addSuffix: true,
                  })}
                </Text>
              </TableData>
            </TableRow>
          );
        })}
        {props.folder.files.map((file) => {
          return (
            <FileRow
              folder={props.folder}
              file={file as DataRoomFileFragmentFragment}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

interface FileRowProps {
  file: DataRoomFileFragmentFragment;
  folder: DataRoomFolderFragmentFragment;
}

function FileRow(props: FileRowProps) {
  const theme = useTheme();
  const client = useGqlClient();
  const history = useHistory();

  const fileDownloadUrl = useFileDownloadUrlQuery(client, {
    id: props.file.id,
  });

  const track = useTrackActivityMutation(client);

  return (
    <TableRow
      className="hover:cursor-pointer"
      key={props.file.id}
      onClick={() => {
        history.push(
          `/b/dataroom/folder/${props.folder.id}/file/${props.file.id}`
        );
      }}
    >
      <TableData>
        <Flex>
          <FileIcon fileType={props.file.fileType} />
          <Text margin="0 0 0 s" weight="bold">
            {props.file.name}
          </Text>
        </Flex>
      </TableData>

      <TableData>
        <Text margin="0">
          {formatDistanceToNowStrict(fromUnixTime(props.file.updatedAt), {
            addSuffix: true,
          })}
        </Text>
      </TableData>
    </TableRow>
  );
}

import { useState } from "react";
import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import {
  ListboxInput,
  ListboxPopover,
  ListboxList,
  ListboxOption,
  ListboxButton,
} from "@reach/listbox";
import { Chevron } from "./Chevron";
import "@reach/listbox/styles.css";
import { Text } from "./Text";

interface Option {
  label: string;
  description?: string;
  value: any;
}

interface Props {
  margin?: string;
  options: Option[];
  value?: string;
  defaultValue?: string;
  onSelect: (option: Option) => void;
  dropdownType: "button" | "text";
}

const Arrow = styled(Chevron)`
  transform: rotate(90deg);
  margin-bottom: -1px;
  height: 1;
  width: 1;
`;

const DropdownWrapper = styled(ListboxInput)<
  { dropdownType: string } & WithMarginProp
>`
  display: flex;
  align-items: center;
  border: none;
  text-align: right;
  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  cursor: pointer;
  background: none;
  color: ${(p) => p.theme.color.typography["secondary"]};

  width: 100%;

  ${(p) =>
    p.dropdownType === "button" &&
    css`
      font-size: ${(p) => p.theme.typography.size.m};
      border: 1px solid ${(p) => p.theme.color.typography.secondary};
      border-radius: ${(p) => p.theme.misc.borderRadius};
      color: ${(p) => p.theme.color.typography["text"]};
      background: ${(p) => p.theme.color.card.background};
    `}

  ${withMargin}

  [data-reach-listbox-option][data-current-selected] {
    background: ${(p) => p.theme.color.card.divider};
  }

  &:focus-visible {
    outline: none !important;
  }

  &:focus-within {
    outline: none !important;
  }

  &:focus {
    outline: none !important;
  }
`;

const DropdownButton = styled(ListboxButton)<{ dropdownType: string }>`
  ${(p) =>
    p.dropdownType === "button" &&
    css`
      padding-left: ${(p) => p.theme.spacing.l};
      padding-top: ${(p) => p.theme.spacing.xs};
      padding-bottom: ${(p) => p.theme.spacing.xs};
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

  border-radius: ${(p) => p.theme.misc.borderRadius};
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: none !important;
  }

  &:focus-within {
    outline: none !important;
  }

  &:focus {
    outline: none !important;
  }
`;

const DropdownPopover = styled(ListboxPopover)<{ dropdownType: string }>`
  padding: ${(p) => p.theme.spacing.xs} 0;
  margin: ${(p) =>
    p.dropdownType === "button" ? `${p.theme.spacing.xs} 0 0` : `0px`};
  background: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.cardHeavy};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  border: none;
  min-width: 120px;
  width: 100%;
  z-index: 1000;

  &:focus-visible {
    outline: none !important;
  }

  &:focus-within {
    outline: none !important;
    box-shadow: ${(p) => p.theme.shadow.cardHeavy};
  }

  &:focus {
    outline: none !important;
  }
`;

const DropdownList = styled(ListboxList)`
  list-style-type: none;
  padding: 0;
  margin: 0px;

  &:focus-visible {
    outline: none !important;
  }

  &:focus-within {
    outline: none !important;
  }

  &:focus {
    outline: none !important;
  }
`;

const DropdownOption = styled(ListboxOption)`
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.s};
  margin: 0px;
  cursor: pointer;

  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  color: ${(p) => p.theme.color.typography["text"]};

  &[data-selected],
  &[data-current-nav] {
    background: ${(p) => p.theme.color.card.divider} !important;
    color: ${(p) => p.theme.color.typography["text"]};
    font-weight: ${(p) => p.theme.typography.weight.bold};
  }

  &[data-current-selected] {
    font-weight: ${(p) => p.theme.typography.weight.bold};
  }

  &:focus-visible {
    outline: none !important;
  }

  &:focus-within {
    outline: none !important;
  }

  &:hover {
    background: ${(p) => p.theme.color.card.divider} !important;
  }
`;

export function Dropdown(props: Props) {
  const [selectedOption, setSelectedOption] = useState(
    props.defaultValue ? props.defaultValue : props.options[0].label
  );

  return (
    <DropdownWrapper
      margin={props.margin}
      dropdownType={props.dropdownType}
      value={selectedOption}
      onChange={(newValue: string) => {
        setSelectedOption(
          props.options.find((o) => o.label === newValue)!.label
        );
        props.onSelect(props.options.find((o) => o.label === newValue)!);
      }}
    >
      <DropdownButton
        dropdownType={props.dropdownType}
        arrow={
          <span>
            <Arrow />
          </span>
        }
      >
        <div className="w-40">
          <Text size="s" align="left" margin="0" weight="bold">
            {selectedOption}
          </Text>
          <Text align="left" margin="0" size="xs" colorPreset="secondary">
            {props.options.find((o) => o.label === selectedOption)!.description}
          </Text>
        </div>
      </DropdownButton>
      <DropdownPopover dropdownType={props.dropdownType}>
        <DropdownList>
          {props.options.map((o, i) => {
            return (
              <DropdownOption
                onClick={(e) => {
                  e.preventDefault();
                }}
                key={i}
                value={o.label}
              >
                {o.label}
                {o.description ? (
                  <Text margin="0" size="xs" colorPreset="secondary">
                    {o.description}
                  </Text>
                ) : null}
              </DropdownOption>
            );
          })}
        </DropdownList>
      </DropdownPopover>
    </DropdownWrapper>
  );
}

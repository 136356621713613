import { useEffect } from "react";
import { useColorScheme } from "./hooks/useColorScheme";
import Routes from "./routes";

import { createGlobalStyle, ThemeProvider } from "./styles";
import { lightTheme } from "./styles/theme";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "./store/auth/selector";

import jwt_decode from "jwt-decode";
import { actions } from "./store/auth/slice";
import { useHistory } from "react-router-dom";
import useGqlClient from "./hooks/useGqlClient";

import { useAccountQuery } from "./graphql/generated";
import "./index.css";

const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    min-height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
  }

  html, body, textarea, input {
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(98deg, ${(p) =>
      p.theme.color.background.start} 0%, ${(p) =>
  p.theme.color.background.end} 100%), ${(p) => p.theme.color.background.start};

  a, a:visited {
    color: ${(p) => p.theme.color.typography.link};
  }
  a:hover {
    color: ${(p) => p.theme.color.typography.link}DD;
  }

  * { /* all element selector */
  box-sizing: border-box; /* border, within the width of the element */
}
input {
  display: block;
  width: 100%
}   

 
/* Main toolbar */
#toolbarViewer #print,
#toolbarViewer #download {
  display: none !important;
}

/* Secondary toolbar (drop-down) */
#secondaryToolbar #secondaryPrint,
#secondaryToolbar #secondaryDownload {
  display: none !important;
}
 
  }
`;

function App() {
  const theme = useColorScheme();
  const token = useSelector(authSelectors.accessToken);
  const dispatch = useDispatch();
  const history = useHistory();

  const client = useGqlClient();
  const accountQuery = useAccountQuery(client, {});

  useEffect(() => {
    if (!accountQuery.data) {
      return;
    }

    dispatch(actions.refreshAccount({ account: accountQuery.data.account }));
  }, [accountQuery.data]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const decoded: any = jwt_decode(token);
    const expires = new Date(decoded.exp * 1000);

    if (expires < new Date()) {
      console.log("Token expired");
      dispatch(actions.loginFailure());
      history.push("/login");
    }
  }, [token, dispatch, history]);

  return (
    <ThemeProvider theme={lightTheme}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}

      <GlobalStyles />
      <GlobalData />
      <Routes />
    </ThemeProvider>
  );
}

const GlobalData = () => {
  const dispatch = useDispatch();
  const client = useGqlClient();
  const auth = useSelector(authSelectors.data);
  // const accountQuery = useAccountsQuery(
  //   client,
  //   {},
  //   {
  //     enabled: auth.isLoggedIn,
  //   }
  // );

  // useEffect(() => {
  //   if (!accountQuery.data) {
  //     return;
  //   }

  //   if (accountQuery.isSuccess) {
  //     dispatch(actions.refreshAccount(accountQuery.data.account));
  //   }
  // }, [accountQuery, dispatch]);

  return null;
};

export default App;

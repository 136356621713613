import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import { Text } from "./Text";

interface Props {
  logo?: string | null;
  name: string;
  bgColor: string;
  size?: number;
  withShadow?: boolean;
  borderSize?: number;
  borderColor?: string;
}

export const CompanyLogoBg = styled.div<Props & WithMarginProp>`
  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(p) =>
    p.size
      ? css`
          width: ${p.size}px;
          height: ${p.size}px;
        `
      : null}

  background: #ffffff;

  ${(p) =>
    p.logo
      ? css`
          background-image: url("${p.logo}");
          background-size: cover;
        `
      : `
        background-color: ${p.bgColor}; 
      `}

  border-radius: 100%;

  ${(p) =>
    p.borderSize || p.borderSize === 0
      ? css`
          border: ${p.borderSize}px solid #ffffff;
        `
      : `border: 3px solid #ffffff;`}

  ${(p) =>
    p.withShadow === undefined || p.withShadow
      ? css`
          box-shadow: 0px 4px 10px rgba(100, 57, 145, 0.17);
        `
      : ``}

 

  cursor: pointer;
  user-select: none;

  flex-shrink: 0;

  ${withMargin}
`;

export default function CompanyLogo(props: Props) {
  if (props.logo) {
    return <Avatar url={props.logo} />;
  }
  return (
    <CompanyLogoBg
      logo={props.logo}
      name={props.name}
      bgColor={props.bgColor}
      size={props.size}
      withShadow={props.withShadow}
      borderColor={props.borderColor}
      borderSize={props.borderSize}
    >
      <Text color="white" margin="0" weight="bold" size="xs">
        {props.name.length > 2 ? props.name.substring(0, 2) : props.name}
      </Text>
    </CompanyLogoBg>
  );
}

const Avatar = styled.div<
  {
    url?: string | null;
    size?: number;
    withShadow?: boolean;
    isBlurry?: boolean;
    borderSize?: number;
    borderColor?: string;
  } & WithMarginProp
>`
  width: 36px;
  height: 36px;

  ${(p) =>
    p.size
      ? css`
          width: ${p.size}px;
          height: ${p.size}px;
        `
      : null}

  background: #ffffff;
  ${(p) =>
    p.url
      ? css`
          background-image: url("${p.url}");
          background-size: cover;
        `
      : ""}

  border-radius: 100%;

  ${(p) =>
    p.borderSize || p.borderSize === 0
      ? css`
          border: ${p.borderSize}px solid #ffffff;
        `
      : `border: 3px solid #ffffff;`}

  ${(p) =>
    p.withShadow === undefined || p.withShadow
      ? css`
          box-shadow: 0px 4px 10px rgba(100, 57, 145, 0.17);
        `
      : ``}

  ${(p) =>
    p.isBlurry
      ? css`
          filter: blur(${p.theme.misc.blur});
          -webkit-filter: blur(${p.theme.misc.blur});
        `
      : ``};

  cursor: pointer;
  user-select: none;

  flex-shrink: 0;

  ${withMargin}
`;

import { GraphQLClient } from "graphql-request";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";

export default function useGqlClient() {
  const token = useSelector(authSelectors.accessToken);
  const activeCompanyId = useSelector(authSelectors.activeCompanyId);

  let endpoint = "https://demo-api-xu7e.onrender.com/graphql"; //confusingly this is staging

  // if (process.env.NODE_ENV === "production") {
  //   endpoint = "https://api.liquidacquire.com/graphql";
  // }

  // endpoint = "http://localhost:8080/graphql";

  const headers = {
    authorization: `Bearer ${token}`,
    "x-company-id": activeCompanyId ? activeCompanyId : "",
  };

  return new GraphQLClient(
    endpoint,
    token
      ? {
          headers,
        }
      : {}
  );
}

import { AppState } from "..";
import { AuthState } from "./slice";

function data(state: AppState): AuthState {
  return state.auth;
}

function isLoading(state: AppState): boolean {
  return state.auth.isLoading;
}

function isLoggedIn(state: AppState): boolean {
  return state.auth.isLoggedIn;
}

function accessToken(state: AppState) {
  return state.auth.accessToken;
}

function activeCompanyId(state: AppState) {
  return state.auth.activeCompanyId;
}

function activeCompany(state: AppState) {
  if (!state.auth.account || !state.auth.account.companies) {
    return undefined;
  }

  return state.auth.account.companies.find(
    (c) => c.id === state.auth.activeCompanyId
  );
}

function account(state: AppState) {
  return state.auth.account;
}

// hack hack hack
function staffSheetQuestionAnswer(state: AppState) {
  return state.auth.staffSheetQuestionAnswer;
}

export const authSelectors = {
  data,
  isLoading,
  isLoggedIn,
  accessToken,
  activeCompany,
  activeCompanyId,
  account,

  // hack hack hack
  staffSheetQuestionAnswer,
};

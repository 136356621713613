import React from "react";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";
import { WithMarginProp } from "../styles/withMargin";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { MOBILE_NAV_BREAKPOINT } from "../config";

type InputProps = {
  label?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  help?: string;
  type?: string;
  error?: string;
  autoFocus?: boolean;
  inputSize?: "s";
};

export const TextInput = (props: InputProps & WithMarginProp) => {
  const theme = useTheme();

  return (
    <Flex direction="column" margin={props.margin}>
      {props.label ? (
        <Text size="s" weight="bold" margin="0">
          {props.label}
        </Text>
      ) : null}
      {props.help && (
        <Text size="s" margin="0" colorPreset="secondary">
          {props.help}
        </Text>
      )}
      <StyledInput
        type={props.type ? props.type : "text"}
        isError={!!props.error}
        placeholder={props.placeholder}
        value={props.value}
        name={props.name}
        autoFocus={props.autoFocus}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        hasLabel={props.label ? true : false}
        inputSize={props.inputSize}
        pattern={props.type === "phone" ? "[0-9]*" : undefined}
      />
      {props.error && (
        <Text size="s" margin="s 0 0 0" color={theme.color.warning}>
          {props.error}
        </Text>
      )}
    </Flex>
  );
};

interface StyledInputProps {
  inputSize?: "s";
  isError?: boolean;
  hasLabel?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  background-color: ${(p) => p.theme.color.card.background};
  padding: ${(p) =>
    p.inputSize === "s" ? p.theme.spacing.s : p.theme.spacing.m};

  ${(p) => (p.hasLabel ? css`` : null)}

  ${(p) =>
    p.type === "date" || p.type === "number"
      ? css`
          width: 175px;
          @media (max-width: 500px) {
            width: 125px;
          }
        `
      : null}

  ${(p) =>
    p.type === "date"
      ? css`
          padding: calc(${(p) => p.theme.spacing.m} - 1px);
          ::-webkit-calendar-picker-indicator {
            margin-left: -10px;
          }
        `
      : null}

  border: 2px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: 5px;
  font-size: ${(p) => p.theme.typography.size.m};

  color: ${(p) => p.theme.color.typography.text};

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.primary}20;
    outline: none;
  }

  ${(p) =>
    p.isError
      ? css`
          border-color: ${(p) => p.theme.color.warning};
          box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.warning}20;
        `
      : null}
`;

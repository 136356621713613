import * as React from "react";
import styled from "../styles";
import theme from "../styles/theme";

type colors = typeof theme.color.typography;

function ChevronSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 8 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.8}
        d="M1.416 0L0 1.416 4.584 6 0 10.584 1.416 12l6-6-6-6z"
      />
    </svg>
  );
}

interface Props {
  direction?: "left" | "down" | "up";
  colorPreset?: keyof colors;
  size?: "s" | "m";
  animatetransition?: string;
}

export const Chevron = styled(ChevronSVG)<Props>`
  path {
    fill: ${(p) =>
      p.color
        ? p.color
        : p.colorPreset
        ? p.theme.color.typography[p.colorPreset]
        : p.theme.color.typography.secondary};
  }

  ${(p) =>
    p.direction === "left" &&
    `
    transform: rotate(180deg);
    padding-left: ${p.theme.spacing.s};
    margin-bottom: 3px; 
  `}

  ${(p) =>
    p.direction === "down" &&
    `
    transform: rotate(90deg);
    padding-left: ${p.theme.spacing.s};
    margin-bottom: 6px; 
  `}

  ${(p) =>
    p.direction === "up" &&
    `
    transform: rotate(-90deg);
    margin-bottom: 6px; 
    margin-right: 6px;
  `}

  ${(p) =>
    p.size === "s" &&
    `
    height: 0.8em;
    width: 0.8em;
  `}

${(p) =>
    p.animatetransition &&
    `
    transition: transform 0.2s ease-out;
  `}

${(p) =>
    p.animatetransition &&
    p.direction === "down" &&
    `
    padding-left: 0;
    margin-bottom: 0; 
  `}

${(p) =>
    p.animatetransition &&
    p.direction === "up" &&
    `
    padding-right: 0;
    margin-bottom: 0;
    margin-right: 0; 
  `}
`;

import React from "react";
import styled from "../styles";
import withMargin from "../styles/withMargin";
import { Text } from "./Text";

type AlertType = "success" | "error" | "warning" | "info";
interface Props {
  type: AlertType;
  text: string;
  margin?: string;
}

export function Alert(props: Props) {
  return (
    <Wrap type={props.type} margin={props.margin}>
      <Text margin="0" weight="bold" colorPreset="warning">
        {props.text}
      </Text>
    </Wrap>
  );
}

const Wrap = styled.div<{ type: AlertType; margin?: string }>`
  border-left: 4px solid ${(props) => props.theme.color.warning};
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.warning}30;
  padding: ${(props) => props.theme.spacing.s};
  ${withMargin};
`;

import { PlusIcon } from "@heroicons/react/24/solid";
import { H3 } from "../components/Heading";
import { Card2 } from "../components/Card";
import CompanyLogo from "../components/CompanyLogo";
import { useState } from "react";
import { AnimatedModal } from "../components/AnimatedModal";
import { useWindowSize } from "../hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { actions } from "../store/auth/slice";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

const deals = [
  {
    name: "Logistics company",
    side: "Buy side",
    length: "Started 3 months ago",
  },
  {
    name: "Freight company",
    side: "Buy side",
    length: "Started 3 weeks ago",
  },
  {
    name: "Trucking company",
    side: "Buy side",
    length: "Started 2 months ago",
  },
  {
    name: "Roofing company",
    side: "Buy side",
    length: "Started 5 months ago",
  },
];

interface Invite {
  read: boolean;
  from: {
    name: string;
    firm: {
      name: string;
      type: string;
      size: string;
      numberOfDealsOnLiquid: number;
    };
  };
  summary: string;
  company: {
    name: string;
    revenue: string;
    employees: string;
    "EBITDA Margin": string;
    CAGR: string;
    location: string;
  };
  timestamp: string;
}

const inboxMessages: Invite[] = [
  {
    read: false,
    from: {
      name: "Matthew Bain",
      firm: {
        name: "Bain Scott & Co.",
        type: "Middle-Market law firm HQ'd in Minnesota",
        size: "10-50",
        numberOfDealsOnLiquid: 3,
      },
    },
    summary: "Logistics company in Minnesota doing $1-2M per year in revenue.",
    company: {
      name: "Minnesota logistics firm",
      revenue: "$1-2M",
      employees: "<10",
      "EBITDA Margin": "75%",
      CAGR: "15%",
      location: "Minnesota",
    },
    timestamp: "14:22",
  },
  {
    read: true,
    from: {
      name: "Lucas Martinez",
      firm: {
        name: "Williams Legal",
        type: "Law Firm HQ'd in California",
        size: "11-50",
        numberOfDealsOnLiquid: 7,
      },
    },
    summary:
      "Healthcare company in California doing $1-5M per year in revenue.",
    company: {
      name: "California healthcare firm",
      revenue: "$1-5M",
      employees: "51-200",
      "EBITDA Margin": "20%",
      CAGR: "10%",
      location: "California",
    },
    timestamp: "26 Feb",
  },
  {
    read: true,
    from: {
      name: "Henry Taylor",
      firm: {
        name: "Mid-market Law Firm",
        type: "Technology Firm HQ'd in North Carolina",
        size: "1001-5000",
        numberOfDealsOnLiquid: 8,
      },
    },
    summary:
      "Finance company in North Carolina doing $20-50M per year in revenue.",
    company: {
      name: "North Carolina finance firm",
      revenue: "$20-50M",
      employees: "501-1000",
      "EBITDA Margin": "40%",
      CAGR: "25%",
      location: "North Carolina",
    },
    timestamp: "25 Feb",
  },
  {
    read: true,
    from: {
      name: "Henry Taylor",
      firm: {
        name: "Mid-market Law Firm",
        type: "Real Estate Firm HQ'd in New York",
        size: "1-10",
        numberOfDealsOnLiquid: 1,
      },
    },
    summary: "Finance company in New York doing $10-20M per year in revenue.",
    company: {
      name: "New York finance firm",
      revenue: "$10-20M",
      employees: "<10",
      "EBITDA Margin": "40%",
      CAGR: "5%",
      location: "New York",
    },
    timestamp: "25 Feb",
  },
  {
    read: true,
    from: {
      name: "Charlotte Wilson",
      firm: {
        name: "M&A Lawyers",
        type: "Technology Firm HQ'd in Georgia",
        size: "1001-5000",
        numberOfDealsOnLiquid: 1,
      },
    },
    summary: "Energy company in Georgia doing $50-100M per year in revenue.",
    company: {
      name: "Georgia energy firm",
      revenue: "$50-100M",
      employees: "<10",
      "EBITDA Margin": "30%",
      CAGR: "5%",
      location: "Georgia",
    },
    timestamp: "22 Feb",
  },
  {
    read: true,
    from: {
      name: "Ethan Williams",
      firm: {
        name: "Taylor Tech",
        type: "Law Firm HQ'd in Pennsylvania",
        size: "10000+",
        numberOfDealsOnLiquid: 6,
      },
    },
    summary:
      "Finance company in Pennsylvania doing $5-10M per year in revenue.",
    company: {
      name: "Pennsylvania finance firm",
      revenue: "$5-10M",
      employees: "10-50",
      "EBITDA Margin": "15%",
      CAGR: "20%",
      location: "Pennsylvania",
    },
    timestamp: "18 Feb",
  },
];

export function Home() {
  const [showDeal, setShowDeal] = useState(false);
  const [showDealDetails, setShowDealDetails] = useState(false);
  const dimensions = useWindowSize();
  const [ndaAgreed, setNdaAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedInvite, setSelectedInvite] = useState<Invite | null>(null);

  return (
    <div className="flex-1">
      <div className="bg-white px-8 py-6 w-full shadow-md">
        <div className="flex items-center justify-between">
          <H3>Home</H3>
          <div>
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              onClick={() => {}}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" />
              New deal
            </button>
          </div>
        </div>
      </div>
      <div className="p-8">
        <p className="text-md text-gray-700 font-semibold">My deals</p>
        <div className="grid grid-cols-4 gap-4 mt-2">
          {deals.map((deal) => {
            return (
              <Card2 padding="m">
                <div className="flex items-center">
                  <CompanyLogo
                    withShadow={false}
                    borderSize={0}
                    size={38}
                    name={deal.name}
                    bgColor={"#000"}
                  />
                  <div className="ml-3">
                    <p className="font-semibold text-gray-600">{deal.name}</p>
                    <p className="text-sm text-gray-500">
                      {deal.side} {`\u2022`} {deal.length}
                    </p>
                  </div>
                </div>
              </Card2>
            );
          })}
        </div>
        <p className="text-md text-gray-700 font-semibold mt-8">
          Inbox{" "}
          <span
            className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-gray-400 px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-gray-400"
            aria-hidden="true"
          >
            1
          </span>
        </p>
        <div className="w-full mt-2">
          <Card2>
            {inboxMessages.map((inbox, i) => {
              return (
                <div
                  className={`cursor-pointer p-4 py-3 hover:border-gray-300 border-b box-content border-opacity-0 hover:border-opacity-100 ${
                    i === 0 ? "rounded-t-md" : ""
                  } ${i === inboxMessages.length - 1 ? "rounded-b-md" : ""} ${
                    inbox.read ? "bg-gray-50 py-2" : ""
                  }`}
                  onClick={() => {
                    setSelectedInvite(inbox);
                  }}
                >
                  <div className="flex items-center gap-x-2">
                    <div className="">
                      <p className="text-sm text-gray-500 font-semibold truncate w-32">
                        {inbox.from.name}
                      </p>
                      <p className="text-xs text-gray-400">
                        {inbox.from.firm.name}
                      </p>
                    </div>
                    <div className="lg:mx-8">
                      <p className="text-gray-600 font-bold">Deal Invite</p>
                    </div>
                    <div className="flex-1">
                      <p className="text-gray-500 font-light text-sm">
                        {inbox.summary}
                      </p>
                    </div>
                    <p className="text-gray-500 font-semibold text-xs ">
                      {inbox.timestamp}
                    </p>
                  </div>
                  <div
                    className={`
                  pr-2
                ${
                  showDeal
                    ? "visible opacity-100 mt-4"
                    : "invisible h-0 opacity-0 mt-0"
                }  `}
                  >
                    <p className="text-gray-500 font-light">
                      Hey! I think this company is a great fit for you based on
                      our convo a couple weeks back.
                    </p>
                    <p className="mt-4 font-semibold text-gray-600">
                      Company details
                    </p>
                    <div className="grid grid-cols-4 mt-2 gap-4">
                      <div>
                        <p className="text-sm text-gray-600 font-semibold">
                          Revenue
                        </p>
                        <p className="text-sm text-gray-500 font-normal">
                          $1-2 million
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600 font-semibold">
                          Employees
                        </p>
                        <p className="text-sm text-gray-500 font-normal">7</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600 font-semibold">
                          EBITDA Margin
                        </p>
                        <p className="text-sm text-gray-500 font-normal">75%</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600 font-semibold">
                          CAGR
                        </p>
                        <p className="text-sm text-gray-500 font-normal">15%</p>
                      </div>
                    </div>
                    <div className="mt-6 flex items-center gap-x-4">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDealDetails(true);
                        }}
                        className="p-2  font-semibold text-indigo-500 text-sm rounded-md hover:text-indigo-600 hover:bg-indigo-50"
                      >
                        Join deal
                      </button>
                      <button className="text-sm text-red-700 font-semibold hover:text-red-900 hover:bg-red-50 p-2 rounded-md">
                        Decline
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </Card2>
        </div>
      </div>
      <AnimatedModal
        position="top"
        size="full"
        open={selectedInvite !== null}
        onClose={() => {
          setShowDealDetails(false);
          setNdaAgreed(false);
          setSelectedInvite(null);
        }}
      >
        {selectedInvite ? (
          <div>
            <p className="text-lg text-gray-700 font-semibold">Deal Invite</p>
            <p className="text-gray-600 mt-2 text-sm">
              You have been invited by {selectedInvite.from.name} to access a
              deal via Liquid. Below is the NDA you need to sign to gain access
              + high level information about the company of interest.
            </p>
            <div className="xl:flex mt-6 gap-6">
              <div className=" xl:w-1/2 w-full">
                <h3 className=" font-bold text-gray-500 mb-4">
                  Non-Disclosure Agreement
                </h3>
                <div
                  style={{
                    height: dimensions.height ? dimensions.height * 0.6 : 700,
                  }}
                  className="max-w-4xl h-1/4 overflow-y-scroll no-scrollbar border-gray-300 border rounded-md  bg-white shadow-lg p-4  "
                >
                  <p>
                    This Non-Disclosure Agreement (the "Agreement") is made as
                    of{" "}
                    <span className="font-semibold">
                      {format(new Date(), "do MMMM yyyy")}
                    </span>{" "}
                    (the "Effective Date"), by and between{" "}
                    <span className="font-semibold">[Buyer Company Name]</span>,
                    a{" "}
                    <span className="font-semibold">
                      [Insert State/Country]
                    </span>{" "}
                    corporation ("Buyer"), and{" "}
                    <span className="font-semibold">
                      {selectedInvite.from.firm.name}
                    </span>
                    , a{" "}
                    <span className="font-semibold">
                      {selectedInvite.company.location}
                    </span>{" "}
                    corporation ("Seller").
                  </p>

                  <h2 className="mt-6 mb-4 font-bold">WHEREAS</h2>
                  <p>
                    Seller and Buyer are considering the possible acquisition by
                    Buyer of substantially all of the assets, stock, or business
                    of Seller (the "Transaction");
                  </p>
                  <p className="mt-4">
                    In connection with Buyer's consideration of the Transaction,
                    Seller may disclose or deliver to Buyer certain confidential
                    and proprietary information;
                  </p>

                  <h2 className="mt-6 mb-4 font-bold">NOW, THEREFORE</h2>
                  <p>
                    In consideration of the mutual covenants and agreements
                    contained herein, and for other good and valuable
                    consideration, the receipt and sufficiency of which are
                    hereby acknowledged, the parties hereto agree as follows:
                  </p>

                  <h3 className="mt-6 mb-2 font-bold">
                    1. Definition of Confidential Information
                  </h3>
                  <p>
                    For purposes of this Agreement, "Confidential Information"
                    means any and all non-public information, including, without
                    limitation, technical, development, marketing, sales,
                    operating, performance, cost, know-how, business plans,
                    business opportunities, and financial information disclosed
                    by Seller to Buyer, either directly or indirectly, in
                    writing, orally, or by drawings or observation.
                  </p>

                  <h3 className="mt-6 mb-2 font-bold">
                    2. Obligations of Buyer
                  </h3>
                  <p className="mb-2">
                    a. Buyer agrees to hold the Confidential Information in
                    strict confidence and to take all reasonable precautions to
                    protect such Confidential Information.
                  </p>
                  <p>
                    b. Buyer agrees not to (i) use the Confidential Information
                    in any way for its own account or for the account of any
                    third party, except as necessary to evaluate and engage in
                    discussions concerning the Transaction, or (ii) disclose any
                    Confidential Information to any third party except to those
                    of its employees and representatives who need to know such
                    information for the purpose of evaluating the Transaction
                    and who are bound by confidentiality obligations at least as
                    restrictive as those herein.
                  </p>

                  <h3 className="mt-6 mb-2 font-bold">
                    3. Exclusions from Confidential Information
                  </h3>
                  <p>
                    Confidential Information shall not include information that
                    (i) is now or subsequently becomes generally available to
                    the public through no fault or breach on the part of Buyer;
                    (ii) Buyer can demonstrate to have had rightfully in its
                    possession prior to disclosure by Seller; (iii) is
                    independently developed by Buyer without the use of any
                    Confidential Information; or (iv) Buyer rightfully obtains
                    from a third party who has the right to transfer or disclose
                    it.
                  </p>

                  <h3 className="mt-6 mb-2 font-bold">4. Term</h3>
                  <p>
                    This Agreement shall remain in effect for a two (2) year
                    period from the Effective Date or until the earlier of (i)
                    the execution of the Transaction or (ii) the written
                    agreement of both parties to terminate this Agreement.
                  </p>

                  <h3 className="mt-6 mb-2 font-bold">5. Miscellaneous</h3>
                  <p className="mb-2">
                    a. <span className="font-semibold">Governing Law</span>:
                    This Agreement shall be governed by and construed in
                    accordance with the laws of{" "}
                    <span className="font-semibold">[Insert Jurisdiction]</span>
                    , without regard to its conflict of laws principles.
                  </p>
                  <p className="mb-2">
                    b. <span className="font-semibold">Entire Agreement</span>:
                    This Agreement constitutes the entire agreement between the
                    parties concerning the subject matter hereof, and supersedes
                    all prior agreements, understandings, negotiations, and
                    discussions, whether oral or written, between the parties.
                  </p>
                  <p className="mb-2">
                    c. <span className="font-semibold">Amendment</span>: No
                    amendment or modification of this Agreement shall be deemed
                    effective unless made in writing and signed by the parties
                    hereto.
                  </p>

                  <p className="mt-6">
                    IN WITNESS WHEREOF, the parties hereto have executed this
                    Agreement as of the Effective Date.
                  </p>

                  <div className="grid grid-cols-2 gap-4 mt-6">
                    <div className="text-center">
                      <p className="font-semibold">[Buyer Company Name]</p>
                      <p>By: ____________________</p>
                      <p>Name:</p>
                      <p>Title:</p>
                      <p>Date:</p>
                    </div>
                    <div className="text-center">
                      <p className="font-semibold">[Seller Company Name]</p>
                      <p>By: ____________________</p>
                      <p>Name:</p>
                      <p>Title:</p>
                      <p>Date:</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div>
                  <h3 className=" font-bold text-gray-500 mb-4 ">
                    Company information
                  </h3>
                  <div className="grid grid-cols-4 mt-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-600 font-semibold">
                        Revenue
                      </p>
                      <p className="text-sm text-gray-500 font-normal">
                        {selectedInvite.company.revenue}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600 font-semibold">
                        Employees
                      </p>
                      <p className="text-sm text-gray-500 font-normal">
                        {selectedInvite.company.employees}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600 font-semibold">
                        EBITDA Margin
                      </p>
                      <p className="text-sm text-gray-500 font-normal">
                        {selectedInvite.company["EBITDA Margin"]}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600 font-semibold">
                        CAGR
                      </p>
                      <p className="text-sm text-gray-500 font-normal">
                        {selectedInvite.company.CAGR}
                      </p>
                    </div>
                  </div>
                  <h3 className=" font-bold text-gray-500 mt-6">Invited by</h3>
                  <div className="mt-2 gap-4 bg-white shadow rounded-md p-4">
                    <div>
                      <div className="flex items-center">
                        <img
                          src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
                          className="w-8 h-8  shadow rounded-full"
                        />
                        <div className="ml-3">
                          <p className="text-gray-600 font-semibold lin">
                            {selectedInvite.from.name}
                          </p>
                          <p className="text-sm text-gray-500 leading-none">
                            Partner
                          </p>
                        </div>
                      </div>
                      <div className="w-full h-px bg-gray-200 my-3"></div>
                      <p className=" text-gray-600 text-sm font-semibold">
                        {selectedInvite.from.firm.name}
                      </p>
                      <p className="text-xs text-gray-500">
                        {selectedInvite.from.firm.type}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="mt-6 font-bold text-gray-500 mb-4">Signing</h3>
                  <div className="relative flex items-start ">
                    <div className="flex h-6 items-center">
                      <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        checked={ndaAgreed}
                        className="h-4 w-4 rounded-md border-gray-300 text-indigo-600"
                        onChange={(e) => {
                          setNdaAgreed(e.target.checked);
                        }}
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6 ">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-900"
                      >
                        <span
                          id="comments-description"
                          className="text-gray-500 select-none"
                        >
                          I have read and agree to the terms of the NDA
                        </span>
                      </label>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setLoading(true);
                      setTimeout(() => {
                        dispatch(
                          actions.setActiveCompanyId({
                            id: "comp_2QkOO3YTXrwa23cKz4vimMbPQGD",
                          })
                        );

                        history.push("/b/dataroom");
                      }, 500);
                    }}
                    className={`w-full ${
                      ndaAgreed
                        ? "bg-indigo-500"
                        : "bg-indigo-500/50 cursor-not-allowed"
                    } py-2 rounded-md text-white font-semibold text-sm mt-4`}
                  >
                    {loading ? "Joining..." : "Join deal"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </AnimatedModal>
    </div>
  );
}

import { GraphQLClient } from 'graphql-request';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  status: AccountStatus;
  createdAt: Scalars['Int'];
  companies: Array<Company>;
  firm?: Maybe<Firm>;
};

export enum AccountStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE'
}

export type ActivateAccountResponse = {
  __typename?: 'ActivateAccountResponse';
  account: Account;
};

export type AuthTokens = {
  __typename?: 'AuthTokens';
  /**
   * An access token to use to make authenticated requests to this API. This token is
   * generally quite short lived but a new one can be obtained by using the refreshToken
   */
  accessToken: Scalars['String'];
  /**
   * A token that can be used to get a new access token. This token is long-lived and
   * so should be stored securely by the client
   */
  refreshToken: Scalars['String'];
};

export type AuthenticateResponse = {
  __typename?: 'AuthenticateResponse';
  account: Account;
  tokens: AuthTokens;
};

export enum ChangeSummaryStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type Column = {
  __typename?: 'Column';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ColumnInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['Int'];
  dataRoom: DataRoom;
  logoColor: Scalars['String'];
  companyAccounts: Array<CompanyAccount>;
  invites: Array<CompanyInvite>;
  activeCompanyAccount: CompanyAccount;
  financialStatements: FinancialStatements;
  logo: Scalars['String'];
};

export type CompanyAccount = {
  __typename?: 'CompanyAccount';
  account: Account;
  ndaSignedAt?: Maybe<Scalars['Int']>;
  accessType: CompanyAccountAccessType;
  permission: DataRoomPermission;
};

export enum CompanyAccountAccessType {
  Admin = 'ADMIN',
  Staff = 'STAFF',
  Guest = 'GUEST',
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export type CompanyInvite = {
  __typename?: 'CompanyInvite';
  id: Scalars['ID'];
  createdAt: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  company: Company;
  dataRoomPermission: DataRoomPermission;
  accessType: CompanyAccountAccessType;
  invitedBy: Account;
};

export type CreateFirmInput = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type DataRoom = {
  __typename?: 'DataRoom';
  id: Scalars['ID'];
  createdAt: Scalars['Int'];
  activity: Array<DataRoomActivity>;
  folder: DataRoomFolder;
  permissions: Array<DataRoomPermission>;
  company: Company;
  allFiles: Array<DataRoomFile>;
};

export type DataRoomActivity = {
  __typename?: 'DataRoomActivity';
  id: Scalars['ID'];
  createdAt: Scalars['Int'];
  type: DataRoomActivityType;
  account: Account;
  file?: Maybe<DataRoomFile>;
  folder?: Maybe<DataRoomFolder>;
  version?: Maybe<DataRoomFileVersion>;
};

export type DataRoomActivityInput = {
  count: Scalars['Int'];
  dataRoomID: Scalars['String'];
  cursor?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  dataRoomFolderID?: Maybe<Scalars['String']>;
  dataRoomFileID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};

export type DataRoomActivityMetrics = {
  __typename?: 'DataRoomActivityMetrics';
  key: Scalars['String'];
  views: Scalars['Int'];
  downloads: Scalars['Int'];
};

export type DataRoomActivityMetricsInput = {
  cursor?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  dataRoomFileID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};

export type DataRoomActivityMetricsResponse = {
  __typename?: 'DataRoomActivityMetricsResponse';
  activity: Array<DataRoomActivity>;
  metrics: Array<DataRoomActivityMetrics>;
  cursor: Scalars['String'];
};

export type DataRoomActivityResponse = {
  __typename?: 'DataRoomActivityResponse';
  activity: Array<DataRoomActivity>;
  cursor: Scalars['String'];
};

export enum DataRoomActivityType {
  FileCreated = 'FILE_CREATED',
  FileUpdated = 'FILE_UPDATED',
  FileVersionCreated = 'FILE_VERSION_CREATED',
  FileViewed = 'FILE_VIEWED',
  FileDownloaded = 'FILE_DOWNLOADED'
}

export type DataRoomFile = {
  __typename?: 'DataRoomFile';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  url: Scalars['String'];
  fileType: FileType;
  versions: Array<DataRoomFileVersion>;
  createdBy: Account;
  activity: Array<DataRoomActivity>;
  permission: DataRoomPermission;
  parentFolders: Array<DataRoomFolder>;
  stats: DataRoomFileStats;
};

export type DataRoomFileStats = {
  __typename?: 'DataRoomFileStats';
  views: Scalars['Int'];
  downloads: Scalars['Int'];
};

export type DataRoomFileVersion = {
  __typename?: 'DataRoomFileVersion';
  id: Scalars['ID'];
  createdAt: Scalars['Int'];
  url: Scalars['String'];
  summary: Scalars['String'];
  createdBy: Account;
  fileName: Scalars['String'];
  fileType: FileType;
  activity: Array<DataRoomActivity>;
};

export type DataRoomFolder = {
  __typename?: 'DataRoomFolder';
  id: Scalars['ID'];
  createdAt: Scalars['Int'];
  files: Array<DataRoomFile>;
  folders: Array<DataRoomFolder>;
  parentFolders: Array<DataRoomFolder>;
  createdBy: Account;
  name: Scalars['String'];
  permission: DataRoomPermission;
};

export type DataRoomPermission = {
  __typename?: 'DataRoomPermission';
  id: Scalars['ID'];
  createdAt: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  accessLevel: Scalars['Int'];
};

export type DeleteAccountResponse = {
  __typename?: 'DeleteAccountResponse';
  success: Scalars['Boolean'];
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  success: Scalars['Boolean'];
};

export enum FileType {
  Pdf = 'PDF',
  Docx = 'DOCX',
  Xlsx = 'XLSX',
  Pptx = 'PPTX',
  Other = 'OTHER'
}

export type FinancialStatements = {
  __typename?: 'FinancialStatements';
  pnl?: Maybe<Pnl>;
};

export type Firm = {
  __typename?: 'Firm';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['Int'];
  description: Scalars['String'];
  logo: Scalars['String'];
  accounts: Array<Account>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: AuthenticateResponse;
  createAccount: AuthenticateResponse;
  createFirm: Firm;
  deleteAccount: DeleteAccountResponse;
  createCompany: Company;
  createCompanyInvite: CompanyInvite;
  deleteCompanyInvite: DeleteResponse;
  removeCompanyAccount: DeleteResponse;
  updateDataRoomFile: DataRoomFile;
  updateDataRoomFolder: DataRoomFolder;
  acceptCompanyInvite: Company;
  createDataRoomPermission: DataRoomPermission;
  updateDataRoomPermission: DataRoomPermission;
  updateCompanyAccount: CompanyAccount;
  deleteDataRoomPermission: DataRoomPermission;
  createDataRoomFolder: DataRoomFolder;
  createDataRoomFile: DataRoomFile;
  createDataRoomFileVersion: PresignedUrlResponse;
  trackActivity: DataRoomActivity;
  createPnlVersion: Pnl;
  createQuestion: Question;
  createQuestionMessage: Question;
  markQuestionAsAnswered: Question;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateFirmArgs = {
  input: CreateFirmInput;
};


export type MutationCreateCompanyArgs = {
  name: Scalars['String'];
};


export type MutationCreateCompanyInviteArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  dataRoomPermissionID: Scalars['String'];
  accessType: CompanyAccountAccessType;
};


export type MutationDeleteCompanyInviteArgs = {
  id: Scalars['String'];
};


export type MutationRemoveCompanyAccountArgs = {
  accountID: Scalars['String'];
};


export type MutationUpdateDataRoomFileArgs = {
  input: UpdateDataRoomFileInput;
};


export type MutationUpdateDataRoomFolderArgs = {
  input: UpdateDataRoomFolderInput;
};


export type MutationAcceptCompanyInviteArgs = {
  token: Scalars['String'];
};


export type MutationCreateDataRoomPermissionArgs = {
  dataRoomID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  accessLevel: Scalars['Int'];
};


export type MutationUpdateDataRoomPermissionArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  accessLevel?: Maybe<Scalars['Int']>;
};


export type MutationUpdateCompanyAccountArgs = {
  accountID: Scalars['String'];
  accessType?: Maybe<CompanyAccountAccessType>;
  permissionID?: Maybe<Scalars['String']>;
};


export type MutationDeleteDataRoomPermissionArgs = {
  id: Scalars['String'];
};


export type MutationCreateDataRoomFolderArgs = {
  dataRoomFolderID: Scalars['String'];
  name: Scalars['String'];
  permissionID: Scalars['String'];
};


export type MutationCreateDataRoomFileArgs = {
  dataRoomFolderID: Scalars['String'];
  name: Scalars['String'];
  permissionID: Scalars['String'];
};


export type MutationCreateDataRoomFileVersionArgs = {
  dataRoomFileID: Scalars['String'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  summary: Scalars['String'];
};


export type MutationTrackActivityArgs = {
  type: DataRoomActivityType;
  fileId: Scalars['String'];
  dataRoomId: Scalars['String'];
  folderId: Scalars['String'];
};


export type MutationCreatePnlVersionArgs = {
  input: PnlInput;
};


export type MutationCreateQuestionArgs = {
  title: Scalars['String'];
  body: Scalars['String'];
  nodeId: Scalars['String'];
  nodeType: QuestionNodeType;
};


export type MutationCreateQuestionMessageArgs = {
  body: Scalars['String'];
  questionId: Scalars['String'];
  dataRoomFileID?: Maybe<Scalars['String']>;
};


export type MutationMarkQuestionAsAnsweredArgs = {
  questionId: Scalars['String'];
};

export type Pnl = {
  __typename?: 'Pnl';
  id: Scalars['ID'];
  latestVersion: PnlVersion;
  versions: Array<PnlVersion>;
  createdAt: Scalars['Int'];
  questions: Array<Question>;
};

export type PnlInput = {
  firmID: Scalars['String'];
  columns: Array<ColumnInput>;
  revenues: Array<RowInput>;
  cogs: Array<RowInput>;
  operatingExpenses: Array<RowInput>;
  depreciations: Array<RowInput>;
  amortizations: Array<RowInput>;
  interests: Array<RowInput>;
  taxes: Array<RowInput>;
};

export type PnlVersion = {
  __typename?: 'PnlVersion';
  id: Scalars['ID'];
  createdAt: Scalars['Int'];
  columns: Array<Column>;
  revenues: Array<Row>;
  cogs: Array<Row>;
  operatingExpenses: Array<Row>;
  depreciations: Array<Row>;
  amortizations: Array<Row>;
  interests: Array<Row>;
  taxes: Array<Row>;
  changeSummary: Scalars['String'];
  changeSummaryStatus: ChangeSummaryStatus;
};

export type PresignedUrlResponse = {
  __typename?: 'PresignedUrlResponse';
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  company: Company;
  firm: Firm;
  dataRoomFile: DataRoomFile;
  searchFiles: Array<DataRoomFile>;
  dataRoomFolder: DataRoomFolder;
  companyInvite: CompanyInvite;
  fileDownloadUrl: PresignedUrlResponse;
  dataRoomActivityMetrics: DataRoomActivityMetricsResponse;
  dataRoomActivity: DataRoomActivityResponse;
  pnlVersion: PnlVersion;
  questions: QuestionsRespose;
  question: Question;
};


export type QueryFirmArgs = {
  id: Scalars['String'];
};


export type QueryDataRoomFileArgs = {
  id: Scalars['String'];
};


export type QuerySearchFilesArgs = {
  query: Scalars['String'];
};


export type QueryDataRoomFolderArgs = {
  id: Scalars['String'];
};


export type QueryCompanyInviteArgs = {
  token: Scalars['String'];
};


export type QueryFileDownloadUrlArgs = {
  id: Scalars['String'];
};


export type QueryDataRoomActivityMetricsArgs = {
  input: DataRoomActivityMetricsInput;
};


export type QueryDataRoomActivityArgs = {
  input: DataRoomActivityInput;
};


export type QueryPnlVersionArgs = {
  id: Scalars['String'];
};


export type QueryQuestionsArgs = {
  input: QuestionsInput;
};


export type QueryQuestionArgs = {
  id: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  nodeId: Scalars['String'];
  createdAt: Scalars['Int'];
  answeredAt?: Maybe<Scalars['Int']>;
  createdBy: Account;
  messages: Array<QuestionMessage>;
};

export type QuestionMessage = {
  __typename?: 'QuestionMessage';
  id: Scalars['ID'];
  body: Scalars['String'];
  createdAt: Scalars['Int'];
  createdBy: Account;
  question: Question;
  file?: Maybe<DataRoomFile>;
};

export enum QuestionNodeType {
  DataRoomFile = 'DATA_ROOM_FILE',
  DataRoomFolder = 'DATA_ROOM_FOLDER',
  Pnl = 'PNL'
}

export type QuestionsInput = {
  nodeId?: Maybe<Scalars['String']>;
};

export type QuestionsRespose = {
  __typename?: 'QuestionsRespose';
  questions: Array<Question>;
};

export type Row = {
  __typename?: 'Row';
  id: Scalars['ID'];
  name: Scalars['String'];
  values: Array<RowValue>;
  type: RowType;
};

export type RowInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  values: Array<RowValueInput>;
  type: RowType;
};

export enum RowType {
  Income = 'INCOME',
  Expense = 'EXPENSE'
}

export type RowValue = {
  __typename?: 'RowValue';
  column: Scalars['String'];
  value: Scalars['Float'];
};

export type RowValueInput = {
  column: Scalars['String'];
  value: Scalars['Float'];
};

export type UpdateDataRoomFileInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  permissionID?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type UpdateDataRoomFolderInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  permissionID?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Int']>;
};

export type AcceptCompanyInviteMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AcceptCompanyInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptCompanyInvite: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
);

export type AccountQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { account: (
    { __typename?: 'Account' }
    & AccountFragmentFragment
  ) }
);

export type AccountFragmentFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'email' | 'status' | 'createdAt'>
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'logoColor' | 'logo'>
    & { companyAccounts: Array<(
      { __typename?: 'CompanyAccount' }
      & Pick<CompanyAccount, 'accessType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name' | 'email'>
      ), permission: (
        { __typename?: 'DataRoomPermission' }
        & Pick<DataRoomPermission, 'id' | 'name'>
      ) }
    )> }
  )>, firm?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name'>
  )> }
);

export type CompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'logo'>
    & { activeCompanyAccount: (
      { __typename?: 'CompanyAccount' }
      & Pick<CompanyAccount, 'accessType' | 'ndaSignedAt'>
      & { permission: (
        { __typename?: 'DataRoomPermission' }
        & Pick<DataRoomPermission, 'id' | 'name' | 'accessLevel'>
      ) }
    ) }
  ) }
);

export type CompanyInviteQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CompanyInviteQuery = (
  { __typename?: 'Query' }
  & { companyInvite: (
    { __typename?: 'CompanyInvite' }
    & Pick<CompanyInvite, 'id' | 'email' | 'accessType'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ), invitedBy: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'email'>
    ) }
  ) }
);

export type CreateAccountMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'AuthenticateResponse' }
    & { account: (
      { __typename?: 'Account' }
      & AccountFragmentFragment
    ), tokens: (
      { __typename?: 'AuthTokens' }
      & Pick<AuthTokens, 'accessToken' | 'refreshToken'>
    ) }
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ) }
);

export type CreateCompanyInviteMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  dataRoomPermissionID: Scalars['String'];
  accessType: CompanyAccountAccessType;
}>;


export type CreateCompanyInviteMutation = (
  { __typename?: 'Mutation' }
  & { createCompanyInvite: (
    { __typename?: 'CompanyInvite' }
    & Pick<CompanyInvite, 'id' | 'email' | 'name' | 'accessType' | 'createdAt'>
    & { dataRoomPermission: (
      { __typename?: 'DataRoomPermission' }
      & Pick<DataRoomPermission, 'id' | 'name'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  ) }
);

export type CreateDataRoomFileMutationVariables = Exact<{
  name: Scalars['String'];
  dataRoomFolderID: Scalars['String'];
  dataRoomPermissionID: Scalars['String'];
}>;


export type CreateDataRoomFileMutation = (
  { __typename?: 'Mutation' }
  & { createDataRoomFile: (
    { __typename?: 'DataRoomFile' }
    & Pick<DataRoomFile, 'id'>
  ) }
);

export type CreateDataFileVersionMutationVariables = Exact<{
  dataRoomFileID: Scalars['String'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  summary: Scalars['String'];
}>;


export type CreateDataFileVersionMutation = (
  { __typename?: 'Mutation' }
  & { createDataRoomFileVersion: (
    { __typename?: 'PresignedUrlResponse' }
    & Pick<PresignedUrlResponse, 'url'>
  ) }
);

export type CreateDataRoomFolderMutationVariables = Exact<{
  name: Scalars['String'];
  dataRoomFolderID: Scalars['String'];
  dataRoomPermissionID: Scalars['String'];
}>;


export type CreateDataRoomFolderMutation = (
  { __typename?: 'Mutation' }
  & { createDataRoomFolder: (
    { __typename?: 'DataRoomFolder' }
    & DataRoomFolderFragmentFragment
  ) }
);

export type CreatePnlVersionMutationVariables = Exact<{
  input: PnlInput;
}>;


export type CreatePnlVersionMutation = (
  { __typename?: 'Mutation' }
  & { createPnlVersion: (
    { __typename?: 'Pnl' }
    & Pick<Pnl, 'id'>
  ) }
);

export type CreateQuestionMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
  nodeId: Scalars['String'];
  nodeType: QuestionNodeType;
}>;


export type CreateQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createQuestion: (
    { __typename?: 'Question' }
    & Pick<Question, 'id'>
  ) }
);

export type CreateQuestionMessageMutationVariables = Exact<{
  body: Scalars['String'];
  questionId: Scalars['String'];
  dataRoomFileID?: Maybe<Scalars['String']>;
}>;


export type CreateQuestionMessageMutation = (
  { __typename?: 'Mutation' }
  & { createQuestionMessage: (
    { __typename?: 'Question' }
    & Pick<Question, 'id'>
  ) }
);

export type DataRoomQueryVariables = Exact<{ [key: string]: never; }>;


export type DataRoomQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & { dataRoom: (
      { __typename?: 'DataRoom' }
      & DataRoomFragmentFragment
    ), activeCompanyAccount: (
      { __typename?: 'CompanyAccount' }
      & Pick<CompanyAccount, 'accessType'>
    ), invites: Array<(
      { __typename?: 'CompanyInvite' }
      & Pick<CompanyInvite, 'id' | 'name' | 'email' | 'accessType'>
      & { dataRoomPermission: (
        { __typename?: 'DataRoomPermission' }
        & Pick<DataRoomPermission, 'id' | 'name'>
      ) }
    )>, companyAccounts: Array<(
      { __typename?: 'CompanyAccount' }
      & Pick<CompanyAccount, 'accessType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name' | 'email'>
      ), permission: (
        { __typename?: 'DataRoomPermission' }
        & Pick<DataRoomPermission, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type DataRoomFragmentFragment = (
  { __typename?: 'DataRoom' }
  & Pick<DataRoom, 'id' | 'createdAt'>
  & { folder: (
    { __typename?: 'DataRoomFolder' }
    & DataRoomFolderFragmentFragment
  ), activity: Array<(
    { __typename?: 'DataRoomActivity' }
    & DataRoomActivityFragmentFragment
  )>, permissions: Array<(
    { __typename?: 'DataRoomPermission' }
    & Pick<DataRoomPermission, 'id' | 'name' | 'description' | 'accessLevel' | 'createdAt'>
  )> }
);

export type DataRoomActivityQueryVariables = Exact<{
  input: DataRoomActivityInput;
}>;


export type DataRoomActivityQuery = (
  { __typename?: 'Query' }
  & { dataRoomActivity: (
    { __typename?: 'DataRoomActivityResponse' }
    & Pick<DataRoomActivityResponse, 'cursor'>
    & { activity: Array<(
      { __typename?: 'DataRoomActivity' }
      & DataRoomActivityFragmentFragment
    )> }
  ) }
);

export type DataRoomActivityFragmentFragment = (
  { __typename?: 'DataRoomActivity' }
  & Pick<DataRoomActivity, 'id' | 'createdAt' | 'type'>
  & { account: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name' | 'email'>
  ), folder?: Maybe<(
    { __typename?: 'DataRoomFolder' }
    & Pick<DataRoomFolder, 'id'>
  )>, file?: Maybe<(
    { __typename?: 'DataRoomFile' }
    & Pick<DataRoomFile, 'id' | 'name' | 'fileType'>
  )>, version?: Maybe<(
    { __typename?: 'DataRoomFileVersion' }
    & Pick<DataRoomFileVersion, 'id' | 'summary'>
  )> }
);

export type DataRoomActivityMetricsQueryVariables = Exact<{
  input: DataRoomActivityMetricsInput;
}>;


export type DataRoomActivityMetricsQuery = (
  { __typename?: 'Query' }
  & { dataRoomActivityMetrics: (
    { __typename?: 'DataRoomActivityMetricsResponse' }
    & Pick<DataRoomActivityMetricsResponse, 'cursor'>
    & { activity: Array<(
      { __typename?: 'DataRoomActivity' }
      & DataRoomActivityFragmentFragment
    )>, metrics: Array<(
      { __typename?: 'DataRoomActivityMetrics' }
      & Pick<DataRoomActivityMetrics, 'key' | 'views' | 'downloads'>
    )> }
  ), company: (
    { __typename?: 'Company' }
    & { activeCompanyAccount: (
      { __typename?: 'CompanyAccount' }
      & Pick<CompanyAccount, 'accessType'>
    ), companyAccounts: Array<(
      { __typename?: 'CompanyAccount' }
      & Pick<CompanyAccount, 'accessType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name' | 'email'>
      ), permission: (
        { __typename?: 'DataRoomPermission' }
        & Pick<DataRoomPermission, 'id' | 'name'>
      ) }
    )>, dataRoom: (
      { __typename?: 'DataRoom' }
      & { allFiles: Array<(
        { __typename?: 'DataRoomFile' }
        & Pick<DataRoomFile, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type DataRoomFileQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DataRoomFileQuery = (
  { __typename?: 'Query' }
  & { dataRoomFile: (
    { __typename?: 'DataRoomFile' }
    & DataRoomFileFragmentFragment
  ) }
);

export type DataRoomFileFragmentFragment = (
  { __typename?: 'DataRoomFile' }
  & Pick<DataRoomFile, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'fileType'>
  & { createdBy: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name'>
  ), permission: (
    { __typename?: 'DataRoomPermission' }
    & Pick<DataRoomPermission, 'id' | 'name'>
  ), parentFolders: Array<(
    { __typename?: 'DataRoomFolder' }
    & Pick<DataRoomFolder, 'id' | 'name'>
  )>, versions: Array<(
    { __typename?: 'DataRoomFileVersion' }
    & Pick<DataRoomFileVersion, 'id' | 'summary' | 'fileName' | 'fileType' | 'createdAt'>
    & { createdBy: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'email' | 'name'>
    ) }
  )>, stats: (
    { __typename?: 'DataRoomFileStats' }
    & Pick<DataRoomFileStats, 'views' | 'downloads'>
  ), activity: Array<(
    { __typename?: 'DataRoomActivity' }
    & Pick<DataRoomActivity, 'id' | 'createdAt' | 'type'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'email'>
    ), file?: Maybe<(
      { __typename?: 'DataRoomFile' }
      & Pick<DataRoomFile, 'id' | 'name' | 'fileType'>
    )>, version?: Maybe<(
      { __typename?: 'DataRoomFileVersion' }
      & Pick<DataRoomFileVersion, 'id' | 'summary'>
    )> }
  )> }
);

export type DataRoomFolderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DataRoomFolderQuery = (
  { __typename?: 'Query' }
  & { dataRoomFolder: (
    { __typename?: 'DataRoomFolder' }
    & DataRoomFolderFragmentFragment
  ) }
);

export type DataRoomFolderFragmentFragment = (
  { __typename?: 'DataRoomFolder' }
  & Pick<DataRoomFolder, 'id' | 'name' | 'createdAt'>
  & { createdBy: (
    { __typename?: 'Account' }
    & Pick<Account, 'email' | 'name'>
  ), files: Array<(
    { __typename?: 'DataRoomFile' }
    & Pick<DataRoomFile, 'id' | 'name' | 'updatedAt' | 'fileType'>
    & { createdBy: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
    ), permission: (
      { __typename?: 'DataRoomPermission' }
      & Pick<DataRoomPermission, 'id' | 'name'>
    ) }
  )>, parentFolders: Array<(
    { __typename?: 'DataRoomFolder' }
    & Pick<DataRoomFolder, 'id' | 'name'>
  )>, folders: Array<(
    { __typename?: 'DataRoomFolder' }
    & Pick<DataRoomFolder, 'id' | 'name' | 'createdAt'>
    & { permission: (
      { __typename?: 'DataRoomPermission' }
      & Pick<DataRoomPermission, 'id' | 'name'>
    ), createdBy: (
      { __typename?: 'Account' }
      & Pick<Account, 'email' | 'name'>
    ) }
  )> }
);

export type DeleteCompanyInviteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCompanyInviteMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyInvite: (
    { __typename?: 'DeleteResponse' }
    & Pick<DeleteResponse, 'success'>
  ) }
);

export type FileDownloadUrlQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FileDownloadUrlQuery = (
  { __typename?: 'Query' }
  & { fileDownloadUrl: (
    { __typename?: 'PresignedUrlResponse' }
    & Pick<PresignedUrlResponse, 'url'>
  ) }
);

export type FinancialsQueryVariables = Exact<{ [key: string]: never; }>;


export type FinancialsQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & { financialStatements: (
      { __typename?: 'FinancialStatements' }
      & { pnl?: Maybe<(
        { __typename?: 'Pnl' }
        & Pick<Pnl, 'id'>
        & { latestVersion: (
          { __typename?: 'PnlVersion' }
          & PnlVersionFragmentFragment
        ), versions: Array<(
          { __typename?: 'PnlVersion' }
          & PnlVersionFragmentFragment
        )>, questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'title' | 'body' | 'nodeId'>
          & { messages: Array<(
            { __typename?: 'QuestionMessage' }
            & Pick<QuestionMessage, 'id' | 'body'>
            & { createdBy: (
              { __typename?: 'Account' }
              & Pick<Account, 'id' | 'name'>
            ), file?: Maybe<(
              { __typename?: 'DataRoomFile' }
              & Pick<DataRoomFile, 'id' | 'name'>
            )> }
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type PnlVersionFragmentFragment = (
  { __typename?: 'PnlVersion' }
  & Pick<PnlVersion, 'id' | 'createdAt' | 'changeSummary' | 'changeSummaryStatus'>
  & { columns: Array<(
    { __typename?: 'Column' }
    & Pick<Column, 'id' | 'name'>
  )>, revenues: Array<(
    { __typename?: 'Row' }
    & RowFragmentFragment
  )>, cogs: Array<(
    { __typename?: 'Row' }
    & RowFragmentFragment
  )>, operatingExpenses: Array<(
    { __typename?: 'Row' }
    & RowFragmentFragment
  )>, depreciations: Array<(
    { __typename?: 'Row' }
    & RowFragmentFragment
  )>, amortizations: Array<(
    { __typename?: 'Row' }
    & RowFragmentFragment
  )>, interests: Array<(
    { __typename?: 'Row' }
    & RowFragmentFragment
  )>, taxes: Array<(
    { __typename?: 'Row' }
    & RowFragmentFragment
  )> }
);

export type RowFragmentFragment = (
  { __typename?: 'Row' }
  & Pick<Row, 'id' | 'name' | 'type'>
  & { values: Array<(
    { __typename?: 'RowValue' }
    & Pick<RowValue, 'column' | 'value'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthenticateResponse' }
    & { account: (
      { __typename?: 'Account' }
      & AccountFragmentFragment
    ), tokens: (
      { __typename?: 'AuthTokens' }
      & Pick<AuthTokens, 'accessToken' | 'refreshToken'>
    ) }
  ) }
);

export type MarkAsAnsweredMutationVariables = Exact<{
  questionId: Scalars['String'];
}>;


export type MarkAsAnsweredMutation = (
  { __typename?: 'Mutation' }
  & { markQuestionAsAnswered: (
    { __typename?: 'Question' }
    & Pick<Question, 'id'>
  ) }
);

export type PnlVersionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PnlVersionQuery = (
  { __typename?: 'Query' }
  & { pnlVersion: (
    { __typename?: 'PnlVersion' }
    & PnlVersionFragmentFragment
  ) }
);

export type QuestionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type QuestionQuery = (
  { __typename?: 'Query' }
  & { question: (
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'title' | 'body' | 'createdAt' | 'answeredAt'>
    & { createdBy: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { firm?: Maybe<(
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      )> }
    ), messages: Array<(
      { __typename?: 'QuestionMessage' }
      & Pick<QuestionMessage, 'id' | 'body' | 'createdAt'>
      & { file?: Maybe<(
        { __typename?: 'DataRoomFile' }
        & Pick<DataRoomFile, 'id' | 'fileType' | 'name'>
        & { parentFolders: Array<(
          { __typename?: 'DataRoomFolder' }
          & Pick<DataRoomFolder, 'id'>
        )> }
      )>, createdBy: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { firm?: Maybe<(
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        )> }
      ) }
    )> }
  ) }
);

export type QuestionsQueryVariables = Exact<{
  input: QuestionsInput;
}>;


export type QuestionsQuery = (
  { __typename?: 'Query' }
  & { questions: (
    { __typename?: 'QuestionsRespose' }
    & { questions: Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'title' | 'body' | 'createdAt' | 'answeredAt'>
      & { createdBy: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { firm?: Maybe<(
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        )> }
      ), messages: Array<(
        { __typename?: 'QuestionMessage' }
        & Pick<QuestionMessage, 'id' | 'body' | 'createdAt'>
        & { file?: Maybe<(
          { __typename?: 'DataRoomFile' }
          & Pick<DataRoomFile, 'id' | 'fileType' | 'name'>
          & { parentFolders: Array<(
            { __typename?: 'DataRoomFolder' }
            & Pick<DataRoomFolder, 'id'>
          )> }
        )>, createdBy: (
          { __typename?: 'Account' }
          & Pick<Account, 'id' | 'name'>
          & { firm?: Maybe<(
            { __typename?: 'Firm' }
            & Pick<Firm, 'id' | 'name'>
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type RemoveCompanyAccountMutationVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type RemoveCompanyAccountMutation = (
  { __typename?: 'Mutation' }
  & { removeCompanyAccount: (
    { __typename?: 'DeleteResponse' }
    & Pick<DeleteResponse, 'success'>
  ) }
);

export type SearchFilesQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchFilesQuery = (
  { __typename?: 'Query' }
  & { searchFiles: Array<(
    { __typename?: 'DataRoomFile' }
    & Pick<DataRoomFile, 'id' | 'name' | 'fileType'>
    & { parentFolders: Array<(
      { __typename?: 'DataRoomFolder' }
      & Pick<DataRoomFolder, 'id' | 'name'>
    )> }
  )> }
);

export type TrackActivityMutationVariables = Exact<{
  type: DataRoomActivityType;
  fileId: Scalars['String'];
  dataRoomId: Scalars['String'];
  folderId: Scalars['String'];
}>;


export type TrackActivityMutation = (
  { __typename?: 'Mutation' }
  & { trackActivity: (
    { __typename?: 'DataRoomActivity' }
    & Pick<DataRoomActivity, 'id'>
  ) }
);

export type UpdateCompanyAccountMutationVariables = Exact<{
  accountID: Scalars['String'];
  accessType?: Maybe<CompanyAccountAccessType>;
  permissionID?: Maybe<Scalars['String']>;
}>;


export type UpdateCompanyAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyAccount: (
    { __typename?: 'CompanyAccount' }
    & Pick<CompanyAccount, 'accessType'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    ), permission: (
      { __typename?: 'DataRoomPermission' }
      & Pick<DataRoomPermission, 'id' | 'name'>
    ) }
  ) }
);

export type UpdateDataRoomFileMutationVariables = Exact<{
  input: UpdateDataRoomFileInput;
}>;


export type UpdateDataRoomFileMutation = (
  { __typename?: 'Mutation' }
  & { updateDataRoomFile: (
    { __typename?: 'DataRoomFile' }
    & Pick<DataRoomFile, 'id' | 'name'>
  ) }
);

export type UpdateDataRoomFolderMutationVariables = Exact<{
  input: UpdateDataRoomFolderInput;
}>;


export type UpdateDataRoomFolderMutation = (
  { __typename?: 'Mutation' }
  & { updateDataRoomFolder: (
    { __typename?: 'DataRoomFolder' }
    & Pick<DataRoomFolder, 'id' | 'name'>
  ) }
);

export const AccountFragmentFragmentDoc = `
    fragment AccountFragment on Account {
  id
  email
  status
  createdAt
  companies {
    id
    name
    logoColor
    logo
    companyAccounts {
      account {
        id
        name
        email
      }
      permission {
        id
        name
      }
      accessType
    }
  }
  firm {
    id
    name
  }
}
    `;
export const DataRoomFolderFragmentFragmentDoc = `
    fragment DataRoomFolderFragment on DataRoomFolder {
  id
  name
  createdAt
  createdBy {
    email
    name
  }
  files {
    id
    name
    updatedAt
    fileType
    createdBy {
      id
      name
    }
    permission {
      id
      name
    }
  }
  parentFolders {
    id
    name
  }
  folders {
    id
    name
    createdAt
    permission {
      id
      name
    }
    createdBy {
      email
      name
    }
  }
}
    `;
export const DataRoomActivityFragmentFragmentDoc = `
    fragment DataRoomActivityFragment on DataRoomActivity {
  id
  createdAt
  type
  account {
    id
    name
    email
  }
  folder {
    id
  }
  file {
    id
    name
    fileType
  }
  version {
    id
    summary
  }
}
    `;
export const DataRoomFragmentFragmentDoc = `
    fragment DataRoomFragment on DataRoom {
  id
  createdAt
  folder {
    ...DataRoomFolderFragment
  }
  activity {
    ...DataRoomActivityFragment
  }
  permissions {
    id
    name
    description
    accessLevel
    createdAt
  }
}
    ${DataRoomFolderFragmentFragmentDoc}
${DataRoomActivityFragmentFragmentDoc}`;
export const DataRoomFileFragmentFragmentDoc = `
    fragment DataRoomFileFragment on DataRoomFile {
  id
  name
  createdAt
  createdBy {
    id
    name
  }
  permission {
    id
    name
  }
  parentFolders {
    id
    name
  }
  updatedAt
  fileType
  versions {
    id
    summary
    fileName
    fileType
    createdAt
    createdBy {
      id
      email
      name
    }
  }
  stats {
    views
    downloads
  }
  activity {
    id
    createdAt
    type
    account {
      id
      name
      email
    }
    file {
      id
      name
      fileType
    }
    version {
      id
      summary
    }
  }
}
    `;
export const RowFragmentFragmentDoc = `
    fragment RowFragment on Row {
  id
  name
  type
  values {
    column
    value
  }
}
    `;
export const PnlVersionFragmentFragmentDoc = `
    fragment PnlVersionFragment on PnlVersion {
  id
  createdAt
  columns {
    id
    name
  }
  revenues {
    ...RowFragment
  }
  cogs {
    ...RowFragment
  }
  operatingExpenses {
    ...RowFragment
  }
  depreciations {
    ...RowFragment
  }
  amortizations {
    ...RowFragment
  }
  interests {
    ...RowFragment
  }
  taxes {
    ...RowFragment
  }
  changeSummary
  changeSummaryStatus
}
    ${RowFragmentFragmentDoc}`;
export const AcceptCompanyInviteDocument = `
    mutation AcceptCompanyInvite($token: String!) {
  acceptCompanyInvite(token: $token) {
    id
    name
  }
}
    `;
export const useAcceptCompanyInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<AcceptCompanyInviteMutation, TError, AcceptCompanyInviteMutationVariables, TContext>
    ) => 
    useMutation<AcceptCompanyInviteMutation, TError, AcceptCompanyInviteMutationVariables, TContext>(
      (variables?: AcceptCompanyInviteMutationVariables) => fetcher<AcceptCompanyInviteMutation, AcceptCompanyInviteMutationVariables>(client, AcceptCompanyInviteDocument, variables)(),
      options
    );
export const AccountDocument = `
    query Account {
  account {
    ...AccountFragment
  }
}
    ${AccountFragmentFragmentDoc}`;
export const useAccountQuery = <
      TData = AccountQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: AccountQueryVariables, 
      options?: UseQueryOptions<AccountQuery, TError, TData>
    ) => 
    useQuery<AccountQuery, TError, TData>(
      ['Account', variables],
      fetcher<AccountQuery, AccountQueryVariables>(client, AccountDocument, variables),
      options
    );
export const CompanyDocument = `
    query Company {
  company {
    id
    name
    logo
    activeCompanyAccount {
      accessType
      ndaSignedAt
      permission {
        id
        name
        accessLevel
      }
    }
  }
}
    `;
export const useCompanyQuery = <
      TData = CompanyQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: CompanyQueryVariables, 
      options?: UseQueryOptions<CompanyQuery, TError, TData>
    ) => 
    useQuery<CompanyQuery, TError, TData>(
      ['Company', variables],
      fetcher<CompanyQuery, CompanyQueryVariables>(client, CompanyDocument, variables),
      options
    );
export const CompanyInviteDocument = `
    query CompanyInvite($token: String!) {
  companyInvite(token: $token) {
    id
    email
    company {
      id
      name
    }
    accessType
    invitedBy {
      id
      name
      email
    }
  }
}
    `;
export const useCompanyInviteQuery = <
      TData = CompanyInviteQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: CompanyInviteQueryVariables, 
      options?: UseQueryOptions<CompanyInviteQuery, TError, TData>
    ) => 
    useQuery<CompanyInviteQuery, TError, TData>(
      ['CompanyInvite', variables],
      fetcher<CompanyInviteQuery, CompanyInviteQueryVariables>(client, CompanyInviteDocument, variables),
      options
    );
export const CreateAccountDocument = `
    mutation CreateAccount($email: String!, $password: String!) {
  createAccount(email: $email, password: $password) {
    account {
      ...AccountFragment
    }
    tokens {
      accessToken
      refreshToken
    }
  }
}
    ${AccountFragmentFragmentDoc}`;
export const useCreateAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateAccountMutation, TError, CreateAccountMutationVariables, TContext>
    ) => 
    useMutation<CreateAccountMutation, TError, CreateAccountMutationVariables, TContext>(
      (variables?: CreateAccountMutationVariables) => fetcher<CreateAccountMutation, CreateAccountMutationVariables>(client, CreateAccountDocument, variables)(),
      options
    );
export const CreateCompanyDocument = `
    mutation CreateCompany($name: String!) {
  createCompany(name: $name) {
    id
    name
  }
}
    `;
export const useCreateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateCompanyMutation, TError, CreateCompanyMutationVariables, TContext>
    ) => 
    useMutation<CreateCompanyMutation, TError, CreateCompanyMutationVariables, TContext>(
      (variables?: CreateCompanyMutationVariables) => fetcher<CreateCompanyMutation, CreateCompanyMutationVariables>(client, CreateCompanyDocument, variables)(),
      options
    );
export const CreateCompanyInviteDocument = `
    mutation CreateCompanyInvite($name: String!, $email: String!, $dataRoomPermissionID: String!, $accessType: CompanyAccountAccessType!) {
  createCompanyInvite(
    name: $name
    email: $email
    dataRoomPermissionID: $dataRoomPermissionID
    accessType: $accessType
  ) {
    id
    email
    name
    accessType
    dataRoomPermission {
      id
      name
    }
    company {
      id
    }
    createdAt
  }
}
    `;
export const useCreateCompanyInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateCompanyInviteMutation, TError, CreateCompanyInviteMutationVariables, TContext>
    ) => 
    useMutation<CreateCompanyInviteMutation, TError, CreateCompanyInviteMutationVariables, TContext>(
      (variables?: CreateCompanyInviteMutationVariables) => fetcher<CreateCompanyInviteMutation, CreateCompanyInviteMutationVariables>(client, CreateCompanyInviteDocument, variables)(),
      options
    );
export const CreateDataRoomFileDocument = `
    mutation CreateDataRoomFile($name: String!, $dataRoomFolderID: String!, $dataRoomPermissionID: String!) {
  createDataRoomFile(
    name: $name
    dataRoomFolderID: $dataRoomFolderID
    permissionID: $dataRoomPermissionID
  ) {
    id
  }
}
    `;
export const useCreateDataRoomFileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateDataRoomFileMutation, TError, CreateDataRoomFileMutationVariables, TContext>
    ) => 
    useMutation<CreateDataRoomFileMutation, TError, CreateDataRoomFileMutationVariables, TContext>(
      (variables?: CreateDataRoomFileMutationVariables) => fetcher<CreateDataRoomFileMutation, CreateDataRoomFileMutationVariables>(client, CreateDataRoomFileDocument, variables)(),
      options
    );
export const CreateDataFileVersionDocument = `
    mutation CreateDataFileVersion($dataRoomFileID: String!, $fileName: String!, $fileType: String!, $summary: String!) {
  createDataRoomFileVersion(
    dataRoomFileID: $dataRoomFileID
    fileName: $fileName
    fileType: $fileType
    summary: $summary
  ) {
    url
  }
}
    `;
export const useCreateDataFileVersionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateDataFileVersionMutation, TError, CreateDataFileVersionMutationVariables, TContext>
    ) => 
    useMutation<CreateDataFileVersionMutation, TError, CreateDataFileVersionMutationVariables, TContext>(
      (variables?: CreateDataFileVersionMutationVariables) => fetcher<CreateDataFileVersionMutation, CreateDataFileVersionMutationVariables>(client, CreateDataFileVersionDocument, variables)(),
      options
    );
export const CreateDataRoomFolderDocument = `
    mutation CreateDataRoomFolder($name: String!, $dataRoomFolderID: String!, $dataRoomPermissionID: String!) {
  createDataRoomFolder(
    name: $name
    dataRoomFolderID: $dataRoomFolderID
    permissionID: $dataRoomPermissionID
  ) {
    ...DataRoomFolderFragment
  }
}
    ${DataRoomFolderFragmentFragmentDoc}`;
export const useCreateDataRoomFolderMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateDataRoomFolderMutation, TError, CreateDataRoomFolderMutationVariables, TContext>
    ) => 
    useMutation<CreateDataRoomFolderMutation, TError, CreateDataRoomFolderMutationVariables, TContext>(
      (variables?: CreateDataRoomFolderMutationVariables) => fetcher<CreateDataRoomFolderMutation, CreateDataRoomFolderMutationVariables>(client, CreateDataRoomFolderDocument, variables)(),
      options
    );
export const CreatePnlVersionDocument = `
    mutation CreatePnlVersion($input: PnlInput!) {
  createPnlVersion(input: $input) {
    id
  }
}
    `;
export const useCreatePnlVersionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreatePnlVersionMutation, TError, CreatePnlVersionMutationVariables, TContext>
    ) => 
    useMutation<CreatePnlVersionMutation, TError, CreatePnlVersionMutationVariables, TContext>(
      (variables?: CreatePnlVersionMutationVariables) => fetcher<CreatePnlVersionMutation, CreatePnlVersionMutationVariables>(client, CreatePnlVersionDocument, variables)(),
      options
    );
export const CreateQuestionDocument = `
    mutation CreateQuestion($title: String!, $body: String!, $nodeId: String!, $nodeType: QuestionNodeType!) {
  createQuestion(title: $title, body: $body, nodeId: $nodeId, nodeType: $nodeType) {
    id
  }
}
    `;
export const useCreateQuestionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateQuestionMutation, TError, CreateQuestionMutationVariables, TContext>
    ) => 
    useMutation<CreateQuestionMutation, TError, CreateQuestionMutationVariables, TContext>(
      (variables?: CreateQuestionMutationVariables) => fetcher<CreateQuestionMutation, CreateQuestionMutationVariables>(client, CreateQuestionDocument, variables)(),
      options
    );
export const CreateQuestionMessageDocument = `
    mutation CreateQuestionMessage($body: String!, $questionId: String!, $dataRoomFileID: String) {
  createQuestionMessage(
    body: $body
    questionId: $questionId
    dataRoomFileID: $dataRoomFileID
  ) {
    id
  }
}
    `;
export const useCreateQuestionMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<CreateQuestionMessageMutation, TError, CreateQuestionMessageMutationVariables, TContext>
    ) => 
    useMutation<CreateQuestionMessageMutation, TError, CreateQuestionMessageMutationVariables, TContext>(
      (variables?: CreateQuestionMessageMutationVariables) => fetcher<CreateQuestionMessageMutation, CreateQuestionMessageMutationVariables>(client, CreateQuestionMessageDocument, variables)(),
      options
    );
export const DataRoomDocument = `
    query DataRoom {
  company {
    dataRoom {
      ...DataRoomFragment
    }
    activeCompanyAccount {
      accessType
    }
    invites {
      id
      name
      email
      dataRoomPermission {
        id
        name
      }
      accessType
    }
    companyAccounts {
      account {
        id
        name
        email
      }
      permission {
        id
        name
      }
      accessType
    }
  }
}
    ${DataRoomFragmentFragmentDoc}`;
export const useDataRoomQuery = <
      TData = DataRoomQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: DataRoomQueryVariables, 
      options?: UseQueryOptions<DataRoomQuery, TError, TData>
    ) => 
    useQuery<DataRoomQuery, TError, TData>(
      ['DataRoom', variables],
      fetcher<DataRoomQuery, DataRoomQueryVariables>(client, DataRoomDocument, variables),
      options
    );
export const DataRoomActivityDocument = `
    query DataRoomActivity($input: DataRoomActivityInput!) {
  dataRoomActivity(input: $input) {
    activity {
      ...DataRoomActivityFragment
    }
    cursor
  }
}
    ${DataRoomActivityFragmentFragmentDoc}`;
export const useDataRoomActivityQuery = <
      TData = DataRoomActivityQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: DataRoomActivityQueryVariables, 
      options?: UseQueryOptions<DataRoomActivityQuery, TError, TData>
    ) => 
    useQuery<DataRoomActivityQuery, TError, TData>(
      ['DataRoomActivity', variables],
      fetcher<DataRoomActivityQuery, DataRoomActivityQueryVariables>(client, DataRoomActivityDocument, variables),
      options
    );
export const DataRoomActivityMetricsDocument = `
    query DataRoomActivityMetrics($input: DataRoomActivityMetricsInput!) {
  dataRoomActivityMetrics(input: $input) {
    activity {
      ...DataRoomActivityFragment
    }
    metrics {
      key
      views
      downloads
    }
    cursor
  }
  company {
    activeCompanyAccount {
      accessType
    }
    companyAccounts {
      account {
        id
        name
        email
      }
      permission {
        id
        name
      }
      accessType
    }
    dataRoom {
      allFiles {
        id
        name
      }
    }
  }
}
    ${DataRoomActivityFragmentFragmentDoc}`;
export const useDataRoomActivityMetricsQuery = <
      TData = DataRoomActivityMetricsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: DataRoomActivityMetricsQueryVariables, 
      options?: UseQueryOptions<DataRoomActivityMetricsQuery, TError, TData>
    ) => 
    useQuery<DataRoomActivityMetricsQuery, TError, TData>(
      ['DataRoomActivityMetrics', variables],
      fetcher<DataRoomActivityMetricsQuery, DataRoomActivityMetricsQueryVariables>(client, DataRoomActivityMetricsDocument, variables),
      options
    );
export const DataRoomFileDocument = `
    query DataRoomFile($id: String!) {
  dataRoomFile(id: $id) {
    ...DataRoomFileFragment
  }
}
    ${DataRoomFileFragmentFragmentDoc}`;
export const useDataRoomFileQuery = <
      TData = DataRoomFileQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: DataRoomFileQueryVariables, 
      options?: UseQueryOptions<DataRoomFileQuery, TError, TData>
    ) => 
    useQuery<DataRoomFileQuery, TError, TData>(
      ['DataRoomFile', variables],
      fetcher<DataRoomFileQuery, DataRoomFileQueryVariables>(client, DataRoomFileDocument, variables),
      options
    );
export const DataRoomFolderDocument = `
    query DataRoomFolder($id: String!) {
  dataRoomFolder(id: $id) {
    ...DataRoomFolderFragment
  }
}
    ${DataRoomFolderFragmentFragmentDoc}`;
export const useDataRoomFolderQuery = <
      TData = DataRoomFolderQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: DataRoomFolderQueryVariables, 
      options?: UseQueryOptions<DataRoomFolderQuery, TError, TData>
    ) => 
    useQuery<DataRoomFolderQuery, TError, TData>(
      ['DataRoomFolder', variables],
      fetcher<DataRoomFolderQuery, DataRoomFolderQueryVariables>(client, DataRoomFolderDocument, variables),
      options
    );
export const DeleteCompanyInviteDocument = `
    mutation DeleteCompanyInvite($id: String!) {
  deleteCompanyInvite(id: $id) {
    success
  }
}
    `;
export const useDeleteCompanyInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<DeleteCompanyInviteMutation, TError, DeleteCompanyInviteMutationVariables, TContext>
    ) => 
    useMutation<DeleteCompanyInviteMutation, TError, DeleteCompanyInviteMutationVariables, TContext>(
      (variables?: DeleteCompanyInviteMutationVariables) => fetcher<DeleteCompanyInviteMutation, DeleteCompanyInviteMutationVariables>(client, DeleteCompanyInviteDocument, variables)(),
      options
    );
export const FileDownloadUrlDocument = `
    query FileDownloadUrl($id: String!) {
  fileDownloadUrl(id: $id) {
    url
  }
}
    `;
export const useFileDownloadUrlQuery = <
      TData = FileDownloadUrlQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: FileDownloadUrlQueryVariables, 
      options?: UseQueryOptions<FileDownloadUrlQuery, TError, TData>
    ) => 
    useQuery<FileDownloadUrlQuery, TError, TData>(
      ['FileDownloadUrl', variables],
      fetcher<FileDownloadUrlQuery, FileDownloadUrlQueryVariables>(client, FileDownloadUrlDocument, variables),
      options
    );
export const FinancialsDocument = `
    query Financials {
  company {
    financialStatements {
      pnl {
        id
        latestVersion {
          ...PnlVersionFragment
        }
        versions {
          ...PnlVersionFragment
        }
        questions {
          id
          title
          body
          nodeId
          messages {
            id
            body
            createdBy {
              id
              name
            }
            file {
              id
              name
            }
          }
        }
      }
    }
  }
}
    ${PnlVersionFragmentFragmentDoc}`;
export const useFinancialsQuery = <
      TData = FinancialsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables?: FinancialsQueryVariables, 
      options?: UseQueryOptions<FinancialsQuery, TError, TData>
    ) => 
    useQuery<FinancialsQuery, TError, TData>(
      ['Financials', variables],
      fetcher<FinancialsQuery, FinancialsQueryVariables>(client, FinancialsDocument, variables),
      options
    );
export const LoginDocument = `
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    account {
      ...AccountFragment
    }
    tokens {
      accessToken
      refreshToken
    }
  }
}
    ${AccountFragmentFragmentDoc}`;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>
    ) => 
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables)(),
      options
    );
export const MarkAsAnsweredDocument = `
    mutation MarkAsAnswered($questionId: String!) {
  markQuestionAsAnswered(questionId: $questionId) {
    id
  }
}
    `;
export const useMarkAsAnsweredMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<MarkAsAnsweredMutation, TError, MarkAsAnsweredMutationVariables, TContext>
    ) => 
    useMutation<MarkAsAnsweredMutation, TError, MarkAsAnsweredMutationVariables, TContext>(
      (variables?: MarkAsAnsweredMutationVariables) => fetcher<MarkAsAnsweredMutation, MarkAsAnsweredMutationVariables>(client, MarkAsAnsweredDocument, variables)(),
      options
    );
export const PnlVersionDocument = `
    query PnlVersion($id: String!) {
  pnlVersion(id: $id) {
    ...PnlVersionFragment
  }
}
    ${PnlVersionFragmentFragmentDoc}`;
export const usePnlVersionQuery = <
      TData = PnlVersionQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: PnlVersionQueryVariables, 
      options?: UseQueryOptions<PnlVersionQuery, TError, TData>
    ) => 
    useQuery<PnlVersionQuery, TError, TData>(
      ['PnlVersion', variables],
      fetcher<PnlVersionQuery, PnlVersionQueryVariables>(client, PnlVersionDocument, variables),
      options
    );
export const QuestionDocument = `
    query Question($id: String!) {
  question(id: $id) {
    id
    title
    body
    createdAt
    answeredAt
    createdBy {
      id
      name
      firm {
        id
        name
      }
    }
    messages {
      id
      body
      createdAt
      file {
        id
        fileType
        name
        parentFolders {
          id
        }
      }
      createdBy {
        id
        name
        firm {
          id
          name
        }
      }
      file {
        id
        name
      }
    }
  }
}
    `;
export const useQuestionQuery = <
      TData = QuestionQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: QuestionQueryVariables, 
      options?: UseQueryOptions<QuestionQuery, TError, TData>
    ) => 
    useQuery<QuestionQuery, TError, TData>(
      ['Question', variables],
      fetcher<QuestionQuery, QuestionQueryVariables>(client, QuestionDocument, variables),
      options
    );
export const QuestionsDocument = `
    query Questions($input: QuestionsInput!) {
  questions(input: $input) {
    questions {
      id
      title
      body
      createdAt
      answeredAt
      createdBy {
        id
        name
        firm {
          id
          name
        }
      }
      messages {
        id
        body
        createdAt
        file {
          id
          fileType
          name
          parentFolders {
            id
          }
        }
        createdBy {
          id
          name
          firm {
            id
            name
          }
        }
        file {
          id
          name
        }
      }
    }
  }
}
    `;
export const useQuestionsQuery = <
      TData = QuestionsQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: QuestionsQueryVariables, 
      options?: UseQueryOptions<QuestionsQuery, TError, TData>
    ) => 
    useQuery<QuestionsQuery, TError, TData>(
      ['Questions', variables],
      fetcher<QuestionsQuery, QuestionsQueryVariables>(client, QuestionsDocument, variables),
      options
    );
export const RemoveCompanyAccountDocument = `
    mutation RemoveCompanyAccount($accountID: String!) {
  removeCompanyAccount(accountID: $accountID) {
    success
  }
}
    `;
export const useRemoveCompanyAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<RemoveCompanyAccountMutation, TError, RemoveCompanyAccountMutationVariables, TContext>
    ) => 
    useMutation<RemoveCompanyAccountMutation, TError, RemoveCompanyAccountMutationVariables, TContext>(
      (variables?: RemoveCompanyAccountMutationVariables) => fetcher<RemoveCompanyAccountMutation, RemoveCompanyAccountMutationVariables>(client, RemoveCompanyAccountDocument, variables)(),
      options
    );
export const SearchFilesDocument = `
    query SearchFiles($query: String!) {
  searchFiles(query: $query) {
    id
    name
    fileType
    parentFolders {
      id
      name
    }
  }
}
    `;
export const useSearchFilesQuery = <
      TData = SearchFilesQuery,
      TError = unknown
    >(
      client: GraphQLClient, 
      variables: SearchFilesQueryVariables, 
      options?: UseQueryOptions<SearchFilesQuery, TError, TData>
    ) => 
    useQuery<SearchFilesQuery, TError, TData>(
      ['SearchFiles', variables],
      fetcher<SearchFilesQuery, SearchFilesQueryVariables>(client, SearchFilesDocument, variables),
      options
    );
export const TrackActivityDocument = `
    mutation TrackActivity($type: DataRoomActivityType!, $fileId: String!, $dataRoomId: String!, $folderId: String!) {
  trackActivity(
    type: $type
    fileId: $fileId
    dataRoomId: $dataRoomId
    folderId: $folderId
  ) {
    id
  }
}
    `;
export const useTrackActivityMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<TrackActivityMutation, TError, TrackActivityMutationVariables, TContext>
    ) => 
    useMutation<TrackActivityMutation, TError, TrackActivityMutationVariables, TContext>(
      (variables?: TrackActivityMutationVariables) => fetcher<TrackActivityMutation, TrackActivityMutationVariables>(client, TrackActivityDocument, variables)(),
      options
    );
export const UpdateCompanyAccountDocument = `
    mutation UpdateCompanyAccount($accountID: String!, $accessType: CompanyAccountAccessType, $permissionID: String) {
  updateCompanyAccount(
    accountID: $accountID
    accessType: $accessType
    permissionID: $permissionID
  ) {
    account {
      id
    }
    accessType
    permission {
      id
      name
    }
  }
}
    `;
export const useUpdateCompanyAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateCompanyAccountMutation, TError, UpdateCompanyAccountMutationVariables, TContext>
    ) => 
    useMutation<UpdateCompanyAccountMutation, TError, UpdateCompanyAccountMutationVariables, TContext>(
      (variables?: UpdateCompanyAccountMutationVariables) => fetcher<UpdateCompanyAccountMutation, UpdateCompanyAccountMutationVariables>(client, UpdateCompanyAccountDocument, variables)(),
      options
    );
export const UpdateDataRoomFileDocument = `
    mutation UpdateDataRoomFile($input: UpdateDataRoomFileInput!) {
  updateDataRoomFile(input: $input) {
    id
    name
  }
}
    `;
export const useUpdateDataRoomFileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateDataRoomFileMutation, TError, UpdateDataRoomFileMutationVariables, TContext>
    ) => 
    useMutation<UpdateDataRoomFileMutation, TError, UpdateDataRoomFileMutationVariables, TContext>(
      (variables?: UpdateDataRoomFileMutationVariables) => fetcher<UpdateDataRoomFileMutation, UpdateDataRoomFileMutationVariables>(client, UpdateDataRoomFileDocument, variables)(),
      options
    );
export const UpdateDataRoomFolderDocument = `
    mutation UpdateDataRoomFolder($input: UpdateDataRoomFolderInput!) {
  updateDataRoomFolder(input: $input) {
    id
    name
  }
}
    `;
export const useUpdateDataRoomFolderMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient, 
      options?: UseMutationOptions<UpdateDataRoomFolderMutation, TError, UpdateDataRoomFolderMutationVariables, TContext>
    ) => 
    useMutation<UpdateDataRoomFolderMutation, TError, UpdateDataRoomFolderMutationVariables, TContext>(
      (variables?: UpdateDataRoomFolderMutationVariables) => fetcher<UpdateDataRoomFolderMutation, UpdateDataRoomFolderMutationVariables>(client, UpdateDataRoomFolderDocument, variables)(),
      options
    );
import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "./auth/slice";
import { reducer as demoReducer } from "./demo/slice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["accessToken", "isLoggedIn", "account", "activeCompanyId"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  demo: demoReducer,
});

export default rootReducer;

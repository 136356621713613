import { css } from "./index";
import theme from "./theme";

export interface WithMarginProp {
  margin?: string;
}

interface SizeMap {
  [K: string]: string;
}

const sizes: SizeMap = theme.spacing;

const twSizes: SizeMap = {
  xs: "1",
  s: "2",
  m: "3",
  l: "4",
  xl: "6",
  xxl: "8",
  xxxl: "10",
};

const margins: SizeMap = {
  "0": "m-0",
  xs: "m-1",
  s: "m-2",
  m: "m-3",
  l: "m-4",
  xl: "m-6",
  xxl: "m-8",
  xxxl: "m-10",
};

const marginXs: SizeMap = {
  "0": "mx-0",
  xs: "mx-1",
  s: "mx-2",
  m: "mx-3",
  l: "mx-4",
  xl: "mx-6",
  xxl: "mx-8",
  xxxl: "mx-10",
};

const marginYs: SizeMap = {
  "0": "my-0",
  xs: "my-1",
  s: "my-2",
  m: "my-3",
  l: "my-4",
  xl: "my-6",
  xxl: "my-8",
  xxxl: "my-10",
};

const marginRs: SizeMap = {
  "0": "mr-0",
  xs: "mr-1",
  s: "mr-2",
  m: "mr-3",
  l: "mr-4",
  xl: "mr-6",
  xxl: "mr-8",
  xxxl: "mr-10",
};

const marginLs: SizeMap = {
  "0": "ml-0",
  xs: "ml-1",
  s: "ml-2",
  m: "ml-3",
  l: "ml-4",
  xl: "ml-6",
  xxl: "ml-8",
  xxxl: "ml-10",
};

const marginTops: SizeMap = {
  "0": "mt-0",
  xs: "mt-1",
  s: "mt-2",
  m: "mt-3",
  l: "mt-4",
  xl: "mt-6",
  xxl: "mt-8",
  xxxl: "mt-10",
};

const marginBottoms: SizeMap = {
  "0": "mb-0",
  xs: "mb-1",
  s: "mb-2",
  m: "mb-3",
  l: "mb-4",
  xl: "mb-6",
  xxl: "mb-8",
  xxxl: "mb-10",
};

export function withMarginTw({ margin = "" }: WithMarginProp) {
  const trimmedMargin = margin.trim();

  if (!trimmedMargin) {
    return "";
  }

  const trimmedMargins: string[] = trimmedMargin
    .split(" ")
    .filter(Boolean)
    .map((sizeStr: string) => {
      if (Object.keys(twSizes).includes(sizeStr)) {
        return sizeStr;
      } else if (sizeStr === "0") {
        return "0";
      } else {
        return "";
      }
    });

  switch (trimmedMargins.slice(0, 4).length) {
    case 1:
      const m = `${margins[trimmedMargins[0]]}`;
      return m;
    case 2:
      const m2 = `${marginYs[trimmedMargins[0]]} ${
        marginXs[trimmedMargins[1]]
      }`;
      return m2;
    case 3:
      const m3 = `${marginTops[trimmedMargins[0]]} ${
        marginXs[trimmedMargins[1]]
      } ${marginBottoms[trimmedMargins[2]]}`;
      return m3;
    case 4:
      const m4 = `${marginTops[trimmedMargins[0]]} ${
        marginRs[trimmedMargins[1]]
      } ${marginBottoms[trimmedMargins[2]]} ${marginLs[trimmedMargins[3]]}`;
      return m4;
    default:
      return "m-0";
  }
}

export default function withMargin({ margin = "" }: WithMarginProp) {
  const trimmedMargin = margin.trim();

  if (!trimmedMargin) {
    return "";
  }

  const margins: string[] = trimmedMargin
    .split(" ")
    .filter(Boolean)
    .map((sizeStr: string) => {
      if (Object.keys(sizes).includes(sizeStr)) {
        return sizes[sizeStr];
      } else if (sizeStr === "0") {
        return "0";
      } else {
        return "";
      }
    });

  switch (margins.slice(0, 4).length) {
    case 1:
      return css`
        margin: ${margins[0]};
      `;
    case 2:
      return css`
        margin: ${margins[0]} ${margins[1]};
      `;
    case 3:
      return css`
        margin: ${margins[0]} ${margins[1]} ${margins[2]};
      `;
    case 4:
      return css`
        margin: ${margins[0]} ${margins[1]} ${margins[2]} ${margins[3]};
      `;
    default:
      return css`
        margin: 0;
      `;
  }
}

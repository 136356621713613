import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { useParams } from "react-router-dom";
import { Card2 } from "../../../components/Card";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import Loading, { Loader } from "../../../components/Loading";
import { Text } from "../../../components/Text";
import { MOBILE_NAV_BREAKPOINT } from "../../../config";
import {
  DataRoomActivityType,
  DataRoomFileQuery,
  FileType,
  useDataRoomFileQuery,
  useFileDownloadUrlQuery,
  useQuestionsQuery,
  useTrackActivityMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import styled, { css } from "../../../styles";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Button } from "../../../components/Button";
import { useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export const TableRow = styled.tr`
  width: 100%;
  display: table-row;
  &:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}33;
  }
`;

export const TableLabel = styled((props) => <Text as="th" {...props} />)`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};
  display: flex;
  white-space: pre;
  ${({ theme }) => css`
    padding: 1px ${theme.spacing.xl} 0 0;
  `}
`;

export const TableValue = styled((props) => <Text as="td" {...props} />)`
  display: table-cell;
  padding: 1px 0;
`;

const Wrap = styled.div`
  width: 100%;
`;

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${(p) => p.theme.spacing.xxl};

  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    column-gap: ${(p) => p.theme.spacing.xxl};
  }
`;

export function DataRoomFile() {
  const { id, folderId } = useParams<{ id: string; folderId: string }>();
  const theme = useTheme();
  const client = useGqlClient();
  const track = useTrackActivityMutation(client);

  const questions = useQuestionsQuery(client, {
    input: {
      nodeId: id,
    },
  });

  useEffect(() => {
    track.mutate({
      dataRoomId: "",
      fileId: id,
      folderId,
      type: DataRoomActivityType.FileViewed,
    });
  }, []);

  const dataRoomFileQuery = useDataRoomFileQuery(client, {
    id,
  });

  if (dataRoomFileQuery.isLoading || questions.isLoading) {
    return <Loading />;
  }

  if (dataRoomFileQuery.error || !dataRoomFileQuery.data || !questions.data) {
    return (
      <Wrap>
        <H1>Something went wrong</H1>
      </Wrap>
    );
  }

  return (
    <Wrap>
      <Flex align="center">
        <div>
          <H1 margin="0 0 0 m">{dataRoomFileQuery.data.dataRoomFile.name}</H1>
          <Text margin="0 0 0 m">
            Last updated{" "}
            {formatDistanceToNow(
              fromUnixTime(dataRoomFileQuery.data.dataRoomFile.updatedAt),
              {
                addSuffix: true,
              }
            )}
          </Text>
        </div>
      </Flex>

      <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
        <div>
          <div className="flex justify-between items-center mb-2">
            <H1>File</H1>
            <Button buttonType="quaternary">Download</Button>
          </div>
          <FileView file={dataRoomFileQuery.data.dataRoomFile} />
        </div>
        <div>
          <div className="flex items-center justify-between mb-2">
            <H1>Questions</H1>
            <div>
              <Button buttonType="tertiary">New question</Button>
            </div>
          </div>
          {questions.data.questions.questions.length > 0 &&
            questions.data.questions.questions.map((question) => {
              return (
                <Card2 padding="m">
                  <div className="flex items-center justify-between">
                    <div>
                      <Text weight="semi">
                        {question.createdBy.name}{" "}
                        {question.createdBy.firm
                          ? `from ${question.createdBy.firm.name}`
                          : ``}{" "}
                        {/* {props.question. asked on{" "}
                  <Text isInline colorPreset="link" weight="bold">
                    "Staff" row of Profit & Loss
                  </Text> : null} */}
                      </Text>
                      <Text size="l" weight="bold">
                        {question.title}
                      </Text>
                    </div>
                    <div>
                      {question.answeredAt ? (
                        <div className="flex items-center opacity-80">
                          <p className="ml-1 text-green-500 font-bold">
                            Answered
                          </p>
                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <Text>{question.body}</Text>
                </Card2>
              );
            })}
        </div>
      </div>
    </Wrap>
  );
}

interface FileViewProps {
  file: DataRoomFileQuery["dataRoomFile"];
}

function FileView(props: FileViewProps) {
  return (
    <Card2 padding="s m">
      <FileViewContent file={props.file} />
    </Card2>
  );
}

function FileViewContent(props: FileViewProps) {
  const client = useGqlClient();
  const fileDownloadUrl = useFileDownloadUrlQuery(client, {
    id: props.file.id,
  });

  const window = useWindowSize();

  if (fileDownloadUrl.isLoading) {
    return <Loader />;
  }

  if (fileDownloadUrl.error || !fileDownloadUrl.data) {
    return <Text>Unable to load file</Text>;
  }

  if (props.file.fileType === FileType.Pdf) {
    return (
      <div
        style={{
          flex: 1,
        }}
      >
        {/* <Document file={fileDownloadUrl.data.fileDownloadUrl}>
          <Page
            width={
              window.width && window.width < MOBILE_NAV_BREAKPOINT
                ? window.width - 80
                : 500
            }
            scale={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={1}
          />
        </Document> */}
        <PdfWrap
          data={`${fileDownloadUrl.data.fileDownloadUrl.url}#toolbar=0`}
          type="application/pdf"
          width="100%"
          height="800"
        >
          <p>Failed to load PDF</p>
        </PdfWrap>
      </div>
    );
  }

  return <Text>File content cannot be displayed</Text>;
}

const PdfWrap = styled.object`
  #toolbarViewer {
    display: none !important;
  }
`;

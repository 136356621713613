import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { MOBILE_NAV_BREAKPOINT } from "../config";
import { useColorScheme } from "../hooks/useColorScheme";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";
import { useTheme } from "../hooks/useTheme";
import { useWindowSize } from "../hooks/useWindowSize";
import { authSelectors } from "../store/auth/selector";
import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import CompanyLogo from "./CompanyLogo";
import { Card } from "./Card";
import { Chevron } from "./Chevron";
import { Flex } from "./Flex";
import MobileMenu from "./MobileMenu";
import { Text } from "./Text";
import { actions } from "../store/auth/slice";
import useGqlClient from "../hooks/useGqlClient";
import {
  CompanyAccountAccessType,
  useCompanyQuery,
} from "../graphql/generated";

import {
  ChartBarIcon,
  CircleStackIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  FolderIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { H3 } from "./Heading";
import {
  ArrowLeftOnRectangleIcon,
  BeakerIcon,
  CalculatorIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  Squares2X2Icon,
  TableCellsIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import Logo from "./Logo";
import { useQueryClient } from "react-query";

const MobileClickHandler = styled.div<{
  showMenu: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  ${(p) =>
    p.showMenu
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    display: none;
  }
`;

const Dropdown = styled(Card)`
  position: absolute;
  right: 0;
  width: 290px;
  z-index: 99;
  overflow-y: scroll;
  max-height: 90vh;
  margin-top: ${(p) => p.theme.spacing.m};

  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    left: 100%;
    /* top: ${(p) => p.theme.spacing.xxl}; */
    top: -${(p) => p.theme.spacing.m};
    margin-left: ${(p) => p.theme.spacing.l};
  }
  box-shadow: ${(p) => p.theme.shadow.cardHeavy};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SwitcherWrap = styled.div`
  position: relative;
  user-select: none;
  z-index: 998;
  width: 100%;
  cursor: pointer;
  margin-bottom: ${(p) => p.theme.spacing.m};
`;

const AvatarWrap = styled(Flex)`
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m}
    ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
  border-radius: ${(p) => p.theme.misc.borderRadius};

  &:hover,
  &.isActive {
    color: ${(p) => p.theme.color.secondary};
    background-color: ${(p) => p.theme.color.card.divider}ad;

    svg {
      path {
        fill: ${(p) => p.theme.color.secondary};
      }
    }
  }
`;

const Row = styled.div`
  padding: calc(${(p) => p.theme.spacing.s} + 2px) ${(p) => p.theme.spacing.l};
  color: ${(p) => p.theme.color.typography.secondary};
  margin: 0 ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.misc.borderRadius};

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.isActive {
    background-color: ${(p) => p.theme.color.card.divider}ad;

    p {
      color: ${(p) => p.theme.color.secondary};
    }

    svg {
      path {
        fill: ${(p) => p.theme.color.secondary};
      }
    }
  }

  cursor: pointer;
`;

interface MobileNavProps {
  menuToggle: () => void;
  showMenu: boolean;
}

const MobileHeader = (props: MobileNavProps) => {
  return (
    <Wrap>
      <Left>
        <MobileMenu menuToggle={props.menuToggle} showMenu={props.showMenu} />
      </Left>
    </Wrap>
  );
};

export function Navigation() {
  const window = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const colorScheme = useColorScheme();
  const theme = useTheme();

  const client = useGqlClient();
  const companyQuery = useCompanyQuery(client);
  const activeCompany = useSelector(authSelectors.activeCompany);
  const account = useSelector(authSelectors.account);
  const wrapperRef = useRef(null);
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const history = useHistory();

  useOutsideAlerter(wrapperRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  return (
    <>
      {window.width !== undefined && window.width < MOBILE_NAV_BREAKPOINT && (
        <MobileHeader
          showMenu={showMenu}
          menuToggle={() => setShowMenu((s) => !s)}
        />
      )}
      <Nav
        className="bg-blue-950"
        colorScheme={colorScheme}
        showMenu={showMenu}
      >
        <Flex direction="column" margin="m s 0">
          <div className="ml-2">
            <Logo width={150} height={50} />
          </div>

          <Link margin="xl 0 s 0" to="/home" exact>
            <HomeIcon className="w-5 h-5 text-white" />
            <span className="text-sm">Home</span>
          </Link>

          {/* <Link margin="0 0 s 0" to="/marketplace" exact>
            <ShoppingCartIcon className="w-5 h-5 text-white" />
            <span className="text-sm">Marketplace</span>
          </Link>
 */}
          <H3 mode="dark" margin="xl m m m">
            My deals
          </H3>
          <SwitcherWrap
            ref={wrapperRef}
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            <AvatarWrap justify="space-between" align="center">
              <Flex style={{ gap: theme.spacing.m }} align="center">
                <div style={{ position: "relative" }}>
                  <CompanyLogo
                    withShadow={false}
                    borderSize={0}
                    size={38}
                    name={activeCompany ? activeCompany.name : ""}
                    bgColor={activeCompany ? activeCompany.logoColor : "#000"}
                  />
                </div>
                <Flex direction="column">
                  <p className="font-bold text-white truncate">
                    {activeCompany ? activeCompany.name : ""}
                  </p>
                  <p className="text-white font-semi text-xs">
                    {companyQuery.data &&
                    companyQuery.data.company.activeCompanyAccount
                      .accessType === CompanyAccountAccessType.Buyer
                      ? "Buy side"
                      : "Sell side"}
                  </p>
                </Flex>
              </Flex>
              {/* <AccountSwitcherIcon colorPreset="secondary" /> */}
              <Chevron
                animatetransition="true"
                size="s"
                direction={showDropdown ? undefined : "down"}
              />
            </AvatarWrap>

            {showDropdown ? (
              <Dropdown padding="s 0">
                {account && account.companies
                  ? account.companies
                      .filter(
                        (company) =>
                          activeCompany && company.id !== activeCompany.id
                      )
                      .map((company) => {
                        return (
                          <Row
                            key={company.id}
                            onClick={() => {
                              dispatch(
                                actions.setActiveCompanyId({ id: company.id })
                              );
                              setShowDropdown(false);
                              queryClient.invalidateQueries();
                              queryClient.resetQueries();
                              history.replace("/");
                            }}
                          >
                            <Flex
                              direction="row"
                              justify="space-between"
                              align="center"
                            >
                              <Flex direction="column">
                                <Text margin="0" weight="bold">
                                  {company.name}
                                </Text>
                              </Flex>
                            </Flex>
                          </Row>
                        );
                      })
                  : null}
              </Dropdown>
            ) : null}
          </SwitcherWrap>
          <SellerOnly>
            <div className="overflow-y-scroll max-h-96 2xl:max-h-none no-scrollbar">
              <Link margin="0 0 s 0" to="/s/dashboard" exact>
                <Squares2X2Icon className="w-5 h-5 text-white" />
                <span className="text-sm">Dashboard</span>
              </Link>
              <Link margin="0 0 s 0" to="/s/bidders">
                <UserGroupIcon className="w-5 h-5 text-white" />
                <span className="text-sm">Buyers</span>
              </Link>
              <Link margin="0 0 s 0" to="/s/finances" exact>
                <CurrencyDollarIcon className="w-5 h-5 text-white" />
                <span className="text-sm">Finances</span>
              </Link>
              {location.pathname.startsWith("/s/finances") ? (
                <div style={{ marginLeft: 12 }}>
                  <Link margin="0 0 s 0" to="/s/finances/statements">
                    <TableCellsIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Statements</span>
                  </Link>
                  <Link margin="0 0 s 0" to="/s/finances/playground">
                    <BeakerIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Playground</span>
                  </Link>
                  <Link margin="0 0 s 0" to="/s/finances/quality-of-earnings">
                    <CalculatorIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Quality of Earnings</span>
                  </Link>
                  <Link margin="0 0 s 0" to="/s/finances/valuation">
                    <MapIcon className="w-5 h-5 white" />
                    <span className="text-sm">Valuation</span>
                  </Link>
                </div>
              ) : null}
              <Link margin="0 0 s 0" to="/s/errors">
                <ExclamationTriangleIcon className="w-5 h-5 text-white" />
                <span className="text-sm">Errors</span>
              </Link>

              <Link margin="0 0 s 0" to="/s/questions">
                <div className="flex items-center space-x-3">
                  <QuestionMarkCircleIcon className="w-5 h-5 text-white" />
                  <p className="text-sm ">Questions</p>
                </div>
              </Link>
              <Link margin="0 0 s 0" to="/s/due-diligence">
                <div className="flex items-center space-x-3">
                  <MagnifyingGlassCircleIcon className="w-5 h-5 text-white" />
                  <p className="text-sm ">Due Diligence Checklist</p>
                </div>
              </Link>
              <Link margin="0 0 xs 0" to="/s/dataroom" exact>
                <div className="flex items-center space-x-3">
                  <CircleStackIcon className="h-5 w-5 text-white" />
                  <span className="text-sm">Data Room</span>
                </div>
              </Link>
              {location.pathname.startsWith("/s/dataroom") ? (
                <div style={{ marginLeft: 12 }}>
                  <Link
                    margin="0 0 s 0"
                    to="/s/dataroom/files"
                    className={
                      location.pathname.startsWith("/s/dataroom/files") ||
                      location.pathname.startsWith("/s/dataroom/folder")
                        ? "isActive"
                        : ""
                    }
                  >
                    <FolderIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Files</span>
                  </Link>

                  <Link margin="0 0 s 0" to="/s/dataroom/activity">
                    <ChartBarIcon className="w-5 h-5 text-white" />
                    <span className="text-sm">Activity</span>
                  </Link>
                </div>
              ) : null}
              <Link margin="0 0 s 0" to="/s/access">
                <UserGroupIcon className="w-5 h-5 text-white" />
                <span className="text-sm">Access</span>
              </Link>
            </div>
          </SellerOnly>
          <BuyerOnly>
            <Link margin="0 0 s 0" to="/b/finances" exact>
              <CurrencyDollarIcon className="w-5 h-5 text-gray-600" />
              <span className="text-sm">Finances</span>
            </Link>
            <Link margin="0 0 xs 0" to="/b/dataroom">
              <CircleStackIcon className="h-5 w-5 text-gray-600" />
              <span className="text-sm">Data Room</span>
            </Link>
            <Link margin="0 0 s 0" to="/b/questions">
              <QuestionMarkCircleIcon className="w-5 h-5 text-gray-600" />
              <span className="text-sm">Questions</span>
            </Link>
          </BuyerOnly>
        </Flex>
        <div className="mt-auto mx-4 mb-8">
          <div className="h-px w-auto bg-gray-300"></div>
          {account && account.firm ? (
            <>
              <H3 mode="dark" margin="l 0 m 0">
                Bain Scott & Co
              </H3>
              <Link size="s" margin="0 0 s 0" to="/firm/team">
                <UsersIcon className="w-5 h-5 text-white" />
                <span>Firm</span>
              </Link>
              <Link size="s" margin="0 0 s 0" to="/firm/settings">
                <CogIcon className="w-5 h-5 text-white" />
                <span className="">Settings</span>
              </Link>
              <Link
                size="s"
                margin="0 0 s 0"
                to="/logout"
                onClick={() => {
                  dispatch(actions.logout());
                  history.push("/login");
                }}
              >
                <ArrowLeftOnRectangleIcon className="w-5 h-5 text-white" />
                <span className="">Logout</span>
              </Link>
            </>
          ) : null}
        </div>
      </Nav>
    </>
  );
}

const Nav = styled.div<{
  showMenu: boolean;
  colorScheme: "light" | "dark";
}>`
  width: 100%;
  max-width: 300px;
  display: flex;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 3;
  user-select: none;
  padding-top: ${(p) => p.theme.spacing.s};

  flex-direction: column;

  @media (max-width: ${MOBILE_NAV_BREAKPOINT}px) {
    position: absolute;
    left: 0;
    border-radius: ${(p) => p.theme.misc.borderRadius};
    margin: ${(p) => p.theme.spacing.m};
    height: auto;
    width: 100%;
    box-sizing: border-box;
    border-right: none;
    transition: visibility 0s, opacity 200ms ease-out;
    box-shadow: ${(p) => p.theme.shadow.card};
    ${(p) =>
      p.showMenu
        ? css`
            visibility: visible;
            opacity: 1;
          `
        : css`
            visibility: hidden;
            opacity: 0;
          `}
  }
`;

const Link = styled(NavLink).attrs({
  activeClassName: "isActive",
})<{ size?: "s" } & WithMarginProp>`
  display: flex;
  gap: ${(p) => p.theme.spacing.m};
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.m};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: #fff !important;
  text-decoration: none;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.l};
  line-height: 130%;

  span {
    margin-top: 2px;
    &.notification {
      background-color: ${(p) => p.theme.color.divider};
      color: ${(p) => p.theme.color.typography.text};
    }
  }

  ${(p) =>
    p.size && p.size === "s"
      ? css`
          padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s};
          font-size: ${(p) => p.theme.typography.size.s};
        `
      : ``}

  &:hover,
  &.isActive {
    color: ${(p) => p.theme.color.secondary} !important;

    ${(p) =>
      p.size && p.size === "s"
        ? css``
        : `
          background-color: ${p.theme.color.card.background}ff;
        `}

    svg {
      path {
        fill: ${(p) => p.theme.color.secondary};
      }
    }
  }

  ${withMargin}
`;

const Wrap = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${(p) => p.theme.spacing.l};
  padding-top: ${(p) => p.theme.spacing.l};

  @media (min-width: 1200px) {
    padding-left: ${(p) => p.theme.spacing.xl};
    padding-right: ${(p) => p.theme.spacing.xl};
  }

  grid-template-columns: 1fr 1fr;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

function BuyerOnly(props: { children: React.ReactNode }) {
  const client = useGqlClient();
  const companyQuery = useCompanyQuery(client);

  if (companyQuery.isLoading || companyQuery.error || !companyQuery.data) {
    return null;
  }

  if (
    companyQuery.data.company.activeCompanyAccount.accessType !==
    CompanyAccountAccessType.Buyer
  ) {
    return null;
  }

  return <>{props.children}</>;
}

function SellerOnly(props: { children: React.ReactNode }) {
  const client = useGqlClient();
  const companyQuery = useCompanyQuery(client);

  if (companyQuery.isLoading || companyQuery.error || !companyQuery.data) {
    return null;
  }

  if (
    companyQuery.data.company.activeCompanyAccount.accessType ===
    CompanyAccountAccessType.Buyer
  ) {
    return null;
  }

  return <>{props.children}</>;
}

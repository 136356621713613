import * as React from "react";
import styled from "../styles";
import theme from "../styles/theme";

type colors = typeof theme.color.typography;

const CheckCircleSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18V18ZM13.1704 7.54537C13.3753 7.3332 13.4887 7.04902 13.4861 6.75405C13.4836 6.45908 13.3653 6.17691 13.1567 5.96833C12.9481 5.75975 12.6659 5.64143 12.371 5.63887C12.076 5.6363 11.7918 5.7497 11.5796 5.95463L7.875 9.65925L6.42037 8.20463C6.2082 7.9997 5.92402 7.8863 5.62905 7.88887C5.33408 7.89143 5.05191 8.00975 4.84333 8.21833C4.63475 8.42691 4.51643 8.70908 4.51387 9.00405C4.5113 9.29902 4.6247 9.5832 4.82963 9.79537L7.07963 12.0454C7.29059 12.2563 7.57669 12.3748 7.875 12.3748C8.17331 12.3748 8.45941 12.2563 8.67037 12.0454L13.1704 7.54537V7.54537Z"
      fill="#41D975"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const CheckCircle = styled(CheckCircleSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#41D975"};
  }
`;

import {
  ChevronRightIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  EyeIcon,
  PlusIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { H3 } from "../../components/Heading";
import { Text } from "../../components/Text";
import { Card2 } from "../../components/Card";
import { Link } from "react-router-dom";
import { FileIcon } from "../../components/FileIcon";
import { FileType } from "../../graphql/generated";
import { classNames } from "../../utils/cn";
import { SparklesIcon as SparklesIconSolid } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { animated, useTransition } from "react-spring";

const statusItems = [
  {
    title: "1",
    subtitle: "New question",
    link: "/s/questions",
  },
  {
    title: "1",
    subtitle: "New error",
    link: "/s/errors",
  },
  {
    title: "3",
    subtitle: "New documents",
    link: "/s/dataroom/files",
  },
  {
    title: "2",
    subtitle: "New document requests",
    link: "/s/finances/quality-of-earnings/documents",
  },
];

export const statusColors: { [key: string]: string } = {
  ACTIVE: "bg-green-400",
  ISSUE: "bg-orange-400",
  PENDING: "bg-gray-300",
  GOOD: "bg-green-400",
};

const statusTextColors: { [key: string]: string } = {
  ACTIVE: "text-green-400",
  ISSUE: "text-orange-400",
  PENDING: "text-gray-300",
  GOOD: "text-green-400",
};

const firms = [
  {
    name: "Bain Scott & Co.",
    admin: true,
    side: "Sell side",
    people: [
      {
        name: "Matthew",
        avatar:
          "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
        avatarColor: "bg-rose-400",
      },
      {
        name: "Dan",
        avatar:
          "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
        avatarColor: "bg-indigo-400",
      },
      {
        name: "Rob",
        avatar: undefined,
        avatarColor: "bg-rose-400",
      },
    ],
  },
  {
    name: "Mid-market Law Firm",
    admin: false,
    side: "Buy side",
    people: [
      {
        name: "Henry",
        avatar: undefined,
        avatarColor: "bg-indigo-400",
      },
      {
        name: "John",
        avatar: undefined,
        avatarColor: "bg-orange-400",
      },
    ],
  },
  {
    name: "M&A Lawyers",
    admin: false,
    side: "Buy side",
    people: [
      {
        name: "Charlotte",
        avatar: undefined,
        avatarColor: "bg-emerald-400",
      },
    ],
  },
];

const latestActivity = [
  {
    title: "Dan uploaded a new version of ",
    subTitle: "Fixed 2023 revenue in P&L",
    file: {
      type: "pdf",
      name: "P&L",
    },
    timestamp: "6 hours ago",
    type: "version_update",
  },
  {
    title: "Issue detected",
    subTitle: "There's a discrepancy in the P&L vs. the model for 2023 revenue",
    type: "issue",
    timestamp: "10 hours ago",
  },
  {
    title: "Matt asked a new question on the P&L",
    question: "What's the reason for the drop in cost of sales in 2023?",
    timestamp: "1 day ago",
    type: "question",
  },
];

export type BuyerStage = "NDA" | "LOI" | "HoT" | "DD" | "DS";
export type StageStatus = "GOOD" | "PENDING" | "ISSUE";
export type BuyerStatus = "ACTIVE" | "INACTIVE" | "DEAD";

const stepsKeys = ["NDA", "LOI", "HoT", "DD", "DS"];

export const BuyerStageMap = {
  NDA: "Non-disclosure agreement",
  LOI: "Letter of Intent",
  HoT: "Heads of Terms",
  DD: "Due Diligence",
  DS: "Deal Signed",
};

export const BuyerStageStatusMap = {
  GOOD: "Complete",
  PENDING: "Pending",
  ISSUE: "Issue",
};

export type BuyerPeople = {
  name: string;
  avatar?: string;
  avatarColor: string;
  position: string;
};

export type BuyerQuestion = {
  title: string;
  commentCount: number;
  askedBy: string;
  askedAt: string;
};

export type Buyer = {
  name: string;
  offer: string;
  stage: BuyerStage;
  stageStatus: StageStatus;
  status: BuyerStatus;
  people: BuyerPeople[];
  questions: BuyerQuestion[];
  joined: string;
  activity: [];
  steps: BuyerStep[];
  lastSeen: string;
};

export type BuyerStep = "GOOD" | "ISSUE" | "PENDING";

export const bidders: Buyer[] = [
  {
    name: "Mid-market Law Firm",
    offer: "$1,500,000",
    status: "ACTIVE",
    stage: "DS",
    stageStatus: "ISSUE",
    steps: ["GOOD", "GOOD", "GOOD", "ISSUE", "PENDING"],
    lastSeen: `3 hours ago (Henry)`,
    joined: "3 weeks ago",
    people: [
      {
        name: "Henry",
        avatar: undefined,
        avatarColor: "bg-indigo-400",
        position: "Partner",
      },
      {
        name: "John",
        avatar: undefined,
        avatarColor: "bg-orange-400",
        position: "Associate",
      },
    ],
    questions: [
      {
        title: "Is there a revenue breakdown anywhere?",
        askedAt: "2 hours ago",
        askedBy: "Henry",
        commentCount: 2,
      },
    ],
    activity: [],
  },
  {
    name: "M&A Lawyers",
    offer: "$1,500,000",
    status: "ACTIVE",
    stage: "DS",
    stageStatus: "GOOD",
    steps: ["GOOD", "GOOD", "GOOD", "PENDING", "PENDING"],
    lastSeen: `6 hours ago (Charlotte)`,
    joined: "1 month ago",
    people: [
      {
        name: "Henry",
        avatar: undefined,
        avatarColor: "bg-indigo-400",
        position: "Partner",
      },
      {
        name: "John",
        avatar: undefined,
        avatarColor: "bg-orange-400",
        position: "Associate",
      },
    ],
    questions: [],
    activity: [],
  },
  {
    name: "SMB Law Firm",
    offer: "$1,500,000",
    status: "ACTIVE",
    stage: "DS",
    stageStatus: "ISSUE",
    joined: "2 weeks ago",
    steps: ["GOOD", "GOOD", "ISSUE", "PENDING", "PENDING"],
    lastSeen: `3 days ago (Eric)`,
    people: [
      {
        name: "Henry",
        avatar: undefined,
        avatarColor: "bg-indigo-400",
        position: "Partner",
      },
      {
        name: "John",
        avatar: undefined,
        avatarColor: "bg-orange-400",
        position: "Associate",
      },
    ],
    questions: [],
    activity: [],
  },
  {
    name: "SMB Accountancy Firm",
    offer: "$1,500,000",
    status: "INACTIVE",
    stage: "DS",
    stageStatus: "PENDING",
    joined: "2 weeks ago",
    steps: ["GOOD", "PENDING", "PENDING", "PENDING", "PENDING"],
    lastSeen: `2 weeks ago (Roger)`,
    people: [
      {
        name: "Henry",
        avatar: undefined,
        avatarColor: "bg-indigo-400",
        position: "Partner",
      },
      {
        name: "John",
        avatar: undefined,
        avatarColor: "bg-orange-400",
        position: "Associate",
      },
    ],
    questions: [],
    activity: [],
  },
];

export function Dashboard() {
  const [showAssistant, setShowAssitant] = useState(false);
  return (
    <div className="flex-1">
      <div>
        <div className="bg-white px-8 py-6 w-full shadow-md">
          <div className="flex items-center justify-between">
            <H3>Dashboard</H3>
            <div className="space-x-2">
              <button
                onClick={() => {
                  setShowAssitant(true);
                }}
                className="border p-2 group border-indigo-300 hover:border-indigo-400 hover:shadow rounded-md"
              >
                <div className="flex items-center space-x-2">
                  <SparklesIconSolid className=" w-5 text-indigo-400 group-hover:text-indigo-500 h-5" />
                  <p className="text-indigo-400 group-hover:text-indigo-500 text-sm font-semibold">
                    Assistant
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="p-8">
          <DealProgress />
          <div className="grid grid-cols-10 w-full mt-8 gap-8">
            <div className="col-span-4">
              <H3 margin="0 0 0 0">Overview</H3>
              <p className="leading-none text-sm text-gray-400">
                Since you were last online
              </p>
              <Card2 margin="m 0 0 0">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                  {statusItems.map((item, i) => {
                    return (
                      <Link to={item.link}>
                        <div className="hover:bg-gray-100 p-3 group">
                          <div
                            className={`py-3 flex items-center justify-between`}
                          >
                            <div>
                              <p className="text-2xl text-gray-600 font-bold">
                                {item.title}
                              </p>
                              <div className="flex items-center mt-1">
                                <Text size="xs" colorPreset="secondary">
                                  {item.subtitle}
                                </Text>
                                <ChevronRightIcon className="w-4 h-4 text-gray-400 group-hover:visible invisible" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </Card2>
            </div>
            <div className="col-span-5">
              <div className="flex items-center justify-between">
                <div>
                  <H3 margin="0 0 0 0">Manage people</H3>
                  <p className="leading-none text-sm text-gray-400">
                    See everyone involved in the deal
                  </p>
                </div>
                <button className="flex items-center group p-1 rounded-md">
                  <PlusIcon className="w-4 h-4 text-indigo-500 group-hover:text-indigo-600 mt-0.5" />
                  <p className="text-sm font-bold text-indigo-500 group-hover:text-indigo-600">
                    Add
                  </p>
                </button>
              </div>
              <Card2 margin="m 0 0 0">
                {firms.map((firm) => {
                  return (
                    <div
                      key={firm.name}
                      className="flex items-center py-2 px-3"
                    >
                      <div className="flex-1">
                        <p className="font-semibold text-gray-600">
                          {firm.name}{" "}
                          {firm.admin ? (
                            <span className="text-xs font-semibold text-blue-400">
                              Deal admin
                            </span>
                          ) : null}
                        </p>
                        <p className="text-sm text-gray-400 leading-none">
                          {firm.side}
                        </p>
                      </div>
                      <div>
                        <div className="flex -space-x-0.5">
                          {firm.people.slice(0, 3).map((p) => {
                            if (p.avatar) {
                              return (
                                <img
                                  src={p.avatar}
                                  alt=""
                                  className="relative ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                                />
                              );
                            }

                            return (
                              <div
                                className={`relative w-6 h-6 ring-2 ring-white flex-none ${p.avatarColor} rounded-full items-center justify-center flex`}
                              >
                                <p className="text-sm text-white">
                                  {p.name[0]}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="px-3 py-2  group hover:bg-gray-100 cursor-pointer rounded-b-md">
                  <Link to="/s">
                    <div className="flex items-center">
                      <p className="text-sm font-semibold leading-none text-gray-500">
                        View all
                      </p>
                      <ChevronRightIcon className="w-4 h-4 mt-0.5 text-gray-500" />
                    </div>
                  </Link>
                </div>
              </Card2>
            </div>
          </div>
          <div className="mt-8">
            <H3>Buyers</H3>
            <div className="grid gap-4 grid-cols-4">
              {bidders.map((bidder, i) => {
                return (
                  <Link to="/s/bidder">
                    <div
                      className={`mt-2 ${
                        bidder.status === "INACTIVE" ? "opacity-60" : ""
                      }`}
                    >
                      <Card2 padding="m">
                        <div className="flex justify-between items-center">
                          <Text weight="bold">{bidder.name}</Text>
                          <BuyerStatusIndicator status={bidder.stageStatus} />
                        </div>
                        <div className="mt-2 w-2/3 flex space-x-0.5">
                          {bidder.steps.map((step, i) => {
                            return (
                              <div className="flex-1">
                                <div
                                  title={step}
                                  className={`${statusColors[step]} h-1 flex-1`}
                                ></div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="mt-4 gap-1 grid grid-cols-2">
                          <div>
                            <Text size="xs" weight="bold">
                              Last seen
                            </Text>
                          </div>
                          <p className="text-sm truncate text-gray-500">
                            {bidder.lastSeen}
                          </p>
                          <div>
                            <Text size="xs" weight="bold">
                              Questions
                            </Text>
                          </div>
                          <p className="text-sm text-gray-500">
                            {bidder.questions.length}
                          </p>

                          <div>
                            <Text size="xs" weight="bold">
                              Phase
                            </Text>
                          </div>
                          <p className="text-sm text-gray-500 truncate">
                            Phase 3
                          </p>
                          <div>
                            <Text size="xs" weight="bold">
                              Joined
                            </Text>
                          </div>
                          <p className="text-sm text-gray-500 truncate">
                            {bidder.joined}
                          </p>
                        </div>
                        <div className="mt-4 flex">
                          {/* <div className="px-3 py-1 rounded-2xl bg-orange-300 opacity-90">
                      <p className="text-sm font-bold text-orange-900">
                        New questions
                      </p>
                    </div> */}
                        </div>
                      </Card2>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="mt-8">
            <H3>Recent activity</H3>
            <Activity />
          </div>
        </div>
      </div>
      <SlideOver
        open={showAssistant}
        onClose={() => {
          setShowAssitant(false);
        }}
      />
    </div>
  );
}

const steps = [
  { id: "NDA", name: "4 buyers have signed", href: "#", status: "complete" },
  { id: "LOI", name: "3 buyers have signed", href: "#", status: "complete" },
  {
    id: "Heads of Terms",
    name: "2 buyers have signed",
    href: "#",
    status: "complete",
  },
  {
    id: "Due Diligence",
    name: "2 buyers",
    href: "#",
    status: "blocked",
  },
  {
    id: "Deal Signed",
    name: "2 buyers pending",
    href: "#",
    status: "upcoming",
  },
];

function DealProgress() {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === "complete" ? (
              <a
                href={step.href}
                className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-green-600 group-hover:text-green-800">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  {step.name}
                </span>
              </a>
            ) : step.status === "blocked" ? (
              <a
                href={step.href}
                className="flex flex-col border-l-4 border-orange-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-sm font-medium text-orange-600">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  {step.name}
                </span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="flex flex-col border-l-4 border-green-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-sm font-medium text-green-600">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  {step.name}
                </span>
              </a>
            ) : (
              <a
                href={step.href}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  {step.name}
                </span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

function BuyerStatusIndicator(props: { status: StageStatus }) {
  if (props.status === "GOOD") {
    return (
      <div className="flex-none rounded-full bg-green-400/20 p-1 text-green-400">
        <div className="h-2 w-2 rounded-full bg-current" />
      </div>
    );
  }

  if (props.status === "ISSUE") {
    return (
      <div className="flex-none rounded-full bg-orange-400/20 p-1 text-orange-400">
        <div className="h-2 w-2 rounded-full bg-current" />
      </div>
    );
  }

  return (
    <div className="flex-none rounded-full bg-gray-400/20 p-1 text-gray-400">
      <div className="h-2 w-2 rounded-full bg-current" />
    </div>
  );
}

const activity = [
  {
    id: 1,
    type: "uploaded",
    person: {
      name: "Dan",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    file: {
      name: "Audited Accounts",
      type: FileType.Xlsx,
    },
    date: "1 hour ago",
    dateTime: "2023-01-23T10:32",
  },

  {
    id: 4,
    type: "questioned",
    person: {
      name: "Henry",
      avatarColor: "bg-indigo-400",
    },
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    comment: "Is there a revenue breakdown anywhere?",
    date: "2 hours ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 2,
    type: "viewed",
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    person: { name: "Henry", avatarColor: "bg-indigo-400" },
    date: "1 day ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 4,
    type: "downloaded",
    person: {
      name: "Henry",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    comment: "",
    date: "1 day ago",
    dateTime: "2023-01-23T15:56",
  },
];

function Activity() {
  return (
    <>
      <ul role="list" className="space-y-6 mt-2 w-1/2">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ||
            activityItem.type === "questioned" ? (
              <>
                {activityItem.person.imageUrl ? (
                  <img
                    src={activityItem.person.imageUrl}
                    alt=""
                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  />
                ) : (
                  <div
                    className={`mt-3 relative w-6 h-6 ring-2 ring-white flex-none ${activityItem.person.avatarColor} rounded-full items-center justify-center flex`}
                  >
                    <p className="text-sm text-white">
                      {activityItem.person.name[0]}
                    </p>
                  </div>
                )}

                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      {activityItem.type === "questioned"
                        ? "asked a question"
                        : activityItem.type}
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.comment}
                  </p>
                </div>
              </>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} a new version of
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : activityItem.type === "viewed" ||
              activityItem.type === "downloaded" ? (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <DocumentArrowDownIcon className="w-4 h-4 text-gray-500" />
                  )}
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type}
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            ) : null}
          </li>
        ))}
      </ul>
    </>
  );
}

function SlideOver(props: { onClose: () => void; open: boolean }) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl">
                    <div className="px-4 sm:px-6 shadow pb-4">
                      <div className="flex items-start  justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Assistant
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => props.onClose()}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 ">
                      <Chat />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface Message {
  type: string;
  from: string;
  id: number;
  text: string;
}

function Chat() {
  const [newMessage, setNewMessage] = useState("");

  const [messages, setMessages] = useState<Message[]>([
    {
      from: "ai",
      id: 1,
      text: "Hey, how can I help you today?",
      type: "",
    },
  ]);

  const sendNewMessage = async () => {
    if (!newMessage.trim()) return;
    let id = Math.random() * 1000;

    const msgs = [
      ...messages,
      {
        text: newMessage,
        from: "user",
        id: id,
        type: "user",
      },
    ];
    setMessages(msgs);

    setNewMessage("");

    id = id + 1;

    setTimeout(() => {
      setMessages([
        ...msgs,
        {
          text: "Liquid is thinking...",
          from: "ai",
          type: "ai",
          id: id,
        },
      ]);
    }, 500);

    setTimeout(() => {
      setMessages([
        ...msgs,
        {
          text: "Searching Data Room...",
          from: "ai",
          type: "ai",
          id: id,
        },
      ]);
    }, 2000);

    setTimeout(() => {
      setMessages([
        ...msgs,
        {
          text: "Summarising...",
          from: "ai",
          type: "ai",
          id: id,
        },
      ]);
    }, 4000);

    setTimeout(() => {
      setMessages([
        ...msgs,
        {
          text: "",
          from: "ai",
          type: "report",
          id: id,
        },
      ]);
    }, 6000);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Check if either Ctrl or Cmd is pressed along with Enter
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
      e.preventDefault(); // Prevent default to avoid adding a new line in textarea
      sendNewMessage();
    }
  };

  const transitions = useTransition(messages, {
    from: { transform: "translate3d(0,40px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-40px,0)", opacity: 0 },
    keys: (message) => message.id,
  });

  return (
    <div className="flex h-full flex-col">
      <div className="flex-1 overflow-y-auto px-4">
        {transitions((style, message) => {
          if (message.from === "user") {
            return (
              <animated.div
                style={style}
                key={message.id + message.text}
                className="flex items-center justify-end space-x-2"
              >
                <div className="bg-indigo-50 max-w-xs shadow-sm rounded-md p-2 my-2">
                  <p className="text-sm text-gray-600">{message.text}</p>
                </div>
              </animated.div>
            );
          }

          if (message.type === "report") {
            return (
              <animated.div
                style={style}
                key={message.id + message.text}
                className="flex items-center  space-x-2"
              >
                <SparklesIcon className="w-4 h-4 mt-2 text-indigo-400" />
                <div className="bg-gray-100 w-full shadow-sm rounded-md p-3 my-2">
                  <p className="font-bold text-gray-600">
                    Logistics Investment in America
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    Investment in the logistics industry is down in 2023
                  </p>
                  <div className="px-2 my-4">
                    <div className="bg-white shadow rounded-md p-3">
                      <p className="text-sm text-gray-600 font-semibold">
                        "North America Investment in the US logistics sector
                        fell by nearly 46% y/y in Q2 to US$21.9 billion"
                      </p>
                    </div>
                  </div>
                  <p className="text-sm mt-2 text-gray-600">
                    This is largely due to:
                  </p>
                  <div className="px-6">
                    <ol className="list-disc mt-1 text-gray-500 text-sm">
                      <li>The large increase in interest rates</li>
                      <li>
                        The market returning to normal after heightened activity
                        during Covid
                      </li>
                    </ol>
                  </div>
                  <p className="text-sm text-gray-600">{message.text}</p>
                  <div className="flex mt-4 flex-wrap items-center gap-2">
                    <Link to="/s/dataroom/folder/dtrmfolder_2f3Y7anBgV5gpMyHw8oEKGzuth2/file/dtrmfile_2f3YDPblRZpWBSK0VrcT3hAaRgn">
                      <div className="cursor-pointer bg-white hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                        <FileIcon size="s" fileType={FileType.Pdf} />
                        <p className="text-xs ml-1">Logistics Market Report</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </animated.div>
            );
          }

          return (
            <animated.div
              style={style}
              key={message.id + message.text}
              className="flex items-center space-x-2"
            >
              <SparklesIcon className="w-4 h-4 mt-2 text-indigo-400" />
              <div className="bg-gray-100 shadow-sm rounded-md p-3  my-2">
                <p className="text-sm text-gray-600">{message.text}</p>
              </div>
            </animated.div>
          );
        })}
      </div>

      <div className="p-4 border-t bg-gray-50">
        <div className="relative flex-auto">
          <div className="bg-white overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={2}
              name="comment"
              value={newMessage}
              onKeyDown={handleKeyDown}
              id="comment"
              onChange={(e) => setNewMessage(e.target.value)}
              className="p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Ask a question..."
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              {/* <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button>
              </div> */}
            </div>
            <button
              onClick={() => sendNewMessage()}
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { format, formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { Card2 } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { H1, H3 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import { Text } from "../../components/Text";
import { TextArea } from "../../components/TextArea";
import { MOBILE_NAV_BREAKPOINT } from "../../config";
import {
  DataRoomActivityFragmentFragment,
  FileType,
  useCreateDataFileVersionMutation,
  useDataRoomFileQuery,
  useFileDownloadUrlQuery,
  DataRoomFileQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useTheme } from "../../hooks/useTheme";
import styled, { css } from "../../styles";
import withMargin from "../../styles/withMargin";
import { formatActivityType } from "../../utils/enums";

import { pdfjs } from "react-pdf";
import tw from "tailwind-styled-components";
import { useWindowSize } from "../../hooks/useWindowSize";
import { PlusIcon } from "@heroicons/react/20/solid";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  ${withMargin};
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableData = styled.td`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
`;

const TableHeading = styled.th`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.typography.secondary};
`;

export const TableRow = styled.tr`
  width: 100%;
  display: table-row;
  &:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}33;
  }
`;

export const TableLabel = styled((props) => <Text as="th" {...props} />)`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};
  display: flex;
  white-space: pre;
  ${({ theme }) => css`
    padding: 1px ${theme.spacing.xl} 0 0;
  `}
`;

export const TableValue = styled((props) => <Text as="td" {...props} />)`
  display: table-cell;
  padding: 1px 0;
`;

const Wrap = styled.div`
  width: 100%;
`;

const BigText = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  text-align: center;
`;

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${(p) => p.theme.spacing.xxl};

  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    column-gap: ${(p) => p.theme.spacing.xxl};
  }
`;

const ContentWrap = tw.div`
  p-8
  pt-4
  grid
  sm:grid-cols-1
  lg:grid-cols-2
  gap-4
`;

const StatsGrid = styled.div<{ margin?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${(p) => p.theme.spacing.xxl};

  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    grid-template-columns: 100px 100px 100px;
  }

  ${withMargin};
`;

const FileUploadWrapper = styled.div<{
  margin?: string;
  dragActive: boolean;
  fileSelected: boolean;
}>`
  border: 1px dashed ${(p) => p.theme.color.secondary};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.s};

  ${(p) =>
    p.dragActive &&
    `
    border-color: ${p.theme.color.primary}; 
  `}

  ${(p) =>
    p.fileSelected &&
    `
    border: 1px solid ${p.theme.color.primary};
  `}

  ${withMargin}
`;

export function DataRoomFile() {
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const client = useGqlClient();
  const location = useLocation();

  const [showFileUploader, setShowFileUploader] = useState(false);

  const [showVersionHistory, setShowVersionHistory] = useState(false);

  const dataRoomFileQuery = useDataRoomFileQuery(client, {
    id,
  });

  const fileDownloadUrl = useFileDownloadUrlQuery(
    client,
    {
      id,
    },
    {}
  );

  useEffect(() => {
    if (location.pathname.includes("versions")) {
      setShowVersionHistory(true);
      return;
    }
    setShowVersionHistory(false);
  }, [location.pathname]);

  if (dataRoomFileQuery.isLoading) {
    return <Loading />;
  }

  if (dataRoomFileQuery.error || !dataRoomFileQuery.data) {
    return (
      <Wrap>
        <H1>Something went wrong</H1>
      </Wrap>
    );
  }

  return (
    <Wrap>
      <div className="bg-white px-8 py-6 w-full shadow-md">
        <Flex justify="space-between" align="center">
          <div>
            <H1>{dataRoomFileQuery.data.dataRoomFile.name}</H1>
            <Text margin="0">
              Created by {dataRoomFileQuery.data.dataRoomFile.createdBy.name}
            </Text>
          </div>
          <div>
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              onClick={() => {
                setShowFileUploader(true);
              }}
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" />
              New version
            </button>
          </div>
        </Flex>
      </div>

      <ContentWrap>
        <FileView file={dataRoomFileQuery.data.dataRoomFile} />

        {showVersionHistory ? (
          <>
            <Flex margin="xl 0 0 0" justify="space-between" align="center">
              <H3>Version history</H3>
              <div>
                <Text
                  onClick={() => {
                    setShowVersionHistory(false);
                  }}
                  size="s"
                  margin="0"
                >
                  Hide previous versions
                </Text>
              </div>
            </Flex>

            <Card2 margin="l 0 0 0">
              <Table>
                <TableBody>
                  <tr>
                    <TableHeading style={{ width: "100%" }}>File</TableHeading>
                    <TableHeading style={{ minWidth: 120 }}>
                      Created by
                    </TableHeading>
                    <TableHeading style={{ minWidth: 130 }}>
                      Created
                    </TableHeading>
                    <TableHeading style={{ minWidth: 130 }}>
                      Download
                    </TableHeading>
                  </tr>
                  {dataRoomFileQuery.data.dataRoomFile.versions.map(
                    (version) => {
                      return (
                        <TableRow key={version.id}>
                          <TableData>
                            <Text margin="0" weight="bold">
                              {version.fileName}
                            </Text>
                            <Text size="s" margin="0">
                              {version.summary}
                            </Text>
                          </TableData>
                          <TableData>
                            <Text margin="0">{version.createdBy.name}</Text>
                          </TableData>
                          <TableData>
                            <Text margin="0">
                              {formatDistanceToNowStrict(
                                fromUnixTime(version.createdAt),
                                {
                                  addSuffix: true,
                                }
                              )}
                            </Text>
                          </TableData>
                          <TableData>
                            <div>
                              <Button
                                onClick={() => {
                                  fileDownloadUrl.refetch().then((res) => {
                                    if (res.data) {
                                      const link = document.createElement("a");
                                      link.href = res.data.fileDownloadUrl.url;
                                      link.download =
                                        dataRoomFileQuery.data.dataRoomFile.versions[0].fileName;
                                      link.target = "_blank";
                                      link.rel = "noreferrer";
                                      document.body.appendChild(link);
                                      link.click();
                                      link.parentNode?.removeChild(link);
                                    }
                                  });
                                }}
                                buttonType="quaternary"
                              >
                                Download
                              </Button>
                            </div>
                          </TableData>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Card2>
          </>
        ) : (
          <div className="mt-6">
            <H3>Stats</H3>
            <Card2 margin="s 0 s 0" padding="m">
              <div>
                <StatsGrid margin="s 0 0 0">
                  <Flex direction="column">
                    <BigText>
                      {dataRoomFileQuery.data.dataRoomFile.stats.views}
                    </BigText>
                    <Text align="center" margin="0">
                      Views
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <BigText>
                      {dataRoomFileQuery.data.dataRoomFile.stats.downloads}
                    </BigText>
                    <Text margin="0" align="center">
                      Downloads
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <BigText>
                      {dataRoomFileQuery.data.dataRoomFile.versions.length}
                    </BigText>
                    <Text margin="0" align="center">
                      Versions
                    </Text>
                  </Flex>
                </StatsGrid>
              </div>
            </Card2>
            <H3 margin="xl 0 0 0">Versions</H3>
            <Card2 margin="s 0 0 0" padding="m">
              <div>
                <Flex margin="0 0 0 0" justify="space-between" align="center">
                  <div>
                    <Text margin="0" colorPreset="success" size="xs">
                      Live version
                    </Text>
                    <Text
                      margin="0"
                      weight="bold"
                      colorPreset="secondaryHeading"
                    >
                      {dataRoomFileQuery.data.dataRoomFile.versions[0].fileName}
                    </Text>
                    <Text size="xs" margin="0">
                      {dataRoomFileQuery.data.dataRoomFile.versions[0].summary}
                    </Text>
                  </div>
                  <Flex>
                    <div>
                      <Button
                        onClick={() => {
                          fileDownloadUrl.refetch().then((res) => {
                            if (res.data) {
                              const link = document.createElement("a");
                              link.href = res.data.fileDownloadUrl.url;
                              link.download =
                                dataRoomFileQuery.data.dataRoomFile.versions[0].fileName;
                              link.target = "_blank";
                              link.rel = "noreferrer";
                              document.body.appendChild(link);
                              link.click();
                              link.parentNode?.removeChild(link);
                            }
                          });
                        }}
                        buttonType="quaternary"
                      >
                        Download
                      </Button>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Card2>
            <H3 margin="xl 0 s 0">Activity</H3>
            <Card2 padding="m">
              {dataRoomFileQuery.data.dataRoomFile.activity.length > 0 ? (
                <Activity
                  activity={dataRoomFileQuery.data.dataRoomFile.activity}
                />
              ) : (
                <Text>No recent activity</Text>
              )}
            </Card2>
          </div>
        )}
      </ContentWrap>

      {showFileUploader ? (
        <FileUploader
          fileName={dataRoomFileQuery.data.dataRoomFile.name}
          dataRoomFileId={dataRoomFileQuery.data.dataRoomFile.id}
          onClose={() => {
            dataRoomFileQuery.refetch();
            setShowFileUploader(false);
          }}
        />
      ) : null}
    </Wrap>
  );
}

interface FileUploaderProps {
  fileName: string;
  dataRoomFileId: string;
  onClose: () => void;
}

function FileUploader(props: FileUploaderProps) {
  const [dragActive, setDragActive] = useState(false);
  const [summary, setSummary] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const client = useGqlClient();
  const createDataFileVersion = useCreateDataFileVersionMutation(client);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length > 1) {
        setError("You can only upload one file at a time");
        return;
      }

      setFile(e.dataTransfer.files[0]);
    }
  };

  return (
    <Modal
      onCancel={props.onClose}
      onDragEnter={(e) => {
        handleDrag(e);
      }}
      onDragLeave={() => {
        setDragActive(false);
      }}
    >
      <H3>New version of {props.fileName}</H3>

      {file ? (
        <FileUploadWrapper
          dragActive={false}
          fileSelected={file !== null}
          margin="m 0 0 0"
        >
          <Text weight="bold" margin="0">
            {file.name}
          </Text>
        </FileUploadWrapper>
      ) : (
        <FileUploadWrapper
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          dragActive={dragActive}
          fileSelected={file !== null}
          margin="m 0 0 0"
        >
          <Text margin="0" align="center">
            Drop file here or
          </Text>
          <Flex justify="center">
            <Button
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
              buttonType="quaternary"
              size="s"
            >
              Upload
            </Button>
            <input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  if (e.target.files.length > 1) {
                    setError("You can only upload one file at a time");
                    return;
                  }

                  setFile(e.target.files[0]);
                }
              }}
              ref={inputRef}
              type="file"
              style={{ display: "none" }}
            />
          </Flex>
        </FileUploadWrapper>
      )}
      <TextArea
        characterLimit={200}
        characterLength={summary.length}
        label="Summary of changes"
        rows={3}
        value={summary}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setSummary(e.currentTarget.value);
        }}
        margin="m 0 0 0"
        placeholder="e.g. Updated forecasts"
      />

      {error ? <Alert margin="m 0 0 0" type="error" text={error} /> : null}
      <Button
        onClick={() => {
          if (!file || !summary) {
            setError("Please select a file and enter a summary of the changes");
            return;
          }

          createDataFileVersion.mutate(
            {
              dataRoomFileID: props.dataRoomFileId,
              fileName: file.name,
              fileType: file.type,
              summary,
            },
            {
              onSuccess: (data) => {
                fetch(data.createDataRoomFileVersion.url, {
                  method: "PUT",
                  body: file,
                })
                  .catch((e) => {
                    setError("Error uploading file");
                  })
                  .then((res) => {
                    // redirect
                    // close modal
                    // refresh files
                    props.onClose();
                  });
              },
            }
          );
        }}
        isDisabled={!summary || !file}
        margin="xl 0 0 0"
      >
        {createDataFileVersion.isLoading
          ? "Uploading..."
          : "Create new version"}
      </Button>
    </Modal>
  );
}

interface ActivityProps {
  activity: DataRoomActivityFragmentFragment[];
}

function Activity(props: ActivityProps) {
  const grouped: { [key: string]: DataRoomActivityFragmentFragment[] } = {};

  props.activity.forEach((activity) => {
    const key = format(fromUnixTime(activity.createdAt), "EEEE do MMMM ");

    if (grouped[key]) {
      grouped[key].push(activity);
    } else {
      grouped[key] = [activity];
    }
  });

  return (
    <div>
      {Object.keys(grouped).map((key) => {
        return (
          <div key={key}>
            <Text size="l" isInline weight="bold" margin="l 0 0 0">
              {key}
            </Text>

            {grouped[key].map((activity) => {
              return <ActivityItem key={activity.id} activity={activity} />;
            })}
          </div>
        );
      })}
    </div>
  );
}

interface ActivityItemProps {
  activity: DataRoomActivityFragmentFragment;
}

function ActivityItem(props: ActivityItemProps) {
  return (
    <div key={props.activity.id}>
      <Text margin="0">
        <Text isInline size="xs" margin="0 s 0 0" colorPreset="secondary">
          {format(fromUnixTime(props.activity.createdAt), "HH:mm")}
        </Text>
        {formatActivityType(props.activity.type)} by{" "}
        {props.activity.account.name}
      </Text>
    </div>
  );
}

interface FileViewProps {
  file: DataRoomFileQuery["dataRoomFile"];
}

function FileView(props: FileViewProps) {
  return (
    <Card2 padding="s m" margin="xl 0 0 0">
      <FileViewContentMemo file={props.file} />
    </Card2>
  );
}

const FileViewContentMemo = React.memo(
  ({ file }: FileViewProps) => {
    return <FileViewContent file={file} />;
  },
  (prevProps, nextProps) => {
    return prevProps.file.id !== nextProps.file.id;
  }
);

function FileViewContent(props: FileViewProps) {
  const client = useGqlClient();
  const fileDownloadUrl = useFileDownloadUrlQuery(client, {
    id: props.file.id,
  });

  const memoizedPdfUrl = useMemo(
    () => fileDownloadUrl.data?.fileDownloadUrl.url,
    [fileDownloadUrl.data, fileDownloadUrl.data?.fileDownloadUrl.url]
  );

  const window = useWindowSize();

  if (fileDownloadUrl.isLoading) {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  if (fileDownloadUrl.error || !fileDownloadUrl.data || !memoizedPdfUrl) {
    return <Text>Unable to load file</Text>;
  }

  if (props.file.fileType === FileType.Pdf) {
    return (
      <div
        style={{
          flex: 1,
        }}
      >
        {/* <Document file={fileDownloadUrl.data.fileDownloadUrl}>
          <Page
            width={
              window.width && window.width < MOBILE_NAV_BREAKPOINT
                ? window.width - 80
                : 500
            }
            scale={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={1}
          />
        </Document> */}
        <PdfWrap
          data={`${memoizedPdfUrl}#toolbar=0`}
          type="application/pdf"
          width="100%"
          height="800"
        >
          <p>Failed to load PDF</p>
        </PdfWrap>
      </div>
    );
  }

  return (
    <DocViewer
      prefetchMethod="GET"
      style={{ height: 800, width: "100%" }}
      documents={[
        {
          uri: memoizedPdfUrl,
        },
      ]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}

const PdfWrap = styled.object`
  #toolbarViewer {
    display: none !important;
  }
`;

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Card2 } from "../../../components/Card";
import { H3 } from "../../../components/Heading";
import { FileType } from "../../../graphql/generated";
import { FileIcon } from "../../../components/FileIcon";

const css = [
  {
    name: "Final Audit and Financial Statements",
    deadline: "31 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: false,
    file: undefined,
  },
  {
    name: "Post closing adjustments",
    deadline: "31 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: false,
    file: undefined,
  },
  {
    name: "Regulatory Approvals Confirmation",
    deadline: "31 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: false,
    file: undefined,
  },
  {
    name: "Integration Plan Execution",
    deadline: "31 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: false,
  },
];

const cps = [
  {
    name: "Tax filing and receipts",
    deadline: "5 Mar",
    overdue: true,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: false,
  },
  {
    name: "Insurance coverage details",
    deadline: "15 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: false,
  },
  {
    name: "Market Analysis reports",
    deadline: "18 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: false,
  },
  {
    name: "Corporate structure chart",
    deadline: "20 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: false,
  },
  {
    name: "Material contracts review",
    deadline: "22 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: false,
  },
  {
    name: "Audit Reports",
    deadline: "31 Mar",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: true,
    file: {
      fileType: FileType.Docx,
      fileName: "Audit Report",
    },
  },
  {
    name: "Compliance Certificates",
    deadline: "1 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: true,
    file: {
      fileType: FileType.Other,
      fileName: "Compliance Certificates",
    },
  },
  {
    name: "Title and ownership documents",
    deadline: "2 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
      name: "Dan",
    },
    complete: true,
    file: {
      fileType: FileType.Docx,
      fileName: "Title & ownership",
    },
  },
  {
    name: "Debt Schedules",
    deadline: "3 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: true,
    file: {
      fileType: FileType.Other,
      fileName: "Debt schedule",
    },
  },
  {
    name: "Revenue and sales proof",
    deadline: "4 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: true,
    file: {
      fileType: FileType.Pdf,
      fileName: "Revenue + sales proof",
    },
  },
  {
    name: "Employment Agreements",
    deadline: "7 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: true,
    file: {
      fileType: FileType.Pdf,
      fileName: "Employment agreements",
    },
  },
  {
    name: "Client consents",
    deadline: "14 Apr",
    overdue: false,
    assignedTo: {
      avatar:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
      name: "Matt",
    },
    complete: true,
    file: {
      fileType: FileType.Docx,
      fileName: "Client consents",
    },
  },
];

const headings = [
  "#",
  "Task",
  "Assigned to",
  "Deadline",
  "Approved by buyer",
  "File",
];

export function DueDiligence() {
  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 py-6 w-full shadow-md z-50">
        <div className="flex items-center justify-between">
          <H3>Due Diligence</H3>
        </div>
      </div>
      <div className="p-8">
        <div className="grid grid-cols-1 xl:grid-cols-3">
          <div className="col-span-2">
            <div>
              <div className="flex justify-between items-center">
                <p className="text-gray-700 font-bold">Condition Precedents</p>
                <div className="items-center flex">
                  <div className="w-48 mt-0.5 bg-orange-400/20 rounded-md h-2">
                    <div className="w-7/12 bg-orange-400 rounded-md h-2" />
                  </div>
                  <p className="text-sm text-gray-500 ml-1 leading-none">
                    7/12
                  </p>
                </div>
              </div>
              <Card2 margin="m 0 0 0">
                <div className="p-2 px-1">
                  <table>
                    <thead>
                      {headings.map((h, i) => {
                        return (
                          <th
                            className={`text-sm truncate text-gray-500 px-4 pb-2 text-left ${
                              i === 1 ? "w-full" : ""
                            }`}
                          >
                            {h}
                          </th>
                        );
                      })}
                    </thead>
                    <tbody>
                      {cps.map((cp, i) => {
                        return (
                          <tr key={i}>
                            <td className="px-4 py-2 text-sm font-bold text-gray-400">
                              {i + 1}
                            </td>
                            <td className="px-4 py-2">
                              <p className="text-gray-600 text-sm">{cp.name}</p>
                            </td>
                            <td className="px-4 py-2">
                              <div className="flex items-center">
                                <img
                                  src={cp.assignedTo.avatar}
                                  className="w-5 h-5 rounded-full"
                                />
                                <p className="text-gray-500 ml-2 text-sm">
                                  {cp.assignedTo.name}
                                </p>
                              </div>
                            </td>
                            <td
                              className={`px-4 py-2 ${
                                cp.overdue ? "text-orange-600" : "text-gray-500"
                              } text-sm`}
                            >
                              {cp.deadline}
                            </td>
                            <td className="px-4 py-2">
                              <div>
                                <CheckCircleIcon
                                  className={`w-4 h-4 ${
                                    cp.complete
                                      ? "text-green-400"
                                      : "text-gray-300"
                                  }`}
                                />
                              </div>
                            </td>
                            <td className="px-4 py-2">
                              <>
                                {cp.file ? (
                                  <div className="flex  flex-wrap items-center gap-2">
                                    <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                                      <FileIcon
                                        size="s"
                                        fileType={cp.file.fileType}
                                      />
                                      <p className="text-xs ml-1 truncate">
                                        {cp.file.fileName}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <button className="text-indigo-500 font-bold hover:text-indigo-600 text-sm">
                                      Add
                                    </button>
                                  </>
                                )}
                              </>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card2>
            </div>
            <div className="mt-8">
              <div className="flex justify-between items-center">
                <p className="text-gray-700 font-bold">Condition Subsequents</p>
                <div className="items-center flex">
                  <div className="w-48 mt-0.5 bg-green-400/20 rounded-md h-2">
                    <div className="w-1/12 bg-green-400 rounded-md h-2" />
                  </div>
                  <p className="text-sm text-gray-500 ml-1 leading-none">0/4</p>
                </div>
              </div>
              <Card2 margin="m 0 0 0">
                <div className="p-2 px-1">
                  <table>
                    <thead>
                      {headings.map((h, i) => {
                        return (
                          <th
                            className={`text-sm truncate text-gray-500 px-4 pb-2 text-left ${
                              i === 1 ? "w-full" : ""
                            }`}
                          >
                            {h}
                          </th>
                        );
                      })}
                    </thead>
                    <tbody>
                      {css.map((cp, i) => {
                        return (
                          <tr key={i}>
                            <td className="px-4 py-2 text-sm font-bold text-gray-400">
                              {i + 1}
                            </td>
                            <td className="px-4 py-2">
                              <p className="text-gray-600 text-sm">{cp.name}</p>
                            </td>
                            <td className="px-4 py-2">
                              <div className="flex items-center">
                                <img
                                  src={cp.assignedTo.avatar}
                                  className="w-5 h-5 rounded-full"
                                />
                                <p className="text-gray-500 ml-2 text-sm">
                                  {cp.assignedTo.name}
                                </p>
                              </div>
                            </td>
                            <td className="px-4 py-2 text-gray-500 text-sm">
                              {cp.deadline}
                            </td>
                            <td className="px-4 py-2">
                              <div>
                                <CheckCircleIcon
                                  className={`w-4 h-4 ${
                                    cp.complete
                                      ? "text-green-400"
                                      : "text-gray-300"
                                  }`}
                                />
                              </div>
                            </td>
                            <td className="px-4 py-2">
                              <>
                                <>
                                  <button className="text-indigo-500 font-bold hover:text-indigo-600 text-sm">
                                    Add
                                  </button>
                                </>
                              </>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import { useTheme } from "../hooks/useTheme";
import styled from "../styles";

const LoadingWrap = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export default function Loading() {
  return (
    <LoadingWrap>
      <Loader style={{ width: 100, height: 100 }} />
    </LoadingWrap>
  );
}

export function Loader(
  props: React.SVGProps<SVGSVGElement> & { fill?: string }
) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 100 100"
      {...props}
    >
      <circle cx={6} cy={50} r={6} fill={theme.color.typography.heading}>
        <animate
          attributeName="opacity"
          begin={0.1}
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
      <circle cx={26} cy={50} r={6} fill={theme.color.typography.heading}>
        <animate
          attributeName="opacity"
          begin={0.2}
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
      <circle cx={46} cy={50} r={6} fill={theme.color.typography.heading}>
        <animate
          attributeName="opacity"
          begin={0.3}
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
    </svg>
  );
}

import { H3, H4 } from "../../components/Heading";
import useGqlClient from "../../hooks/useGqlClient";
import styled, { css } from "../../styles";
import {
  CompanyAccount,
  CompanyAccountAccessType,
  CompanyInvite,
  DataRoomPermission,
  useCreateCompanyInviteMutation,
  useDataRoomQuery,
  useDeleteCompanyInviteMutation,
  useRemoveCompanyAccountMutation,
  useUpdateCompanyAccountMutation,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { Text } from "../../components/Text";
import { Card2 } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { Dropdown } from "../../components/Dropdown";
import { useState } from "react";
import withMargin from "../../styles/withMargin";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

const Wrap = styled.div`
  flex: 1;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  ${withMargin};
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableData = styled.td`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
`;

const TableHeading = styled.th`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.typography.secondary};
`;

export const TableRow = styled.tr`
  width: 100%;
  display: table-row;
`;

export const TableLabel = styled((props) => <Text as="th" {...props} />)`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};
  display: flex;
  white-space: pre;
  ${({ theme }) => css`
    padding: 1px ${theme.spacing.xl} 0 0;
  `}
`;

export const TableValue = styled((props) => <Text as="td" {...props} />)`
  display: table-cell;
  padding: 1px 0;
`;

const bainScott = [
  {
    name: "Daniel Scott",
    email: "daniel.scott@bainscott.co.uk",
    role: "Admin",
    phase: "Phase 1",
    avatar:
      "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
  },
  {
    name: "Matthew Bain",
    email: "matthew.bain@bainscott.co.uk",
    role: "Admin",
    phase: "Phase 1",
    avatar:
      "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg",
  },
];

const firms = [
  {
    name: "Mid-market Law Firm",
    phase: "Phase 3",
    side: "Buy side",
    people: [
      {
        name: "Henry Smith",
        email: "henry@lawfirm.com",
        position: "Partner",
        avatarColor: "bg-indigo-400",
        phase: "Phase 1",
      },
    ],
  },
];

export function Access() {
  const client = useGqlClient();
  const dataRoomQuery = useDataRoomQuery(client);
  const [showCreateInvite, setShowCreateInvite] = useState(false);

  const [showEditCompanyAccount, setShowEditCompanyAccount] =
    useState<CompanyAccount | null>(null);

  const [showDeleteInvite, setShowDeleteInvite] =
    useState<CompanyInvite | null>(null);

  const [showRemoveCompanyAccount, setShowRemoveCompanyAccount] =
    useState<CompanyAccount | null>(null);

  if (dataRoomQuery.isLoading || !dataRoomQuery.data) {
    return <Loading />;
  }

  if (dataRoomQuery.error) {
    return <div>Something went wrong</div>;
  }

  return (
    <Wrap>
      <div className="bg-white px-8 py-6 w-full shadow-md">
        <H3>Access</H3>
        <Text size="s" colorPreset="secondary">
          Admin and staff can create new files, upload new versions and remove
          files from the data room. Buyers can only see live versions of files.
        </Text>
      </div>
      <div className="p-8">
        <div className="grid grid-cols-1 xl:grid-cols-2">
          <div>
            <H3 margin="l 0 0 0">Buy side</H3>

            <Card2 margin="m 0 0 0">
              <div className="p-3">
                <H3>Bain Scott & Co.</H3>
                <p className="leading-none text-sm text-gray-500 font-normal">
                  Deal Admin
                </p>
              </div>
              <div className="w-full h-px bg-gray-200" />
              <div className="mt-2">
                <Table>
                  <TableBody>
                    <tr>
                      <th style={{ width: "100%" }}>
                        <p className="px-3 font-semibold text-gray-600">Name</p>
                      </th>
                      <th className="truncate">
                        <p className="px-3 font-semibold text-gray-600">
                          Phase
                        </p>
                      </th>
                      <th>
                        <p className="px-3 font-semibold text-gray-600">Role</p>
                      </th>
                      <th className="w-5"></th>
                    </tr>

                    {bainScott.map((ca, i) => {
                      return (
                        <TableRow key={i} className="group">
                          <TableData>
                            <div className="flex items-center gap-x-3">
                              {ca.avatar ? (
                                <img
                                  className="w-7 h-7 rounded-full"
                                  src={ca.avatar}
                                />
                              ) : null}
                              <div>
                                <Text margin="0" weight="bold" size="m">
                                  {ca.name}
                                  <Text
                                    margin="0"
                                    weight="semi"
                                    size="s"
                                    colorPreset="secondary"
                                  >
                                    {ca.email}
                                  </Text>
                                </Text>
                              </div>
                            </div>
                          </TableData>

                          <TableData>
                            <p className="truncate text-sm text-gray-500">
                              {ca.phase}
                            </p>
                          </TableData>
                          <TableData>
                            <p className="truncate text-sm text-gray-500">
                              {ca.role}
                            </p>
                          </TableData>
                          <TableData>
                            <Menu as="div" className="relative flex-none">
                              <Menu.Button
                                onClick={(
                                  e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                  e.stopPropagation();
                                }}
                                className=" -m-2.5 block p-2.5 text-gray-400 hover:text-gray-900"
                              >
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon
                                  className="h-5 w-5 "
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </Menu>
                          </TableData>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Card2>
          </div>
        </div>

        <H3 margin="xl 0 0 0">Sell side</H3>
        <div className="grid grid-cols-1 xl:grid-cols-2 mt-2">
          {firms.map((firm) => {
            return (
              <div>
                <Card2>
                  <div className="p-3">
                    <H3>{firm.name}</H3>
                    <p className="leading-none text-sm text-gray-500 font-normal">
                      Phase 1
                    </p>
                  </div>
                  <div className="w-full h-px bg-gray-200"></div>
                  <div className="mt-2">
                    <Table>
                      <TableBody>
                        <tr>
                          <th style={{ width: "100%" }}>
                            <p className="px-3 font-semibold text-gray-600">
                              Name
                            </p>
                          </th>
                          <th className="truncate">
                            <p className="px-3 font-semibold text-gray-600">
                              Phase
                            </p>
                          </th>
                          <th className="w-5"></th>
                        </tr>

                        {firm.people.map((ca, i) => {
                          return (
                            <TableRow key={i} className="group">
                              <TableData>
                                <div className="flex items-center gap-x-3">
                                  <div
                                    className={`relative w-6 h-6 ring-2 ring-white flex-none ${ca.avatarColor} rounded-full items-center justify-center flex`}
                                  >
                                    <p className="text-sm text-white">
                                      {ca.name[0]}
                                    </p>
                                  </div>
                                  <div>
                                    <Text margin="0" weight="bold" size="m">
                                      {ca.name}
                                      <Text
                                        margin="0"
                                        weight="semi"
                                        size="s"
                                        colorPreset="secondary"
                                      >
                                        {ca.email}
                                      </Text>
                                    </Text>
                                  </div>
                                </div>
                              </TableData>
                              <TableData>
                                <p className="truncate text-sm text-gray-500">
                                  {ca.phase}
                                </p>
                              </TableData>
                              <TableData>
                                <Menu as="div" className="relative flex-none">
                                  <Menu.Button
                                    onClick={(
                                      e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                      e.stopPropagation();
                                    }}
                                    className=" -m-2.5 block p-2.5 text-gray-400 hover:text-gray-900"
                                  >
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <EllipsisVerticalIcon
                                      className="h-5 w-5 "
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </Menu>
                              </TableData>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </Card2>
              </div>
            );
          })}
        </div>

        <div className="w-full xl:w-1/2">
          <Flex
            margin="xxl 0 s 0"
            direction="row"
            justify="space-between"
            align="center"
          >
            <H3>Invites</H3>
            <div>
              <Button
                buttonType="quaternary"
                onClick={() => {
                  setShowCreateInvite(true);
                }}
              >
                Create invite
              </Button>
            </div>
          </Flex>
          <Card2>
            <Table>
              <TableBody>
                <tr>
                  <TableHeading style={{ width: "100%" }}>Name</TableHeading>
                  <TableHeading>Phase</TableHeading>
                  <TableHeading>Type</TableHeading>
                </tr>
                <TableRow>
                  <TableData>
                    <Text margin="0" weight="bold" size="m">
                      Adam Townsend
                    </Text>
                    <Text
                      margin="0"
                      weight="semi"
                      size="s"
                      colorPreset="secondary"
                    >
                      adam@norfolklegal.co.uk
                    </Text>
                  </TableData>
                  <TableData>
                    <p className="truncate text-sm text-gray-500">Phase 1</p>
                  </TableData>
                  <TableData>
                    <p className="truncate text-sm text-gray-500">Sell side</p>
                  </TableData>
                  <TableData>
                    <Button
                      onClick={() => {
                        // setShowDeleteInvite(invite as CompanyInvite);
                      }}
                      variant="destructive"
                      buttonType="quaternary"
                    >
                      Delete
                    </Button>
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>
            {dataRoomQuery.data.company.invites.length === 0 ? (
              <Text
                align="center"
                margin="m 0 m"
                weight="semi"
                size="m"
                colorPreset="secondary"
              >
                No invites
              </Text>
            ) : null}
          </Card2>
          {showCreateInvite ? (
            <CreateInvite
              onClose={() => {
                setShowCreateInvite(false);
                dataRoomQuery.refetch();
              }}
              permissions={dataRoomQuery.data.company.dataRoom.permissions}
            />
          ) : null}
          {showDeleteInvite ? (
            <DeleteCompanyInvite
              invite={showDeleteInvite}
              onClose={() => {
                setShowDeleteInvite(null);
                dataRoomQuery.refetch();
              }}
            />
          ) : null}
          {showEditCompanyAccount ? (
            <EditCompanyAccount
              permissions={dataRoomQuery.data.company.dataRoom.permissions}
              companyAccount={showEditCompanyAccount}
              onClose={() => {
                setShowEditCompanyAccount(null);
                dataRoomQuery.refetch();
              }}
            />
          ) : null}
          {showRemoveCompanyAccount ? (
            <RemoveCompanyAccount
              companyAccount={showRemoveCompanyAccount}
              onClose={() => {
                setShowRemoveCompanyAccount(null);
              }}
            />
          ) : null}
        </div>
      </div>
    </Wrap>
  );
}

interface CreateInviteProps {
  onClose: () => void;
  permissions: DataRoomPermission[];
}

const accessTypesMap = {
  [CompanyAccountAccessType.Admin]: "Admin",
  [CompanyAccountAccessType.Buyer]: "Buyer",
  [CompanyAccountAccessType.Seller]: "Seller",
  [CompanyAccountAccessType.Staff]: "Staff",
  [CompanyAccountAccessType.Guest]: "Guest",
};

function CreateInvite(props: CreateInviteProps) {
  const [dataRoomPermissionId, setDataRoomPermissionId] = useState(
    props.permissions[0].id
  );

  const [type, setType] = useState<
    CompanyAccountAccessType.Buyer | CompanyAccountAccessType.Seller
  >(CompanyAccountAccessType.Buyer);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const client = useGqlClient();
  const createCompanyInvite = useCreateCompanyInviteMutation(client);

  return (
    <Modal
      onCancel={() => {
        props.onClose();
      }}
    >
      <H4 margin="0 0 xl 0">Create invite</H4>
      <TextInput
        onChange={(e) => {
          setName(e.currentTarget.value);
        }}
        value={name}
        label="Name"
        placeholder="Name"
      />
      <TextInput
        margin="m 0 0 0"
        onChange={(e) => {
          setEmail(e.currentTarget.value);
        }}
        value={email}
        label="Email"
        placeholder="Email address"
      />
      <Text margin="m 0 xs 0" size="s" weight="bold">
        Phase access
      </Text>
      <Dropdown
        dropdownType="button"
        value={dataRoomPermissionId}
        onSelect={(option) => {
          setDataRoomPermissionId(option.value);
        }}
        options={props.permissions.map((permission) => {
          return {
            value: permission.id,
            label: permission.name,
            description: permission.description,
          };
        })}
      />

      <Text margin="m 0 xs 0" size="s" weight="bold">
        Type
      </Text>
      <Dropdown
        dropdownType="button"
        value={accessTypesMap[type]}
        onSelect={(option) => {
          setType(option.value);
        }}
        options={[
          {
            label: "Buyer",
            value: CompanyAccountAccessType.Buyer,
            description: "Anyone on the buy side",
          },
          {
            label: "Seller",
            value: CompanyAccountAccessType.Seller,
            description: "Anyone on the sell side",
          },
        ]}
      />

      <div>
        <Button
          isDisabled={!email}
          margin="xl 0 0 0"
          title="Invite"
          onClick={() => {
            createCompanyInvite.mutate(
              {
                dataRoomPermissionID: dataRoomPermissionId,
                email,
                name,
                accessType: type,
              },
              {
                onSuccess: () => {
                  props.onClose();
                },
              }
            );
          }}
        >
          {createCompanyInvite.isLoading ? "Inviting..." : "Invite"}
        </Button>
      </div>
    </Modal>
  );
}

interface DeleteCompanyInviteProps {
  invite: CompanyInvite;
  onClose: () => void;
}

function DeleteCompanyInvite(props: DeleteCompanyInviteProps) {
  const client = useGqlClient();
  const deleteCompanyInvite = useDeleteCompanyInviteMutation(client);

  return (
    <Modal
      onCancel={() => {
        props.onClose();
      }}
    >
      <H4 margin="0 0 m 0">Delete invite for {props.invite.name}?</H4>
      <Text margin="0">
        You can create a new invite for the same email later on if needed.
      </Text>

      <Button
        margin="m 0 0 0"
        onClick={() => {
          deleteCompanyInvite.mutate(
            {
              id: props.invite.id,
            },
            {
              onSuccess: () => {
                props.onClose();
              },
            }
          );
        }}
        buttonType="destructive"
      >
        {deleteCompanyInvite.isLoading ? "Deleting..." : "Delete invite"}
      </Button>
    </Modal>
  );
}

interface RemoveCompanyAccountProps {
  companyAccount: CompanyAccount;
  onClose: () => void;
}

function RemoveCompanyAccount(props: RemoveCompanyAccountProps) {
  const client = useGqlClient();
  const removeCompanyAccount = useRemoveCompanyAccountMutation(client);

  return (
    <Modal
      onCancel={() => {
        props.onClose();
      }}
    >
      <H4 margin="0 0 m 0">
        Remove {props.companyAccount.account.name} from the Data Room?
      </H4>
      <Text margin="0">They can be added back if needed.</Text>

      <Button
        margin="m 0 0 0"
        onClick={() => {
          removeCompanyAccount.mutate(
            {
              accountID: props.companyAccount.account.id,
            },
            {
              onSuccess: () => {
                props.onClose();
              },
            }
          );
        }}
        buttonType="destructive"
      >
        {removeCompanyAccount.isLoading ? "Removing..." : "Remove"}
      </Button>
    </Modal>
  );
}

interface EditCompanyAccountProps {
  permissions: DataRoomPermission[];
  companyAccount: CompanyAccount;
  onClose: () => void;
}

function EditCompanyAccount(props: EditCompanyAccountProps) {
  const client = useGqlClient();
  const updateCompanyAccount = useUpdateCompanyAccountMutation(client);

  const [type, setType] = useState<CompanyAccountAccessType>(
    props.companyAccount.accessType
  );

  const [dataRoomPermissionId, setDataRoomPermissionId] = useState(
    props.companyAccount.permission.id
  );

  return (
    <Modal
      onCancel={() => {
        props.onClose();
      }}
    >
      <H4 margin="0 0 m 0">Edit {props.companyAccount.account.name}</H4>

      <Text margin="m 0 xs 0" size="s" weight="bold">
        Access level
      </Text>
      <Dropdown
        dropdownType="button"
        defaultValue={props.companyAccount.permission.name}
        value={dataRoomPermissionId}
        onSelect={(option) => {
          setDataRoomPermissionId(option.value);
        }}
        options={props.permissions.map((permission) => {
          return {
            value: permission.id,
            label: permission.name,
            description: permission.description,
          };
        })}
      />

      <Text margin="m 0 xs 0" size="s" weight="bold">
        Type
      </Text>
      <Dropdown
        dropdownType="button"
        defaultValue={accessTypesMap[type]}
        value={accessTypesMap[type]}
        onSelect={(option) => {
          setType(option.value);
        }}
        options={[
          {
            label: "Buyer",
            value: CompanyAccountAccessType.Buyer,
            description: "Anyone on the buy side",
          },
          {
            label: "Seller",
            value: CompanyAccountAccessType.Seller,
            description: "Anyone on the sell side",
          },
          {
            label: "Admin",
            value: CompanyAccountAccessType.Admin,
            description: "Manage team members",
          },
          {
            label: "Staff",
            value: CompanyAccountAccessType.Seller,
            description: "Can reply to DDQLs",
          },
        ]}
      />

      <div>
        <Button
          margin="xl 0 0 0"
          title="Invite"
          onClick={() => {
            updateCompanyAccount.mutate(
              {
                accountID: props.companyAccount.account.id,
                permissionID: dataRoomPermissionId,
                accessType: type,
              },
              {
                onSuccess: () => {
                  props.onClose();
                },
              }
            );
          }}
        >
          {updateCompanyAccount.isLoading ? "Saving..." : "Save"}
        </Button>
      </div>
    </Modal>
  );
}

import { useEffect, useState } from "react";
import { H1, H2, H3 } from "../../components/Heading";
import { Text } from "../../components/Text";
import React from "react";
import { Button } from "../../components/Button";
import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { withMarginTw } from "../../styles/withMargin";
import {
  RowInput,
  RowType,
  Row as gqlRow,
  RowValue,
  RowValueInput,
  useFinancialsQuery,
  PnlVersionFragmentFragment,
  ChangeSummaryStatus,
  usePnlVersionQuery,
  FileType,
  DataRoomFile,
  useSearchFilesQuery,
  useCreateQuestionMutation,
  QuestionNodeType,
  Question,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import Loading from "../../components/Loading";
import { Card, Card2 } from "../../components/Card";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { v4 as uuid } from "uuid";
import {
  ChatBubbleLeftRightIcon,
  DocumentMagnifyingGlassIcon,
  LinkIcon,
  PaperAirplaneIcon,
  PlusSmallIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import styled from "../../styles";
import { Overlay } from "../../components/Overlay";
import { TextInput } from "../../components/TextInput";
import { Flex } from "../../components/Flex";
import { actions } from "../../store/auth/slice";
import { useDispatch } from "react-redux";
import { TextArea } from "../../components/TextArea";
import { FileIcon } from "../../components/FileIcon";

interface Row {
  id: string;
  name: string;
  values: Record<string, number>;
  type: RowType;
}

interface Column {
  id: string;
  name: string;
}

interface CellError {
  rowId: string;
  columndId: string;
}

type EditingMode = "view";

export function ProfitLoss() {
  const client = useGqlClient();

  const [showFiles, setShowFiles] = useState(false);

  const [selectedFile, setSelectedFile] = useState<DataRoomFile | null>(null);
  const [answer, setAnswer] = useState<string>("");
  const dispatch = useDispatch();

  const [editingMode, setEditingMode] = useState<EditingMode>("view");

  const { data, isLoading, error, refetch } = useFinancialsQuery(client);

  const [showQuestion, setShowQuestion] = useState(false);

  const [columns, setColumns] = useState<Column[]>([
    {
      id: "col-1",
      name: "FY22",
    },
    {
      id: "col-2",
      name: "FY23",
    },
  ]);

  const [incomeRows, setIncomeRows] = useState<Row[]>([
    {
      id: "income-1",
      name: "Revenue",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Income,
    },
  ]);

  const [costOfGoodsRows, setCostOfGoodsRows] = useState<Row[]>([
    {
      id: "cost-of-goods-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [operatingExpenses, setOperatingExpenseRows] = useState<Row[]>([
    {
      id: "expense-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [depreciationRows, setDepreciationRows] = useState<Row[]>([
    {
      id: "depreciation-1",
      name: "Depreciation",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
    {
      id: "depreciation-2",
      name: "Amortization",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [interestRows, setInterestRows] = useState<Row[]>([
    {
      id: "interest-1",
      name: "Interest received",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Income,
    },
    {
      id: "interest-2",
      name: "Interest paid",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [taxRows, setTaxRows] = useState<Row[]>([
    {
      id: "tax-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  useEffect(() => {
    if (data && data.company.financialStatements.pnl) {
      setColumns(
        gqlColumnsToColumns(
          data.company.financialStatements.pnl.latestVersion.columns
        )
      );

      setIncomeRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.revenues
        )
      );

      setCostOfGoodsRows(
        gqlRowsToRows(data.company.financialStatements.pnl.latestVersion.cogs)
      );

      setOperatingExpenseRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.operatingExpenses
        )
      );

      setDepreciationRows(
        gqlRowsToRows([
          ...data.company.financialStatements.pnl.latestVersion.depreciations,
          ...data.company.financialStatements.pnl.latestVersion.amortizations,
        ])
      );

      setInterestRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.interests
        )
      );

      setTaxRows(
        gqlRowsToRows(data.company.financialStatements.pnl.latestVersion.taxes)
      );
    }
  }, [data]);

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className="flex-1 mb-8">
      <H1>Profit & Loss</H1>
      <div className="grid grid-cols-3 gap-8">
        <div className="col-span-2">
          <div className="mt-2 flex justify-between items-center">
            <div className="flex items-center">
              <LinkIcon className="w-5 h-5" />
              <Text margin="0 0 0 xs" weight="bold">
                Linked to
              </Text>
              <Link to="/b/dataroom/folder/dtrmfolder_2QkXa0TYgbahLwXipXsd1dFsQqH/file/dtrmfile_2UMQEJltpmczEK1llRu5APTr1JU">
                <div className="flex border border-3 ml-2 rounded-lg p-1 px-2 hover:border-gray-600 hover:cursor-pointer">
                  <FileIcon fileType={FileType.Pdf} />
                  <Text weight="bold">Profit & Loss</Text>
                </div>
              </Link>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-5 gap-1 overflow-hidden rounded-lg bg-white shadow p-4 overflow-x-scroll no-scrollbar">
            {[{ id: "numbers", name: "" }, ...columns].map((tab, i) => {
              return (
                <div key={tab.id} className={i === 0 ? `col-span-2` : ``}>
                  <Text weight="bold">{tab.name}</Text>
                </div>
              );
            })}

            <Heading name="Revenue" />
            <Rows
              questions={
                data.company.financialStatements.pnl
                  ? (data.company.financialStatements.pnl
                      .questions as Question[])
                  : []
              }
              editingMode={editingMode}
              columns={columns}
              rows={incomeRows}
              onChange={(rows) => {
                setIncomeRows(rows);
              }}
            />

            <AddButton
              editingMode={editingMode}
              onClick={() => {
                const rows = incomeRows;

                rows.push({
                  id: `income-${uuid()}`,
                  name: "",
                  values: columns.reduce(
                    (acc: Record<string, number>, col) => (
                      (acc[col.id] = 0), acc
                    ),
                    {}
                  ),
                  type: RowType.Income,
                });

                setIncomeRows([...rows]);
              }}
            />

            <TotalRow
              columns={columns}
              rows={incomeRows}
              negativeRows={[]}
              colorPreset="secondaryHeading"
              name="Total revenue"
            />

            <Heading
              margin="l 0 0 0"
              name="Cost of Goods Sold / Cost of Sales"
            />
            <Rows
              questions={
                data.company.financialStatements.pnl
                  ? (data.company.financialStatements.pnl
                      .questions as Question[])
                  : []
              }
              editingMode={editingMode}
              columns={columns}
              rows={costOfGoodsRows}
              onChange={setCostOfGoodsRows}
            />

            <AddButton
              editingMode={editingMode}
              onClick={() => {
                const rows = costOfGoodsRows;

                rows.push({
                  id: `cost-of-goods-${uuid()}`,
                  name: "",
                  values: {
                    FY22: 0,
                    FY23: 0,
                    Forecast: 0,
                  },
                  type: RowType.Expense,
                });

                setCostOfGoodsRows([...rows]);
              }}
            />

            <TotalRow
              columns={columns}
              rows={costOfGoodsRows}
              negativeRows={[]}
              colorPreset="secondaryHeading"
              name="Total costs"
            />

            <TotalRow
              columns={columns}
              rows={incomeRows}
              negativeRows={costOfGoodsRows}
              name="Gross profit"
              colorPreset="success"
            />

            <Heading margin="l 0 0 0" name="Operating expenses" />
            <Rows
              questions={
                data.company.financialStatements.pnl
                  ? (data.company.financialStatements.pnl
                      .questions as Question[])
                  : []
              }
              editingMode={editingMode}
              columns={columns}
              rows={operatingExpenses}
              onChange={setOperatingExpenseRows}
              onShowQuestion={() => {
                setShowQuestion(true);
              }}
            />
            <AddButton
              editingMode={editingMode}
              onClick={() => {
                const rows = operatingExpenses;

                rows.push({
                  id: `operating-expense-${uuid()}`,
                  name: "",
                  values: {
                    FY22: 0,
                    FY23: 0,
                    Forecast: 0,
                  },
                  type: RowType.Expense,
                });

                setOperatingExpenseRows([...rows]);
              }}
            />

            <TotalRow
              columns={columns}
              rows={incomeRows}
              negativeRows={[...costOfGoodsRows, ...operatingExpenses]}
              name="EBITDA"
              colorPreset="success"
            />

            <Heading margin="l 0 0 0" name="Depreciation & Amortization" />
            <Rows
              questions={
                data.company.financialStatements.pnl
                  ? (data.company.financialStatements.pnl
                      .questions as Question[])
                  : []
              }
              editingMode={editingMode}
              columns={columns}
              rows={depreciationRows}
              onChange={setDepreciationRows}
            />
            <AddButton
              editingMode={editingMode}
              onClick={() => {
                const rows = depreciationRows;

                rows.push({
                  id: `depreciation-${uuid()}`,
                  name: "",
                  values: {
                    FY22: 0,
                    FY23: 0,
                    Forecast: 0,
                  },
                  type: RowType.Expense,
                });

                setDepreciationRows([...rows]);
              }}
            />

            <TotalRow
              columns={columns}
              rows={incomeRows}
              negativeRows={[
                ...costOfGoodsRows,
                ...operatingExpenses,
                ...depreciationRows,
              ]}
              name="EBIT"
              colorPreset="success"
            />

            <Heading margin="l 0 0 0" name="Interest" />
            <Rows
              questions={
                data.company.financialStatements.pnl
                  ? (data.company.financialStatements.pnl
                      .questions as Question[])
                  : []
              }
              editingMode={editingMode}
              columns={columns}
              rows={interestRows}
              onChange={setInterestRows}
            />

            <TotalRow
              columns={columns}
              rows={interestRows.filter((row) => row.type === RowType.Income)}
              negativeRows={interestRows.filter(
                (row) => row.type === RowType.Expense
              )}
              name="Net interest"
              colorPreset="secondaryHeading"
            />

            <Heading margin="l 0 0 0" name="Tax" />
            <Rows
              questions={
                data.company.financialStatements.pnl
                  ? (data.company.financialStatements.pnl
                      .questions as Question[])
                  : []
              }
              editingMode={editingMode}
              columns={columns}
              rows={taxRows}
              onChange={setTaxRows}
            />
            <AddButton
              editingMode={editingMode}
              onClick={() => {
                const rows = taxRows;

                rows.push({
                  id: `tax-${uuid()}`,
                  name: "",
                  values: {
                    FY22: 0,
                    FY23: 0,
                    Forecast: 0,
                  },
                  type: RowType.Expense,
                });

                setTaxRows([...rows]);
              }}
            />

            <TotalRow
              columns={columns}
              rows={[
                ...incomeRows,
                ...interestRows.filter((row) => row.type === RowType.Income),
              ]}
              negativeRows={[
                ...costOfGoodsRows,
                ...operatingExpenses,
                ...depreciationRows,
                ...interestRows.filter((row) => row.type === RowType.Expense),
                ...taxRows,
              ]}
              name="Net income"
              colorPreset="success"
            />
          </div>
        </div>
        <div className="mt-28">
          <Text weight="bold">Changes</Text>
          {data.company.financialStatements.pnl &&
            data.company.financialStatements.pnl.versions.map((version) => {
              return <ChangeSummary key={version.id} version={version} />;
            })}
        </div>
      </div>
      {showQuestion ? (
        <Modal
          onCancel={() => {
            setShowQuestion(false);
          }}
        >
          {showFiles ? (
            <ChooseDataRoomFile
              onFileSelected={(f) => {
                setSelectedFile(f);
                setShowFiles(false);
              }}
            />
          ) : (
            <>
              <Text weight="semi">Matt from Bain & Co. asked</Text>
              <Text size="l" weight="bold">
                Is there a staff sheet that breaks down this line item?
              </Text>

              <div className="my-4 w-full h-px bg-gray-300"></div>
              <div className="w-full flex">
                <UserCircleIcon className="w-6 h-6 text-gray-400" />
                <div className="mr-4 ml-1 w-full ">
                  <input
                    placeholder="Answer or ask to clarify..."
                    className="px-2 py-1 border border-3 hover:border-gray-500 rounded-lg"
                    value={answer}
                    onChange={(e) => {
                      setAnswer(e.currentTarget.value);
                    }}
                  />
                </div>
                <PaperAirplaneIcon
                  onClick={() => {
                    if (!selectedFile) {
                      return;
                    }

                    dispatch(
                      actions.setStaffSheetQuestionAnswer({
                        answer: {
                          file: selectedFile,
                          answer,
                        },
                      })
                    );

                    setShowQuestion(false);
                  }}
                  className="w-6 h-6 text-blue-400 hover:text-blue-500 hover:cursor-pointer self-center"
                />
              </div>
              {selectedFile ? (
                <div className="mt-2 ml-6 flex w-fit border border-3 rounded-lg p-1 px-2 ">
                  <FileIcon fileType={selectedFile.fileType} />
                  <Text weight="bold">{selectedFile.name}</Text>
                </div>
              ) : (
                <Button
                  onClick={() => {
                    setShowFiles(true);
                  }}
                  margin="s 0 0 xl"
                  buttonType="quaternary"
                >
                  <div className="flex">
                    <PlusSmallIcon className="w-4 h-4" />
                    Link Data Room file
                  </div>
                </Button>
              )}
            </>
          )}
        </Modal>
      ) : null}
    </div>
  );
}

interface ChangeSummaryProps {
  version: PnlVersionFragmentFragment;
}

function ChangeSummary(props: ChangeSummaryProps) {
  const client = useGqlClient();
  const { data } = usePnlVersionQuery(
    client,
    {
      id: props.version.id,
    },
    {
      refetchInterval: (data, query) => {
        if (props.version.changeSummaryStatus === ChangeSummaryStatus.Success) {
          return false;
        }

        if (
          data &&
          data.pnlVersion.changeSummaryStatus === ChangeSummaryStatus.Pending
        ) {
          return 1000;
        }

        return false;
      },
    }
  );

  const [changeSummary, setChangeSummary] = useState("");

  useEffect(() => {
    if (
      data &&
      data.pnlVersion.changeSummaryStatus === ChangeSummaryStatus.Success
    ) {
      setChangeSummary(data.pnlVersion.changeSummary);
      return;
    }

    if (
      data &&
      data.pnlVersion.changeSummaryStatus === ChangeSummaryStatus.Pending
    ) {
      setChangeSummary("Summary of changes is pending...");
      return;
    }

    setChangeSummary("");
  }, [data, props.version]);

  return (
    <Card2 key={props.version.id} padding="m" margin="0 0 l 0">
      <Text weight="bold">
        Dan Scott{" "}
        {formatDistanceToNow(fromUnixTime(props.version.createdAt), {
          addSuffix: true,
        })}
      </Text>
      <Text>{changeSummary}</Text>
    </Card2>
  );
}

function rowsToRowsInput(rows: Row[]): RowInput[] {
  return rows.map((row) => ({
    id: row.id,
    name: row.name,
    values: rowValuesToRowValueInput(row.values),
    type: row.type === RowType.Income ? RowType.Income : RowType.Expense,
  }));
}

function rowValuesToRowValueInput(
  values: Record<string, number>
): RowValueInput[] {
  return Object.entries(values).map(([column, amount]) => ({
    column,
    value: amount,
  }));
}

function gqlRowsToRows(rows: gqlRow[]): Row[] {
  return rows.map((row) => ({
    id: row.id === "" ? uuid() : row.id,
    name: row.name,
    values: gqlRowValuesToRowValues(row.values),
    type: row.type,
  }));
}

function gqlColumnsToColumns(columns: Column[]): Column[] {
  return columns.map((col) => ({
    id: col.id,
    name: col.name,
  }));
}

function gqlRowValuesToRowValues(values: RowValue[]): Record<string, number> {
  return values.reduce(
    (acc: Record<string, number>, rowValue) => (
      (acc[rowValue.column] = rowValue.value), acc
    ),
    {}
  );
}

interface HeadingProps {
  name: string;
  margin?: string;
}

function Heading(props: HeadingProps) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <React.Fragment>
      <div className={`${margin} col-span-2 mb-4 pr-8`}>
        <Text weight="bold">{props.name}</Text>
        <div className="h-px bg-gray-300"></div>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </React.Fragment>
  );
}

interface AddButtonProps {
  onClick: () => void;
  editingMode: EditingMode;
}

function AddButton(props: AddButtonProps) {
  if (props.editingMode === "view") {
    return null;
  }

  return (
    <React.Fragment>
      <div className="mt-2 px-1 col-span-2 mb-4 pr-8 h-4">
        <Button
          margin="xs 0 0 0"
          buttonType="quaternary"
          onClick={() => {
            props.onClick();
          }}
        >
          <div className="h-4 flex items-center text-gray-500">
            <p>Add row</p>
            <PlusIcon className="w-4 h-4" />
          </div>
        </Button>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </React.Fragment>
  );
}

function getTotal(rows: Row[], columnId: string) {
  return rows.reduce(
    (sum, row) =>
      sum +
      (typeof row.values[columnId] === "number" ? row.values[columnId] : 0),
    0
  );
}

interface RowsProps {
  questions: Question[];
  columns: Column[];
  rows: Row[];
  onChange: (rows: Row[]) => void;
  editingMode: EditingMode;
  onShowQuestion?: () => void;
}

interface RowItemProps {
  questions: Question[];
  columns: Column[];
  row: Row;
  onChange: (row: Row) => void;
  onDelete: () => void;
  editingMode: EditingMode;
  onShowQuestion?: () => void;
}

function formatNumberWithCommas(num: number) {
  try {
    const parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } catch (e) {
    return "";
  }
}

// Remove commas from a string
function removeCommas(str: string) {
  return str.replace(/,/g, "");
}

function RowItem(props: RowItemProps) {
  const client = useGqlClient();
  const createQuestion = useCreateQuestionMutation(client);
  const [inputValues, setInputValues] = useState<Record<string, string>>(
    props.columns.reduce(
      (acc: Record<string, string>, col) => (
        (acc[col.id] = formatNumberWithCommas(props.row.values[col.id])), acc
      ),
      {}
    )
  );
  const [cellErrors, setCellErrors] = useState<CellError[]>([]);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showAskQuestion, setShowAskQuestion] = useState("");

  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDetails, setQuestionDetails] = useState("");

  useEffect(() => {
    setInputValues(
      props.columns.reduce(
        (acc: Record<string, string>, col) => (
          (acc[col.id] = formatNumberWithCommas(props.row.values[col.id])), acc
        ),
        {}
      )
    );
  }, [props.row.values, props.columns]);

  if (props.editingMode === "view") {
    return (
      <React.Fragment key={props.row.id}>
        <div className="flex col-span-2 mr-8 mt-2 group">
          <div className="flex flex-1 items-center justify-between pr-8">
            <Text weight="bold">{props.row.name}</Text>
          </div>
          {props.questions.length > 0 ? (
            <div
              onClick={() => {
                setShowAskQuestion(`${props.row.id}`);
              }}
              className="group group-hover:cursor-pointer group-hover:border-gray-400 border border-2 rounded-lg flex items-center py-1 px-2"
            >
              <Text size="xs" weight="bold">
                {props.questions.length}
              </Text>
              <ChatBubbleLeftRightIcon
                onClick={() => {
                  setShowAskQuestion(`${props.row.id}`);
                }}
                className="ml-1 w-4 h-4 text-gray-500 group-hover:text-gray-800"
              />
            </div>
          ) : (
            <Button
              className="opacity-0 group-hover:opacity-80 "
              buttonType="quaternary"
            >
              <ChatBubbleLeftRightIcon
                onClick={() => {
                  setShowAskQuestion(`${props.row.id}`);
                }}
                className="w-4 h-4 text-gray-500 hover:text-gray-800"
              />
            </Button>
          )}
        </div>
        {props.columns.map((col) => {
          return (
            <div className="pr-1 mt-2" key={col.id}>
              <Text>
                {props.row.values[col.id]
                  ? props.row.values[col.id].toLocaleString()
                  : ""}
              </Text>
            </div>
          );
        })}
        {showAskQuestion ? (
          <QuestionModal
            onCancel={() => {
              setShowAskQuestion("");
            }}
          >
            <div className="flex flex-col  h-full">
              <div className="flex justify-between items-center">
                <H3>{`Ask a question about ${props.row.name}`}</H3>
                <XCircleIcon
                  onClick={() => {
                    setShowAskQuestion("");
                  }}
                  className="w-5 h-5 text-gray-400 hover:text-gray-600 hover:cursor-pointer"
                />
              </div>
              <Text margin="l 0 0 0" weight="bold" size="xs">
                Title
              </Text>
              <TextInput
                value={questionTitle}
                onChange={(e) => {
                  setQuestionTitle(e.currentTarget.value);
                }}
                placeholder="Title"
              />
              <Text margin="l 0 0 0" weight="bold" size="xs">
                Details
              </Text>
              <TextArea
                value={questionDetails}
                onChange={(e) => {
                  setQuestionDetails(e.currentTarget.value);
                }}
                placeholder="Title"
              />
              <div className="flex-1" />
              <Button
                isDisabled={createQuestion.isLoading || questionTitle === ""}
                onClick={() => {
                  if (createQuestion.isLoading) {
                    return;
                  }

                  createQuestion.mutate(
                    {
                      title: questionTitle,
                      body: questionDetails,
                      nodeId: props.row.id,
                      nodeType: QuestionNodeType.Pnl,
                    },
                    {
                      onSuccess: () => {
                        setShowAskQuestion("");
                      },
                    }
                  );
                }}
              >
                {createQuestion.isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </QuestionModal>
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment key={props.row.id}>
      <div className="col-span-2 flex mr-8 group">
        <input
          placeholder="Name of item"
          className="p-1 border-transparent focus-visible:outline-none font-semibold text-gray-500"
          value={props.row.name}
          onChange={(e) => {
            const row = { ...props.row };
            row.name = e.currentTarget.value;
            props.onChange(row);
          }}
        />
        {showConfirmDelete ? (
          <Button
            className="opacity-80 "
            buttonType="quaternary"
            variant="destructive"
            onClick={() => {
              props.onDelete();
            }}
          >
            Confirm
          </Button>
        ) : (
          <Button
            className="opacity-0 group-hover:opacity-80 "
            buttonType="quaternary"
            variant="destructive"
          >
            <TrashIcon
              onClick={() => {
                setShowConfirmDelete(true);
                setTimeout(() => {
                  setShowConfirmDelete(false);
                }, 5000);
              }}
              className="w-4 h-4 text-red-500 hover:text-red-800"
            />
          </Button>
        )}
      </div>
      {props.columns.map((col) => {
        return (
          <div className="pr-1" key={col.id}>
            <input
              className={`p-1 border border-gray-300 rounded ${
                cellErrors.filter(
                  (ce) => ce.columndId === col.id && ce.rowId === props.row.id
                ).length > 0
                  ? "border-red-500"
                  : ""
              }`}
              value={inputValues[col.id]}
              onBlur={(e) => {
                const value = removeCommas(e.currentTarget.value.trim());
                if (isNaN(Number(value)) && e.currentTarget.value !== "") {
                  const errors = [...cellErrors];
                  errors.push({
                    rowId: props.row.id,
                    columndId: col.id,
                  });

                  setCellErrors(errors);
                } else {
                  const errors = cellErrors.filter(
                    (ce) => ce.columndId !== col.id && ce.rowId !== props.row.id
                  );

                  setCellErrors([...errors]);
                }
              }}
              onChange={(e) => {
                const value = removeCommas(e.currentTarget.value);

                if (value.trim() === "") {
                  const vals = { ...inputValues };
                  vals[col.id] = value;

                  setInputValues(vals);

                  const row = { ...props.row };
                  row.values[col.id] = 0;
                  props.onChange(row);

                  return;
                }

                if (
                  isNaN(Number(value.trim())) ||
                  e.currentTarget.value.endsWith(".")
                ) {
                  const vals = { ...inputValues };
                  vals[col.id] = value;

                  setInputValues(vals);

                  return;
                }

                const vals = { ...inputValues };
                vals[col.id] = formatNumberWithCommas(parseFloat(value));

                setInputValues(vals);

                const row = { ...props.row };
                row.values[col.id] = parseFloat(value);
                props.onChange(row);
              }}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
}

function Rows(props: RowsProps) {
  return (
    <React.Fragment>
      {props.rows.map((row, i) => {
        return (
          <RowItem
            questions={props.questions.filter((q) => q.nodeId.includes(row.id))}
            onShowQuestion={props.onShowQuestion}
            editingMode={props.editingMode}
            columns={props.columns}
            row={row}
            key={i}
            onDelete={() => {
              const rows = props.rows.filter((r) => r.id !== row.id);
              props.onChange(rows);
            }}
            onChange={(row) => {
              const rows = [...props.rows];
              rows[i] = row;

              props.onChange(rows);
            }}
          />
        );
      })}
    </React.Fragment>
  );
}

interface Props {
  rows: Row[];
  negativeRows: Row[];
  columns: Column[];
  colorPreset: "success" | "secondaryHeading";
  name: string;
  margin?: string;
}

function TotalRow(props: Props) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <React.Fragment>
      <div className={`${margin} mt-6 px-1 col-span-2 mb-4 pr-8`}>
        <Text weight="bold" colorPreset={props.colorPreset}>
          {props.name}
        </Text>
      </div>
      {props.columns.map((col) => {
        return (
          <div className="mt-4" key={col.id}>
            <div className="h-px bg-gray-300"></div>
            <Text
              margin="xs 0 0 0"
              weight="bold"
              colorPreset={props.colorPreset}
            >
              {(
                getTotal(props.rows, col.id) -
                getTotal(props.negativeRows, col.id)
              ).toLocaleString()}
            </Text>
          </div>
        );
      })}
    </React.Fragment>
  );
}

const ModalWrap = styled(Card)`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  cursor: default;
`;

interface ModalProps {
  children: React.ReactNode;
  onCancel?: () => void;
  padding?: string;
  onDragEnter?: React.DragEventHandler<HTMLDivElement>;
  onDragLeave?: React.DragEventHandler<HTMLDivElement>;
}

export function Modal(props: ModalProps) {
  return (
    <Overlay
      onClick={props.onCancel}
      onDragEnter={props.onDragEnter}
      onDragLeave={props.onDragLeave}
    >
      <ModalWrap
        onClick={(e) => e.stopPropagation()}
        padding={props.padding ? props.padding : "xl"}
      >
        {props.children}
      </ModalWrap>
    </Overlay>
  );
}

function ChooseDataRoomFile(props: {
  onFileSelected(file: DataRoomFile): void;
}) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="flex flex-col h-full">
      <div>
        <div className="flex justify-between items-center mb-4">
          <H2 margin="0">Search for files</H2>
          <XCircleIcon className="w-4 h-4 text-gray-700 hover:text-gray-900 hover:cursor-pointer" />
        </div>
        <TextInput
          margin="0 0 xl 0"
          placeholder="Search..."
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.currentTarget.value);
          }}
        />
      </div>
      <div className="flex-1">
        <SearchResults
          onFileSelected={(file) => {
            props.onFileSelected(file);
          }}
          searchTerm={searchTerm}
          folderId={""}
        />
      </div>
    </div>
  );
}

interface SearchResultsProps {
  folderId: string;
  searchTerm: string;
  onFileSelected(file: DataRoomFile): void;
}

let searchTimeout: NodeJS.Timeout | null = null;

export function SearchResults(props: SearchResultsProps) {
  const [files, setFiles] = useState<DataRoomFile[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const client = useGqlClient();
  const searchQuery = useSearchFilesQuery(
    client,
    {
      query: searchTerm,
    },
    {
      enabled: searchTerm.length > 2,
    }
  );

  useEffect(() => {
    const term = props.searchTerm;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setSearchTerm(term);
    }, 1000);
  }, [props.searchTerm]);

  useEffect(() => {
    if (searchTerm.length < 2) {
      setFiles([]);
      return;
    }

    if (!searchQuery.data) {
      return;
    }

    setFiles(searchQuery.data.searchFiles as DataRoomFile[]);
  }, [searchTerm, searchQuery]);

  if (searchQuery.isError) {
    return <div>Something went wrong</div>;
  }

  if (searchQuery.isLoading) {
    return (
      <div className="h-full  justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (!searchQuery.data || files.length === 0) {
    return (
      <div className="flex h-full justify-center items-center flex-col">
        <DocumentMagnifyingGlassIcon className="w-14 h-14 text-gray-700" />
        <Text margin="s 0 0 0">Search for files by name</Text>
      </div>
    );
  }

  return (
    <>
      {files.map((f) => {
        return (
          <div
            key={f.id}
            onClick={() => {
              props.onFileSelected(f);
            }}
            className="py-1 cursor-pointer hover:opacity-80"
          >
            <Flex justify="space-between">
              <Flex>
                <FileIcon fileType={f.fileType} />
                <Text margin="0 0 0 s" weight="bold">
                  {f.name}
                </Text>
              </Flex>
              <Text
                colorPreset="secondary"
                margin="0 0 0 s"
                size="s"
                weight="bold"
              >
                in{" "}
                {f.parentFolders
                  .map((pf, i) => {
                    return `${pf.name === "" ? "Home" : pf.name}${
                      i > 0 ? "/" : ""
                    }`;
                  })
                  .reverse()}
              </Text>
            </Flex>
          </div>
        );
      })}
    </>
  );
}

const QuestionModalWrap = styled(Card)`
  width: 100%;
  max-width: 600px;
  max-height: 500px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  cursor: default;
`;

interface ModalProps {
  children: React.ReactNode;
  onCancel?: () => void;
  padding?: string;
}

function QuestionModal(props: ModalProps) {
  return (
    <QuestionOverlay onClick={props.onCancel}>
      <QuestionModalWrap
        onClick={(e) => e.stopPropagation()}
        padding={props.padding ? props.padding : "xl"}
      >
        {props.children}
      </QuestionModalWrap>
    </QuestionOverlay>
  );
}

export const QuestionOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 999;
  display: flex;
  justify-content: center;
  padding: 0 ${(p) => p.theme.spacing.m};
  padding-top: 64px;
`;

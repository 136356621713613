import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Card2 } from "../../../components/Card";
import { FileIcon } from "../../../components/FileIcon";
import { FileType } from "../../../graphql/generated";
import { PlusIcon } from "@heroicons/react/20/solid";

export function Documents() {
  return (
    <div className="flex-1">
      <div className="grid grid-cols-1 gap-8">
        <div className="">
          <p className="font-semibold text-gray-600">
            Finances
            <span className="text-xs text-gray-400 ml-1 font-normal">
              Required
            </span>
          </p>
          <p className="text-sm text-gray-500">Needed for revenue analysis</p>
          <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2">
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Financial statements for the last 3 years
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Pdf} />
                  <p className="text-xs ml-1 truncate">Profit & Loss</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Pdf} />
                  <p className="text-xs ml-1">Cashflow</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Pdf} />
                  <p className="text-xs ml-1 truncate">Balance Sheet</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Bank statements for the last 3 years
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-2 flex-wrap">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Other} />
                  <p className="text-xs ml-1 truncate">2022</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Other} />
                  <p className="text-xs ml-1">2023</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Other} />
                  <p className="text-xs ml-1 truncate">2024</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Tax Returns for the last 3 years
                </p>
                <p className="text-xs font-semibold text-orange-500/90">
                  No documents
                </p>
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Budgets + Forecasts
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Pdf} />
                  <p className="text-xs ml-1 truncate">Profit & Loss</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Pdf} />
                  <p className="text-xs ml-1">Cashflow</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Pdf} />
                  <p className="text-xs ml-1 truncate">Balance Sheet</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
          </div>
        </div>

        <div className="">
          <p className="font-semibold text-gray-600">
            Contracts
            <span className="text-xs text-gray-400 ml-1 font-normal">
              Required
            </span>
          </p>
          <p className="text-sm text-gray-500">Needed for risk analysis</p>
          <div className="grid grid-cols-2 gap-2">
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Customer contracts
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">KML</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">Concrete jungle</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">Tesco</p>
                </div>

                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Supplier contracts
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-2 flex-wrap">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">Pearce Tires</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
          </div>
        </div>

        <div className="">
          <p className="font-semibold text-gray-600">
            Agreements & Schedules
            <span className="text-xs text-gray-400 ml-1 font-normal">
              Required
            </span>
          </p>
          <p className="text-sm text-gray-500">Needed for risk analysis</p>
          <div className="grid grid-cols-2 gap-2">
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Lease Agreements
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">
                    Warehouse rental contract
                  </p>
                </div>

                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Debt Agreements
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-2 flex-wrap">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">SBA Loan agreement</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">Other</p>
                <p className="text-xs font-semibold text-gray-400/90">
                  Optional
                </p>
              </div>
              <div className="flex mt-6 items-center gap-2 flex-wrap">
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
          </div>
        </div>

        <div className="">
          <p className="font-semibold text-gray-600">
            Customer breakdown
            <span className="text-xs text-gray-400 ml-1 font-normal">
              Required
            </span>
          </p>
          <p className="text-sm text-gray-500">
            Needed for customer concentration analysis
          </p>
          <div className="grid grid-cols-2 gap-2">
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Customer breakdown
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Xlsx} />
                  <p className="text-xs ml-1 truncate">Customers by revenue</p>
                </div>

                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
          </div>
        </div>

        <div className="">
          <p className="font-semibold text-gray-600">
            Employee contracts and benefits
            <span className="text-xs text-gray-400 ml-1 font-normal">
              Required
            </span>
          </p>
          <p className="text-sm text-gray-500">
            Needed for key people analysis
          </p>
          <div className="grid grid-cols-2 gap-2">
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Employee contracts
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">CEO contract</p>
                </div>

                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Employee benefits
                </p>
                <CheckCircleIcon className="h-5 w-5 ml-2 text-green-500/80" />
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">Healthcare plan</p>
                </div>
                <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <FileIcon size="s" fileType={FileType.Docx} />
                  <p className="text-xs ml-1 truncate">Dental plan</p>
                </div>

                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
          </div>
        </div>

        <div className="">
          <p className="font-semibold text-gray-600">
            Other supporting documents
          </p>
          <p className="text-sm text-gray-500"></p>
          <div className="grid grid-cols-2 gap-2">
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">
                  Litigation & legals
                </p>
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
            <Card2 margin="m 0 0 0" padding="m">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold text-gray-500">Other</p>
              </div>
              <div className="flex mt-6 items-center gap-x-2 flex-wrap gap-y-2">
                <div className="cursor-pointer hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                  <PlusIcon className="h-5 w-5 text-gray-600/80" />
                  <p className="text-xs select-none text-gray-600 ml-1 truncate">
                    Add file
                  </p>
                </div>
              </div>
            </Card2>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";
import { WithMarginProp } from "../styles/withMargin";
import { Flex } from "./Flex";
import { Text } from "./Text";

type Props = {
  label?: string;
  defaultValue?: string | number;
  name?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  help?: string;
  helpWithLink?: {
    preText: string;
    linkLabel: string;
    linkURL: string;
    postText?: string;
  };
  type?: string;
  error?: string;
  rows?: number;
  characterLimit?: number;
  characterLength?: number;
};

export const TextArea = (props: Props & WithMarginProp) => {
  const theme = useTheme();
  const ref = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.style.height = "0px";
    const scrollHeight =
      ref.current.scrollHeight < 150
        ? props.rows
          ? props.rows * 32
          : 150
        : ref.current.scrollHeight;

    ref.current.style.height = scrollHeight + "px";
    console.log(ref.current.scrollHeight);
  }, [ref, props.value]);

  return (
    <Flex direction="column" margin={props.margin}>
      {props.label && (
        <Text weight="semi" margin="0 0 s">
          {props.label}
        </Text>
      )}

      <TextAreaWrap isError={!!props.error}>
        <StyledArea
          ref={ref}
          maxLength={props.characterLimit}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          rows={props.rows ? props.rows : 6}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
        />
        {props.characterLimit && (
          <CharacterLimit size="xs" margin="xs s xs">
            {props.characterLength}/{props.characterLimit}
          </CharacterLimit>
        )}
      </TextAreaWrap>

      {props.error && (
        <Text size="s" margin="s 0 0 0" color={theme.color.destructive}>
          {props.error}
        </Text>
      )}
    </Flex>
  );
};

const CharacterLimit = styled(Text)`
  position: absolute;
  right: ${(p) => p.theme.spacing.xs};
  bottom: ${(p) => p.theme.spacing.s};
`;

const TextAreaWrap = styled.div<{ isError?: boolean }>`
  position: relative;
  padding-bottom: 0px;

  ${(p) =>
    p.isError
      ? css`
          border-color: ${(p) => p.theme.color.warning};
          box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.warning}20;
        `
      : null}
`;

const StyledArea = styled.textarea`
  padding: ${(p) => p.theme.spacing.m};
  border: none;
  background-color: ${(p) => p.theme.color.card.background};
  border: 2px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: 5px;
  min-width: 100%;
  min-height: 100%;
  max-height: 350px;
  box-sizing: border-box;
  background-color: ${(p) => p.theme.color.card.background};
  margin-bottom: 0;

  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 140%;
  resize: vertical;
  white-space: pre-line;

  color: ${(p) => p.theme.color.typography.text};

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.primary}20;
    outline: none;
  }
`;

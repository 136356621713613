import { useEffect, useRef, useState } from "react";
import { H3 } from "../../components/Heading";

import { Card2 } from "../../components/Card";
import { Dropdown } from "../../components/Dropdown";
import { Text } from "../../components/Text";
import { v4 as uuid } from "uuid";

import { LayerProps, MapRef } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import styled, { css } from "../../styles";
import withMargin from "../../styles/withMargin";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";

// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line import/no-webpack-loader-syntax

const stateLocations: { [key: string]: { lat: number; lng: number } } = {
  Minnesota: { lat: 46.7296, lng: -94.6859 },
  "New York": { lat: 42.1657, lng: -74.9481 },
  California: { lat: 36.7783, lng: -119.4179 },
  Illinois: { lat: 40.3495, lng: -88.9861 },
  Texas: { lat: 31.9686, lng: -99.9018 },
  Arizona: { lat: 34.0489, lng: -111.0937 },
  Pennsylvania: { lat: 40.5908, lng: -77.2098 },
  Florida: { lat: 27.9944, lng: -81.7603 },
  Ohio: { lat: 40.3888, lng: -82.7649 },
  Indiana: { lat: 40.2672, lng: -86.1349 },
  "North Carolina": { lat: 35.6301, lng: -79.8064 },
  Washington: { lat: 47.7511, lng: -120.7401 },
  Colorado: { lat: 39.0598, lng: -105.3111 },
  "District of Columbia": { lat: 38.905, lng: -77.0377 },
  Tennessee: { lat: 35.7478, lng: -86.6923 },
};

type Stats = {
  ebitda_multiple: number;
  edbitda: number;
  recurring_revenue: number;
  ebitda_margin: number;
  growth_rate: number;
  revenue: number;
};

const originalStats: Stats = {
  ebitda_multiple: 3,
  recurring_revenue: 0.6,
  ebitda_margin: 0.23,
  growth_rate: 0.4,
  revenue: 30000000,
  edbitda: 9000000,
};

const similarCompanies = [
  {
    id: 1,
    state: "Minnesota",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "85%",
    industry: "Logistics",
    multiple: "3.5x",
  },
  {
    id: 2,
    state: "Florida",
    revenue: "$2-3M",
    ebitda: "25-35%",
    growth: "15-25%",
    salePrice: "$4M",
    verified: true,
    date: "2 months ago",
    match: "70%",
    industry: "Logistics",
    multiple: "3.2x",
  },
  {
    id: 3,
    state: "California",
    revenue: "$3-4M",
    ebitda: "20-30%",
    growth: "20-30%",
    salePrice: "$4.5M",
    verified: false,
    date: "9 months ago",
    match: "78%",
    industry: "Logistics",
    multiple: "3.8x",
  },
];

const similarGeographicCompanies = [
  {
    id: 1,
    state: "Minnesota",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "85%",
    industry: "Logistics",
    multiple: "3.5x",
  },
  {
    id: 2,
    state: "Minnesota",
    revenue: "$2-3M",
    ebitda: "25-35%",
    growth: "15-25%",
    salePrice: "$4M",
    verified: true,
    date: "2 months ago",
    match: "70%",
    industry: "Agriculture",
    multiple: "3.2x",
  },
  {
    id: 3,
    state: "Minnesota",
    revenue: "$3-4M",
    ebitda: "20-30%",
    growth: "20-30%",
    salePrice: "$4.5M",
    verified: false,
    date: "9 months ago",
    match: "78%",
    industry: "Logistics",
    multiple: "3.8x",
  },

  {
    id: 4,
    state: "Minnesota",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "85%",
    industry: "Logistics",
    multiple: "3.5x",
  },
  {
    id: 5,
    state: "Minnesota",
    revenue: "$2-3M",
    ebitda: "25-35%",
    growth: "15-25%",
    salePrice: "$4M",
    verified: true,
    date: "2 months ago",
    match: "70%",
    industry: "Agriculture",
    multiple: "3.2x",
  },
  {
    id: 6,
    state: "Minnesota",
    revenue: "$3-4M",
    ebitda: "20-30%",
    growth: "20-30%",
    salePrice: "$4.5M",
    verified: false,
    date: "9 months ago",
    match: "78%",
    industry: "Logistics",
    multiple: "3.8x",
  },
  {
    id: 7,
    state: "Minnesota",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "85%",
    industry: "Logistics",
    multiple: "3.5x",
  },
  {
    id: 8,
    state: "Minnesota",
    revenue: "$2-3M",
    ebitda: "25-35%",
    growth: "15-25%",
    salePrice: "$4M",
    verified: true,
    date: "2 months ago",
    match: "70%",
    industry: "Agriculture",
    multiple: "3.2x",
  },
];

const similarLogisticsCompaniesCountrywide = [
  {
    id: 1,
    state: "Florida",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "60%",
    industry: "Logistics",
    multiple: "3.5x",
  },
  {
    id: 2,
    state: "Texas",
    revenue: "$2-3M",
    ebitda: "25-35%",
    growth: "15-25%",
    salePrice: "$4M",
    verified: true,
    date: "2 months ago",
    match: "61%",
    industry: "Logistics",
    multiple: "3.2x",
  },
  {
    id: 3,
    state: "Texas",
    revenue: "$3-4M",
    ebitda: "20-30%",
    growth: "20-30%",
    salePrice: "$4.5M",
    verified: false,
    date: "9 months ago",
    match: "58%",
    industry: "Logistics",
    multiple: "3.8x",
  },
  {
    id: 4,
    state: "Michigan",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "70%",
    industry: "Logistics",
    multiple: "3.5x",
  },
  {
    id: 5,
    state: "New York",
    revenue: "$2-3M",
    ebitda: "25-35%",
    growth: "15-25%",
    salePrice: "$4M",
    verified: true,
    date: "2 months ago",
    match: "70%",
    industry: "Logistics",
    multiple: "3.2x",
  },

  {
    id: 6,
    state: "Texas",
    revenue: "$3-4M",
    ebitda: "20-30%",
    growth: "20-30%",
    salePrice: "$4.5M",
    verified: false,
    date: "9 months ago",
    match: "64%",
    industry: "Logistics",
    multiple: "3.8x",
  },
  {
    id: 7,
    state: "California",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "59%",
    industry: "Logistics",
    multiple: "3.5x",
  },
  {
    id: 8,
    state: "New York",
    revenue: "$2-3M",
    ebitda: "25-35%",
    growth: "15-25%",
    salePrice: "$4M",
    verified: true,
    date: "2 months ago",
    match: "70%",
    industry: "Logistics",
    multiple: "3.2x",
  },
  {
    id: 9,
    state: "Texas",
    revenue: "$3-4M",
    ebitda: "20-30%",
    growth: "20-30%",
    salePrice: "$4.5M",
    verified: false,
    date: "9 months ago",
    match: "62%",
    industry: "Logistics",
    multiple: "3.8x",
  },
  {
    id: 10,
    state: "Wyoming",
    revenue: "$1-2M",
    ebitda: "30-40%",
    growth: "10-20%",
    salePrice: "$3.5M",
    verified: true,
    date: "3 months ago",
    match: "60%",
    industry: "Logistics",
    multiple: "3.5x",
  },
];

export function Valuation() {
  const [data, setData] = useState(generateRandomData(1000));

  const [industryFilter, setIndustryFilter] = useState<string | null>(null);
  const [stateFilter, setStateFilter] = useState<string | null>(null);

  const [stats, setStats] = useState(originalStats);
  const [growthPercentile, setGrowthPercentile] = useState(0);
  const [ebitdaPercentile, setEbitdaPercentile] = useState(0);

  const [multiple, setMultiple] = useState(0);
  const [valuation, setValuation] = useState(0);

  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    console.log(
      "DATA",
      data.slice(0, 100).map((d) => d.ebitda_multiple)
    );
  }, [data]);

  useEffect(() => {
    const growthRate = stats.growth_rate;
    const d = data
      .filter((d) => industryFilter === null || d.industry === industryFilter)
      .filter((d) => stateFilter === null || d.state === stateFilter);

    const srted = d
      .map((d) => Number(d.growth_rate.toFixed(2)))
      .sort((a, b) => a - b);

    const index = findClosestIndex(srted, growthRate);

    console.log(
      data.map((d) => Number(d.growth_rate.toFixed(2))).sort((a, b) => a - b)
    );
    console.log("RANK", index);

    const percentileRank = ((index - 1) / d.length) * 100;

    console.log(percentileRank);
    setGrowthPercentile(percentileRank);
  }, [stats.growth_rate, data, industryFilter, stateFilter]);

  useEffect(() => {
    const ebitda_margin = stats.ebitda_margin;
    const d = data
      .filter((d) => industryFilter === null || d.industry === industryFilter)
      .filter((d) => stateFilter === null || d.state === stateFilter);

    const sorted = d.map((d) => d.ebitda_margin).sort((a, b) => a - b);

    const index = findClosestIndex(sorted, ebitda_margin);

    console.log("INFEX", index);

    const percentileRank = ((index - 1) / d.length) * 100;

    console.log("EDBIT RANK", percentileRank);
    setEbitdaPercentile(percentileRank);
  }, [stats.ebitda_margin, data, industryFilter, stateFilter]);

  useEffect(() => {
    const ebitda_margin = stats.ebitda_margin;
    const growthRate = stats.growth_rate;

    const d = data
      .filter((d) => industryFilter === null || d.industry === industryFilter)
      .filter((d) => stateFilter === null || d.state === stateFilter);

    console.log("STUFFFFFF", d);

    const growthRank =
      d
        .map((d) => Number(d.growth_rate.toFixed(2)))
        .sort((a, b) => a - b)
        .findIndex((d) => d === Number(growthRate.toFixed(2))) + 1;

    const startIdx = Math.max(0, growthRank - 20);
    const endIdx = Math.min(d.length, growthRank + 19);

    const growthComps = d
      .sort((a, b) => a.growth_rate - b.growth_rate)
      .slice(startIdx, endIdx);

    console.log("GROTWTH_COMPS", growthComps);

    const avgMultiple =
      growthComps.reduce((acc, curr) => {
        return acc + curr.ebitda_multiple;
      }, 0) / growthComps.length;

    console.log("AVG1", avgMultiple);

    const edbitdaRank =
      d
        .map((d) => Number(d.ebitda_margin.toFixed(2)))
        .sort((a, b) => a - b)
        .findIndex((d) => d === Number(ebitda_margin.toFixed(2))) + 1;

    const startIdx2 = Math.max(0, edbitdaRank - 20);
    const endIdx2 = Math.min(d.length, edbitdaRank + 19);

    const ebitdaComps = d
      .sort((a, b) => a.ebitda_margin - b.ebitda_margin)
      .slice(startIdx2, endIdx2);

    console.log("EBITDA", ebitdaComps);

    const avgMultiple2 =
      ebitdaComps.reduce((acc, curr) => {
        return acc + curr.ebitda_multiple;
      }, 0) / ebitdaComps.length;

    console.log(avgMultiple2);

    const avgMultiple3 = (avgMultiple + avgMultiple2) / 2;
    console.log("avg", avgMultiple3);

    setMultiple(avgMultiple3);

    const valuation = avgMultiple3 * stats.edbitda;
    console.log(valuation);
    setValuation(valuation);
  }, [
    stats.ebitda_margin,
    stats.growth_rate,
    data,
    industryFilter,
    stateFilter,
    setMultiple,
    setValuation,
    stats.edbitda,
  ]);

  const mapRef = useRef<MapRef>(null);
  return (
    <div className="flex-1">
      <div className="bg-white px-8 py-6 w-full shadow-md">
        <div className="flex items-center justify-between">
          <H3>Valuation</H3>
        </div>
      </div>

      <div className="flex p-8">
        <div>
          <div className=" mb-2 flex justify-between items-center">
            <H3 margin="0 0 s 0">Top matches</H3>
            <div className="">
              <p className="text-sm text-gray-500 leading-none">
                Liquidity score
              </p>
              <div className="flex items-center space-x-2">
                <div className="bg-green-400/20 rounded-md h-2 w-40">
                  <div className="bg-green-500 rounded-md h-2 w-32"></div>
                </div>
                <p className="text-gray-500 text-sm">85%</p>
              </div>
            </div>
          </div>
          <Card2 type="success">
            <Table>
              <TableBody>
                <tr>
                  <TableHeading>State</TableHeading>
                  <TableHeading>Revenue</TableHeading>
                  <TableHeading>EBITDA</TableHeading>
                  <TableHeading>Growth</TableHeading>
                  <TableHeading>Multiple</TableHeading>
                  <TableHeading style={{ minWidth: 140 }}>
                    Sale price
                  </TableHeading>
                  <TableHeading>Industry</TableHeading>
                  <TableHeading>Verified</TableHeading>
                  <TableHeading>Date</TableHeading>
                  <TableHeading>Match</TableHeading>
                </tr>
                {similarCompanies.map((item, i) => {
                  return (
                    <TableRow key={item.id} className="group">
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            item.state === "Minnesota" ? "font-bold" : ""
                          }`}
                        >
                          {item.state}
                        </p>
                      </TableData>
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            i === 1 ? "font-bold" : ""
                          }`}
                        >
                          {item.revenue}
                        </p>
                      </TableData>
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            i === 2 ? "font-bold" : ""
                          }`}
                        >
                          {item.ebitda}
                        </p>
                      </TableData>
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            i === 1 ? "font-bold" : ""
                          }`}
                        >
                          {item.growth}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">{item.multiple}</p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">
                          {item.salePrice}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500 font-bold">
                          {item.industry}
                        </p>
                      </TableData>
                      <TableData>
                        {item.verified ? (
                          <CheckIcon className="w-4 h-4 text-green-500" />
                        ) : (
                          <XMarkIcon className="w-4 h-4 text-red-500" />
                        )}
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500 truncate">
                          {item.date}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">{item.match}</p>
                      </TableData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card2>

          <H3 margin="xxxl 0 s 0">Similar companies in Minnesota</H3>
          <Card2>
            <Table>
              <TableBody>
                <tr>
                  <TableHeading>State</TableHeading>
                  <TableHeading>Revenue</TableHeading>
                  <TableHeading>EBITDA</TableHeading>
                  <TableHeading>Growth</TableHeading>
                  <TableHeading>Multiple</TableHeading>
                  <TableHeading style={{ minWidth: 140 }}>
                    Sale price
                  </TableHeading>
                  <TableHeading>Industry</TableHeading>
                  <TableHeading>Verified</TableHeading>
                  <TableHeading>Date</TableHeading>
                  <TableHeading>Match</TableHeading>
                </tr>
                {similarGeographicCompanies.map((item, i) => {
                  return (
                    <TableRow key={item.id} className="group">
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            item.state === "Minnesota" ? "font-bold" : ""
                          }`}
                        >
                          {item.state}
                        </p>
                      </TableData>
                      <TableData>
                        <p className={`text-sm text-gray-500`}>
                          {item.revenue}
                        </p>
                      </TableData>
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            i === 2 ? "" : ""
                          }`}
                        >
                          {item.ebitda}
                        </p>
                      </TableData>
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            i === 1 ? "" : ""
                          }`}
                        >
                          {item.growth}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">{item.multiple}</p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">
                          {item.salePrice}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500 ">
                          {item.industry}
                        </p>
                      </TableData>
                      <TableData>
                        {item.verified ? (
                          <CheckIcon className="w-4 h-4 text-green-500" />
                        ) : (
                          <XMarkIcon className="w-4 h-4 text-red-500" />
                        )}
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500 truncate">
                          {item.date}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">{item.match}</p>
                      </TableData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card2>

          <H3 margin="xxxl 0 s 0">Similar Logistics companies</H3>
          <Card2>
            <Table>
              <TableBody>
                <tr>
                  <TableHeading>State</TableHeading>
                  <TableHeading>Revenue</TableHeading>
                  <TableHeading>EBIDTA</TableHeading>
                  <TableHeading>Growth</TableHeading>
                  <TableHeading>Multiple</TableHeading>
                  <TableHeading style={{ minWidth: 140 }}>
                    Sale price
                  </TableHeading>
                  <TableHeading>Industry</TableHeading>
                  <TableHeading>Verified</TableHeading>
                  <TableHeading>Date</TableHeading>
                  <TableHeading>Match</TableHeading>
                </tr>
                {similarLogisticsCompaniesCountrywide.map((item, i) => {
                  return (
                    <TableRow key={item.id} className="group">
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            item.state === "Minnesota" ? "font-bold" : ""
                          }`}
                        >
                          {item.state}
                        </p>
                      </TableData>
                      <TableData>
                        <p className={`text-sm text-gray-500`}>
                          {item.revenue}
                        </p>
                      </TableData>
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            i === 2 ? "" : ""
                          }`}
                        >
                          {item.ebitda}
                        </p>
                      </TableData>
                      <TableData>
                        <p
                          className={`text-sm text-gray-500 ${
                            i === 1 ? "" : ""
                          }`}
                        >
                          {item.growth}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">{item.multiple}</p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">
                          {item.salePrice}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500 font-bold">
                          {item.industry}
                        </p>
                      </TableData>
                      <TableData>
                        {item.verified ? (
                          <CheckIcon className="w-4 h-4 text-green-500" />
                        ) : (
                          <XMarkIcon className="w-4 h-4 text-red-500" />
                        )}
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500 truncate">
                          {item.date}
                        </p>
                      </TableData>
                      <TableData>
                        <p className="text-sm text-gray-500">{item.match}</p>
                      </TableData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card2>
        </div>

        <div className="ml-4 max-w-sm w-full">
          <H3 margin="0 0 s 0">Your company</H3>
          <Card2>
            <div className="bg-gray-100 p-3">
              <p>
                Logistics company is a family run logistics company based out of
                Minnesota.
              </p>
            </div>
          </Card2>
          {/* <H3 margin="xl 0 s 0">Liquidity score</H3>
          <Card2 padding="m">
            <div className="flex items-center space-x-2">
              <div className="bg-green-400/20 rounded-md h-2 w-full">
                <div className="bg-green-500 rounded-md h-2 w-5/6"></div>
              </div>
              <p className="text-gray-500 text-sm">90%</p>
            </div>
          </Card2> */}
          <H3 margin="xl 0 s 0">Variables</H3>
          <Card2 padding="m">
            <div className="flex">
              <div className="flex-1">
                <Text margin="m 0 xs 0" weight="bold" size="s">
                  Playground analysis
                </Text>
                <Dropdown
                  onSelect={(o: { label: string; value: string | null }) => {}}
                  dropdownType="button"
                  options={[
                    {
                      label: "None selected",
                      value: null,
                    },
                    {
                      label: "Margin adjustments with new staffing plan",
                      value: "margin",
                    },
                    {
                      label: "Revenue adjustments with new pricing plan",
                      value: "revenue",
                    },
                  ]}
                />
              </div>
              {/* <div className="flex-1 mx-4">
                <Text margin="m 0 xs 0" weight="bold" size="s">
                  Industry
                </Text>
                <Dropdown
                  onSelect={(o) => {
                    setIndustryFilter(o.value);
                  }}
                  dropdownType="button"
                  options={[
                    {
                      label: "All industries",
                      value: null,
                    },
                    ...[...new Set(data.map((d) => d.industry))].map((d) => {
                      return {
                        label: d,
                        value: d,
                      };
                    }),
                  ]}
                />
              </div> */}
            </div>
            <H3 margin="xl 0 0 0">Your values</H3>
            <div className="text-gray-500 mt-3 grid grid-cols-2">
              <p className="text-gray-500 font-semibold">Revenue</p>
              <p className="text-gray-500 text-right">$2.2M</p>
              <p className="text-gray-500 font-semibold">EBITDA</p>
              <p className="text-gray-500 text-right">23%</p>
              <p className="text-gray-500 font-semibold">Growth</p>
              <p className="text-gray-500 text-right">18%</p>
            </div>
            {/* <Text margin="m 0 0 0" weight="bold">
              Growth rate
            </Text>
            <Slider
              onChange={(value) => {
                setStats({
                  ...stats,
                  growth_rate: Number(value.toFixed(2)),
                });
              }}
              min={0.01}
              max={0.9}
              value={stats.growth_rate}
            />

            <Text margin="m 0 0 0" weight="bold">
              Ebitda margin
            </Text>
            <Slider
              onChange={(value) => {
                setStats({
                  ...stats,
                  ebitda_margin: Number(value.toFixed(2)),
                });
              }}
              min={0.01}
              max={0.9}
              value={stats.ebitda_margin}
            /> */}

            <div className="w-full h-px bg-gray-200 my-6"></div>
            <div>
              <div className="flex justify-between">
                <div>
                  <p className="text-sm text-gray-500 font-bold">
                    EBITDA Multiple
                  </p>
                  <p className="text-lg font-semibold">3-4x</p>
                </div>

                <div>
                  <p className="text-sm text-gray-500 font-bold">Valuation</p>
                  <p className="text-lg font-semibold">$1-2M</p>
                </div>
              </div>
            </div>
          </Card2>
        </div>
      </div>
    </div>
  );
}

export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "companies",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#51bbd6",
      100,
      "#f1f075",
      750,
      "#f28cb1",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: "companies",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "circle",
  source: "companies",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#11b4da",
    "circle-radius": 4,
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff",
  },
};

function ProgressBar(props: { progress: number }) {
  return (
    <div className="mt-4 relative w-full rounded-lg bg-gray-200 h-4 flex">
      <div className=" bg-green-400  opacity-20 h-full rounded-s-lg w-1/5"></div>
      <div className="bg-green-400 opacity-40  h-full  w-1/5"></div>
      <div className="bg-green-400 opacity-60 h-full  w-1/5"></div>
      <div className="bg-green-400 opacity-80  h-full  w-1/5"></div>
      <div className="bg-green-400  h-full rounded-e-lg  w-1/5"></div>
      <div
        className="transition-all duration-500 absolute bottom-2"
        style={{ left: `${props.progress}%` }}
      >
        <div className="h-4 w-1 rounded-lg bg-green-900 m-auto"></div>
      </div>
    </div>
  );
}

const generateRandomData = (count: number): any[] => {
  const statesWithCities = [
    { state: "Minnesota", lat: 44.9778, lng: -93.265 },
    { state: "New York", city: "New York City", lat: 40.7128, lng: -74.006 },
    { state: "California", city: "Los Angeles", lat: 34.0522, lng: -118.2437 },
    { state: "Illinois", city: "Chicago", lat: 41.8781, lng: -87.6298 },
    { state: "Texas", city: "Houston", lat: 29.7604, lng: -95.3698 },
    { state: "Arizona", city: "Phoenix", lat: 33.4484, lng: -112.074 },
    {
      state: "Pennsylvania",
      city: "Philadelphia",
      lat: 39.9526,
      lng: -75.1652,
    },
    { state: "Texas", city: "San Antonio", lat: 29.4241, lng: -98.4936 },
    { state: "California", city: "San Diego", lat: 32.7157, lng: -117.1611 },
    { state: "Texas", city: "Dallas", lat: 32.7767, lng: -96.797 },
    { state: "California", city: "San Jose", lat: 37.3382, lng: -121.8863 },
    { state: "Florida", city: "Jacksonville", lat: 30.3322, lng: -81.6557 },
    {
      state: "California",
      city: "San Francisco",
      lat: 37.7749,
      lng: -122.4194,
    },
    { state: "Ohio", city: "Columbus", lat: 39.9612, lng: -82.9988 },
    { state: "Texas", city: "Fort Worth", lat: 32.7555, lng: -97.3308 },
    { state: "Indiana", city: "Indianapolis", lat: 39.7684, lng: -86.1581 },
    { state: "North Carolina", city: "Charlotte", lat: 35.2271, lng: -80.8431 },
    { state: "Washington", city: "Seattle", lat: 47.6062, lng: -122.3321 },
    { state: "Colorado", city: "Denver", lat: 39.7392, lng: -104.9903 },
    {
      state: "District of Columbia",
      city: "Washington",
      lat: 38.9072,
      lng: -77.0369,
    },
    { state: "Tennessee", city: "Nashville", lat: 36.1627, lng: -86.7816 },
    // Add more states and cities if needed
  ];

  const industries = [
    "Plumbing",
    "Roofing",
    "Trucking",
    "Construction",
    "Tiling",
  ];

  const randomBetween = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const data: any[] = [];

  for (let i = 0; i < count; i++) {
    const randomStateWithCity =
      statesWithCities[Math.floor(Math.random() * statesWithCities.length)];
    const randomIndustry =
      industries[Math.floor(Math.random() * industries.length)];

    const recurring_revenue = randomBetween(0.1, 0.9);
    const ebitda_margin = randomBetween(0.1, 0.9);
    const growth_rate = randomBetween(0.1, 0.5);

    data.push({
      id: uuid(),
      state: randomStateWithCity.state,
      ebitda_multiple: calculateMultiple(
        ebitda_margin,
        recurring_revenue,
        growth_rate
      ),
      recurring_revenue,
      ebitda_margin,
      growth_rate,
      lat: randomCoordinateShift(randomStateWithCity.lat, 10),
      lng: randomCoordinateShift(randomStateWithCity.lng, 10),
      revenue: randomBetween(1, 30000000),
      industry: randomIndustry,
    });
  }

  return data;
};

interface SliderProps {
  onChange: (value: number) => void;
  min: number;
  max: number;
  value: number;
}

const Slider: React.FC<SliderProps> = ({ onChange, min, max, value }) => {
  const sliderRef = useRef<HTMLInputElement>(null);
  const [labelPosition, setLabelPosition] = useState(0);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value) / 100);
  };

  useEffect(() => {
    if (sliderRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const thumbWidth = 30; // Estimate, can be adjusted based on the browser
      const percent = (value - min) / (max - min);
      const position = percent * sliderWidth - thumbWidth * percent;
      setLabelPosition(position);
    }
  }, [value, min, max]);

  return (
    <div className=" w-full p-4">
      <div className="relative">
        <input
          type="range"
          ref={sliderRef}
          min={min * 100}
          max={max * 100}
          value={value * 100}
          className="w-full cursor-pointer"
          onChange={handleSliderChange}
        />
        <span
          style={{ left: `${labelPosition}px` }}
          className="absolute bottom-full text-center w-8 -translate-x-1/2 transform"
        >
          {(value * 100).toFixed(0)}%
        </span>
      </div>
      <div className="flex justify-between mt-2 text-sm">
        <span>{min * 100}%</span>
        <span>{max * 100}%</span>
      </div>
    </div>
  );
};

function formatCurrency(value: number, compact: boolean) {
  if (compact) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      notation: "compact",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value);
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
}

function randomCoordinateShift(coord: number, maxShiftInMiles: number) {
  const milesPerDegree = 69; // Approximate miles per degree of lat/lon
  const shift = (Math.random() * 2 - 1) * (maxShiftInMiles / milesPerDegree); // random value between -maxShift and +maxShift
  return coord + shift;
}

function calculateMultiple(
  ebitda_margin: number,
  recurring_revenue: number,
  growth_rate: number
): number {
  // Normalize the values
  const normalizedEbitdaMargin = (ebitda_margin - 0.1) / 0.8; // Range: 0.1 - 0.9
  const normalizedRecurringRevenue = (recurring_revenue - 0.1) / 0.8; // Range: 0.1 - 0.9
  const normalizedGrowthRate = (growth_rate - 0.1) / 0.4; // Range: 0.1 - 0.5

  // Calculate the final multiple based on the normalized values
  const multiple =
    1 +
    (14 *
      (normalizedEbitdaMargin +
        normalizedRecurringRevenue +
        normalizedGrowthRate)) /
      3;

  // Ensure the final multiple is between 1 and 15
  return Math.min(Math.max(multiple, 1), 15);
}

function findClosestIndex(arr: number[], target: number): number {
  if (arr.length === 0) return -1; // Return -1 if the array is empty

  let closestIdx = 0;
  let closestDist = Math.abs(arr[0] - target);

  for (let i = 1; i < arr.length; i++) {
    const dist = Math.abs(arr[i] - target);
    if (dist < closestDist) {
      closestDist = dist;
      closestIdx = i;
    }
  }

  return closestIdx;
}

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  ${withMargin};
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableData = styled.td`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
`;

const TableHeading = styled.th`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.typography.secondary};
`;

export const TableRow = styled.tr`
  width: 100%;
  display: table-row;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}33;
  }
`;

export const TableLabel = styled((props) => <Text as="th" {...props} />)`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};
  display: flex;
  white-space: pre;
  ${({ theme }) => css`
    padding: 1px ${theme.spacing.xl} 0 0;
  `}
`;

export const TableValue = styled((props) => <Text as="td" {...props} />)`
  display: table-cell;
  padding: 1px 0;
`;

import { createSlice } from "@reduxjs/toolkit";
import { annualGrowthToPeriodGrowth } from "../../utils/growth";
import { toFixedNumber } from "../../utils/formatCurrency";
import { FileType } from "../../graphql/generated";

export interface FinancialDataValues {
  [key: string]: number; // For quarterly values, e.g., "2024": 50000
}

export interface FinancialCategory {
  type: string;
  values: FinancialDataValues;
  hidden?: boolean;
}

export interface PnLData {
  [category: string]: FinancialCategory; // Categories like "Revenue", "COGS", etc.
}

interface File {
  name: string;
  type: FileType;
}

export interface QofEAnalysis {
  name: string;
  files: File[];
  summary: string[];
  createdBy: {
    username: string;
    avatar: string;
  };
  createdAt: string;
}

export type DemoState = {
  playgroundAnalysis: {
    loading: boolean;
    messages: { id: number; text: string; from: string }[];
    inputs: {
      growth: {
        [key: string]: {
          [key: string]: number; // For quarterly values, e.g., "2024": 50000
        };
      };
    };

    pnl: {
      periods: string[];
      data: PnLData;
    };
  };
  qualityOfEarnings: {
    summary: {};
    accountRec: {};
    analysis: {
      pnl: PnLData;
      cashFlow: PnLData;
      balanceSheet: any[][];
      analyses: QofEAnalysis[];
    };
  };
};

const initialState: DemoState = {
  qualityOfEarnings: {
    summary: {},
    accountRec: {},
    analysis: {
      pnl: {
        Revenue: {
          type: "REVENUE",
          values: {
            "2022": 140000.0,
            "2023": 180000.0,
            "2024": 200000.0,
          },
        },
        Cogs: {
          type: "EXPENSE",
          values: {
            "2022": 24000.0,
            "2023": 28000.0,
            "2024": 32000.0,
          },
        },
        Opex: {
          type: "EXPENSE",
          values: {
            "2022": 2200.0,
            "2023": 2600.0,
            "2024": 3200.0,
          },
        },
        EBITDA: {
          type: "TOTAL",
          values: {
            "2022": 113800.0,
            "2023": 149400.0,
            "2024": 164800.0,
          },
        },
      },
      cashFlow: {
        "Starting Cash Balance": {
          type: "TOTAL",
          values: {
            "2022": 225000,
            "2023": 2,
          },
        },
      },
      balanceSheet: [],
      analyses: [
        {
          name: "Customer Concentration",
          createdAt: "3 days ago",
          createdBy: {
            username: "Dan",
            avatar: "",
          },
          files: [
            {
              name: "Customer breakdown",
              type: FileType.Xlsx,
            },
            {
              name: "Asda Contract",
              type: FileType.Docx,
            },
            {
              name: "Tesco Contract",
              type: FileType.Docx,
            },
          ],
          summary: [
            "40% of revenue comes from 1 customer",
            "Asda contract is locked in for 5 years",
          ],
        },
      ],
    },
  },
  playgroundAnalysis: {
    loading: true,
    messages: [
      {
        id: 1,
        text: "Welcome to the playground!",
        from: "ai",
      },
    ],
    inputs: {
      growth: {
        Revenue: {
          default: 8,
        },
        COGS: {
          default: 4,
        },
        Opex: {
          default: 0.3,
        },
        "Depreciation & Amortization": {
          default: 4,
        },
        Interest: {
          default: 5,
        },
        Tax: {
          default: 6,
        },
      },
    },
    pnl: {
      periods: ["2024", "2025", "2026", "2027", "2028", "2029"],
      data: {
        Revenue: {
          type: "REVENUE",
          values: {
            "2024": 50000.0,
          },
        },
        COGS: {
          type: "EXPENSE",
          values: {
            "2024": 30000.0,
          },
        },
        Opex: {
          type: "EXPENSE",
          values: {
            "2024": 25000.0,
          },
        },
        EBITDA: {
          type: "TOTAL",
          values: {},
        },
        "Depreciation & Amortization": {
          hidden: true,
          type: "EXPENSE",
          values: {
            "2024": 2000,
          },
        },
        EBIT: {
          type: "TOTAL",
          values: {},
        },
        Interest: {
          hidden: true,
          type: "EXPENSE",
          values: {
            "2024": 1500,
          },
        },
        Tax: {
          hidden: true,
          type: "EXPENSE",
          values: {
            "2024": 1625.0,
          },
        },
        "Net Income": {
          type: "TOTAL",
          values: {},
        },
      },
    },
  },
};

export const { actions, reducer } = createSlice({
  name: "demo",
  initialState,
  reducers: {
    updatePeriodGrowth: (
      state,
      action: {
        payload: {
          key: string;
          period: string;
          growth: number;
        };
      }
    ) => {
      state.playgroundAnalysis.inputs.growth[action.payload.key][
        action.payload.period
      ] = action.payload.growth;
    },
    updateAnnualGrowth: (
      state,
      action: {
        payload: {
          key: string;
          annualGrowth: number;
        };
      }
    ) => {
      const periodGrowth = action.payload.annualGrowth;

      const newGrowth = {
        ...state.playgroundAnalysis.inputs.growth[action.payload.key],
      };

      // Object.keys(newGrowth).forEach((quarter) => {
      //   newGrowth[quarter] = periodGrowth;
      // });

      newGrowth["default"] = periodGrowth;

      state.playgroundAnalysis.inputs.growth[action.payload.key] = newGrowth;

      let newForecast = {
        ...state.playgroundAnalysis.pnl,
      };

      let prevQuarter = "";
      newForecast.periods.forEach((quarter, i, quarters) => {
        if (i === 0) {
          prevQuarter = quarter;
          return;
        }

        const prevQuarterValue =
          newForecast.data[action.payload.key].values[prevQuarter];

        const quarterlyGrowth =
          state.playgroundAnalysis.inputs.growth[action.payload.key][quarter] ??
          annualGrowthToPeriodGrowth(
            state.playgroundAnalysis.inputs.growth[action.payload.key][
              "default"
            ],
            "annual"
          );

        if (quarterlyGrowth !== undefined) {
          // Assuming previous quarter's value is available and can be accessed directly
          const prevValue =
            newForecast.data[action.payload.key].values[quarter];
          newForecast.data[action.payload.key].values[quarter] = toFixedNumber(
            prevQuarterValue * (1 + quarterlyGrowth),
            1
          );
        }

        prevQuarter = quarter;
      });

      const totalRows = Object.keys(state.playgroundAnalysis.pnl.data).filter(
        (r) => state.playgroundAnalysis.pnl.data[r].type === "TOTAL"
      );

      totalRows.forEach((row) => {
        if (row === "EBITDA") {
          const revenue = newForecast.data["Revenue"].values;
          const cogs = newForecast.data["COGS"].values;
          const opex = newForecast.data["Opex"].values;

          const ebitda = {
            ...newForecast.data["EBITDA"].values,
          };

          state.playgroundAnalysis.pnl.periods.forEach((quarter) => {
            ebitda[quarter] = toFixedNumber(
              revenue[quarter] - cogs[quarter] - opex[quarter],
              1
            );
          });

          newForecast.data["EBITDA"].values = ebitda;
        }

        if (row === "EBIT") {
          const ebitda = newForecast.data["EBITDA"].values;
          const depreciation =
            newForecast.data["Depreciation & Amortization"].values;

          const ebit = {
            ...newForecast.data["EBIT"].values,
          };

          state.playgroundAnalysis.pnl.periods.forEach((quarter) => {
            ebit[quarter] = toFixedNumber(
              ebitda[quarter] - depreciation[quarter],
              1
            );
          });

          newForecast.data["EBIT"].values = ebit;
        }

        if (row === "Net Income") {
          const ebit = newForecast.data["EBIT"].values;
          const interest = newForecast.data["Interest"].values;
          const tax = newForecast.data["Tax"].values;

          const netIncome = {
            ...newForecast.data["Net Income"].values,
          };

          state.playgroundAnalysis.pnl.periods.forEach((quarter) => {
            netIncome[quarter] = toFixedNumber(
              ebit[quarter] - interest[quarter] - tax[quarter],
              1
            );
          });

          newForecast.data["Net Income"].values = netIncome;
        }
      });

      state.playgroundAnalysis.pnl = newForecast;
    },
    initialize: (state) => {
      const rows = Object.keys(state.playgroundAnalysis.pnl.data).filter(
        (r) => state.playgroundAnalysis.pnl.data[r].type !== "TOTAL"
      );

      const totalRows = Object.keys(state.playgroundAnalysis.pnl.data).filter(
        (r) => state.playgroundAnalysis.pnl.data[r].type === "TOTAL"
      );

      const newData = {
        ...state.playgroundAnalysis.pnl.data,
      };

      rows.forEach((row) => {
        let prevQuarter = "";
        state.playgroundAnalysis.pnl.periods.forEach((quarter, i) => {
          if (i === 0) {
            prevQuarter = quarter;
            return;
          }

          const prevQuarterValue = newData[row].values[prevQuarter];

          const quarterlyGrowth =
            state.playgroundAnalysis.inputs.growth[row][quarter] ??
            annualGrowthToPeriodGrowth(
              state.playgroundAnalysis.inputs.growth[row]["default"],
              "annual"
            );

          if (quarterlyGrowth !== undefined) {
            // Assuming previous quarter's value is available and can be accessed directly
            newData[row].values[quarter] = toFixedNumber(
              prevQuarterValue * (1 + quarterlyGrowth),
              1
            );
          }

          prevQuarter = quarter;
        });
      });

      totalRows.forEach((row) => {
        if (row === "EBITDA") {
          const revenue = newData["Revenue"].values;
          const cogs = newData["COGS"].values;
          const opex = newData["Opex"].values;

          const ebitda = {
            ...newData["EBITDA"].values,
          };

          state.playgroundAnalysis.pnl.periods.forEach((quarter) => {
            ebitda[quarter] = toFixedNumber(
              revenue[quarter] - cogs[quarter] - opex[quarter],
              1
            );
          });

          newData["EBITDA"].values = ebitda;
        }

        if (row === "EBIT") {
          const ebitda = newData["EBITDA"].values;
          const depreciation = newData["Depreciation & Amortization"].values;

          const ebit = {
            ...newData["EBIT"].values,
          };

          state.playgroundAnalysis.pnl.periods.forEach((quarter) => {
            ebit[quarter] = toFixedNumber(
              ebitda[quarter] - depreciation[quarter],
              1
            );
          });

          newData["EBIT"].values = ebit;
        }

        if (row === "Net Income") {
          const ebit = newData["EBIT"].values;
          const interest = newData["Interest"].values;
          const tax = newData["Tax"].values;

          const netIncome = {
            ...newData["Net Income"].values,
          };

          state.playgroundAnalysis.pnl.periods.forEach((quarter) => {
            netIncome[quarter] = toFixedNumber(
              ebit[quarter] - interest[quarter] - tax[quarter],
              1
            );
          });

          newData["Net Income"].values = netIncome;
        }
      });

      state.playgroundAnalysis.loading = false;
    },
    recalculateForecast: (state, action: { payload: { key: string } }) => {},
    addMessage: (
      state,
      action: { payload: { id: number; message: string; from: string } }
    ) => {
      const newMessage = {
        id: action.payload.id,
        text: action.payload.message,
        from: action.payload.from,
      };
      state.playgroundAnalysis.messages.push(newMessage);
    },
    updateMessage: (
      state,
      action: { payload: { id: number; text: string } }
    ) => {
      const message = state.playgroundAnalysis.messages.find(
        (m) => m.id === action.payload.id
      );

      if (message) {
        message.text = action.payload.text;
      }
    },
  },
});

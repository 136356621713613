import { Link, useLocation } from "react-router-dom";
import { H3 } from "../../../components/Heading";
import { Card2 } from "../../../components/Card";
import { classNames } from "../../../utils/cn";
import { statusColors } from "../Dashboard";

const tabs = [
  {
    name: "Onboarding",
  },
  {
    name: "NDA",
  },
  {
    name: "Heads of Terms",
  },
  {
    name: "Due Diligence",
  },
  {
    name: "LOI",
  },
];

const bidders = [
  {
    name: "Mid-market Law Firm",
    offer: "$1,500,000",
    status: "ACTIVE",
    stage: "DS",
    stageStatus: "GOOD",
    steps: ["GOOD", "GOOD", "GOOD", "GOOD", "PENDING"],
    lastSeen: `3 hours ago (Henry)`,
    joined: "3 weeks ago",
    people: [
      {
        name: "Henry",
        avatar: undefined,
        avatarColor: "bg-indigo-400",
        position: "Partner",
      },
      {
        name: "John",
        avatar: undefined,
        avatarColor: "bg-orange-400",
        position: "Associate",
      },
    ],
    questions: [
      {
        title: "Is there a revenue breakdown anywhere?",
        askedAt: "2 hours ago",
        askedBy: "Henry",
        commentCount: 2,
      },
    ],
    activity: [],
  },
  {
    name: "M&A Lawyers",
    offer: "$1,500,000",
    status: "ACTIVE",
    stage: "DS",
    stageStatus: "GOOD",
    steps: ["GOOD", "GOOD", "GOOD", "GOOD", "PENDING"],
    lastSeen: `6 hours ago (Charlotte)`,
    joined: "1 month ago",
    people: [
      {
        name: "Charlotte",
        avatar: undefined,
        avatarColor: "bg-emerald-400",
        position: "Partner",
      },
      {
        name: "Stephen",
        avatar: undefined,
        avatarColor: "bg-blue-400",
        position: "Associate",
      },
    ],
    questions: [],
    activity: [],
  },
  {
    name: "SMB Law Firm",
    offer: "$1,500,000",
    status: "ACTIVE",
    stage: "DS",
    stageStatus: "ISSUE",
    joined: "2 weeks ago",
    steps: ["GOOD", "GOOD", "ISSUE", "PENDING", "PENDING"],
    lastSeen: `3 days ago (Eric)`,
    people: [
      {
        name: "E",
        avatar: undefined,
        avatarColor: "bg-violet-400",
        position: "Partner",
      },
      {
        name: "E",
        avatar: undefined,
        avatarColor: "bg-fuchsia-400",
        position: "Associate",
      },
    ],
    questions: [],
    activity: [],
  },
  {
    name: "SMB Accountancy Firm",
    offer: "$1,500,000",
    status: "INACTIVE",
    stage: "DS",
    stageStatus: "PENDING",
    joined: "2 weeks ago",
    steps: ["GOOD", "PENDING", "PENDING", "PENDING", "PENDING"],
    lastSeen: `2 weeks ago (Roger)`,
    people: [
      {
        name: "Rob",
        avatar: undefined,
        avatarColor: "bg-rose-400",
        position: "Partner",
      },
      {
        name: "V",
        avatar: undefined,
        avatarColor: "bg-blue-400",
        position: "Associate",
      },
    ],
    questions: [],
    activity: [],
  },
];

const navigation = [
  {
    name: "Active",
    href: "/s/bidders/active",
    current: true,
  },
  {
    name: "Inactive",
    href: "/s/bidders/inactive",
    current: false,
  },
];

export function Bidders() {
  const location = useLocation();

  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 pt-4 w-full shadow-md z-50">
        <div className="flex items-center justify-between">
          <H3>Buyers</H3>
        </div>
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="hidden sm:-my-px sm:flex sm:space-x-8">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname === item.href
                      ? "border-indigo-500 text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 p-8 w-full 2xl:w-1/2">
        <Card2>
          {bidders.map((item, i) => (
            <Link to="/s/bidder">
              <div
                className={classNames(
                  `p-4  flex items-center hover:bg-gray-100`,
                  i === 0 ? "rounded-t-md" : "",
                  i === bidders.length - 1 ? "rounded-b-md" : ""
                )}
              >
                <div className="flex-1 ">
                  <p className="font-bold text-gray-600 text-xl">{item.name}</p>
                  <div className="mt-3 w-2/3 flex space-x-0.5">
                    {item.steps.map((step, i) => {
                      return (
                        <div className="flex-1">
                          <div
                            title={step}
                            className={`${statusColors[step]} h-1 flex-1`}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                  {/* {item.name === "KKR" ? (
                    <div className="w-2 h-2 ml-2 rounded-full bg-rose-300 animate-pulse"></div>
                  ) : null} */}
                </div>

                <div className="flex gap-x-4">
                  <div className="flex -space-x-0.5">
                    {item.people.slice(0, 3).map((p) => {
                      if (p.avatar) {
                        return (
                          <img
                            src={p.avatar}
                            alt=""
                            className="relative ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                          />
                        );
                      }

                      return (
                        <div
                          className={`relative w-6 h-6 ring-2 ring-white flex-none ${p.avatarColor} rounded-full items-center justify-center flex`}
                        >
                          <p className="text-sm text-white">{p.name[0]}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Card2>

        {/* {tabs.map((item) => (
          <div>
            <div className="font-bold text-gray-600">{item.name}</div>
            <div className="mt-4">
              <div className="mb-3">
                <Card2 padding="m">
                  <div className="flex justify-between">
                    <div>
                      <div className="font-bold text-gray-600">Company A</div>
                      <div className="text-gray-600">Offer: $1,000,000</div>
                    </div>
                    <div className="flex items-center">
                      <div className="text-gray-600">Stage: Due Diligence</div>
                    </div>
                  </div>
                </Card2>
              </div>
              <div>
                <Card2 padding="m">
                  <div className="flex justify-between">
                    <div>
                      <div className="font-bold text-gray-600">Company A</div>
                      <div className="text-gray-600">Offer: $1,000,000</div>
                    </div>
                    <div className="flex items-center">
                      <div className="text-gray-600">Stage: Due Diligence</div>
                    </div>
                  </div>
                </Card2>
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
}

interface BidderProgressProps {
  status: string;
}

function BidderProgress(props: BidderProgressProps) {
  if (props.status === "Onboarding") {
    return (
      <div>
        <div className="flex items-center bg-purple-500"></div>
      </div>
    );
  }

  return (
    <div className="mt-8 grid grid-cols-4">
      <div className="my-2 flex items-center">
        <div className="w-5 h-5 bg-green-400 opacity-90 rounded-full"></div>
        <div className="ml-2">
          <p className=" text-gray-500 text-sm font-bold">NDA</p>
          <p className="text-xs text-gray-400">Signed 2 weeks ago</p>
        </div>
      </div>
      <div className="my-2 flex items-center">
        <div className="w-5 h-5 bg-orange-300  rounded-full"></div>
        <div className="ml-2">
          <p className=" text-gray-500 text-sm font-bold">Questions</p>
          <p className="text-xs text-gray-400">2 new questions</p>
        </div>
      </div>
      <div className="my-2 flex items-center">
        <div className="w-5 h-5 bg-orange-300  rounded-full"></div>
        <div className="ml-2">
          <p className=" text-gray-500 text-sm font-bold">Tasks</p>
          <p className="text-xs text-gray-400">3 tasks left</p>
        </div>
      </div>
      <div className="my-2 flex items-center">
        <div className="relative rotate-90 flex justify-center items-center">
          <div
            className="h-2.5 w-5 bg-green-400 
            rounded-tl-full rounded-tr-full "
          ></div>
        </div>
        <div className="ml-2">
          <p className=" text-gray-500 text-sm font-bold">Due Diligence</p>
          <p className="text-xs text-gray-400">4 days left</p>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import { useTheme } from "../hooks/useTheme";
import styled from "../styles";
import { Flex } from "./Flex";

const BurgerPatties = styled.div`
  max-height: 3px;
  height: 100%;
  border-radius: 99px;
  width: 32px;
  transform-origin: 3px;
  position: relative;
  background: ${(p) => p.theme.color.typography.secondary};
  transition: opacity 150ms linear, transform 200ms ease-out;
`;

const BurgerMenu = styled(Flex)<{ showMenu?: boolean }>`
  cursor: pointer;
  width: 32px;
  height: 22px;

  div {
    :first-child {
      transform: ${(p) => (p.showMenu ? "rotate(45deg)" : "rotate(0deg)")};
    }

    :nth-child(2) {
      opacity: ${(p) => (p.showMenu ? 0 : 1)};
    }

    :nth-child(3) {
      transform: ${(p) => (p.showMenu ? "rotate(-45deg)" : "rotate(0deg)")};
    }
  }
`;

function MobileMenu({
  showMenu,
  menuToggle,
}: {
  showMenu: boolean;
  menuToggle: (x: boolean) => void;
}) {
  const theme = useTheme();

  return (
    <BurgerMenu
      justify="space-between"
      direction="column"
      onClick={() => menuToggle(!showMenu)}
      showMenu={showMenu}
    >
      <BurgerPatties />
      <BurgerPatties />
      <BurgerPatties />
    </BurgerMenu>
  );
}

export default MobileMenu;

export function formatCurrency(value: number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // Use 'EUR', 'GBP', etc., based on the currency you need
    minimumFractionDigits: 1, // Ensure at least one decimal place
    maximumFractionDigits: 1, // Ensure no more than one decimal place
  }).format(value);
}

export function toFixedNumber(num: number, digits: number) {
  const base = 10;
  const pow = Math.pow(base, digits);
  return Math.round(num * pow) / pow;
}

import React, { useEffect, useState } from "react";
import { H3 } from "../../../components/Heading";
import { classNames } from "../../../utils/cn";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  ChartOptions,
} from "chart.js";
import { FileIcon } from "../../../components/FileIcon";
import { useDispatch, useSelector } from "react-redux";
import { demoSelectors } from "../../../store/demo/selector";
import Spreadsheet, { ColumnIndicatorProps } from "react-spreadsheet";
import { QofEAnalysis, actions } from "../../../store/demo/slice";
import Loading from "../../../components/Loading";
import { Dropdown } from "../../../components/Dropdown";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DocumentArrowUpIcon,
  EyeIcon,
  PaperClipIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { DocumentIcon as DocumentIconOutline } from "@heroicons/react/24/outline";
import { Card2 } from "../../../components/Card";
import { v4 as uuid } from "uuid";
import {
  Column,
  Heading,
  Row,
  Rows,
  TotalRow,
} from "../statements2/BalanceSheet";
import {
  DataRoomFile,
  FileType,
  RowInput,
  RowType,
  RowValue,
  RowValueInput,
  Row as gqlRow,
  useCreatePnlVersionMutation,
  useFinancialsQuery,
} from "../../../graphql/generated";
import { Text } from "../../../components/Text";
import { Button } from "../../../components/Button";
import useGqlClient from "../../../hooks/useGqlClient";
import { highlightedFields } from "../statements2/ProfitLoss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);

export function Report() {
  return (
    <div className="flex-1">
      <div className="grid grid-cols-1 gap-8">
        <div className="">
          <Tabs />
        </div>
        <div>
          <H3>Activity</H3>
          <Activity />
        </div>
      </div>
    </div>
  );
}

const tabs = [
  { name: "Summary", href: "#", current: true },
  { name: "Account Reconciliation", href: "#", current: false },
  { name: "Analysis", href: "#", current: false },
];

function Tabs() {
  const [currentTab, setCurrentTab] = React.useState(tabs[0]);
  return (
    <div className="px-2">
      <Card2 padding="0 m xxs m">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={currentTab ? currentTab.name : ""}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block sm:overflow-x-scroll no-scrollbar">
          <div className="">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  onClick={() => {
                    setCurrentTab(tab);
                  }}
                  className={classNames(
                    currentTab === tab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "cursor-pointer whitespace-nowrap border-b-2 py-4  px-1 text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </Card2>
      <div className="mt-4">
        <TabRenderer currentTab={currentTab.name} />
      </div>
    </div>
  );
}

interface TabRendererProps {
  currentTab: string;
}

function TabRenderer(props: TabRendererProps) {
  switch (props.currentTab) {
    case "Summary":
      return <Summary />;
    case "Analysis":
      return <Analysis />;
    case "Account Reconciliation":
      return <AccountReconiliation />;
    // case "Working Capital Analysis":
    //     return <WorkingCapitalAnalysis />;
    // case "Other considerations":
    //     return <OtherConsiderations />;
    default:
      return <Summary />;
  }
}

function AccountReconiliation() {
  const analysis = useSelector(demoSelectors.qofEanalyses)[0];
  return (
    <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
      <div className="col-span-2">
        <Card2 padding="m">
          <div className="overflow-y-scroll no-scrollbar">
            <div className="flex items-center justify-between mb-2">
              <p className="font-semibold text-gray-600">Financials</p>
            </div>
            <FinanceTable
              title="Profit and Loss (£k)"
              data={[
                ["Revenue", 4804.7, 5157.8, 5436.3, 5644.7, 5758.1],
                ["Revenue Growth %", "9.4%", "7.3%", "5.4%", "3.8%", "2.0%"],
                ["Gross Margin %", "29.0%", "35.0", "37.0%", "40.0%", "42.0%"],
                ["", "", "", "", "", ""],
                [
                  "Staff as % of opex",
                  "12.0%",
                  "12.0%",
                  "12.0%",
                  "12.0%",
                  "12.0%",
                ],
                [
                  "Marketing as % of opex",
                  "3.0%",
                  "3.0%",
                  "3.0%",
                  "3.0%",
                  "3.0%",
                ],
                ["", "", "", "", "", ""],
                ["EBITDA", 624.6, 980.0, 1141.6, 1354.7, 1497.1],
                [
                  "EBITDA margin %",
                  "13.0%",
                  "19.0%",
                  "21.0%",
                  "24.0%",
                  "26.0%",
                ],
                [
                  "EBITDA growth %",
                  "12.3%",
                  "56.9%",
                  "16.5%",
                  "18.7%",
                  "10.5%",
                ],
                ["", "", "", "", "", ""],
                ["Adjusted EBITDA", 624.6, 980.0, 1141.6, 1354.7, 1497.1],
                [
                  "Adjusted EBITDA margin %",
                  "13.0%",
                  "19.0%",
                  "21.0%",
                  "24.0%",
                  "26.0%",
                ],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["D&A as % of revenue", "1.0%", "1.0%", "1.0%", "1.0%", "1.0%"],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["EBIT", 672.7, 1031.6, 1196.0, 1411.2, 1554.7],
                ["EBIT margin %", "14.0%", "20.0%", "22.0%", "25.0%", "27.0%"],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                [
                  "Implied Cost of Debt",
                  "10.0%",
                  "6.7%",
                  "0.0%",
                  "0.0%",
                  "0.0%",
                ],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["EBT", 622.7, 1011.6, 1196.0, 1411.2, 1554.7],
                ["EBT Margin %", "14.0%", "20.0%", "22.0%", "25.0%", "27.0%"],
                ["Implied Tax Rate", "-20%", "-20%", "-20%", "-20%", "-20%"],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Net profit", 747.2, 1213.9, 1435.2, 1693.4, 1865.6],
                ["Net margin %", "15.6%", "23.5%", "26.4%", "30.0%", "32.4%"],
                [
                  "Net profit growth %",
                  "-157.1%",
                  "62.5%",
                  "18.2%",
                  "18.0%",
                  "10.2%",
                ],
              ]}
            />
            {/* <Financials /> */}
          </div>
        </Card2>
      </div>
      <div>
        <Card2 padding="m">
          <div className="flex items-center justify-between">
            <p className="text-gray-500 font-semibold mt-2">Variance</p>
          </div>
          <table className="mt-4 mb-12 px-4 ">
            <tr>
              <th>
                <p></p>
              </th>

              <th className="pr-4">
                <p className="text-right text-gray-600 text-sm">2022</p>
              </th>
              <th className="pr-4">
                <p className="text-right text-gray-600 text-sm">2023</p>
              </th>
            </tr>

            <tr>
              <td className="w-full text-gray-500 font-semibold pl-4">P&L</td>

              <td className="text-right text-sm  text-gray-600 pr-4">
                {(4804.7).toLocaleString()}
              </td>
              <td className="text-right text-sm  text-gray-600 pr-4">
                {(5157.8).toLocaleString()}
              </td>
            </tr>
            <tr>
              <td className="w-full pl-4 text-gray-500 font-semibold">
                Bank Statement
              </td>

              <td className="text-right text-sm text-gray-600 pr-4">
                {(4610).toLocaleString()}
              </td>
              <td className="text-right text-sm text-gray-600 pr-4">
                {(5140.5).toLocaleString()}
              </td>
            </tr>
            <tr className="bg-gray-100">
              <td className="w-full text-gray-500 font-semibold pl-4">
                Variance
              </td>
              <td className="text-right text-sm  text-gray-600 pr-4">-4.05%</td>
              <td className="text-right text-sm  text-gray-600 pr-4">-0.34%</td>
            </tr>
          </table>

          <p className="text-gray-500 font-semibold mt-4">Documents</p>
          <div className="flex mt-2 items-center gap-2 flex-wrap">
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Other} />
              <p className="text-xs ml-1 truncate">2022</p>
            </div>
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Other} />
              <p className="text-xs ml-1">2023</p>
            </div>
            <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
              <FileIcon size="s" fileType={FileType.Other} />
              <p className="text-xs ml-1 truncate">2024</p>
            </div>
          </div>
        </Card2>
      </div>
    </div>
  );
}

function Summary() {
  const analysis = useSelector(demoSelectors.qofEanalyses)[0];
  return (
    <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
      <div className="col-span-2">
        <Card2 padding="m">
          <div
            style={{ height: 800 }}
            className="overflow-y-scroll no-scrollbar"
          >
            <div className="flex items-center justify-between">
              <p className="font-semibold text-gray-600">Report</p>
              <button className="text-indigo-500 text-sm font-semibold hover:text-indigo-600 hover:bg-indigo-50 px-2 py-1 rounded-md">
                Export
              </button>
            </div>
            <p className="my-2 mt-4 text-lg text-gray-600">Financials</p>

            <FinanceTable
              title="Profit and Loss (£k)"
              data={[
                ["Revenue", 4804.7, 5157.8, 5436.3, 5644.7, 5758.1],
                ["Revenue Growth %", "9.4%", "7.3%", "5.4%", "3.8%", "2.0%"],
                ["Gross Margin %", "29.0%", "35.0", "37.0%", "40.0%", "42.0%"],
                ["", "", "", "", "", ""],
                [
                  "Staff as % of opex",
                  "12.0%",
                  "12.0%",
                  "12.0%",
                  "12.0%",
                  "12.0%",
                ],
                [
                  "Marketing as % of opex",
                  "3.0%",
                  "3.0%",
                  "3.0%",
                  "3.0%",
                  "3.0%",
                ],
                ["", "", "", "", "", ""],
                ["EBITDA", 624.6, 980.0, 1141.6, 1354.7, 1497.1],
                [
                  "EBITDA margin %",
                  "13.0%",
                  "19.0%",
                  "21.0%",
                  "24.0%",
                  "26.0%",
                ],
                [
                  "EBITDA growth %",
                  "12.3%",
                  "56.9%",
                  "16.5%",
                  "18.7%",
                  "10.5%",
                ],
                ["", "", "", "", "", ""],
                ["Adjusted EBITDA", 624.6, 980.0, 1141.6, 1354.7, 1497.1],
                [
                  "Adjusted EBITDA margin %",
                  "13.0%",
                  "19.0%",
                  "21.0%",
                  "24.0%",
                  "26.0%",
                ],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["D&A as % of revenue", "1.0%", "1.0%", "1.0%", "1.0%", "1.0%"],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["EBIT", 672.7, 1031.6, 1196.0, 1411.2, 1554.7],
                ["EBIT margin %", "14.0%", "20.0%", "22.0%", "25.0%", "27.0%"],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                [
                  "Implied Cost of Debt",
                  "10.0%",
                  "6.7%",
                  "0.0%",
                  "0.0%",
                  "0.0%",
                ],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["EBT", 622.7, 1011.6, 1196.0, 1411.2, 1554.7],
                ["EBT Margin %", "14.0%", "20.0%", "22.0%", "25.0%", "27.0%"],
                ["Implied Tax Rate", "-20%", "-20%", "-20%", "-20%", "-20%"],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Net profit", 747.2, 1213.9, 1435.2, 1693.4, 1865.6],
                ["Net margin %", "15.6%", "23.5%", "26.4%", "30.0%", "32.4%"],
                [
                  "Net profit growth %",
                  "-157.1%",
                  "62.5%",
                  "18.2%",
                  "18.0%",
                  "10.2%",
                ],
              ]}
            />

            <div className="my-6 h-px w-full bg-gray-200"></div>
            <FinanceTable
              title="Key metrics (£k)"
              data={[
                [
                  "Annual Reccuring Revenue",
                  5765.6,
                  6189.3,
                  6523.6,
                  6773.6,
                  6909.7,
                ],
                ["Implied CAC", 0.3, 0.4, 0.6, 0.8, 1.5],
                ["Implied LTV", 1.4, 1.8, 2.3, 3.3, 6.1],
                ["LTV/CAC", "4.0x", "4.2x", "4.4x", "4.6x", "4.8x"],
                ["Number of customers", 12000, 12882, 13578, 14098, 14381],
                ["Number of employees", 10, 11, 12, 13, 14],
                ["Average employee salary", 57.7, 56.3, 54.4, 52.1, 49.4],
              ]}
            />

            <div className="my-6 h-px w-full bg-gray-200"></div>
            <FinanceTable
              title="Cash Flow (£k)"
              data={[
                ["Change in Net Working Capital", 77.4, 74.2, 92.7, 84.4, 88.6],
                ["Capex", -300, -300, -300, -300, -300],
                ["Operating Cash Flow", 477.4, 811.9, 995.1, 1156.9, 1274.8],
                ["Financing Cash Flow", -300, -300, -300, -300, -300],
                ["Investing Cash Flow", 0, -50, -20, 0, 0],
                ["Beginning Cash", 400, 577.4, 1039.3, 1714.4, 2571.3],
                ["Net Change in Cash", 177.4, 461.9, 675.1, 856.9, 974.8],
                ["Ending Cash", 577.4, 1039.3, 1714.4, 2571.3, 3546.1],
              ]}
            />

            <div className="my-6 h-px w-full bg-gray-200"></div>
            <FinanceTable
              title="Balance Sheet Pre-Completion (£k)"
              data={[
                [
                  "Cash and Cash Equivalents",
                  577.4,
                  1039.3,
                  1714.4,
                  2571.3,
                  3546.1,
                ],
                ["Total Debt", 500, 300, 0, 0, 0],
                ["Net debt", -77.4, -739.3, -1714.4, -2571.3, -3546.1],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Current Assets", 840.7, 1321.9, 2012.3, 2880.6, 3861.6],
                ["Non-Current Assets", 2181.7, 2288.7, 2280.9, 2528, 2774],
                ["Total Assets", 3022.4, 3610.7, 4293.2, 5408.6, 6635.6],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Current Liabilities", 1215, 1194.1, 1219.8, 1206.3, 1189.5],
                ["Non-Current Liabilities", 500, 300, 0, 0, 0],
                ["Total Liabilities", 1715, 1494.1, 1219.8, 1206.3, 1189.5],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Retained Earnings", 1307.4, 2116.6, 3073.4, 4202.3, 5446.1],
                [
                  "Total Shareholder Equity",
                  1307.4,
                  2116.6,
                  3073.4,
                  4202.3,
                  5446.1,
                ],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                [
                  "Total Liabilities & Shareholder Equity",
                  3022.4,
                  3610.7,
                  4293.2,
                  5408.6,
                  6635.6,
                ],
              ]}
            />

            <div className="my-6 h-px w-full bg-gray-200"></div>
            <FinanceTable
              columns={[""]}
              title="Balance Sheet Post-Completion (£k)"
              data={[
                ["Cash and Cash Equivalents", 1826.5],
                ["Total Debt", 500],
                ["Net debt", -326.5],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Current Assets", 2042.6],
                ["Non-Current Assets", 1672.4],
                ["Total Assets", 3715],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Current Liabilities", 1167.8],
                ["Non-Current Liabilities", 500],
                ["Total Liabilities", 1715],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Retained Earnings", 1961.1],
                ["Total Shareholder Equity", 2000],
                ["", "", "", "", "", ""],
                ["", "", "", "", "", ""],
                ["Total Liabilities & Shareholder Equity", 3715],
              ]}
            />

            {/* <Financials /> */}

            <div className="w-full h-px bg-gray-200 my-8" />
            <p className="mt-4 text-lg text-gray-600">Account Reconciliation</p>
            <div className="flex items-center justify-between">
              <p className="text-gray-500 mt-2">
                P&L Revenue variance vs. Bank statements (£k)
              </p>

              <div className="flex items-center">
                <DocumentIconOutline className="w-4 h-4 text-gray-400" />
                <p className="text-sm text-gray-500">3</p>
              </div>
            </div>
            <table className="mt-4 mb-12 px-4 ">
              <tr>
                <th>
                  <p></p>
                </th>

                <th className="pr-4">
                  <p className="text-right text-gray-600 text-sm">2022</p>
                </th>
                <th className="pr-4">
                  <p className="text-right text-gray-600 text-sm">2023</p>
                </th>
              </tr>

              <tr>
                <td className="w-full text-gray-500 font-semibold pl-4">P&L</td>

                <td className="text-right text-sm  text-gray-600 pr-4">
                  {(4804.7).toLocaleString()}
                </td>
                <td className="text-right text-sm  text-gray-600 pr-4">
                  {(5157.8).toLocaleString()}
                </td>
              </tr>
              <tr>
                <td className="w-full pl-4 text-gray-500 font-semibold">
                  Bank Statement
                </td>

                <td className="text-right text-sm text-gray-600 pr-4">
                  {(4610).toLocaleString()}
                </td>
                <td className="text-right text-sm text-gray-600 pr-4">
                  {(5140.5).toLocaleString()}
                </td>
              </tr>
              <tr className="bg-gray-100">
                <td className="w-full text-gray-500 font-semibold pl-4">
                  Variance
                </td>
                <td className="text-right text-sm  text-gray-600 pr-4">
                  -4.05%
                </td>
                <td className="text-right text-sm  text-gray-600 pr-4">
                  -0.34%
                </td>
              </tr>
            </table>

            <div className="w-full h-px bg-gray-200 my-8" />
            <p className="mt-4 text-lg text-gray-600">Revenue Analysis</p>
            <div className="flex items-center justify-between">
              <p className="text-gray-500 mt-2">Customer Concentration</p>

              <div className="flex items-center">
                <DocumentIconOutline className="w-4 h-4 text-gray-400" />
                <p className="text-sm text-gray-500">2</p>
              </div>
            </div>
            <div className="mt-6 flex">
              <PieChart />
              <div className="ml-4">
                {analysis.summary.map((sum) => {
                  return (
                    <p className="text-sm text-gray-500">
                      {`\u2022`} {sum}
                    </p>
                  );
                })}
              </div>
            </div>

            <p className="text-gray-500 mt-4">
              Recurring vs. Non-recurring revenue
            </p>

            <p className="mt-2 text-sm text-gray-500">
              {`\u2022`} 30% of Logistics company' revenue is recurring.
            </p>
            <p className="text-sm text-gray-500">
              {`\u2022`} High customer concentration, 1 customer accounts for
              40% of 2023 revenue.
            </p>
            <p className="text-sm text-gray-500">
              {`\u2022`} One off interest payment last year reduced net profit
              by 10%.{" "}
            </p>
          </div>
        </Card2>
      </div>
      <div>
        <Card2 padding="m">
          <p className="font-semibold text-gray-600">Requests for documents</p>
          <div className="mt-2 flex-wrap flex">
            <div className="flex items-center border border-gray-300 p-4 rounded-md justify-between">
              <div className="flex items-center">
                <img
                  src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                  alt=""
                  className="relative h-5 w-5 flex-none rounded-full bg-gray-50"
                />
                <p className="text-sm text-gray-500 ml-2">
                  Dan and 3 others requested documents for{" "}
                  <span className="font-semibold">Revenue Analysis</span>
                </p>
              </div>
              <div className="flex items-center ml-8">
                <p className="text-xs font-semibold text-indigo-500 truncate">
                  Manage
                </p>
              </div>
            </div>
          </div>
        </Card2>
      </div>
    </div>
  );
}

function Financials() {
  return (
    <div>
      <FinancialItem title="Profit & Loss">
        <div className="pl-5 mt-4">
          <ProfitLoss />
        </div>
      </FinancialItem>
      <FinancialItem title="Cash Flow">
        <div className="pl-5 mt-4">
          <CashFlowInner />
        </div>
      </FinancialItem>
      <FinancialItem title="Balance Sheet">
        <div className="pl-5 mt-4">
          <BalanceSheetInner />
        </div>
      </FinancialItem>
    </div>
  );
}

function FinanceTable(props: {
  data: Array<(string | number)[]>;
  title: string;
  columns?: string[];
}) {
  const columns = props.columns
    ? props.columns
    : ["2022A", "2023A", "2024F", "2025F", "2026F"];

  return (
    <div className="w-full overflow-scroll no-scrollbar">
      <table className={`  `}>
        <thead>
          <tr>
            <th
              style={{ width: "100%" }}
              scope="col"
              className="pr-8    text-left"
            >
              <p className="text-gray-500 truncate  font-normal mt-2">
                {props.title}
              </p>
            </th>
            {columns.map((c) => {
              return (
                <th scope="col" className=" text-sm  text-gray-600 text-right">
                  {c}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.data.map((d) => (
            <tr
              key={d[0]}
              className={`
         
          `}
            >
              <td
                className={`${
                  highlightedFields.includes(d[0].toString())
                    ? " font-semibold"
                    : "font-normal"
                } text-sm truncate ${
                  d[0].toString().includes("%") ? "italic font-light" : ""
                } `}
              >
                {d[0]}
              </td>
              {d.slice(1, d.length).map((column) => (
                <td
                  className={`border-0 ring-0 p-0 text-sm  pl-12`}
                  key={column}
                >
                  <div
                    className={` text-right w-full  py-1 focus:ring-0 focus:outline-none`}
                  >
                    <p
                      className={`${
                        d[0].toString().includes("%")
                          ? "text-gray-500 italic"
                          : ""
                      } ${
                        highlightedFields.includes(d[0].toString())
                          ? " font-semibold"
                          : "font-normal"
                      } `}
                    >
                      {typeof column === "number"
                        ? column.toLocaleString()
                        : column}
                    </p>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function FinancialItem(props: { children: React.ReactNode; title: string }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mb-4 py-1">
      <div
        className={`flex items-center hover:cursor-pointer `}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <ChevronDownIcon className="w-5 h-5 text-gray-500" />
        ) : (
          <ChevronRightIcon className="w-5 h-5 text-gray-500" />
        )}
        <p className="select-none text-gray-600 font-semibold ml-1">
          {props.title}
        </p>
      </div>
      {isOpen ? <>{props.children}</> : null}
    </div>
  );
}

function Analyses() {
  const analyses = useSelector(demoSelectors.qofEanalyses);
  const [selectedAnalysis, setSelectedAnalysis] = useState<QofEAnalysis | null>(
    null
  );

  if (selectedAnalysis) {
    return (
      <div className="mt-2">
        <div className="flex justify-between items-center">
          <p className="text-gray-600 font-semibold">{selectedAnalysis.name}</p>
          <XCircleIcon
            className="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-500"
            onClick={() => {
              setSelectedAnalysis(null);
            }}
          />
        </div>
        <div className="mt-6 flex">
          <PieChart />
          <div className="ml-4">
            <p className="text-gray-600 text-sm font-semibold mb-1">Summary</p>
            {selectedAnalysis.summary.map((sum) => {
              return (
                <p className="text-sm text-gray-500">
                  {`\u2022`} {sum}
                </p>
              );
            })}
            <p className="text-gray-600 text-sm font-semibold mt-4">Files</p>
            <div className="flex items-center gap-x-2 flex-wrap mt-2 gap-y-2">
              {selectedAnalysis.files.map((file) => {
                return (
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={file.type} />
                    <p className="text-xs ml-1 truncate">{file.name}</p>
                  </div>
                );
              })}
            </div>
            <p className="text-gray-600 text-sm font-semibold mt-4">Info</p>
            <div className="flex gap-x-4">
              <div>
                <p className="mt-2 text-xs text-gray-600 font-semibold">
                  Created by
                </p>
                <p className="text-xs text-gray-500">
                  {selectedAnalysis.createdBy.username} {`\u2022`} 3 days ago
                </p>
              </div>
              <div>
                <p className="mt-2 text-xs text-gray-600 font-semibold">
                  Approved by
                </p>
                <p className="text-xs text-gray-500">
                  {selectedAnalysis.createdBy.username} {`\u2022`} 3 days ago
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 mt-2 xl:grid-cols-2">
      {analyses.map((analysis, i) => {
        return (
          <div
            key={i}
            className="px-4 border border-gray-300 rounded-md shadow-sm hover:shadow py-1 cursor-pointer"
            onClick={() => {
              setSelectedAnalysis(analysis);
            }}
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mt-2">
                  {analysis.name}
                </p>
                <p className="text-gray-400 text-sm">{`Created by ${analysis.createdBy.username} ${analysis.createdAt}`}</p>
              </div>
              <div>
                <CheckCircleIcon className="w-5 h-5 text-green-300" />
              </div>
            </div>
            <div className="flex items-center gap-x-2 flex-wrap mt-6 gap-y-2">
              {analysis.files.map((file) => {
                return (
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={file.type} />
                    <p className="text-xs ml-1 truncate">{file.name}</p>
                  </div>
                );
              })}
            </div>
            <div className="mt-4"></div>
          </div>
        );
      })}

      <div className="px-4 hover:cursor-pointer hover:shadow border border-dashed border-gray-300 hover:border-gray-400 rounded-md shadow-sm group py-4">
        <div className="justify-center items-center flex h-full">
          <PlusIcon className="w-6 h-6 text-gray-400 group-hover:text-gray-500" />
          <p className="text-center text-gray-400 group-hover:text-gray-500">
            New
          </p>
        </div>
      </div>
    </div>
  );
}

function Analysis() {
  return (
    <div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div>
          <Card2 padding="m">
            <div className="overflow-x-scroll no-scrollbar">
              <p className="text-gray-500 font-semibold mt-2">Financials</p>
              <div className="mt-2">
                <FinanceTable
                  title="Profit and Loss (£k)"
                  data={[
                    ["Revenue", 4804.7, 5157.8, 5436.3, 5644.7, 5758.1],
                    [
                      "Revenue Growth %",
                      "9.4%",
                      "7.3%",
                      "5.4%",
                      "3.8%",
                      "2.0%",
                    ],
                    [
                      "Gross Margin %",
                      "29.0%",
                      "35.0",
                      "37.0%",
                      "40.0%",
                      "42.0%",
                    ],
                    ["", "", "", "", "", ""],
                    [
                      "Staff as % of opex",
                      "12.0%",
                      "12.0%",
                      "12.0%",
                      "12.0%",
                      "12.0%",
                    ],
                    [
                      "Marketing as % of opex",
                      "3.0%",
                      "3.0%",
                      "3.0%",
                      "3.0%",
                      "3.0%",
                    ],
                    ["", "", "", "", "", ""],
                    ["EBITDA", 624.6, 980.0, 1141.6, 1354.7, 1497.1],
                    [
                      "EBITDA margin %",
                      "13.0%",
                      "19.0%",
                      "21.0%",
                      "24.0%",
                      "26.0%",
                    ],
                    [
                      "EBITDA growth %",
                      "12.3%",
                      "56.9%",
                      "16.5%",
                      "18.7%",
                      "10.5%",
                    ],
                    ["", "", "", "", "", ""],
                    ["Adjusted EBITDA", 624.6, 980.0, 1141.6, 1354.7, 1497.1],
                    [
                      "Adjusted EBITDA margin %",
                      "13.0%",
                      "19.0%",
                      "21.0%",
                      "24.0%",
                      "26.0%",
                    ],
                    ["", "", "", "", "", ""],
                    ["", "", "", "", "", ""],
                    [
                      "D&A as % of revenue",
                      "1.0%",
                      "1.0%",
                      "1.0%",
                      "1.0%",
                      "1.0%",
                    ],
                    ["", "", "", "", "", ""],
                    ["", "", "", "", "", ""],
                    ["EBIT", 672.7, 1031.6, 1196.0, 1411.2, 1554.7],
                    [
                      "EBIT margin %",
                      "14.0%",
                      "20.0%",
                      "22.0%",
                      "25.0%",
                      "27.0%",
                    ],
                    ["", "", "", "", "", ""],
                    ["", "", "", "", "", ""],
                    [
                      "Implied Cost of Debt",
                      "10.0%",
                      "6.7%",
                      "0.0%",
                      "0.0%",
                      "0.0%",
                    ],
                    ["", "", "", "", "", ""],
                    ["", "", "", "", "", ""],
                    ["EBT", 622.7, 1011.6, 1196.0, 1411.2, 1554.7],
                    [
                      "EBT Margin %",
                      "14.0%",
                      "20.0%",
                      "22.0%",
                      "25.0%",
                      "27.0%",
                    ],
                    [
                      "Implied Tax Rate",
                      "-20%",
                      "-20%",
                      "-20%",
                      "-20%",
                      "-20%",
                    ],
                    ["", "", "", "", "", ""],
                    ["", "", "", "", "", ""],
                    ["Net profit", 747.2, 1213.9, 1435.2, 1693.4, 1865.6],
                    [
                      "Net margin %",
                      "15.6%",
                      "23.5%",
                      "26.4%",
                      "30.0%",
                      "32.4%",
                    ],
                    [
                      "Net profit growth %",
                      "-157.1%",
                      "62.5%",
                      "18.2%",
                      "18.0%",
                      "10.2%",
                    ],
                  ]}
                />
                {/* <Financials /> */}
              </div>
            </div>
          </Card2>
        </div>

        <div>
          <Card2>
            <div className="p-4 ">
              <div className="flex w-full items-center justify-between">
                <div>
                  <p className="text-gray-500 font-semibold ">Analyses</p>
                </div>
                <div>
                  <Dropdown
                    dropdownType="text"
                    defaultValue="Revenue analysis"
                    onSelect={(o) => {}}
                    options={[
                      {
                        label: "Revenue analysis",
                        value: "Revenue analysis",
                      },
                      {
                        label: "Cost analysis",
                        value: "Cost analysis",
                      },
                      {
                        label: "Financial performance",
                        value: "Financial performance",
                      },
                      {
                        label: "EBITDA Adjustments",
                        value: "EBITDA Adjustments",
                      },
                    ]}
                  />
                </div>
              </div>

              <Analyses />
            </div>
          </Card2>
        </div>
      </div>
    </div>
  );
}

const customers = {};

function PieChart() {
  const revenues = Array.from(
    { length: 5 },
    () => Math.floor(Math.random() * 1000) + 1
  );

  const data = {
    labels: ["Tesco", "Sainsburys", "Waitrose", "Asda", "Lidl"],
    datasets: [
      {
        label: "Revenue",
        data: [120000, 50000, 35000, 200000, 10000],
        backgroundColor: [
          "#60a5facc",
          "#34d399cc",
          "#fb7185cc",
          "#fdba74cc",
          "#0ea5e9cc",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options: ChartOptions<"pie"> = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <div className="w-56 h-56">
      <Pie data={data} options={options} />
    </div>
  );
}

function RecurringPieChart() {
  const revenues = Array.from(
    { length: 5 },
    () => Math.floor(Math.random() * 1000) + 1
  );

  const data = {
    labels: ["Recurring", "Non-recurring"],
    datasets: [
      {
        label: "Revenue",
        data: [120000 + 50000 + 35000 + 200000, 10000],
        backgroundColor: [
          "#60a5facc",
          "#34d399cc",
          "#fb7185cc",
          "#fdba74cc",
          "#0ea5e9cc",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options: ChartOptions<"pie"> = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <div className="w-60 h-60">
      <Pie data={data} options={options} />
    </div>
  );
}

function CustomColumnIndicator(props: ColumnIndicatorProps) {
  return (
    <th>
      <p className="text-gray-500 text-sm">{props.label}</p>
    </th>
  );
}

// function quartersToYears(pnlData: PnLData) {
//     let result: {[key: string]:FinancialCategory } = {}
//     const quarters = Object.keys(pnlData["Revenue"].values);

//     for (const q of quarters) {
//         const year = q.split("-")[0]
//         result[year].values[year] = result[year].values[]
//     }

// }

const FinancialTable = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.initialize());
  }, []);

  const loading = useSelector(demoSelectors.playgroundLoading);
  const pnl = useSelector(demoSelectors.paygroundPnl);
  const pnlData = pnl.data;
  // Extract all unique quarters from the "Revenue" section (or any other with complete date range)
  const quarters = Object.keys(pnlData["Revenue"].values);

  const columnLabels = quarters;
  const rowLabels = [...Object.keys(pnlData)];
  const data = rowLabels.map((rowLabel) => {
    const values = quarters.map((quarter) => {
      return {
        value: pnlData[rowLabel].values[quarter],
        readOnly: pnlData[rowLabel].type === "TOTAL",
      };
    });
    return values;
  });

  //   console.log(pnlData, loading);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="">
      <Spreadsheet
        ColumnIndicator={CustomColumnIndicator}
        // Cell={CustomCell}
        RowIndicator={({ row, label }) => {
          if (pnlData[label as string].type === "TOTAL") {
            return (
              <div className="flex items-center px-8 py-2">
                <p className="truncate text-indigo-500 font-semibold">
                  {label}
                </p>
              </div>
            );
          }
          return (
            <div className="flex items-center px-8 py-2">
              <p className="truncate text-gray-600 font-semibold">{label}</p>
            </div>
          );
        }}
        columnLabels={columnLabels}
        rowLabels={rowLabels}
        data={data}
      />
      {/* <table className={` w-full `}>
        <thead>
          <tr>
            <th scope="col" className="pr-8  text-gray-600 text-left">
              Category
            </th>
            {quarters.map((quarter) => (
              <th
                scope="col"
                className="px-8 py-0 text-gray-500 text-sm bg-red-500"
                key={quarter}
              >
                {quarter}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(pnlData.pnlData).map(([category, data]) => (
            <tr key={category}>
              <td className="pr-8 text-sm font-semibold truncate">
                {category}
              </td>
              {quarters.map((quarter) => (
                <td
                  className="border-0 ring-0 p-0 text-sm text-gray-500"
                  key={quarter}
                >
                  <input
                    className="border w-full px-4 py-2 border-gray-300 focus:border-gray-500 focus:ring-0 focus:outline-none"
                    type="text"
                    value={data.values[quarter]}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};

function BalanceSheetInner() {
  const [answer, setAnswer] = useState<string>("");
  const dispatch = useDispatch();

  const [editingMode, setEditingMode] = useState<"view" | "edit">("view");
  const [columns, setColumns] = useState<Column[]>([
    {
      id: "col-1",
      name: "FY22",
    },
    {
      id: "col-2",
      name: "FY23",
    },
  ]);

  const [currentAssets, setCurrentAssets] = useState<Row[]>([
    {
      id: "current-assets-1",
      name: "Cash and cash equivalents",
      values: { "col-1": 38016, "col-2": 48844 },
      type: RowType.Income,
    },
    {
      id: "current-assets-2",
      name: "Marketable securities",
      values: {
        "col-1": 52927,
        "col-2": 51713,
      },
      type: RowType.Income,
    },
    {
      id: "current-assets-3",
      name: "Accounts receivable, net",
      values: {
        "col-1": 16120,
        "col-2": 22926,
      },
      type: RowType.Income,
    },
    {
      id: "current-assets-4",
      name: "Inventories",
      values: {
        "col-1": 4061,
        "col-2": 4106,
      },
      type: RowType.Income,
    },
    {
      id: "current-assets-5",
      name: "Vendor non-trade receivables",
      values: {
        "col-1": 21325,
        "col-2": 22878,
      },
      type: RowType.Income,
    },
    {
      id: "current-assets-6",
      name: "Other current assets",
      values: {
        "col-1": 11264,
        "col-2": 12352,
      },
      type: RowType.Income,
    },
  ]);

  const [nonCurrentAssets, setNonCurrentAssets] = useState<Row[]>([
    {
      id: "non-current-assets-1",
      name: "Property, plant and equipment, net",
      values: {
        "col-1": 36766,
        "col-2": 37378,
      },
      type: RowType.Income,
    },
    {
      id: "non-current-assets-2",
      name: "Marketable securities",
      values: {
        "col-1": 100887,
        "col-2": 105341,
      },
      type: RowType.Income,
    },
    {
      id: "non-current-assets-4",
      name: "Other non-current assets",
      values: {
        "col-1": 42522,
        "col-2": 32978,
      },
      type: RowType.Income,
    },
  ]);

  const [currentLiabilities, setCurrentLiabilities] = useState<Row[]>([
    {
      id: "current-liabilities-1",
      name: "Accounts payable",
      values: {
        "col-1": 42296,
        "col-2": 46236,
      },
      type: RowType.Income,
    },
    {
      id: "current-liabilities-2",
      name: "Other current liabilities",
      values: {
        "col-1": 42684,
        "col-2": 37720,
      },
      type: RowType.Income,
    },
    {
      id: "current-liabilities-3",
      name: "Deferred revenue",
      values: {
        "col-1": 6643,
        "col-2": 5522,
      },
      type: RowType.Income,
    },
    {
      id: "current-liabilities-4",
      name: "Commercial paper",
      values: {
        "col-1": 4996,
        "col-2": 5980,
      },
      type: RowType.Income,
    },
    {
      id: "current-liabilities-4",
      name: "Term debt",
      values: {
        "col-1": 8773,
        "col-2": 10260,
      },
      type: RowType.Income,
    },
  ]);

  const [nonCurrentLiabilities, setNonCurrentLiabilities] = useState<Row[]>([
    {
      id: "non-current-liabilities-1",
      name: "Term debt",
      values: {
        "col-1": 98667,
        "col-2": 91807,
        "col-3": 0,
      },
      type: RowType.Income,
    },
    {
      id: "non-current-liabilities-2",
      name: "Other non-current liabilities",
      values: {
        "col-1": 54490,
        "col-2": 50503,
        "col-3": 0,
      },
      type: RowType.Income,
    },
  ]);

  const [equity, setEquity] = useState<Row[]>([
    {
      id: "equity-1",
      name: "Common stock",
      values: {
        "col-1": 50779,
        "col-2": 45174,
        "col-3": 0,
      },
      type: RowType.Income,
    },
    {
      id: "equity-2",
      name: "Retained earnings",
      values: {
        "col-1": 14966,
        "col-2": 45898,
        "col-3": 0,
      },
      type: RowType.Income,
    },
    {
      id: "equity-3",
      name: "Accumulated other comprehensive income/(loss)",
      values: {
        "col-1": -406,
        "col-2": -584,
        "col-3": 0,
      },
      type: RowType.Income,
    },
  ]);
  return (
    <div className="mt-2 p-4 grid grid-cols-5 gap-1 overflow-hidden  overflow-x-scroll no-scrollbar">
      {[{ id: "numbers", name: "" }, ...columns].map((tab, i) => {
        return (
          <div key={tab.id} className={i === 0 ? `col-span-2` : ``}>
            <Text weight="bold">{tab.name}</Text>
          </div>
        );
      })}

      <Heading margin="l 0 0 0" name="Assets" size="l" />

      <TotalRow
        columns={columns}
        rows={currentAssets}
        negativeRows={[]}
        name="Total Current Assets"
        colorPreset="secondaryHeading"
      />

      <TotalRow
        columns={columns}
        rows={nonCurrentAssets}
        negativeRows={[]}
        name="Total Non-Current Assets"
        colorPreset="secondaryHeading"
      />

      <TotalRow
        columns={columns}
        rows={[...currentAssets, ...nonCurrentAssets]}
        negativeRows={[]}
        name="Total Assets"
        colorPreset="success"
      />

      <Heading margin="l 0 0 0" name="Liabilities and Equity" size="l" />

      <TotalRow
        columns={columns}
        rows={currentLiabilities}
        negativeRows={[]}
        colorPreset="secondaryHeading"
        name="Total current liabilities"
      />

      <TotalRow
        columns={columns}
        rows={nonCurrentLiabilities}
        negativeRows={[]}
        colorPreset="secondaryHeading"
        name="Total non-current liabilities"
      />

      <TotalRow
        columns={columns}
        rows={[...currentLiabilities, ...nonCurrentLiabilities]}
        negativeRows={[]}
        colorPreset="secondaryHeading"
        name="Total Liabilities"
      />

      <Heading margin="l 0 0 0" name="Shareholder's Equity" />
      <Rows
        questions={[]}
        editingMode={editingMode}
        columns={columns}
        rows={equity}
        onChange={setEquity}
      />

      <TotalRow
        columns={columns}
        rows={equity}
        negativeRows={[]}
        colorPreset="secondaryHeading"
        name="Total shareholders' equity"
      />

      <TotalRow
        columns={columns}
        rows={[...currentLiabilities, ...nonCurrentLiabilities, ...equity]}
        negativeRows={[]}
        colorPreset="success"
        name="Total Liabilities and Equity"
      />
    </div>
  );
}

function CashFlowInner() {
  const dispatch = useDispatch();

  const [editingMode, setEditingMode] = useState<"view">("view");

  // const { data, isLoading, error, refetch } = useFinancialsQuery(client);

  const [columns, setColumns] = useState<Column[]>([
    {
      id: "col-1",
      name: "FY22",
    },
    {
      id: "col-2",
      name: "FY23",
    },
  ]);

  const [cashBalance, setCashBalance] = useState<Row[]>([
    {
      id: "cash-balance-1",
      name: "Starting Cash balance",
      values: { "col-1": 25000, "col-2": 273300, "col-3": 752100 },
      type: RowType.Income,
    },
  ]);

  const [incomeRows, setIncomeRows] = useState<Row[]>([
    {
      id: "income-1",
      name: "Cash from Operations",
      values: { "col-1": 10000, "col-2": 10000 * 1.2, "col-3": 10000 * 1.3 },
      type: RowType.Income,
    },
    {
      id: "income-1",
      name: "Cash from Sales",
      values: { "col-1": 300000, "col-2": 300000 * 1.2, "col-3": 300000 * 1.3 },
      type: RowType.Income,
    },
  ]);

  const [currentBorrowing, setCurrentBorrowing] = useState<Row[]>([
    {
      id: "current-borrowing-1",
      name: "New current borrowing",
      values: { "col-1": 0, "col-2": 0, "col-3": 0 },
      type: RowType.Income,
    },
  ]);

  const [longTermLiabilities, setLongTermLiabilities] = useState<Row[]>([
    {
      id: "long-term-liabilities-1",
      name: "New long-term liabilities",
      values: { "col-1": 0, "col-2": 0, "col-3": 0 },
      type: RowType.Income,
    },
  ]);

  const [saleOfCurrentAssets, setSaleOfCurrentAssets] = useState<Row[]>([
    {
      id: "sale-of-current-assets-1",
      name: "Sale of current assets",
      values: { "col-1": 150, "col-2": 0, "col-3": 350 },
      type: RowType.Income,
    },
  ]);

  const [saleOfLongTermAssets, setSaleOfLongTermAssets] = useState<Row[]>([
    {
      id: "sale-of-long-term-assets-1",
      name: "Sale of long-term assets",
      values: { "col-1": 0, "col-2": 10000, "col-3": 0 },
      type: RowType.Income,
    },
  ]);

  const [newInvestmentReceived, setNewInvestmentReceived] = useState<Row[]>([
    {
      id: "new-investment-received-1",
      name: "New investment received",
      values: { "col-1": 0, "col-2": 250000, "col-3": 0 },
      type: RowType.Income,
    },
  ]);

  const [expenditures, setExpenditures] = useState<Row[]>([
    {
      id: "expenditure-1",
      name: "Expenditures from Operations",
      values: {
        "col-1": 50000,
        "col-2": 50000 * 1.2,
        "col-3": 50000 * 1.4,
      },
      type: RowType.Expense,
    },
    {
      id: "expenditure-2",
      name: "Cash spending",
      values: {
        "col-1": 1000,
        "col-2": 1500,
        "col-3": 1800,
      },
      type: RowType.Expense,
    },
    {
      id: "expenditure-3",
      name: "Bills",
      values: {
        "col-1": 7500,
        "col-2": 7500 * 1.2,
        "col-3": 7500 * 1.3,
      },
      type: RowType.Expense,
    },
  ]);

  const [additionalCashSpent, setAdditionalCashSpent] = useState<Row[]>([
    {
      id: "additional-cash-spent-1",
      name: "Repayment of current borrowing",
      values: { "col-1": 250, "col-2": 2500, "col-3": 0 },
      type: RowType.Expense,
    },
    {
      id: "additional-cash-spent-2",
      name: "Repayment of long-term liabilities",
      values: { "col-1": 100, "col-2": 200, "col-3": 300 },
      type: RowType.Expense,
    },
    {
      id: "additional-cash-spent-3",
      name: "Purchase of current assets",
      values: { "col-1": 3000, "col-2": 5000, "col-3": 500 },
      type: RowType.Expense,
    },
    {
      id: "additional-cash-spent-4",
      name: "Purchase of long-term assets",
      values: { "col-1": 0, "col-2": 75000, "col-3": 75000 },
      type: RowType.Expense,
    },
    {
      id: "additional-cash-spent-5",
      name: "Dividends",
      values: { "col-1": 0, "col-2": 0, "col-3": 0 },
      type: RowType.Expense,
    },
  ]);
  return (
    <div className="">
      <div className="mt-2 p-4 grid grid-cols-5 gap-1 overflow-hidden  overflow-x-scroll no-scrollbar">
        {[{ id: "numbers", name: "" }, ...columns].map((tab, i) => {
          return (
            <div key={tab.id} className={i === 0 ? `col-span-2` : ``}>
              <Text weight="bold">{tab.name}</Text>
            </div>
          );
        })}

        <TotalRow
          columns={columns}
          rows={cashBalance}
          negativeRows={[]}
          colorPreset="secondaryHeading"
          name="Starting Cash Balance"
        />

        <AddButton
          editingMode={editingMode}
          onClick={() => {
            const rows = incomeRows;

            rows.push({
              id: `income-${uuid()}`,
              name: "",
              values: columns.reduce(
                (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
                {}
              ),
              type: RowType.Income,
            });

            setIncomeRows([...rows]);
          }}
        />

        <TotalRow
          columns={columns}
          rows={incomeRows}
          negativeRows={[]}
          colorPreset="secondaryHeading"
          name="Subtotal cash from operations"
        />

        <TotalRow
          columns={columns}
          rows={[
            ...currentBorrowing,
            ...longTermLiabilities,
            ...saleOfCurrentAssets,
            ...saleOfLongTermAssets,
            ...newInvestmentReceived,
          ]}
          negativeRows={[]}
          colorPreset="secondaryHeading"
          name="Subtotal additional cash received"
        />

        <TotalRow
          columns={columns}
          rows={[
            ...currentBorrowing,
            ...longTermLiabilities,
            ...saleOfCurrentAssets,
            ...saleOfLongTermAssets,
            ...newInvestmentReceived,
            ...incomeRows,
          ]}
          negativeRows={[]}
          colorPreset="success"
          name="Total Cash Received"
        />

        <TotalRow
          columns={columns}
          rows={expenditures}
          negativeRows={[]}
          colorPreset="secondaryHeading"
          name="Subtotal spent on operations"
        />

        <TotalRow
          columns={columns}
          rows={additionalCashSpent}
          negativeRows={[]}
          colorPreset="secondaryHeading"
          name="Subtotal additional cash spent"
        />

        <TotalRow
          columns={columns}
          rows={[...expenditures, ...additionalCashSpent]}
          negativeRows={[]}
          colorPreset="success"
          name="Total Cash Spent"
        />

        <TotalRow
          columns={columns}
          rows={[
            ...currentBorrowing,
            ...longTermLiabilities,
            ...saleOfCurrentAssets,
            ...saleOfLongTermAssets,
            ...newInvestmentReceived,
            ...incomeRows,
          ]}
          negativeRows={[...expenditures, ...additionalCashSpent]}
          colorPreset="secondaryHeading"
          name="Net Cash Flow"
        />

        <TotalRow
          columns={columns}
          rows={[
            ...cashBalance,
            ...currentBorrowing,
            ...longTermLiabilities,
            ...saleOfCurrentAssets,
            ...saleOfLongTermAssets,
            ...newInvestmentReceived,
            ...incomeRows,
          ]}
          negativeRows={[...expenditures, ...additionalCashSpent]}
          colorPreset="success"
          name="Ending Cash Balance"
        />
      </div>
    </div>
  );
}

interface AddButtonProps {
  onClick: () => void;
  editingMode: "view";
}

function AddButton(props: AddButtonProps) {
  if (props.editingMode === "view") {
    return null;
  }

  return (
    <React.Fragment>
      <div className="mt-2 px-1 col-span-2 mb-4 pr-8 h-4">
        <Button
          margin="xs 0 0 0"
          buttonType="quaternary"
          onClick={() => {
            props.onClick();
          }}
        >
          <div className="h-4 flex items-center text-gray-500">
            <p>Add row</p>
            <PlusIcon className="w-4 h-4" />
          </div>
        </Button>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </React.Fragment>
  );
}

function ProfitLoss() {
  const client = useGqlClient();
  const createPnlVersion = useCreatePnlVersionMutation(client);

  const [showFiles, setShowFiles] = useState(false);

  const [selectedFile, setSelectedFile] = useState<DataRoomFile | null>(null);
  const [answer, setAnswer] = useState<string>("");
  const dispatch = useDispatch();

  const [editingMode, setEditingMode] = useState<"view">("view");

  const { data, isLoading, error, refetch } = useFinancialsQuery(client);

  const [showQuestion, setShowQuestion] = useState(false);

  const [columns, setColumns] = useState<Column[]>([
    {
      id: "col-1",
      name: "FY22",
    },
    {
      id: "col-2",
      name: "FY23",
    },
  ]);

  const [incomeRows, setIncomeRows] = useState<Row[]>([
    {
      id: "income-1",
      name: "Revenue",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Income,
    },
  ]);

  const [costOfGoodsRows, setCostOfGoodsRows] = useState<Row[]>([
    {
      id: "cost-of-goods-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [operatingExpenses, setOperatingExpenseRows] = useState<Row[]>([
    {
      id: "expense-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [depreciationRows, setDepreciationRows] = useState<Row[]>([
    {
      id: "depreciation-1",
      name: "Depreciation",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
    {
      id: "depreciation-2",
      name: "Amortization",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [interestRows, setInterestRows] = useState<Row[]>([
    {
      id: "interest-1",
      name: "Interest received",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Income,
    },
    {
      id: "interest-2",
      name: "Interest paid",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [taxRows, setTaxRows] = useState<Row[]>([
    {
      id: "tax-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  useEffect(() => {
    if (data && data.company.financialStatements.pnl) {
      setIncomeRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.revenues
        )
      );

      setCostOfGoodsRows(
        gqlRowsToRows(data.company.financialStatements.pnl.latestVersion.cogs)
      );

      setOperatingExpenseRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.operatingExpenses
        )
      );

      setDepreciationRows(
        gqlRowsToRows([
          ...data.company.financialStatements.pnl.latestVersion.depreciations,
          ...data.company.financialStatements.pnl.latestVersion.amortizations,
        ])
      );

      setInterestRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.interests
        )
      );

      setTaxRows(
        gqlRowsToRows(data.company.financialStatements.pnl.latestVersion.taxes)
      );
    }
  }, [data]);

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className="flex-1 mb-8">
      <div className="bg-white">
        <div className="mt-2 p-4 grid grid-cols-5 gap-1 overflow-hidden  overflow-x-scroll no-scrollbar">
          {[{ id: "numbers", name: "" }, ...columns].map((tab, i) => {
            return (
              <div key={tab.id} className={i === 0 ? `col-span-2` : ``}>
                <Text weight="bold">{tab.name}</Text>
              </div>
            );
          })}

          <TotalRow
            columns={columns}
            rows={incomeRows}
            negativeRows={[]}
            colorPreset="secondaryHeading"
            name="Total revenue"
          />

          <TotalRow
            columns={columns}
            rows={costOfGoodsRows}
            negativeRows={[]}
            colorPreset="secondaryHeading"
            name="Total costs"
          />

          <TotalRow
            columns={columns}
            rows={incomeRows}
            negativeRows={costOfGoodsRows}
            name="Gross profit"
            colorPreset="success"
          />

          <TotalRow
            columns={columns}
            rows={incomeRows}
            negativeRows={[...costOfGoodsRows, ...operatingExpenses]}
            name="EBITDA"
            colorPreset="success"
          />

          <TotalRow
            columns={columns}
            rows={incomeRows}
            negativeRows={[
              ...costOfGoodsRows,
              ...operatingExpenses,
              ...depreciationRows,
            ]}
            name="EBIT"
            colorPreset="success"
          />

          <TotalRow
            columns={columns}
            rows={interestRows.filter((row) => row.type === RowType.Income)}
            negativeRows={interestRows.filter(
              (row) => row.type === RowType.Expense
            )}
            name="Net interest"
            colorPreset="secondaryHeading"
          />

          <TotalRow
            columns={columns}
            rows={[
              ...incomeRows,
              ...interestRows.filter((row) => row.type === RowType.Income),
            ]}
            negativeRows={[
              ...costOfGoodsRows,
              ...operatingExpenses,
              ...depreciationRows,
              ...interestRows.filter((row) => row.type === RowType.Expense),
              ...taxRows,
            ]}
            name="Net income"
            colorPreset="success"
          />
        </div>
      </div>
    </div>
  );
}

function rowsToRowsInput(rows: Row[]): RowInput[] {
  return rows.map((row) => ({
    id: row.id,
    name: row.name,
    values: rowValuesToRowValueInput(row.values),
    type: row.type === RowType.Income ? RowType.Income : RowType.Expense,
  }));
}

function rowValuesToRowValueInput(
  values: Record<string, number>
): RowValueInput[] {
  return Object.entries(values).map(([column, amount]) => ({
    column,
    value: amount,
  }));
}

function gqlRowsToRows(rows: gqlRow[]): Row[] {
  return rows.map((row) => ({
    id: row.id === "" ? uuid() : row.id,
    name: row.name,
    values: gqlRowValuesToRowValues(row.values),
    type: row.type,
  }));
}

function gqlRowValuesToRowValues(values: RowValue[]): Record<string, number> {
  return values.reduce(
    (acc: Record<string, number>, rowValue) => (
      (acc[rowValue.column] = rowValue.value), acc
    ),
    {}
  );
}

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Matt" },
    date: "2 days ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "requested",
    person: {
      name: "Dan",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    date: "1 day ago",
    dateTime: "2023-01-23T11:03",
    requestedFor: "Revenue Analysis",
  },
  {
    id: 5,
    type: "viewed",
    person: { name: "Rob" },
    date: "1 day ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 4,
    type: "commented",
    person: {
      name: "Dan",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    comment: "Do we have all the bank statements? ",
    date: "1 day ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 8,
    type: "uploaded",
    person: { name: "Matt" },
    date: "4 hours ago",
    dateTime: "2023-01-24T09:20",
    file: {
      name: "Customer Breakdown",
      type: FileType.Xlsx,
    },
  },
];

function Activity() {
  return (
    <>
      <ul role="list" className="space-y-6 mt-2 w-1/2">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ? (
              <>
                <img
                  src={activityItem.person.imageUrl}
                  alt=""
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      commented
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.comment}
                  </p>
                </div>
              </>
            ) : activityItem.type === "created" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <ArrowPathIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    generated a new report
                  </p>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    added
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file?.name}</p>
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    to <span className="font-semibold">Revenue Analysis</span>
                  </p>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : activityItem.type === "requested" ? (
              <>
                <img
                  src={activityItem.person.imageUrl}
                  alt=""
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto mt-3">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      requested documents for{" "}
                      <span className="font-semibold">
                        {activityItem.requestedFor}
                      </span>
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.comment}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "fixed" ? (
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "requested" ? (
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                      alt=""
                      className="relative h-4 w-4 flex-none rounded-full bg-gray-50"
                    />
                  ) : activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "uploaded" ? (
                    <DocumentArrowUpIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  )}
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {activityItem.person.name}
                  </span>{" "}
                  {activityItem.type} the report
                </p>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            )}
          </li>
        ))}
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3 w-1/2">
        <img
          src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
          alt=""
          className="h-6 w-6 flex-none rounded-full bg-gray-50"
        />
        <form action="#" className="relative flex-auto">
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={2}
              name="comment"
              id="comment"
              className="p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              defaultValue={""}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button>
              </div>
            </div>
            <button
              type="submit"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Comment
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

import { Link } from "react-router-dom";
import { H3 } from "../../../components/Heading";

import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";

import { Card2 } from "../../../components/Card";
const navigation = [
  {
    name: "Open",
    href: "",
    current: true,
  },
  {
    name: "Closed",
    href: "",
    current: false,
  },
];
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function Issues() {
  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 pt-4 w-full shadow-md">
        <div className="flex items-center space-x-2">
          <H3>Errors</H3>
        </div>
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="hidden sm:-my-px sm:flex sm:space-x-8">
              {navigation.map((item) => (
                <Link
                  style={{ textDecoration: "none" }}
                  key={item.name}
                  to="#"
                  className={classNames(
                    item.name === "Open"
                      ? "border-indigo-500 text-gray-900 font-semibold"
                      : "border-transparent text-gray-500 hover:border-gray-300  hover:text-gray-700",
                    "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name} {item.name === "Open" ? "(1)" : "(1)"}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="p-8">
        <div className=" w-1/2">
          <Card2>
            <Link to="/s/errors/details">
              <div className="flex p-4 rounded-b-md hover:bg-gray-200 items-center cursor-pointer">
                <div className="bg-orange-400 rounded-full w-2 h-2 mr-4"></div>
                <div className="flex-1">
                  <H3>Inconsistent Revenue 2022 numbers</H3>
                  <p className="text-sm text-gray-500">
                    2 days ago {`\u2022`} 3 documents affected
                  </p>
                </div>
                <div className="flex gap-x-4">
                  <div className="flex -space-x-0.5">
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
                      alt=""
                      className="relative ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                    />
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                      alt=""
                      className="relative ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                    />

                    <div className="relative w-6 h-6 ring-2 ring-white flex-none bg-rose-400 rounded-full items-center justify-center flex">
                      <p className="text-sm text-white">R</p>
                    </div>
                  </div>
                  <div className="flex gap-x-2">
                    <ChatBubbleLeftIcon className="w-6 h-6 text-gray-600" />
                    <p className="text-gray-600">1</p>
                  </div>
                </div>
              </div>
            </Link>
          </Card2>
        </div>
      </div>
    </div>
  );
}

import { Fragment, useEffect } from "react";
import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import { Card2 } from "../../../components/Card";
import { FileIcon } from "../../../components/FileIcon";
import { H3, H4 } from "../../../components/Heading";
import { FileType, useFileDownloadUrlQuery } from "../../../graphql/generated";
import {
  ArchiveBoxXMarkIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  DocumentArrowUpIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  NoSymbolIcon,
  PaperClipIcon,
  SparklesIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "../../../utils/cn";
import { Button } from "../../../components/Button";
import styled from "../../../styles";
import useGqlClient from "../../../hooks/useGqlClient";
import React from "react";

const valueOptions = [
  {
    value: "£4,804.7",
    id: 2,
  },
  {
    value: "£4,690.5",
    id: 1,
  },
];

interface File {
  name: string;
  type: FileType;
  revenue2022Value: string;
  uploadedBy: string;
  uploadedAt: string;
  latestVersionMessage: string;
}

export function Issue() {
  const [showFileDetails, setShowFileDetails] = React.useState(false);

  const [selectedOption, setSelectedOption] = React.useState(valueOptions[0]);

  const [files, setFiles] = React.useState<File[]>([
    {
      name: "Profit & Loss",
      type: FileType.Pdf,
      revenue2022Value: "£4,690.5",
      uploadedBy: "Dan",
      uploadedAt: "2 hours ago",
      latestVersionMessage: "Add in the latest numbers",
    },
    {
      name: "Closing Terms",
      type: FileType.Docx,
      revenue2022Value: "£4,804.7",
      uploadedBy: "Rob",
      uploadedAt: "3 days ago",
      latestVersionMessage: "Change terms to reflect new forecast",
    },
    {
      name: "Model",
      type: FileType.Xlsx,
      revenue2022Value: "£4,804.7",
      uploadedBy: "Dan",
      uploadedAt: "1 day ago",
      latestVersionMessage: "Add a couple extra years to forecast",
    },
  ]);

  useEffect(() => {
    setSelectedOption(valueOptions[0]);
  }, []);

  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 py-2 w-full shadow-md">
        <div className="flex justify-between">
          <div>
            <div className="flex items-center">
              <div className="flex items-center">
                <p className="font-semibold text-gray-400 text-sm">Errors</p>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </div>
              <div>
                <p className="font-semibold text-gray-400 text-sm">
                  Inconsistent Revenue 2022 numbers
                </p>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div
                  className={classNames(
                    "rounded-full w-3 h-3",
                    files.every(
                      (f) => f.revenue2022Value === selectedOption.value
                    )
                      ? "bg-green-400"
                      : "bg-orange-400"
                  )}
                ></div>
                <div className="ml-3">
                  <H3>Inconsistent Revenue 2022 numbers</H3>
                  <p className="text-gray-500 text-xs font-semibold">
                    Created 2 hours ago
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            {files.every((f) => f.revenue2022Value === selectedOption.value) ? (
              <Button buttonType="tertiary" variant="success">
                Resolved
              </Button>
            ) : (
              <Button buttonType="tertiary">Resolve</Button>
            )}
          </div>
        </div>
      </div>

      {/* <div className="mt-8 flex items-center">
        <H3>We've detected an issue with the latest version of</H3>
      </div> */}

      <div className="mt-2 p-8">
        <H3>Select the correct value</H3>
        <SelectCorrectValue
          selectedOption={selectedOption}
          setSelectedOption={(selected) => {
            const option = valueOptions.find(
              (option) => option.id === selected.id
            );
            if (option) {
              setSelectedOption(option);
            }
          }}
        />
        {/* <p className="mt-6 text-gray-500">
          Liquid has detected 3 places where{" "}
          <span className="font-bold">Revenue 2022</span> is referenced
        </p> */}
        <H3 margin="xl 0 0 0">3 files affected</H3>
        <div className="grid grid-cols-3 gap-4 mt-2 pb-2 overflow-visible">
          {files.map((file, i) => {
            return (
              <div key={i}>
                <div
                  className="cursor-pointer "
                  onClick={() => {
                    setShowFileDetails(true);
                  }}
                >
                  <Card2 padding="m">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <FileIcon fileType={file.type} />
                        <p className="text font-bold ml-2">{file.name}</p>
                      </div>
                      <FileOptionsMenu />
                    </div>
                    <div className="mt-2 ml-1">
                      <div className="flex items-center">
                        <div
                          className={classNames(
                            `rounded-full w-2 h-2 bg-${
                              file.revenue2022Value !== selectedOption.value
                                ? "orange"
                                : "green"
                            }-400`
                          )}
                        ></div>
                        <div className="ml-3">
                          <p className="font-bold text-sm text-gray-600">
                            {file.latestVersionMessage}
                          </p>
                          <p className="text-sm text-gray-500">
                            Uploaded by {file.uploadedBy} {`\u2022`}{" "}
                            {file.uploadedAt}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="my-3 bg-gray-300 w-full h-px"></div>
                    <div className="flex justify-between">
                      <div>
                        <p className="font-bold  text-gray-600">
                          Revenue 2022:
                        </p>
                      </div>

                      <div>
                        <p
                          className={`text-${
                            file.revenue2022Value !== selectedOption.value
                              ? "orange"
                              : "green"
                          }-600 font-semibold`}
                        >
                          {file.revenue2022Value}
                        </p>
                      </div>
                    </div>
                  </Card2>
                </div>
                {file.revenue2022Value !== selectedOption.value ? (
                  <div className="mt-2">
                    <Card2>
                      <div className="flex justify-between items-center">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="py-3 hover:bg-gray-100 cursor-pointer rounded-md group justify-center flex flex-1"
                        >
                          <DocumentArrowUpIcon className="w-5 h-5 text-gray-500 group-hover:text-gray-600" />
                          <p className="select-none font-bold text-sm text-gray-500 ml-1 group-hover:text-gray-600">
                            Upload new version
                          </p>
                        </div>
                      </div>
                    </Card2>
                    <AiSuggestFile
                      selectedOption={selectedOption}
                      file={file}
                      onDoesStuff={() => {
                        const cur = files[0];
                        const newFile = {
                          ...cur,
                          revenue2022Value: selectedOption.value,
                          latestVersionMessage:
                            "Change Revenue 2022 to £4,804.7",
                          uploadedBy: "Liquid",
                          uploadedAt: "Just now",
                        };
                        setFiles([newFile, ...files.slice(1, 3)]);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        <div className="mt-8">
          <H4>Activity</H4>
          <Activity />
          <div className="mt-3">
            {/* <div>
              <div className="flex items-center">
                <div>
                  <div className="w-6  h-6 flex items-center justify-center mr-2">
                    <div className="w-2 h-2 rounded-full bg-orange-500 "></div>
                  </div>
                </div>
                <p className="text-sm text-gray-500">
                  <span className=" font-bold">Liquid</span> raised this error{" "}
                  {`\u2022`} 2 hours ago
                </p>
              </div>
              <div className="w-6 h-4 flex justify-center ">
                <div className="h-4 w-px bg-gray-300"></div>
              </div>
            </div> */}

            {/* <div className="flex items-start space-x-4">
              <div className="pt-2">
                <div className="bg-orange-500 rounded-full h-4 w-4 flex items-center justify-center">
                </div>
                <div className="w-0.5 bg-gray-300 h-full" />
              </div>
              <div className="pb-8">
                <p className="text-sm text-gray-500">
                  <span className=" font-bold">Liquid</span> raised this error{" "}
                  {`\u2022`} 2 hours ago
                </p>
              </div>
            </div> */}

            {/* <div className="my-1">
              <div className="flex ">
                <div className="w-6  h-6 flex items-center justify-center mr-2">
                  <img
                    className="w-6 h-6 rounded-full"
                    src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                    alt="Dan's profile pic"
                  />
                </div>
                <div className="rounded-md px-4 -mt-2 py-2 shadow-sm border-1 bg-gray-100 max-w-lg">
                  <div className="flex items-center">
                    <p className="font-bold text-xs text-gray-600">Dan</p>
                    <p className="ml-1 text-xs text-gray-500">1 hour ago</p>
                  </div>
                  <p className="text-md text-gray-600 mt-1">
                    <span className="font-bold">Matt</span> where did you get
                    the latest numbers from?
                  </p>
                </div>
              </div>
              <div className="w-6 h-4 my-1 flex justify-center ">
                <div className="h-4 w-px bg-gray-300"></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <FileDetails
        open={showFileDetails}
        onClose={() => {
          setShowFileDetails(false);
        }}
      />
    </div>
  );
}

function FileOptionsMenu() {
  return (
    <Menu as="div" className="relative flex-none">
      <Menu.Button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
        }}
        className=" -m-2.5 block p-2.5 text-gray-500 hover:text-gray-900"
      >
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <div
                className={classNames(
                  active ? "bg-gray-50 text-gray-700" : "",
                  "overflow-hidden flex items-center px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer"
                )}
              >
                <NoSymbolIcon className="w-5 h-5 mr-2" />
                <div
                  className="truncate"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Ignore file for this error
                </div>
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <div
                className={classNames(
                  active ? "bg-gray-50 text-gray-700" : "",
                  "overflow-hidden flex items-center px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer"
                )}
              >
                <ArchiveBoxXMarkIcon className="w-5 h-5 mr-2" />
                <div
                  className="truncate"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Ignore file for all errors
                </div>
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function SelectCorrectValue(props: {
  selectedOption: { value: string; id: number };
  setSelectedOption: (value: any) => void;
}) {
  return (
    <RadioGroup
      value={props.selectedOption}
      onChange={props.setSelectedOption}
      className="mt-2"
    >
      <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
        {valueOptions.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ active, checked }) =>
              classNames(
                active ? "ring-2 ring-indigo-500 ring-offset-2" : "",
                checked
                  ? "bg-indigo-500 text-white hover:bg-indigo-400"
                  : "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1 cursor-pointer focus:outline-none"
              )
            }
          >
            <RadioGroup.Label as="span">{option.value}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

function AiSuggestFile(props: {
  file: File;
  selectedOption: { value: string };
  onDoesStuff: () => void;
}) {
  const [uploading, setUploading] = React.useState(false);
  return (
    <div
      className="cursor-pointer mt-2 opacity-60 hover:opacity-100"
      onClick={() => {}}
    >
      <Card2>
        <div className="p-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center ">
              <SparklesIcon className="w-4 h-4 text-indigo-500" />
              <p className="font-semibold text-sm ml-1 text-indigo-500">
                Generated for you
              </p>
            </div>
            <XCircleIcon className="w-5 h-5 text-gray-400" />
          </div>
          <div className="w-full bg-gray-200 h-px my-3"></div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FileIcon fileType={props.file.type} />
              <p className="text font-bold ml-2">{props.file.name}</p>
            </div>
          </div>
          <div className="mt-2 ml-1">
            <div className="flex items-center">
              <div
                className={classNames(`rounded-full w-2 h-2 bg-green-400`)}
              ></div>
              <div className="ml-3">
                <p className="font-bold text-sm text-gray-600">
                  Change Revenue 2022 to {props.selectedOption.value}
                </p>
                <p className="text-sm text-gray-500">
                  Uploaded by Liquid {`\u2022`} Just now
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-300 h-px w-full"></div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setUploading(true);
            setTimeout(() => {
              props.onDoesStuff();
            }, 5000);
          }}
          className="py-3 hover:bg-gray-100 flex justify-center items-center"
        >
          <p className="text-sm text-gray-600 font-bold">
            {uploading ? "Uploading... " : `Use this version`}
          </p>
        </div>
      </Card2>
    </div>
  );
}

const versions = [
  {
    message: "Add in the latest numbers",
    uploadedBy: "Dan",
    uploadedAt: "2 hours ago",
    value: "$1,000,000",
    color: "orange",
  },
  {
    message: "Got the latest numbers from the finance team",
    uploadedBy: "Matt",
    uploadedAt: "1 day ago",
    value: "$1,100,000",
    color: "green",
  },
  {
    message: "Initial version",
    uploadedBy: "Dan",
    uploadedAt: "6 days ago",
    value: "$1,100,000",
    color: "green",
  },
];

function FileDetails(props: { open: boolean; onClose: () => void }) {
  const client = useGqlClient();

  const [files, setFiles] = React.useState([
    "dtrmfile_2UMQEJltpmczEK1llRu5APTr1JU",
    "dtrmfile_2bsKOT6jFbus0MWR6jVCgGZeTkI",
    "dtrmfile_2UMQEJltpmczEK1llRu5APTr1JU",
  ]);

  const [fileId, setFileId] = React.useState(files[0]);

  const fileDownloadUrl = useFileDownloadUrlQuery(client, {
    id: fileId,
  });

  const [selectedVersion, setSelectedVersion] = React.useState(0);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(e) => {
          props.onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-50"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-50"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-2/3 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:p-6">
                <div>
                  <div className="flex justify-between">
                    <div className="flex items-center mb-3">
                      <FileIcon fileType={FileType.Pdf} />
                      <p className="text-lg font-bold ml-2">Profit & Loss</p>
                    </div>
                    <div>
                      <XCircleIcon
                        onClick={() => {
                          props.onClose();
                        }}
                        className=" cursor-pointer w-6 h-6 text-gray-400 hover:text-gray-600"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <PdfWrap
                      data={`${
                        fileDownloadUrl && fileDownloadUrl.data
                          ? fileDownloadUrl.data.fileDownloadUrl.url
                          : ""
                      }#toolbar=0&page=2`}
                      type="application/pdf"
                      width="60%"
                      height="800"
                    >
                      <p>Failed to load PDF</p>
                    </PdfWrap>
                    <div className="ml-5 flex-1">
                      <div className="flex justify-between items-center">
                        <p className="font-bold text-lg text-gray-600">
                          Version history
                        </p>
                        <div>
                          <Button buttonType="quaternary">
                            Upload new version
                          </Button>
                        </div>
                      </div>
                      <div className="flex-1 w-full">
                        {versions.map((version, i) => {
                          return (
                            <div
                              className="cursor-pointer"
                              key={i}
                              onClick={() => {
                                setSelectedVersion(i);
                                setFileId(files[i]);
                              }}
                            >
                              <Card2
                                padding="m"
                                margin="m 0"
                                type={
                                  version.color === "orange"
                                    ? "warning"
                                    : "normal"
                                }
                              >
                                <div
                                  className={classNames(
                                    "flex items-center",
                                    i === selectedVersion ? "" : "opacity-60"
                                  )}
                                >
                                  <div
                                    className={`rounded-full w-2 h-2 ${
                                      version.color === "orange"
                                        ? "bg-orange-400"
                                        : "bg-green-400"
                                    }`}
                                  ></div>
                                  <div className="ml-3">
                                    <p className="font-bold text-gray-600">
                                      {version.message}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      Uploaded by {version.uploadedBy}{" "}
                                      {`\u2022`} {version.uploadedAt}
                                    </p>
                                  </div>
                                </div>
                              </Card2>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Liquid" },
    date: "2 days ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "assigned",
    person: { name: "Dan" },
    assignee: "Matt",
    date: "1 day ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 5,
    type: "viewed",
    person: { name: "Rob" },
    date: "1 day ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 4,
    type: "commented",
    person: {
      name: "Dan",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    comment: "",
    date: "1 day ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 8,
    type: "uploaded",
    person: { name: "Matt" },
    date: "Just now",
    dateTime: "2023-01-24T09:20",
  },
  {
    id: 6,
    type: "fixed",
    person: { name: "Matt" },
    date: "Just now",
    dateTime: "2023-01-24T09:20",
  },
];

function Activity() {
  return (
    <>
      <ul role="list" className="space-y-6 mt-2 w-1/2">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ? (
              <>
                <img
                  src={activityItem.person.imageUrl}
                  alt=""
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      commented
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    <span className="font-bold">Matt</span> where did you get
                    the latest numbers from?
                  </p>
                </div>
              </>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} a new version of
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Pdf} />
                    <p className="text-xs ml-1">Profit & Loss</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "fixed" ? (
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "assigned" ? (
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                      alt=""
                      className="relative h-4 w-4 flex-none rounded-full bg-gray-50"
                    />
                  ) : activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "uploaded" ? (
                    <DocumentArrowUpIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  )}
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {activityItem.person.name}
                  </span>{" "}
                  {activityItem.type} the error
                  <span className="font-bold">
                    {activityItem.assignee
                      ? ` to ${activityItem.assignee}`
                      : ""}
                  </span>
                  .
                </p>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            )}
          </li>
        ))}
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3 w-1/2">
        <img
          src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
          alt=""
          className="h-6 w-6 flex-none rounded-full bg-gray-50"
        />
        <form action="#" className="relative flex-auto">
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={2}
              name="comment"
              id="comment"
              className="p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              defaultValue={""}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button>
              </div>
            </div>
            <button
              type="submit"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Comment
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

const PdfWrap = styled.object`
  #toolbarViewer {
    display: none !important;
  }
`;

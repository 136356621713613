import { useEffect, useState } from "react";
import { H1, H2, H3 } from "../../../components/Heading";
import { Text } from "../../../components/Text";
import React from "react";
import { Button } from "../../../components/Button";
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  DocumentArrowUpIcon,
  EyeIcon,
  PaperClipIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { withMarginTw } from "../../../styles/withMargin";
import {
  RowInput,
  RowType,
  Row as gqlRow,
  RowValue,
  RowValueInput,
  useCreatePnlVersionMutation,
  useFinancialsQuery,
  PnlVersionFragmentFragment,
  ChangeSummaryStatus,
  usePnlVersionQuery,
  FileType,
  DataRoomFile,
  useSearchFilesQuery,
  Question,
  useCreateQuestionMessageMutation,
  useQuestionQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import Loading from "../../../components/Loading";
import { Card, Card2 } from "../../../components/Card";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { v4 as uuid } from "uuid";
import {
  ChatBubbleLeftRightIcon,
  DocumentMagnifyingGlassIcon,
  PaperAirplaneIcon,
  PlusSmallIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import styled from "../../../styles";
import { Overlay } from "../../../components/Overlay";
import { TextInput } from "../../../components/TextInput";
import { Flex } from "../../../components/Flex";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import { FileIcon } from "../../../components/FileIcon";
import { Dialog } from "../../../components/Dialog";
import { classNames } from "../../../utils/cn";
import {
  ChatBubbleLeftIcon,
  QuestionMarkCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { AnimatedModal } from "../../../components/AnimatedModal";

interface Row {
  id: string;
  name: string;
  values: Record<string, number>;
  type: RowType;
}

interface Column {
  id: string;
  name: string;
}

interface CellError {
  rowId: string;
  columndId: string;
}

type EditingMode = "view" | "edit";

export function ProfitLoss() {
  const client = useGqlClient();
  const createPnlVersion = useCreatePnlVersionMutation(client);

  const [showFiles, setShowFiles] = useState(false);

  const [selectedFile, setSelectedFile] = useState<DataRoomFile | null>(null);
  const [answer, setAnswer] = useState<string>("");
  const dispatch = useDispatch();

  const [editingMode, setEditingMode] = useState<EditingMode>("view");

  const { data, isLoading, error, refetch } = useFinancialsQuery(client);

  const [showQuestion, setShowQuestion] = useState(false);
  const [showError, setShowError] = useState(false);

  const [columns, setColumns] = useState<Column[]>([
    {
      id: "col-1",
      name: "2022A",
    },
    {
      id: "col-2",
      name: "2023A",
    },
    {
      id: "col-3",
      name: "2024F",
    },
    {
      id: "col-4",
      name: "2025F",
    },
    {
      id: "col-5",
      name: "2026F",
    },
  ]);

  const [incomeRows, setIncomeRows] = useState<Row[]>([
    {
      id: "income-1",
      name: "Revenue",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Income,
    },
  ]);

  const [costOfGoodsRows, setCostOfGoodsRows] = useState<Row[]>([
    {
      id: "cost-of-goods-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [operatingExpenses, setOperatingExpenseRows] = useState<Row[]>([
    {
      id: "expense-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [depreciationRows, setDepreciationRows] = useState<Row[]>([
    {
      id: "depreciation-1",
      name: "Depreciation",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
    {
      id: "depreciation-2",
      name: "Amortization",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [interestRows, setInterestRows] = useState<Row[]>([
    {
      id: "interest-1",
      name: "Interest received",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Income,
    },
    {
      id: "interest-2",
      name: "Interest paid",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  const [taxRows, setTaxRows] = useState<Row[]>([
    {
      id: "tax-1",
      name: "",
      values: columns.reduce(
        (acc: Record<string, number>, col) => ((acc[col.id] = 0), acc),
        {}
      ),
      type: RowType.Expense,
    },
  ]);

  useEffect(() => {
    if (data && data.company.financialStatements.pnl) {
      setIncomeRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.revenues
        )
      );

      setCostOfGoodsRows(
        gqlRowsToRows(data.company.financialStatements.pnl.latestVersion.cogs)
      );

      setOperatingExpenseRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.operatingExpenses
        )
      );

      setDepreciationRows(
        gqlRowsToRows([
          ...data.company.financialStatements.pnl.latestVersion.depreciations,
          ...data.company.financialStatements.pnl.latestVersion.amortizations,
        ])
      );

      setInterestRows(
        gqlRowsToRows(
          data.company.financialStatements.pnl.latestVersion.interests
        )
      );

      setTaxRows(
        gqlRowsToRows(data.company.financialStatements.pnl.latestVersion.taxes)
      );
    }
  }, [data]);

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div className="flex-1 mb-8">
      <div className="grid grid-cols-3 gap-8">
        <div className="col-span-2">
          <div className="rounded-lg bg-white shadow">
            <div className="flex justify-between items-center p-4">
              <div>
                <p className="font-bold text-gray-600">Profit & Loss</p>
                <div className="flex items-center mt-1 gap-x-1">
                  <p className=" text-gray-500 text-sm">Linked to</p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Pdf} />
                    <p className="text-xs ml-1">Profit & Loss</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-x-3">
                <div className="flex gap-x-4">
                  <div className="flex -space-x-0.5">
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
                      alt=""
                      className="relative ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                    />
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                      alt=""
                      className="relative opacity-50 ring-2 ring-white h-6 w-6 flex-none rounded-full bg-gray-50"
                    />

                    <div className="relative w-6 h-6 opacity-50 ring-2 ring-white flex-none bg-rose-400 rounded-full items-center justify-center flex">
                      <p className="text-sm text-white">R</p>
                    </div>
                  </div>
                </div>
                <Button
                  buttonType="quaternary"
                  onClick={() => {
                    setEditingMode("edit");
                  }}
                >
                  Edit
                </Button>
                <Button buttonType="quaternary" onClick={() => {}}>
                  Upload new version
                </Button>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200"></div>

            <div className="px-4">
              <FinanceTable
                onErrorClicked={() => {
                  setShowError(true);
                }}
                onQuestionClicked={() => {
                  setShowQuestion(true);
                }}
                title="31-Dec y/e (£k)"
                data={[
                  ["Revenue", 4804.7, 5157.8, 5436.3, 5644.7, 5758.1],
                  [
                    "Cost of Goods Sold",
                    3411.3,
                    3352.5,
                    3424.9,
                    3386.8,
                    3339.7,
                  ],
                  ["Gross Profit", 1393.4, 1805.2, 2011.4, 2257.9, 2418.4],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["Marketing costs", 144.1, 154.7, 163.1, 169.3, 172.7],
                  ["Staff costs", 576.6, 618.9, 652.4, 677.4, 691],
                  [
                    "Total operating expenses",
                    720.7,
                    773.7,
                    815.5,
                    846.7,
                    863.7,
                  ],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["EBITDA", 672.7, 1031.6, 1196.0, 1411.2, 1554.7],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["Depreciation", 24, 25.8, 27.2, 28.2, 28.8],
                  ["Amortisation", 24, 25.8, 27.2, 28.2, 28.8],
                  ["Depreciation & Amortisation", 48, 51.6, 54.4, 56.4, 57.6],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["EBIT", 624.6, 980, 1141.6, 1354.7, 1497.1],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["Interest Received", 0, 0, 0, 0, 0],
                  ["Interest Expense", 50, 20, 0, 0, 0],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["EBT", 574.6, 960, 1141.6, 1354.7, 1497.1],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["Tax", 124.5, 202.3, 239.2, 282.2, 310.9],
                  ["", "", "", "", "", ""],
                  ["", "", "", "", "", ""],
                  ["Net Income", 699.1, 1162.3, 1380.8, 1636.9, 1808],
                ]}
              />

              {/* <table className={` p-8 `}>
                <thead>
                  <tr>
                    <th
                      style={{ width: "100%" }}
                      scope="col"
                      className="pr-8 text-left"
                    >
                      <p className="text-gray-500 font-normal mt-2">£k</p>
                    </th>
                    {columns.map((cl) => {
                      return (
                        <th
                          scope="col"
                          className=" text-sm  text-gray-600 text-right"
                        >
                          {cl.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <SimpleHeading heading="Revenue" />
                  <SimpleRow
                    onShowQuestion={() => setShowQuestion(true)}
                    rows={incomeRows}
                  />

                  <SimpleHeading heading="COGS/COS" />
                  <SimpleRow
                    onShowQuestion={() => setShowQuestion(true)}
                    rows={costOfGoodsRows}
                  />

                  <SimpleHeading heading="Opex" />
                  <SimpleRow
                    onShowQuestion={() => setShowQuestion(true)}
                    rows={operatingExpenses}
                  />

                  <SimpleTotalRow
                    title="EBITDA"
                    incomeRows={incomeRows}
                    negativeRows={[...costOfGoodsRows, ...operatingExpenses]}
                    columns={columns}
                  />

                  <SimpleHeading heading="Depreciation & Amortization" />
                  <SimpleRow
                    onShowQuestion={() => setShowQuestion(true)}
                    rows={depreciationRows}
                  />

                  <SimpleHeading heading="Interest" />
                  <SimpleRow
                    onShowQuestion={() => setShowQuestion(true)}
                    rows={interestRows}
                  />

                  <SimpleHeading heading="Tax" />
                  <SimpleRow
                    onShowQuestion={() => setShowQuestion(true)}
                    rows={taxRows}
                  />

                  <SimpleTotalRow
                    title="Net Profit"
                    columns={columns}
                    incomeRows={incomeRows}
                    negativeRows={[
                      ...costOfGoodsRows,
                      ...operatingExpenses,
                      ...depreciationRows,
                      ...interestRows,
                      ...taxRows,
                    ]}
                  />
                </tbody>
              </table> */}
            </div>
          </div>
        </div>

        <div className="">
          <div className="flex justify-between items-center">
            <Text weight="bold">Activity</Text>
          </div>
          <Activity />
          {/* {data.company.financialStatements.pnl &&
            data.company.financialStatements.pnl.versions.map((version) => {
              return <ChangeSummary key={version.id} version={version} />;
            })} */}
        </div>
      </div>
      <AnimatedModal
        size="lg"
        open={showQuestion}
        onClose={() => setShowQuestion(false)}
      >
        <>
          <p className="font-bold text-gray-700 mb-4">Questions</p>
          <Link to={`/s/questions/question_2cUzXfhcvPx9mId8kjJkjMYuaJG`}>
            <div className="flex items-center space-x-3 ">
              <div className="relative w-6 h-6 ring-2 ring-white flex-none bg-indigo-400 rounded-full items-center justify-center flex">
                <p className="text-sm text-white">H</p>
              </div>
              <div className="flex-1">
                <p className="text-gray-600 text-lg font-bold">
                  Is there a revenue breakdown anywhere?
                </p>
                <p className="text-sm text-gray-500 text-ellipsis">
                  Created 2 hours ago
                </p>
              </div>
              <div className="flex gap-x-2">
                <ChatBubbleLeftIcon className="w-6 h-6 text-gray-600" />
                <p className="text-gray-600">2</p>
              </div>
            </div>
          </Link>
        </>
      </AnimatedModal>
      <AnimatedModal
        size="lg"
        open={showError}
        onClose={() => setShowError(false)}
      >
        <>
          <p className="font-bold text-gray-700 mb-4">Errors</p>
          <Link to={`/s/errors/details`}>
            <div className="flex items-center space-x-3 ">
              <ExclamationCircleIcon className="w-6 h-6 text-orange-600" />
              <div className="flex-1">
                <p className="text-gray-600 text-lg font-bold">
                  Inconsistent Revenue 2022 numbers
                </p>
                <p className="text-sm text-gray-500 text-ellipsis">
                  Created 2 days ago
                </p>
              </div>
              <div className="flex gap-x-2">
                <ChatBubbleLeftIcon className="w-6 h-6 text-gray-600" />
                <p className="text-gray-600">1</p>
              </div>
            </div>
          </Link>
        </>
      </AnimatedModal>
    </div>
  );
}

export function SimpleHeading(props: { heading: string }) {
  return (
    <tr>
      <td className={` text-xs text-gray-500 font-semibold truncate`}>
        <p className="mt-2">{props.heading}</p>
      </td>
    </tr>
  );
}

export function SimpleRow(props: { rows: Row[]; onShowQuestion: () => void }) {
  return (
    <>
      {props.rows.map((incomeRow, i) => {
        return (
          <tr key={incomeRow.id}>
            <td className={` text-sm font-semibold truncate`}>
              <div className="flex items-center">
                <p>{incomeRow.name}</p>
                {incomeRow.name === "Revenue" ? (
                  <QuestionMarkCircleIcon
                    onClick={() => props.onShowQuestion()}
                    className="w-5 h-5 mt-0.5 ml-1 cursor-pointer text-gray-400 hover:text-gray-500"
                  />
                ) : null}
              </div>
            </td>
            {Object.values(incomeRow.values).map((value, i) => (
              <td
                className={`border-0 ring-0 p-0 text-sm  pl-12`}
                key={`${value}-${i}`}
              >
                <div
                  className={` text-right w-full  py-1 focus:ring-0 focus:outline-none`}
                >
                  <p>{value.toLocaleString()}</p>
                </div>
              </td>
            ))}
          </tr>
        );
      })}
    </>
  );
}

export function SimpleTotalRow(props: {
  incomeRows: Row[];
  negativeRows: Row[];
  columns: Column[];
  title: string;
}) {
  return (
    <tr className="bg-gray-100">
      <td className={` text-sm font-semibold truncate`}>{props.title}</td>
      {props.columns.map((col) => {
        return (
          <td className={`border-0 ring-0 p-0 text-sm  pl-12`}>
            <div
              className={` text-right w-full  py-1 focus:ring-0 focus:outline-none`}
            >
              <p>
                {(
                  getTotal(props.incomeRows, col.id) -
                  getTotal(props.negativeRows, col.id)
                ).toLocaleString()}
              </p>
            </div>
          </td>
        );
      })}
    </tr>
  );
}

// function TotalRow(props: Props) {
//   const margin = withMarginTw({ margin: props.margin });
//   return (
//     <React.Fragment>
//       <div className={`${margin} mt-2 col-span-2 pr-8`}>
//         <Text weight="bold" colorPreset={props.colorPreset}>
//           {props.name}
//         </Text>
//       </div>
//       {props.columns.map((col) => {
//         return (
//           <div className="mt-2" key={col.id}>
//             <Text weight="bold" align="right" colorPreset={props.colorPreset}>
//               {(
//                 getTotal(props.rows, col.id) -
//                 getTotal(props.negativeRows, col.id)
//               ).toLocaleString()}
//             </Text>
//           </div>
//         );
//       })}
//     </React.Fragment>
//   );
// }

interface ChangeSummaryProps {
  version: PnlVersionFragmentFragment;
}

function ChangeSummary(props: ChangeSummaryProps) {
  const client = useGqlClient();
  const { data } = usePnlVersionQuery(
    client,
    {
      id: props.version.id,
    },
    {
      refetchInterval: (data, query) => {
        if (props.version.changeSummaryStatus === ChangeSummaryStatus.Success) {
          return false;
        }

        if (
          data &&
          data.pnlVersion.changeSummaryStatus === ChangeSummaryStatus.Pending
        ) {
          return 1000;
        }

        return false;
      },
    }
  );

  const [changeSummary, setChangeSummary] = useState("");

  useEffect(() => {
    if (
      data &&
      data.pnlVersion.changeSummaryStatus === ChangeSummaryStatus.Success
    ) {
      setChangeSummary(data.pnlVersion.changeSummary);
      return;
    }

    if (
      data &&
      data.pnlVersion.changeSummaryStatus === ChangeSummaryStatus.Pending
    ) {
      setChangeSummary("Summary of changes is pending...");
      return;
    }

    setChangeSummary("");
  }, [data, props.version]);

  return (
    <Card2 key={props.version.id} padding="m" margin="0 0 l 0">
      <Text weight="bold">
        Michael Seibel{" "}
        {formatDistanceToNow(fromUnixTime(props.version.createdAt), {
          addSuffix: true,
        })}
      </Text>
      <Text>{changeSummary}</Text>
    </Card2>
  );
}

function rowsToRowsInput(rows: Row[]): RowInput[] {
  return rows.map((row) => ({
    id: row.id,
    name: row.name,
    values: rowValuesToRowValueInput(row.values),
    type: row.type === RowType.Income ? RowType.Income : RowType.Expense,
  }));
}

function rowValuesToRowValueInput(
  values: Record<string, number>
): RowValueInput[] {
  return Object.entries(values).map(([column, amount]) => ({
    column,
    value: amount,
  }));
}

function gqlRowsToRows(rows: gqlRow[]): Row[] {
  return rows.map((row) => ({
    id: row.id === "" ? uuid() : row.id,
    name: row.name,
    values: gqlRowValuesToRowValues(row.values),
    type: row.type,
  }));
}

function gqlRowValuesToRowValues(values: RowValue[]): Record<string, number> {
  return values.reduce(
    (acc: Record<string, number>, rowValue) => (
      (acc[rowValue.column] = rowValue.value), acc
    ),
    {}
  );
}

interface HeadingProps {
  name: string;
  margin?: string;
}

function Heading(props: HeadingProps) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <React.Fragment>
      <div className={`${margin} col-span-2 mb-4 pr-8`}>
        <Text weight="bold" size="s">
          {props.name}
        </Text>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </React.Fragment>
  );
}

interface AddButtonProps {
  onClick: () => void;
  editingMode: EditingMode;
}

function AddButton(props: AddButtonProps) {
  if (props.editingMode === "view") {
    return null;
  }

  return (
    <React.Fragment>
      <div className="mt-2 px-1 col-span-2 mb-4 pr-8 h-4">
        <Button
          margin="xs 0 0 0"
          buttonType="quaternary"
          onClick={() => {
            props.onClick();
          }}
        >
          <div className="h-4 flex items-center text-gray-500">
            <p>Add row</p>
            <PlusIcon className="w-4 h-4" />
          </div>
        </Button>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </React.Fragment>
  );
}

function getTotal(rows: Row[], columnId: string) {
  return rows.reduce(
    (sum, row) =>
      sum +
      (typeof row.values[columnId] === "number" ? row.values[columnId] : 0),
    0
  );
}

interface RowsProps {
  questions: Question[];
  columns: Column[];
  rows: Row[];
  onChange: (rows: Row[]) => void;
  editingMode: EditingMode;
  onShowQuestion?: () => void;
}

interface RowItemProps {
  questions: Question[];
  columns: Column[];
  row: Row;
  onChange: (row: Row) => void;
  onDelete: () => void;
  editingMode: EditingMode;
  onShowQuestion?: () => void;
}

function formatNumberWithCommas(num: number) {
  try {
    const parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } catch (e) {
    return "";
  }
}

// Remove commas from a string
function removeCommas(str: string) {
  return str.replace(/,/g, "");
}

function RowItem(props: RowItemProps) {
  const [inputValues, setInputValues] = useState<Record<string, string>>(
    props.columns.reduce(
      (acc: Record<string, string>, col) => (
        (acc[col.id] = formatNumberWithCommas(props.row.values[col.id])), acc
      ),
      {}
    )
  );
  const [cellErrors, setCellErrors] = useState<CellError[]>([]);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showQuestions, setShowQuestions] = useState(false);

  useEffect(() => {
    setInputValues(
      props.columns.reduce(
        (acc: Record<string, string>, col) => (
          (acc[col.id] = formatNumberWithCommas(props.row.values[col.id])), acc
        ),
        {}
      )
    );
  }, [props.row.values, props.columns]);

  if (props.editingMode === "view") {
    return (
      <React.Fragment key={props.row.id}>
        <div className="col-span-2 mt-2">
          <div className="group flex items-center justify-between pr-8">
            <Text weight="bold" align="right">
              {props.row.name}
            </Text>
            {props.questions.length > 0 ? (
              <div
                onClick={() => {
                  setShowQuestions(true);
                }}
                className="group group-hover:cursor-pointer group-hover:border-gray-400 border border-2 rounded-lg flex items-center py-1 px-2"
              >
                <Text size="xs" weight="bold">
                  {props.questions.length}
                </Text>
                <ChatBubbleLeftRightIcon
                  onClick={() => {
                    setShowQuestions(true);
                  }}
                  className="ml-1 w-4 h-4 text-gray-500 group-hover:text-gray-800"
                />
              </div>
            ) : null}
          </div>
        </div>
        {props.columns.map((col) => {
          return <Cell col={col} value={props.row.values[col.id]} />;
        })}
        {showQuestions ? (
          <Modal
            onCancel={() => {
              setShowQuestions(false);
            }}
          >
            <QuestionsModal
              questions={props.questions}
              nodeName={props.row.name}
            />
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment key={props.row.id}>
      <div className="col-span-2 flex mr-8 group">
        <input
          placeholder="Name of item"
          className="p-1 border-transparent focus-visible:outline-none font-semibold text-gray-500"
          value={props.row.name}
          onChange={(e) => {
            const row = { ...props.row };
            row.name = e.currentTarget.value;
            props.onChange(row);
          }}
        />
        {showConfirmDelete ? (
          <Button
            className="opacity-80 "
            buttonType="quaternary"
            variant="destructive"
            onClick={() => {
              props.onDelete();
            }}
          >
            Confirm
          </Button>
        ) : (
          <Button
            className="opacity-0 group-hover:opacity-80 "
            buttonType="quaternary"
            variant="destructive"
          >
            <TrashIcon
              onClick={() => {
                setShowConfirmDelete(true);
                setTimeout(() => {
                  setShowConfirmDelete(false);
                }, 5000);
              }}
              className="w-4 h-4 text-red-500 hover:text-red-800"
            />
          </Button>
        )}
      </div>
      {props.columns.map((col) => {
        return (
          <div className="pr-1" key={col.id}>
            <input
              className={`p-1 border border-gray-300 rounded ${
                cellErrors.filter(
                  (ce) => ce.columndId === col.id && ce.rowId === props.row.id
                ).length > 0
                  ? "border-red-500"
                  : ""
              }`}
              value={inputValues[col.id]}
              onBlur={(e) => {
                const value = removeCommas(e.currentTarget.value.trim());
                if (isNaN(Number(value)) && e.currentTarget.value !== "") {
                  const errors = [...cellErrors];
                  errors.push({
                    rowId: props.row.id,
                    columndId: col.id,
                  });

                  setCellErrors(errors);
                } else {
                  const errors = cellErrors.filter(
                    (ce) => ce.columndId !== col.id && ce.rowId !== props.row.id
                  );

                  setCellErrors([...errors]);
                }
              }}
              onChange={(e) => {
                const value = removeCommas(e.currentTarget.value);

                if (value.trim() === "") {
                  const vals = { ...inputValues };
                  vals[col.id] = value;

                  setInputValues(vals);

                  const row = { ...props.row };
                  row.values[col.id] = 0;
                  props.onChange(row);

                  return;
                }

                if (
                  isNaN(Number(value.trim())) ||
                  e.currentTarget.value.endsWith(".")
                ) {
                  const vals = { ...inputValues };
                  vals[col.id] = value;

                  setInputValues(vals);

                  return;
                }

                const vals = { ...inputValues };
                vals[col.id] = formatNumberWithCommas(parseFloat(value));

                setInputValues(vals);

                const row = { ...props.row };
                row.values[col.id] = parseFloat(value);
                props.onChange(row);
              }}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
}

function Cell({ col, value }: { col: Column; value?: number }) {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <div className="pr-1 mt-2 flex items-center justify-between" key={col.id}>
      <Text align="right">{value ? value.toLocaleString() : ""}</Text>
      {col.id === "col-1" && (
        <div className="cursor-pointer text-orange-500 hover:text-orange-600 p-1">
          <ExclamationCircleIcon
            onClick={() => {
              setShowDialog(true);
            }}
            className="w-4 h-4 "
          />
        </div>
      )}
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <div>
          <H3>Issues found</H3>
          <p className="text-gray-500">
            We've found different values for{" "}
            <span className="font-bold">Revenue FY22</span>
          </p>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <FileIcon fileType={FileType.Pdf} />
              <div className="ml-3 mt-1">
                <p className="font-bold text-gray-700">Financial statements</p>
                <p className="text-sm text-gray-500">
                  View document
                  <ArrowTopRightOnSquareIcon className="w-4 h-4 inline-block ml-1" />
                </p>
              </div>
            </div>
            <div>
              <p className="text-gray-600 font-semibold">$3,500,000.00</p>
            </div>
          </div>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center">
              <FileIcon fileType={FileType.Xlsx} />
              <div className="ml-3 mt-1">
                <p className="font-bold text-gray-700">Model 22/23</p>
                <p className="text-sm text-gray-500">
                  View document
                  <ArrowTopRightOnSquareIcon className="w-4 h-4 inline-block ml-1" />
                </p>
              </div>
            </div>
            <div>
              <p className="text-gray-600 font-semibold">$3,200,000.00</p>
            </div>
          </div>
          <p className="mt-8 text-gray-700">Which number is correct?</p>
          <div className=" gap-2 flex flex-row-reverse">
            <div>
              <Button buttonType="tertiary" margin="m 0 0 m" onClick={() => {}}>
                $3,500,000.00
              </Button>
            </div>
            <div>
              <Button buttonType="tertiary" margin="m 0 0 m" onClick={() => {}}>
                $3,200,000.00
              </Button>
            </div>
            <Button
              margin="m 0 0 0"
              buttonType="quaternary"
              onClick={() => {
                setShowDialog(false);
              }}
            >
              Ignore, they are both correct
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

function Rows(props: RowsProps) {
  return (
    <React.Fragment>
      {props.rows.map((row, i) => {
        return (
          <RowItem
            questions={props.questions.filter(
              (q) => q.nodeId !== "" && row.id.includes(q.nodeId)
            )}
            onShowQuestion={props.onShowQuestion}
            editingMode={props.editingMode}
            columns={props.columns}
            row={row}
            key={i}
            onDelete={() => {
              const rows = props.rows.filter((r) => r.id !== row.id);
              props.onChange(rows);
            }}
            onChange={(row) => {
              const rows = [...props.rows];
              rows[i] = row;

              props.onChange(rows);
            }}
          />
        );
      })}
    </React.Fragment>
  );
}

interface Props {
  rows: Row[];
  negativeRows: Row[];
  columns: Column[];
  colorPreset: "success" | "secondaryHeading";
  name: string;
  margin?: string;
}

function TotalRow(props: Props) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <React.Fragment>
      <div className={`${margin} mt-2 col-span-2 pr-8`}>
        <Text weight="bold" colorPreset={props.colorPreset}>
          {props.name}
        </Text>
      </div>
      {props.columns.map((col) => {
        return (
          <div className="mt-2" key={col.id}>
            <Text weight="bold" align="right" colorPreset={props.colorPreset}>
              {(
                getTotal(props.rows, col.id) -
                getTotal(props.negativeRows, col.id)
              ).toLocaleString()}
            </Text>
          </div>
        );
      })}
    </React.Fragment>
  );
}

const ModalWrap = styled(Card)`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  cursor: default;
`;

interface ModalProps {
  children: React.ReactNode;
  onCancel?: () => void;
  padding?: string;
  onDragEnter?: React.DragEventHandler<HTMLDivElement>;
  onDragLeave?: React.DragEventHandler<HTMLDivElement>;
}

export function Modal(props: ModalProps) {
  return (
    <Overlay
      onClick={props.onCancel}
      onDragEnter={props.onDragEnter}
      onDragLeave={props.onDragLeave}
    >
      <ModalWrap
        onClick={(e) => e.stopPropagation()}
        padding={props.padding ? props.padding : "xl"}
      >
        {props.children}
      </ModalWrap>
    </Overlay>
  );
}

function ChooseDataRoomFile(props: {
  onFileSelected(file: DataRoomFile): void;
}) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="flex flex-col h-full">
      <div>
        <div className="flex justify-between items-center mb-4">
          <H2 margin="0">Search for files</H2>
          <XCircleIcon className="w-4 h-4 text-gray-700 hover:text-gray-900 hover:cursor-pointer" />
        </div>
        <TextInput
          margin="0 0 xl 0"
          placeholder="Search..."
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.currentTarget.value);
          }}
        />
      </div>
      <div className="flex-1">
        <SearchResults
          onFileSelected={(file) => {
            props.onFileSelected(file);
          }}
          searchTerm={searchTerm}
          folderId={""}
        />
      </div>
    </div>
  );
}

interface SearchResultsProps {
  folderId: string;
  searchTerm: string;
  onFileSelected(file: DataRoomFile): void;
}

let searchTimeout: NodeJS.Timeout | null = null;

export function SearchResults(props: SearchResultsProps) {
  const [files, setFiles] = useState<DataRoomFile[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const client = useGqlClient();
  const searchQuery = useSearchFilesQuery(
    client,
    {
      query: searchTerm,
    },
    {
      enabled: searchTerm.length > 2,
    }
  );

  useEffect(() => {
    const term = props.searchTerm;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setSearchTerm(term);
    }, 1000);
  }, [props.searchTerm]);

  useEffect(() => {
    if (searchTerm.length < 2) {
      setFiles([]);
      return;
    }

    if (!searchQuery.data) {
      return;
    }

    setFiles(searchQuery.data.searchFiles as DataRoomFile[]);
  }, [searchTerm, searchQuery]);

  if (searchQuery.isError) {
    return <div>Something went wrong</div>;
  }

  if (searchQuery.isLoading) {
    return (
      <div className="h-full  justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (!searchQuery.data || files.length === 0) {
    return (
      <div className="flex h-full justify-center items-center flex-col">
        <DocumentMagnifyingGlassIcon className="w-14 h-14 text-gray-700" />
        <Text margin="s 0 0 0">Search for files by name</Text>
      </div>
    );
  }

  return (
    <>
      {files.map((f) => {
        return (
          <div
            key={f.id}
            onClick={() => {
              props.onFileSelected(f);
            }}
            className="py-1 cursor-pointer hover:opacity-80"
          >
            <Flex justify="space-between">
              <Flex>
                <FileIcon fileType={f.fileType} />
                <Text margin="0 0 0 s" weight="bold">
                  {f.name}
                </Text>
              </Flex>
              <Text
                colorPreset="secondary"
                margin="0 0 0 s"
                size="s"
                weight="bold"
              >
                in{" "}
                {f.parentFolders
                  .map((pf, i) => {
                    return `${pf.name === "" ? "Home" : pf.name}${
                      i > 0 ? "/" : ""
                    }`;
                  })
                  .reverse()}
              </Text>
            </Flex>
          </div>
        );
      })}
    </>
  );
}

interface QuestionsModalProps {
  questions: Question[];
  nodeName: string;
}

function QuestionsModal(props: QuestionsModalProps) {
  const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);

  if (activeQuestion) {
    return (
      <div>
        <Button
          onClick={() => {
            setActiveQuestion(null);
          }}
          buttonType="quaternary"
        >
          Back to questions
        </Button>
        <QuestionDetails question={activeQuestion} />
      </div>
    );
  }

  return (
    <div>
      <H1>Questions on {props.nodeName}</H1>
      <Questions
        onSelect={(q) => {
          setActiveQuestion(q);
        }}
        questions={props.questions}
      />
    </div>
  );
}

interface QuestionsProps {
  questions: Question[];
  onSelect: (q: Question) => void;
}

function Questions(props: QuestionsProps) {
  const [activeQuestionId, setActiveQuestionId] = useState<string | null>(null);

  return (
    <div className="mt-2">
      {props.questions.map((q) => {
        return <QuestionItem question={q} onSelect={props.onSelect} />;
      })}
    </div>
  );
}

interface QuestionDetailsProps {
  question: Question;
}

function QuestionDetails(props: QuestionDetailsProps) {
  const client = useGqlClient();

  const { data, isLoading, error, refetch } = useQuestionQuery(client, {
    id: props.question.id,
  });
  const createQuestionMessage = useCreateQuestionMessageMutation(client);
  const [message, setMessage] = useState("");

  const [showSearchFiles, setShowSearchFiles] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [file, setFile] = useState<DataRoomFile | null>(null);

  const account = useSelector(authSelectors.account);

  if (error) {
    return <div>Something went wrong</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  function createMessage() {
    if (!message) {
      return;
    }

    createQuestionMessage.mutate(
      {
        questionId: props.question.id,
        body: message,
        dataRoomFileID: file ? file.id : undefined,
      },
      {
        onSuccess: () => {
          setFile(null);
          setMessage("");
          refetch();
        },
      }
    );
  }

  if (showSearchFiles) {
    return (
      <div className="flex flex-col h-full">
        <div>
          <div className="flex justify-between items-center mb-4">
            <H2 margin="0">Search for files</H2>
            <XCircleIcon
              onClick={() => {
                setShowSearchFiles(false);
              }}
              className="w-4 h-4 text-gray-700 hover:text-gray-900 hover:cursor-pointer"
            />
          </div>
          <TextInput
            margin="0 0 xl 0"
            placeholder="Search..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>
        <div className="flex-1">
          <SearchResults
            onFileSelected={(file) => {
              setFile(file);
              setShowSearchFiles(false);
            }}
            searchTerm={searchTerm}
            folderId={""}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="transition-opacity duration-200">
      <div className="mt-4 mb-4 flex">
        <div className="flex-1">
          <H3>{data.question.title}</H3>
          <Text>{data.question.body}</Text>
        </div>
        <div>
          <Button buttonType="tertiary" variant="success">
            Mark answered
          </Button>
        </div>
      </div>
      {data.question.messages.map((qm) => {
        return (
          <div key={qm.id} className="transition-all duration-500 mb-4 w-full ">
            <div className="flex items-center">
              <UserCircleIcon className="w-6 h-6 text-gray-400" />
              <div className="ml-2">
                <Text size="xs" weight="semi">
                  {qm.createdBy.name}{" "}
                  {qm.createdBy.firm ? `\u2022 ${qm.createdBy.firm.name}` : ``}
                </Text>
                <div
                  className={`px-4 py-2    ${
                    account && account.id === qm.createdBy.id
                      ? `bg-blue-50`
                      : `bg-gray-200`
                  } rounded-lg`}
                >
                  <Text size="s" weight="bold">
                    {qm.body}
                  </Text>
                </div>
              </div>
            </div>
            {qm.file ? (
              <Link
                to={`/s/dataroom/folder/${qm.file.parentFolders[0].id}/file/${qm.file.id}`}
              >
                <div className="mt-2 ml-8 flex items-center w-fit border border-3 rounded-lg p-1 px-2 ">
                  <FileIcon fileType={qm.file.fileType} />
                  <Text margin="0 0 0 xs" weight="bold">
                    {qm.file.name}
                  </Text>
                </div>
              </Link>
            ) : null}
          </div>
        );
      })}

      <div className="w-full h-px bg-gray-300 my-4" />
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          createMessage();
        }}
        className="flex items-center"
      >
        <UserCircleIcon className="w-6 h-6 text-gray-400" />
        <input
          onClick={(e) => {
            e.stopPropagation();
          }}
          value={message}
          onChange={(e) => {
            setMessage(e.currentTarget.value);
          }}
          placeholder="What do you want to say?"
          className="mr-4 ml-1 w-full px-2 py-1 border border-3 hover:border-gray-500 rounded-lg"
        />
        <PaperAirplaneIcon
          onClick={createMessage}
          className={`w-6 h-6 hover:cursor-pointer ${
            message ? `text-blue-400` : `text-gray-400`
          }`}
        />
      </form>
      {file ? (
        <div className="mt-2 ml-6 flex items-center w-fit border border-3 rounded-lg p-1 px-2 ">
          <FileIcon fileType={file.fileType} />
          <Text margin="0 0 0 xs" weight="bold">
            {file.name}
          </Text>
          <XCircleIcon
            onClick={() => {
              setFile(null);
            }}
            className="ml-2 w-4 h-4 text-gray-500 align-super hover:cursor-pointer hover:text-gray-600"
          />
        </div>
      ) : (
        <Button
          onClick={() => {
            setShowSearchFiles(true);
          }}
          margin="s 0 0 xl"
          buttonType="quaternary"
        >
          <div className="flex">
            <PlusSmallIcon className="w-4 h-4" />
            Link Data Room file
          </div>
        </Button>
      )}
    </div>
  );
}

interface QuestionItemProps {
  question: Question;
  onSelect: (q: Question) => void;
}

function QuestionItem(props: QuestionItemProps) {
  return (
    <div
      onClick={() => {
        props.onSelect(props.question);
      }}
      className="hover:opacity-70 hover:cursor-pointer mt-4 "
      key={props.question.id}
    >
      <div className="flex justify-between">
        <div className="group">
          <H3>{props.question.title}</H3>
          <Text>{props.question.body}</Text>
        </div>
      </div>
    </div>
  );
}

// FAKE SHIT

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Matt" },
    date: "5 days ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "assigned",
    person: { name: "Dan" },
    assignee: "Matt",
    date: "1 day ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 5,
    type: "viewed",
    person: { name: "Rob" },
    date: "1 day ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 5,
    type: "question",
    person: { name: "Rob" },
    question: "Do you have any more granular info on your Revenue numbers?",
    date: "1 day ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 4,
    type: "commented",
    person: {
      name: "Dan",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    comment: "",
    date: "1 day ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 8,
    type: "uploaded",
    person: { name: "Matt" },
    date: "Just now",
    dateTime: "2023-01-24T09:20",
  },
  {
    id: 6,
    type: "fixed",
    person: { name: "Matt" },
    date: "Just now",
    dateTime: "2023-01-24T09:20",
  },
].reverse();

function Activity() {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <>
      <ul role="list" className="space-y-6 mt-2">
        {/* New comment form */}
        <div className="mt-6 flex gap-x-3 ">
          <img
            src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/matt.jpg"
            alt=""
            className="h-6 w-6 flex-none rounded-full bg-gray-50"
          />
          <form action="#" className="relative flex-auto">
            <div
              className={`overflow-hidden rounded-lg ${
                isFocused ? "pb-12" : "pb-1"
              } shadow-sm ring-1 ring-inset transition-all ease-in-out duration-300 ring-gray-300 focus-within:ring-indigo-500 focus:ring-2`}
            >
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <textarea
                rows={isFocused ? 2 : 1}
                name="comment"
                id="comment"
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Add your comment..."
                defaultValue={""}
              />
            </div>

            <div
              className={`ring-0  absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
                isFocused
                  ? "opacity-100 visible duration-300 delay-100 "
                  : " opacity-0 invisible duration-150 delay-0"
              }`}
            >
              <div className="flex items-center space-x-5">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                  >
                    <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Attach a file</span>
                  </button>
                </div>
              </div>
              <button
                type="submit"
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Comment
              </button>
            </div>
          </form>
        </div>
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ? (
              <>
                <img
                  src={activityItem.person.imageUrl}
                  alt=""
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      commented
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    <span className="font-bold">Matt</span> where did you get
                    the latest numbers from?
                  </p>
                </div>
              </>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} a new version of
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Pdf} />
                    <p className="text-xs ml-1">Profit & Loss</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : activityItem.type === "created" ? (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {activityItem.person.name}
                  </span>{" "}
                  uploaded the first version
                </p>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            ) : (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "fixed" ? (
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "assigned" ? (
                    <img
                      src="https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg"
                      alt=""
                      className="relative h-4 w-4 flex-none rounded-full bg-gray-50"
                    />
                  ) : activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : activityItem.type === "uploaded" ? (
                    <DocumentArrowUpIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  )}
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <span className="font-medium text-gray-900">
                    {activityItem.person.name}
                  </span>{" "}
                  {activityItem.type} the error
                  <span className="font-bold">
                    {activityItem.assignee
                      ? ` to ${activityItem.assignee}`
                      : ""}
                  </span>
                  .
                </p>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

export const highlightedFields = [
  "Revenue",
  "EBITDA",
  "EBIT",
  "EBT",
  "Net profit",
  "Ending Cash",
  "Net debt",
  "Total Assets",
  "Total Liabilities",
  "Total Liabilities & Shareholder Equity",
  "Net Income",
];

function FinanceTable(props: {
  data: Array<(string | number)[]>;
  title: string;
  columns?: string[];
  onQuestionClicked?: () => void;
  onErrorClicked?: () => void;
}) {
  const columns = props.columns
    ? props.columns
    : ["2022A", "2023A", "2024F", "2025F", "2026F"];

  return (
    <div className="w-full overflow-scroll no-scrollbar">
      <table className={`  `}>
        <thead>
          <tr>
            <th
              style={{ width: "100%" }}
              scope="col"
              className="pr-8    text-left"
            >
              <p className="text-gray-500 truncate  font-normal mt-2">
                {props.title}
              </p>
            </th>
            {columns.map((c) => {
              return (
                <th scope="col" className=" text-sm  text-gray-600 text-right">
                  {c}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.data.map((d) => (
            <tr
              key={d[0]}
              className={`
          ${(d[0] as string).startsWith("Adjusted") ? "bg-blue-50" : ""} 
          `}
            >
              <td
                className={`${
                  highlightedFields.includes(d[0].toString())
                    ? " font-semibold"
                    : "font-normal"
                } text-sm truncate ${
                  d[0].toString().includes("%") ? "italic font-light" : ""
                } `}
              >
                <div className="flex items-center gap-x-2">
                  <p>{d[0]}</p>
                  {d[0] === "Revenue" ? (
                    <div className="flex items-center space-x-1">
                      <QuestionMarkCircleIcon
                        onClick={() => {
                          if (!props.onQuestionClicked) {
                            return;
                          }
                          props.onQuestionClicked();
                        }}
                        className="w-5 h-5 mt-0.5 text-gray-500 hover:text-gray-600 cursor-pointer"
                      />
                      <ExclamationCircleIcon
                        onClick={() => {
                          if (!props.onErrorClicked) {
                            return;
                          }
                          props.onErrorClicked();
                        }}
                        className="w-5 h-5 mt-0.5 text-orange-600 hover:text-orange-700 cursor-pointer"
                      />
                    </div>
                  ) : null}
                </div>
              </td>
              {d.slice(1, d.length).map((column) => (
                <td
                  className={`border-0 ring-0 p-0 text-sm  pl-12`}
                  key={column}
                >
                  <div
                    className={` text-right w-full  py-1 focus:ring-0 focus:outline-none`}
                  >
                    <p
                      className={`${
                        d[0].toString().includes("%")
                          ? "text-gray-500 italic"
                          : ""
                      } ${
                        highlightedFields.includes(d[0].toString())
                          ? " font-semibold"
                          : "font-normal"
                      } `}
                    >
                      {typeof column === "number"
                        ? column.toLocaleString()
                        : column}
                    </p>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

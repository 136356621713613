import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert } from "../components/Alert";
import { Button } from "../components/Button";
import { Flex } from "../components/Flex";
import { H1 } from "../components/Heading";
import { Text } from "../components/Text";
import { TextInput } from "../components/TextInput";
import { MOBILE_NAV_BREAKPOINT } from "../config";
import {
  useAccountQuery,
  useCreateCompanyMutation,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { actions } from "../store/auth/slice";
import { styled } from "../styles";

const Wrap = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentWrap = styled.div`
  transition: all 0.2s;
  overflow: hidden;

  box-sizing: border-box;
  border: 1px solid transparent;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.xxl}};

  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    max-width: 400px;
    box-shadow: ${(p) => p.theme.shadow.card};
    background: ${(p) => p.theme.color.card.background};
    border-radius: ${(p) => p.theme.misc.borderRadius};
  }
`;

export function CreateCompany() {
  const [name, setName] = useState("");
  const client = useGqlClient();
  const createCompany = useCreateCompanyMutation(client);
  const dispatch = useDispatch();
  const history = useHistory();

  const accountQuery = useAccountQuery(client);

  return (
    <Wrap>
      <ContentWrap>
        <Flex align="center" direction="column">
          <H1 margin="s 0 0 0">Create a company</H1>
          <Text margin="xs 0 m 0">Tell use the name of the new company</Text>
        </Flex>
        <TextInput
          margin="m 0 m 0"
          placeholder="Name"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.currentTarget.value);
          }}
        />
        {createCompany.error ? (
          <Alert type="error" text="Something went wrong" />
        ) : null}
        <Button
          onClick={() => {
            createCompany.mutate(
              {
                name,
              },
              {
                onSuccess: (data) => {
                  accountQuery
                    .refetch()
                    .then((res) => {
                      if (!res.data) return;
                      dispatch(
                        actions.refreshAccount({ account: res.data.account })
                      );
                    })
                    .then(() => {
                      dispatch(
                        actions.setActiveCompanyId({
                          id: data.createCompany.id,
                        })
                      );
                    })
                    .then(() => {
                      history.push("/");
                    });
                },
              }
            );
          }}
          margin="m 0 0 0"
        >
          {createCompany.isLoading ? "Loading..." : "Create"}
        </Button>
      </ContentWrap>
    </Wrap>
  );
}

import { DataRoomActivityType } from "../graphql/generated";

export function formatActivityType(type: DataRoomActivityType) {
  switch (type) {
    case DataRoomActivityType.FileCreated:
      return "File created";
    case DataRoomActivityType.FileVersionCreated:
      return "New version uploaded";
    case DataRoomActivityType.FileViewed:
      return "File viewed";
    case DataRoomActivityType.FileDownloaded:
      return "File downloaded";
    default:
      return "Unknown activity";
  }
}

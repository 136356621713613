const core = {
  spacing: {
    xs: "4px",
    s: "8px",
    m: "12px",
    l: "16px",
    xl: "24px",
    xxl: "32px",
    xxxl: "40px",
  },
  typography: {
    bodyFont: "Inter",
    bodyFamily: "Inter",
    headingFont: "Hind",
    headingFamily: "'Hind', sans-serif",
    weight: {
      bold: "600",
      semi: "500",
      normal: "400",
    },
    size: {
      xxxs: "10px",
      xxs: "11px",
      xs: "13px",
      s: "14px",
      m: "16px",
      l: "18px",
      xl: "20px",
      xxl: "24px",
      xxxl: "32px",
    },
  },
  misc: {
    borderRadius: "6px",
    focusRingShadow: "0 0 0 3px",
    blur: "4px",
  },
};

const sharedColours = {
  primary: "#3A6EA5",
  secondary: "#5F7AE9",
  highlight: "#E5F5FD",
  constructive: "#059669",
  warning: "#F0722B",
  destructive: "#E11748",
  inactive: "#9F9F9F",
  additional: {
    lightBlue: "#6AB8FF",
    darkBlue: "#3C5998",
    darkPurple: "#533C98",
  },
  tags: {
    lightOrange: "#FCF3CC",
    darkOrange: "#E9A23B",
    lightGreen: "#D7FCE3",
    darkGreen: "#15532B",
    lightRed: "#FEDEDE",
    darkRed: "#701010",
    lightPurple: "#F1E5FF",
    darkPurple: "#4E1A7E",
    lightBlue: "#D6E7FE",
    darkBlue: "#132A84",
  },
};

const lightColors = {
  color: {
    background: {
      start: "#F2F4F8",
      end: "#E4E9F2",
    },
    typography: {
      heading: "#000000",
      secondaryHeading: "#233249",
      text: "#445363",
      secondary: "#788AA5",
      success: "#9570ED",
      warning: "#F0722B",
      link: "#111827",
    },
    stats: {
      positiveBackground: "#D1FAE5",
      positiveValue: "#10B981",
      negativeBackground: "#FEE2E2",
      negativeValue: "#EF4444",
      neutralValue: "#5F7AE9",
      neutralBackground: "#E9EDFF",
    },
    card: {
      background: "#fbfdff",
      divider: "#EAF4FF",
    },
    input: {
      background: "#ffffff",
    },
    button: {
      secondaryBorder: "#D0D5DD",
      secondaryText: "#233249",
    },
    nav: {
      border: "#BFCADC",
    },
    divider: "#E7E1F8",
    accent: "#E466B5",
    ...sharedColours,
  },
  shadow: {
    card: "0px 0px 20px rgba(100, 114, 136, 0.15)",
    cardHeavy: "0px 8px 20px rgba(84, 114, 136, 0.30)",
    nav: "0px 8px 20px 0px rgba(0, 0, 0, 0.1)",
    filters: "0px 3px 10px rgba(0, 0, 0, 0.1)",
    imageFloatingIcon:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
  },
};

const darkColors = {
  color: {
    background: {
      start: "#1E2A3A",
      end: "#2B2F3C",
    },
    typography: {
      heading: "#FFFFFF",
      secondaryHeading: "#FBFDFF",
      text: "#dfe9f1",
      secondary: "#91a4bf",
      success: "#9570ED",
      warning: "#F0722B",
      link: "#3A7FAB",
    },
    stats: {
      positiveBackground: "#1DB54933",
      positiveValue: "#1DB549",
      negativeBackground: "#F43F5E26",
      negativeValue: "#E11D48",
      neutralValue: "#5F7AE9",
      neutralBackground: "#2C3558",
    },
    card: {
      background: "#1B2433",
      divider: "#384A70",
    },
    input: {
      background: "#33415d",
    },
    button: {
      secondaryBorder: "#788AA5",
      secondaryText: "#91a4bf",
    },
    nav: {
      border: "#384A70",
    },
    divider: "#454076",
    accent: "#5F7AE9",
    ...sharedColours,
  },
  shadow: {
    card: "0px 8px 20px rgba(17, 28, 36, 0.55)",
    cardHeavy: "0px 8px 20px rgba(17, 28, 36, 0.75)",
    nav: "0px 8px 20px 0px rgba(0, 0, 0, 0.1)",
    filters: "0px 3px 10px rgba(84, 114, 136, 0.15)",
    imageFloatingIcon:
      "0px 1px 3px rgba(255, 255, 255, 0.1), 0px 1px 2px rgba(255, 255, 255, 0.06);",
  },
};

const defaultTheme = {
  ...core,
  ...lightColors,
};

export const lightTheme = {
  ...core,
  ...lightColors,
};

export const darkTheme = {
  ...core,
  ...darkColors,
};

export default defaultTheme;

import styled, { css } from "../styles";
import theme from "../styles/theme";
import withMargin, { WithMarginProp } from "../styles/withMargin";

type sizes = typeof theme.typography.size;
type weight = typeof theme.typography.weight;
type colors = typeof theme.color.typography;

type TextProps = {
  color?: string;
  colorPreset?: keyof colors;
  size?: keyof sizes;
  weight?: keyof weight;
  align?: "center" | "left" | "right";
  isInline?: boolean;
  isCompact?: boolean;
  isBlurry?: boolean;
  numberOfLines?: number;
};

export const Text = styled.p<TextProps & WithMarginProp>`
  ${(p) =>
    p.isInline
      ? css`
          display: inline-block;
        `
      : null}

  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) =>
    p.colorPreset
      ? p.theme.color.typography[p.colorPreset]
      : p.color
      ? p.color
      : p.theme.color.typography.text};

  ${(p) =>
    p.align
      ? css`
          text-align: ${p.align};
        `
      : null}

  font-style: normal;
  font-weight: ${(p) =>
    p.weight
      ? p.theme.typography.weight[p.weight]
      : p.theme.typography.weight.normal};
  font-size: ${(p) =>
    p.size ? p.theme.typography.size[p.size] : p.theme.typography.size.m};
  line-height: ${(p) => (p.isCompact ? "120%" : "150%")};

  ${(p) =>
    p.isBlurry
      ? css`
          filter: blur(${p.theme.misc.blur});
          -webkit-filter: blur(${p.theme.misc.blur});
        `
      : ``};

  ${(p) =>
    p.onClick
      ? css`
          cursor: pointer;
        `
      : css``}

  ${withMargin}
`;

import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { authSelectors } from "../store/auth/selector";
import Loading from "./Loading";

const ProtectedRoute = (
  props: RouteProps & {
    children: React.ReactNode;
    onlyUnAuthenticated?: boolean;
  }
) => {
  const auth = useSelector(authSelectors.data);

  let onlyUnAuthenticated =
    props.onlyUnAuthenticated === null ? false : props.onlyUnAuthenticated;

  let children = props.children;

  const renderChildren = () => {
    if (auth.isLoading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if (!auth.isLoggedIn) {
      console.log("not logged in");
      if (onlyUnAuthenticated) {
        return children;
      }
      return <Redirect to="/signup" />;
    }

    return children;
  };

  return <Route {...props}>{renderChildren}</Route>;
};

export default ProtectedRoute;

import { AppState } from "..";

function paygroundPnl(state: AppState) {
  return state.demo.playgroundAnalysis.pnl;
}

function playgroundLoading(state: AppState) {
  return state.demo.playgroundAnalysis.loading;
}

function messages(state: AppState) {
  return state.demo.playgroundAnalysis.messages;
}

function playgroundInputs(state: AppState) {
  return state.demo.playgroundAnalysis.inputs;
}

function qofEPnl(state: AppState) {
  return state.demo.qualityOfEarnings.analysis.pnl;
}

function qofEanalyses(state: AppState) {
  return state.demo.qualityOfEarnings.analysis.analyses;
}

export const demoSelectors = {
  paygroundPnl,
  playgroundLoading,
  messages,
  playgroundInputs,
  qofEPnl,
  qofEanalyses,
};

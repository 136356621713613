import { H3 } from "../../../components/Heading";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { classNames } from "../../../utils/cn";
import { Report } from "./Report";
import { Documents } from "./Documents";

const navigation = [
  {
    name: "Report",
    href: "/s/finances/quality-of-earnings/report",
    current: true,
  },
  {
    name: "Supporting Documents",
    href: "/s/finances/quality-of-earnings/documents",
    current: false,
  },
];

export function QualityOfEarnings() {
  const location = useLocation();

  if (location.pathname === "/s/finances/quality-of-earnings") {
    return <Redirect to="/s/finances/quality-of-earnings/report" />;
  }

  return (
    <div className="flex-1">
      <div className="bg-white px-8 pt-4 w-full shadow-md">
        <H3>Quality of Earnings</H3>
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="hidden sm:-my-px sm:flex sm:space-x-8">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname === item.href
                      ? "border-indigo-500 text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="p-8">
        <div className="flex-1">
          <Switch>
            <Route path="/s/finances/quality-of-earnings/report">
              <Report />
            </Route>
            <Route path="/s/finances/quality-of-earnings/documents">
              <Documents />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";

import tw from "tailwind-styled-components";

type ButtonProps = {
  buttonType?:
    | "primary"
    | "destructive"
    | "secondary"
    | "tertiary"
    | "quaternary";
  isDisabled?: boolean;
  size?: "l" | "s";
  variant?: "normal" | "destructive" | "success";
};

export const Button2 = tw.button`
  bg-pink-600
  border-none
  rounded
  cursor-pointer
`;

export const Button = styled.button<ButtonProps & WithMarginProp>`
  background: linear-gradient(91.18deg, #3a6ea5 0%, #2f5a8a 100%);
  border-radius: ${(p) => p.theme.misc.borderRadius};
  color: #ffffff;
  border: none;
  user-select: none;
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: ${(p) => p.theme.typography.weight.bold};
  font-size: ${(p) => p.theme.typography.size.m};
  text-align: center;
  width: 100%;
  min-width: 100px;
  &:hover {
    box-shadow: none;
    filter: brightness(0.95);
    ${(p) =>
      p.isDisabled
        ? css`
            cursor: not-allowed;
          `
        : css`
            cursor: pointer;
          `}
  }

  ${(p) =>
    p.size === "l" &&
    css`
      padding: calc(${(p) => p.theme.spacing.m} - 2px)
        ${(p) => p.theme.spacing.m};
    `};

  ${(p) =>
    p.size === "s" &&
    css`
      padding: calc(${(p) => p.theme.spacing.m} - 2px)
        ${(p) => p.theme.spacing.m};
    `};

  ${(p) =>
    p.buttonType === "secondary"
      ? css`
          background: none;
          padding: calc(${(p) => p.theme.spacing.m} - 2px)
            ${(p) => p.theme.spacing.m};
          border: 2px solid ${(p) => p.theme.color.button.secondaryBorder};
          color: ${(p) => p.theme.color.button.secondaryText};
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

          &:hover {
            background: ${(p) => p.theme.color.typography.secondary}10;
          }
        `
      : null};

  ${(p) =>
    p.buttonType === "tertiary"
      ? css`
          background: ${(p) => p.theme.color.primary}15;
          color: ${(p) => p.theme.color.primary};
          box-shadow: none;
          padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
          font-weight: ${(p) => p.theme.typography.weight.semi};
        `
      : null};

  ${(p) =>
    p.buttonType === "tertiary" && p.size === "s"
      ? css`
          background: ${(p) => p.theme.color.primary}15;
          color: ${(p) => p.theme.color.primary};
          box-shadow: none;
          padding: calc(${(p) => p.theme.spacing.s} - 4px)
            ${(p) => p.theme.spacing.s};
          font-weight: ${(p) => p.theme.typography.weight.semi};
          font-size: ${(p) => p.theme.typography.size.xs};
        `
      : null};

  ${(p) =>
    p.buttonType === "tertiary" && p.variant === "success"
      ? css`
          background: ${(p) => p.theme.color.constructive}15;
          color: ${(p) => p.theme.color.constructive};
          box-shadow: none;
          padding: calc(${(p) => p.theme.spacing.m} - 2px)
            ${(p) => p.theme.spacing.m};
          font-weight: ${(p) => p.theme.typography.weight.semi};
        `
      : null};

  ${(p) =>
    p.buttonType === "tertiary" && p.variant === "destructive"
      ? css`
          background: ${(p) => p.theme.color.destructive}15;
          color: ${(p) => p.theme.color.destructive};
          box-shadow: none;
          padding: calc(${(p) => p.theme.spacing.m} - 2px)
            ${(p) => p.theme.spacing.m};
          font-weight: ${(p) => p.theme.typography.weight.semi};
        `
      : null};

  ${(p) =>
    p.buttonType === "quaternary"
      ? css`
          background: transparent;
          color: ${(p) => p.theme.color.primary};
          text-align: left;
          box-shadow: none;
          display: inline-block;
          width: auto !important;
          padding: 0;
          font-weight: ${(p) => p.theme.typography.weight.semi};
          font-size: ${(p) => p.theme.typography.size.s};
          min-width: auto;
        `
      : null};

  ${(p) =>
    p.buttonType === "destructive"
      ? css`
          background: none;
          border: 2px solid ${(p) => p.theme.color.destructive};
          color: ${(p) => p.theme.color.destructive};
        `
      : null};

  ${(p) =>
    p.variant === "destructive"
      ? css`
          color: ${(p) => p.theme.color.destructive};
        `
      : null};

  ${(p) =>
    p.isDisabled
      ? css`
          opacity: 0.4;
        `
      : null};

  font-family: ${(p) => p.theme.typography.bodyFamily};

  display: block;
  box-sizing: border-box;

  text-decoration: none;

  ${withMargin}
`;

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { Card, Card2 } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { Text } from "../../components/Text";
import { TextInput } from "../../components/TextInput";
import {
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
  getUnixTime,
} from "date-fns";
import {
  DataRoomActivityFragmentFragment,
  DataRoomActivityType,
  DataRoomFile,
  DataRoomFolderFragmentFragment,
  DataRoomFolderQuery,
  DataRoomPermission,
  FileType,
  useCreateDataFileVersionMutation,
  useCreateDataRoomFileMutation,
  useCreateDataRoomFolderMutation,
  useDataRoomActivityQuery,
  useDataRoomFolderQuery,
  useDataRoomQuery,
  useSearchFilesQuery,
  useUpdateDataRoomFolderMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useTheme } from "../../hooks/useTheme";
import styled, { css } from "../../styles";
import withMargin from "../../styles/withMargin";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { formatActivityType } from "../../utils/enums";
import { Dropdown } from "../../components/Dropdown";
import { SellerOnly } from "../../components/SellerOnly";
import {
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  EyeIcon,
  FolderIcon,
} from "@heroicons/react/24/solid";
import { FolderIcon as FolderIconOutline } from "@heroicons/react/24/outline";
import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Combobox, Dialog, Menu, Transition } from "@headlessui/react";
import { useQueryClient } from "react-query";
import { FileIcon } from "../../components/FileIcon";
import { DeleteFile } from "./DeleteFile";
import {
  DocumentPlusIcon,
  FolderPlusIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { AnimatedModal } from "../../components/AnimatedModal";

const Wrap = styled.div`
  flex: 1;
`;

const FilesWrapper = styled(Card2)`
  height: 400px;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  ${withMargin};
`;

const TableBody = styled.tbody`
  width: 100%;
`;

const TableData = styled.td`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
`;

const TableHeading = styled.th`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  color: ${(p) => p.theme.color.typography.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TableRow = styled.tr`
  width: 100%;
  display: table-row;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}33;
  }
`;

export const TableLabel = styled((props) => <Text as="th" {...props} />)`
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.secondary};
  display: flex;
  white-space: pre;
  ${({ theme }) => css`
    padding: 1px ${theme.spacing.xl} 0 0;
  `}
`;

export const TableValue = styled((props) => <Text as="td" {...props} />)`
  display: table-cell;
  padding: 1px 0;
`;

const FileUploadWrapper = styled.div<{
  margin?: string;
  dragActive: boolean;
  fileSelected: boolean;
}>`
  border: 1px dashed ${(p) => p.theme.color.secondary};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.s};

  ${(p) =>
    p.dragActive &&
    `
    border-color: ${p.theme.color.primary}; 
  `}

  ${(p) =>
    p.fileSelected &&
    `
    border: 1px solid ${p.theme.color.primary};
  `}

  ${withMargin}
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function DataRoom() {
  const client = useGqlClient();
  const dataRoomQuery = useDataRoomQuery(client);
  const history = useHistory();
  const location = useLocation();

  const [showFileUploader, setShowFileUploader] = useState(false);
  const [showFolderCreator, setShowFolderCreator] = useState(false);

  const { folderId } = useParams<{ folderId: string }>();

  const inputRef = useRef<HTMLInputElement>(null);

  if (!dataRoomQuery.data || dataRoomQuery.isLoading) {
    return <Loading />;
  }

  if (dataRoomQuery.error) {
    return <div>Something went wrong</div>;
  }

  return (
    <Wrap>
      <div className="bg-white px-8 py-6 w-full shadow-md">
        <div className="flex items-center justify-between">
          <div>
            <H3 margin="0 0 0 0">Files</H3>
            <p className="text-sm text-gray-400">Last updated 2 hours ago</p>
          </div>
          <div className="w-1/3">
            <div className="relative">
              <MagnifyingGlassIcon className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
              <div
                ref={inputRef}
                onClick={(e) => {
                  e.stopPropagation();
                  const params = new URLSearchParams({ search: "" });
                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });
                  if (inputRef.current) {
                    inputRef.current.blur();
                  }
                }}
                onFocus={() => {
                  // const params = new URLSearchParams({ search: "" });
                  // history.replace({
                  //   pathname: location.pathname,
                  //   search: params.toString(),
                  // });
                  // if (inputRef.current) {
                  //   inputRef.current.blur();
                  // }
                }}
                className="h-12 cursor-text items-center flex w-full border-0 bg-gray-100 rounded-md shadow-sm  pl-11 pr-4 text-gray-900 placeholder:text-gray-400  sm:text-sm focus:outline-none"
                placeholder="Search..."
              >
                <p className="text-gray-500">Search...</p>
              </div>
            </div>
          </div>
          <div className="space-x-2">
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              onClick={() => {
                setShowFolderCreator(true);
              }}
            >
              <FolderPlusIcon className="-ml-0.5 h-5 w-5" />
              Folder
            </button>
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setShowFileUploader(true);
              }}
            >
              <DocumentPlusIcon className="-ml-0.5 h-5 w-5" />
              File
            </button>
          </div>
        </div>
      </div>
      <div className="p-8">
        <SellerOnly>
          <Folder
            id={
              folderId
                ? folderId
                : dataRoomQuery.data.company.dataRoom.folder.id
            }
            permissions={dataRoomQuery.data.company.dataRoom.permissions}
          />
          <div className="grid md:grid-cols-2 mt-8">
            <div>
              <div className="flex justify-between items-center mr-12">
                <H3 margin="0">Recent activity</H3>
                <Link to="/s/dataroom/activity">
                  <Text size="s" colorPreset="link">
                    View all
                  </Text>
                </Link>
              </div>
              <Activity2 />
            </div>
            <div></div>
          </div>
        </SellerOnly>
      </div>

      <FileUploader
        open={showFileUploader}
        permissions={dataRoomQuery.data.company.dataRoom.permissions}
        dataRoomFolderId={
          folderId ? folderId : dataRoomQuery.data.company.dataRoom.folder.id
        }
        onClose={() => {
          setShowFileUploader(false);
        }}
      />
      <NewFolder
        open={showFolderCreator}
        permissions={dataRoomQuery.data.company.dataRoom.permissions}
        dataRoomFolderId={
          folderId ? folderId : dataRoomQuery.data.company.dataRoom.folder.id
        }
        onClose={() => {
          setShowFolderCreator(false);
        }}
      />
    </Wrap>
  );
}

const STORAGE_KEY = "recentFileSearches";

// Function to get recent searches from local storage
function getRecentSearches() {
  const searches = localStorage.getItem(STORAGE_KEY);
  return searches ? (JSON.parse(searches) as DataRoomFile[]) : [];
}

// Function to save a new search to local storage
function saveSearch(newSearch: DataRoomFile) {
  const searches = getRecentSearches();
  // Add new search at the beginning of the array
  searches.unshift(newSearch);
  // Keep only the 3 most recent searches
  const recentSearches = searches.slice(0, 3);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recentSearches));
}

interface FolderProps {
  id: string;
  permissions: DataRoomPermission[];
}

function Folder(props: FolderProps) {
  const client = useGqlClient();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [showFolderCreator, setShowFolderCreator] = useState(false);
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id,
  });

  const [recentSearches, setRecentSearches] = useState<DataRoomFile[]>([]);

  useEffect(() => {
    // Load recent searches from local storage on component mount
    const searches = getRecentSearches();
    setRecentSearches(searches);
  }, []);

  // Example function to handle a new search
  // This should be called with the actual search result when a search is performed
  function handleNewSearch(searchResult: DataRoomFile) {
    saveSearch(searchResult);
    // Optionally, update the state to immediately reflect the new search without reloading
    setRecentSearches(getRecentSearches());
  }

  if (!folderQuery.data || folderQuery.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Breadcrumbs
        viewerType="s"
        folders={folderQuery.data.dataRoomFolder.parentFolders}
      />

      <FilesWrapper>
        <Files folder={folderQuery.data.dataRoomFolder} />
      </FilesWrapper>

      <DeleteFolder
        open={
          query.get("deleteFolder") !== null && query.get("deleteFolder") !== ""
        }
        onClose={() => {
          history.replace({ pathname: location.pathname, search: "" });
        }}
      />
      <DeleteFile
        open={
          query.get("deleteFile") !== null && query.get("deleteFile") !== ""
        }
        onClose={() => {
          history.replace({ pathname: location.pathname, search: "" });
        }}
      />
      <AnimatedModal
        open={query.get("search") !== null || query.get("search") === ""}
        onClose={() => {
          history.replace({ pathname: location.pathname, search: "" });
        }}
        position="top"
        size="xl"
        padding="p-0"
      >
        <Combobox
          onChange={(v: any) => {
            if (v.shortcut) {
              return;
            }

            handleNewSearch(v);
            history.replace({ pathname: location.pathname, search: "" });
            history.push(`/s/dataroom/folder/${props.id}/file/${v.id}`);
          }}
        >
          <div className="flex flex-col h-full">
            <div className="relative">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Combobox.Input
                className="h-12 w-full border-b border-gray-100  focus:outline-none bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                onChange={(event) => {
                  const params = new URLSearchParams({
                    search: event.target.value,
                  });

                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });
                }}
              />
            </div>
            <div className="flex-1">
              <SearchResults
                searchTerm={query.get("search") || ""}
                folderId={props.id}
                recentSearches={recentSearches}
              />
            </div>
          </div>
        </Combobox>
      </AnimatedModal>
    </>
  );
}

interface FilesProps {
  folder: DataRoomFolderFragmentFragment;
}

const CenterWrap = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function Files(props: FilesProps) {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  if (props.folder.files.length === 0 && props.folder.folders.length === 0) {
    return (
      <CenterWrap>
        <Text margin="0" size="xl" weight="bold">
          You haven't uploaded any files yet
        </Text>
        <Text margin="0" size="s">
          Click & drag files into this box or use the upload button
        </Text>
      </CenterWrap>
    );
  }

  return (
    <Table>
      <TableBody>
        <tr>
          <TableHeading style={{ width: "100%" }}>Name</TableHeading>
          <TableHeading style={{ minWidth: 140 }}>Created by</TableHeading>
          <TableHeading>Last updated</TableHeading>
          <TableHeading style={{ minWidth: 130 }}>Access level</TableHeading>
          <TableHeading className="w-5"></TableHeading>
        </tr>
        {props.folder.folders.map((folder) => {
          return (
            <TableRow
              key={folder.id}
              className="group"
              onClick={() => {
                history.push("/s/dataroom/folder/" + folder.id);
              }}
            >
              <TableData>
                <Flex>
                  <FolderIcon
                    width={20}
                    height={20}
                    fill={theme.color.secondary}
                  />
                  <Text margin="0 0 0 s" weight="bold">
                    {folder.name}
                  </Text>
                </Flex>
              </TableData>
              <TableData>
                <p className="text-gray-500">{folder.createdBy.name}</p>
              </TableData>
              <TableData>
                <p className="text-gray-500 truncate">
                  {formatDistanceToNowStrict(fromUnixTime(folder.createdAt), {
                    addSuffix: true,
                  })}
                </p>
              </TableData>
              <TableData>
                <p className="text-gray-500">{folder.permission.name}</p>
              </TableData>
              <TableData>
                <Menu as="div" className="relative flex-none">
                  <Menu.Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                    }}
                    className="opacity-0 group-hover:opacity-100 -m-2.5 block p-2.5 text-gray-500 hover:text-gray-900"
                  >
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              const params = new URLSearchParams({
                                deleteFolder: folder.id,
                              });
                              history.replace({
                                pathname: location.pathname,
                                search: params.toString(),
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-50 text-red-700" : "",
                              "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                            )}
                          >
                            Delete
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </TableData>
            </TableRow>
          );
        })}
        {props.folder.files.map((file) => {
          return (
            <TableRow
              className="group"
              key={file.id}
              onClick={() => {
                history.push(
                  `/s/dataroom/folder/${props.folder.id}/file/${file.id}`
                );
              }}
            >
              <TableData>
                <Flex>
                  <FileIcon fileType={file.fileType} />
                  <Text margin="0 0 0 s" weight="bold">
                    {file.name}
                  </Text>
                </Flex>
              </TableData>
              <TableData>
                <p className="text-gray-500">{file.createdBy.name}</p>
              </TableData>
              <TableData>
                <p className="text-gray-500 truncate">
                  {formatDistanceToNowStrict(fromUnixTime(file.updatedAt), {
                    addSuffix: true,
                  })}
                </p>
              </TableData>
              <TableData>
                <p className="text-gray-500">{file.permission.name}</p>
              </TableData>
              <TableData>
                <Menu as="div" className="relative flex-none overflow-visible">
                  <Menu.Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                    }}
                    className="opacity-0 group-hover:opacity-100 -m-2.5 block p-2.5 text-gray-500 hover:text-gray-900"
                  >
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              const params = new URLSearchParams({
                                deleteFile: file.id,
                              });
                              history.replace({
                                pathname: location.pathname,
                                search: params.toString(),
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-50 text-red-700" : "",
                              "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                            )}
                          >
                            Delete
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </TableData>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

interface FileUploaderProps {
  dataRoomFolderId: string;
  onClose: () => void;
  open: boolean;
  permissions: DataRoomPermission[];
}

function FileUploader(props: FileUploaderProps) {
  const [dragActive, setDragActive] = useState(false);
  const [name, setName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const client = useGqlClient();
  const createDataFile = useCreateDataRoomFileMutation(client);
  const createDataFileVersion = useCreateDataFileVersionMutation(client);

  const [dataRoomPermissionId, setDataRoomPermissionId] = useState(
    props.permissions[0].id
  );

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length > 1) {
        setError("You can only upload one file at a time");
        return;
      }

      setFile(e.dataTransfer.files[0]);
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(e) => {
          setName("");
          setDataRoomPermissionId(props.permissions[0].id);
          setFile(null);
          props.onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-50"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-50"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <H3>New file</H3>
                  <TextInput
                    label="Name"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setName(e.currentTarget.value);
                    }}
                    margin="m 0 0 0"
                    placeholder="P&L 2023"
                  />
                  <Text margin="m 0 xs 0" size="s" weight="bold">
                    File
                  </Text>
                  {file ? (
                    <FileUploadWrapper
                      dragActive={false}
                      fileSelected={file !== null}
                    >
                      <Text weight="bold" margin="0">
                        {file.name}
                      </Text>
                    </FileUploadWrapper>
                  ) : (
                    <FileUploadWrapper
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                      dragActive={dragActive}
                      fileSelected={file !== null}
                    >
                      <Text margin="0" align="center">
                        Drop file here or
                      </Text>
                      <Flex justify="center">
                        <Button
                          onClick={() => {
                            if (inputRef.current) {
                              inputRef.current.click();
                            }
                          }}
                          buttonType="quaternary"
                          size="s"
                        >
                          Upload
                        </Button>
                        <input
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (e.target.files) {
                              if (e.target.files.length > 1) {
                                setError(
                                  "You can only upload one file at a time"
                                );
                                return;
                              }

                              setFile(e.target.files[0]);
                            }
                          }}
                          ref={inputRef}
                          type="file"
                          style={{ display: "none" }}
                        />
                      </Flex>
                    </FileUploadWrapper>
                  )}

                  <Text margin="m 0 xs 0" size="s" weight="bold">
                    Access level
                  </Text>
                  <Dropdown
                    dropdownType="button"
                    value={dataRoomPermissionId}
                    onSelect={(option) => {
                      setDataRoomPermissionId(option.value);
                    }}
                    options={props.permissions.map((permission) => {
                      return {
                        value: permission.id,
                        label: permission.name,
                        description: permission.description,
                      };
                    })}
                  />

                  {error ? (
                    <Alert margin="m 0 0 0" type="error" text={error} />
                  ) : null}
                  <Button
                    onClick={() => {
                      if (!file || !name) {
                        setError("Please select a file and enter a name");
                        return;
                      }

                      createDataFile.mutate(
                        {
                          name,
                          dataRoomFolderID: props.dataRoomFolderId,
                          dataRoomPermissionID: dataRoomPermissionId,
                        },
                        {
                          onSuccess: (data) => {
                            createDataFileVersion.mutate(
                              {
                                dataRoomFileID: data.createDataRoomFile.id,
                                fileName: file.name,
                                fileType: file.type,
                                summary: "Initial version",
                              },
                              {
                                onSuccess: (data) => {
                                  fetch(data.createDataRoomFileVersion.url, {
                                    method: "PUT",
                                    body: file,
                                  })
                                    .catch((e) => {
                                      setError("Error uploading file");
                                    })
                                    .then((res) => {
                                      // redirect
                                      // close modal
                                      // refresh files
                                      props.onClose();
                                    });
                                },
                              }
                            );
                          },
                        }
                      );
                    }}
                    isDisabled={!name || !file}
                    margin="xl 0 0 0"
                  >
                    {createDataFile.isLoading || createDataFileVersion.isLoading
                      ? "Creating file..."
                      : "Create new file"}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface ActivityProps {
  dataRoomId: string;
  dataRoomFolderId?: string;
}

export function Activity(props: ActivityProps) {
  const client = useGqlClient();

  const { data, isLoading, error } = useDataRoomActivityQuery(client, {
    input: {
      dataRoomID: props.dataRoomId,
      dataRoomFolderID: props.dataRoomFolderId,
      count: 30,
      cursor: "",
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  if (data.dataRoomActivity.activity.length === 0) {
    return (
      <div>
        <Text>No recent activity</Text>
      </div>
    );
  }

  const grouped: { [key: string]: DataRoomActivityFragmentFragment[] } = {};

  data.dataRoomActivity.activity.forEach((activity) => {
    const key = format(fromUnixTime(activity.createdAt), "EEEE do MMMM ");

    if (grouped[key]) {
      grouped[key].push(activity);
    } else {
      grouped[key] = [activity];
    }
  });

  return (
    <div>
      {Object.keys(grouped).map((key) => {
        return (
          <div key={key}>
            <Text size="l" isInline weight="bold" margin="l 0 0 0">
              {key}
            </Text>

            {grouped[key].map((activity) => {
              return <ActivityItem key={activity.id} activity={activity} />;
            })}
          </div>
        );
      })}
    </div>
  );
}

interface ActivityItemProps {
  activity: DataRoomActivityFragmentFragment;
}

export function ActivityItem(props: ActivityItemProps) {
  if (props.activity.type === DataRoomActivityType.FileCreated) {
    if (!props.activity.file) {
      return null;
    }

    return (
      <div key={props.activity.id}>
        <Flex align="center">
          <Text isInline size="xs" margin="0 s 0 0" colorPreset="secondary">
            {format(fromUnixTime(props.activity.createdAt), "HH:mm")}
          </Text>
          <PlusIcon className="w-5 h-5 text-gray-500" />
          <Text margin="0 0 0 xs" isInline>
            New file{" "}
            <Link
              style={{ textDecoration: "none" }}
              to={`/dataroom/${props.activity.file.id}`}
            >
              <span style={{ fontWeight: "bold" }}>
                {props.activity.file.name}
              </span>
            </Link>{" "}
            created by {props.activity.account.name}
          </Text>
        </Flex>
      </div>
    );
  }

  if (props.activity.type === DataRoomActivityType.FileVersionCreated) {
    if (!props.activity.file) {
      return null;
    }

    return (
      <div key={props.activity.id}>
        <Text margin="0">
          <Text isInline size="xs" margin="0 s 0 0" colorPreset="secondary">
            {format(fromUnixTime(props.activity.createdAt), "HH:mm")}
          </Text>
          New version of{" "}
          <Link
            style={{ textDecoration: "none" }}
            to={`/dataroom/${props.activity.file.id}`}
          >
            <span style={{ fontWeight: "bold" }}>
              {props.activity.file.name}
            </span>
          </Link>{" "}
          uploaded by {props.activity.account.name}
        </Text>
      </div>
    );
  }

  if (props.activity.type === DataRoomActivityType.FileDownloaded) {
    if (!props.activity.file || !props.activity.folder) {
      return null;
    }

    return (
      <div key={props.activity.id}>
        <Flex align="center">
          <Text isInline size="xs" margin="0 s 0 0" colorPreset="secondary">
            {format(fromUnixTime(props.activity.createdAt), "HH:mm")}
          </Text>
          <ArrowDownTrayIcon className="w-5 h-5 text-gray-500" />
          <Text margin="0 0 0 xs" isInline>
            <Link
              style={{ textDecoration: "none" }}
              to={`/s/dataroom/folder/${props.activity.folder.id}/file/${props.activity.file.id}`}
            >
              <span style={{ fontWeight: "bold" }}>
                {props.activity.file.name}
              </span>
            </Link>{" "}
            downloaded by {props.activity.account.name}
          </Text>
        </Flex>
      </div>
    );
  }

  return (
    <div key={props.activity.id}>
      <Text margin="0">
        <Text isInline size="xs" margin="0 s 0 0" colorPreset="secondary">
          {format(fromUnixTime(props.activity.createdAt), "HH:mm")}
        </Text>
        {formatActivityType(props.activity.type)} by:{" "}
        {props.activity.account.name}
      </Text>
    </div>
  );
}

interface NewFolderProps {
  dataRoomFolderId: string;
  onClose: () => void;
  open: boolean;
  permissions: DataRoomPermission[];
}

function NewFolder(props: NewFolderProps) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const client = useGqlClient();
  const createFolder = useCreateDataRoomFolderMutation(client);

  const [dataRoomPermissionId, setDataRoomPermissionId] = useState(
    props.permissions[0].id
  );

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(e) => {
          setName("");
          setDataRoomPermissionId(props.permissions[0].id);
          props.onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-50"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-50"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <H3>New folder</H3>
                  <TextInput
                    label="Name"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setName(e.currentTarget.value);
                    }}
                    margin="m 0 0 0"
                    placeholder="e.g. Accounting"
                  />

                  <Text margin="m 0 xs 0" size="s" weight="bold">
                    Access level
                  </Text>
                  <Dropdown
                    dropdownType="button"
                    value={dataRoomPermissionId}
                    onSelect={(option) => {
                      setDataRoomPermissionId(option.value);
                    }}
                    options={props.permissions.map((permission) => {
                      return {
                        value: permission.id,
                        label: permission.name,
                        description: permission.description,
                      };
                    })}
                  />

                  {error ? (
                    <Alert margin="m 0 0 0" type="error" text={error} />
                  ) : null}
                  <Button
                    onClick={() => {
                      if (!name) {
                        setError("Please name the folder");
                        return;
                      }

                      createFolder.mutate(
                        {
                          name,
                          dataRoomFolderID: props.dataRoomFolderId,
                          dataRoomPermissionID: dataRoomPermissionId,
                        },
                        {
                          onSuccess: (data) => {
                            setName("");
                            setDataRoomPermissionId(props.permissions[0].id);
                            props.onClose();
                          },
                        }
                      );
                    }}
                    isDisabled={!name}
                    margin="xl 0 0 0"
                  >
                    {createFolder.isLoading || createFolder.isLoading
                      ? "Creating folder..."
                      : "Create folder"}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface DeleteFolderProps {
  onClose: () => void;
  open: boolean;
}

function DeleteFolder(props: DeleteFolderProps) {
  const id = useQuery().get("deleteFolder");

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(e) => {
          props.onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-50"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-50"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <DeleteFolderContent
                  id={id}
                  onCancel={() => props.onClose()}
                  onDelete={() => props.onClose()}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface DeleteFolderContentProps {
  id: string | null;
  onCancel: () => void;
  onDelete: () => void;
}

function DeleteFolderContent(props: DeleteFolderContentProps) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id || "",
  });

  const updateDataRoomFolder = useUpdateDataRoomFolderMutation(client);

  const [data, setData] = useState<DataRoomFolderQuery | null>(null);

  useEffect(() => {
    if (folderQuery.data) {
      setData(folderQuery.data);
    }
  }, [folderQuery.data]);

  if (folderQuery.isLoading && !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <FolderIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Loading...
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Please wait</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (folderQuery.error || !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Something went wrong
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                We couldn't load the folder. Please try again.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <FolderIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {`Delete ${data.dataRoomFolder.name}`}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete the {data.dataRoomFolder.name}{" "}
              folder? All files and folders inside will be deleted.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            updateDataRoomFolder.mutate(
              {
                input: {
                  id: data.dataRoomFolder.id,
                  deletedAt: getUnixTime(new Date()),
                },
              },
              {
                onSuccess: () => {
                  queryClient
                    .invalidateQueries([
                      "DataRoomFolder",
                      {
                        id:
                          data.dataRoomFolder.parentFolders.length > 1
                            ? data.dataRoomFolder.parentFolders[1].id
                            : data.dataRoomFolder.id,
                      },
                    ])
                    .then(() => {
                      props.onDelete();
                    });
                },
              }
            );
          }}
        >
          {updateDataRoomFolder.isLoading ? "Deleting..." : "Delete"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

interface BreadcrumbProps {
  folders: DataRoomFolderFragmentFragment["parentFolders"];
  viewerType: "b" | "s";
}

export function Breadcrumbs(props: BreadcrumbProps) {
  const history = useHistory();
  const location = useLocation();

  const crumbs: {
    name: string;
    link: string;
  }[] = [
    {
      name: "Home",
      link: `/${props.viewerType}/dataroom/files`,
    },
    ...props.folders
      .slice(0, props.folders.length - 1)
      .map((folder) => {
        return {
          name: folder.name,
          link: `/${props.viewerType}/dataroom/folder/${folder.id}`,
        };
      })
      .reverse(),
  ];

  return (
    <div className="flex mb-1">
      {crumbs.map((folder, index) => {
        return (
          <div className="flex" key={folder.link}>
            <Text
              margin="0"
              weight="bold"
              colorPreset={
                location.pathname === folder.link ? "success" : "secondary"
              }
              size="s"
              onClick={() => {
                history.push(folder.link);
              }}
              style={{ cursor: "pointer" }}
            >
              {folder.name}
            </Text>
            {index !== props.folders.length - 1 ? (
              <Text margin="0 xs" colorPreset="secondary" size="s">
                /
              </Text>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

interface SearchResultsProps {
  folderId: string;
  searchTerm: string;
  recentSearches: DataRoomFile[];
}

let searchTimeout: NodeJS.Timeout | null = null;

const quickActions = [
  { name: "Add new file...", icon: DocumentPlusIcon, shortcut: "N", url: "#" },
  { name: "Add new folder...", icon: FolderPlusIcon, shortcut: "F", url: "#" },
];

function SearchResults(props: SearchResultsProps) {
  const [files, setFiles] = useState<DataRoomFile[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const client = useGqlClient();
  const searchQuery = useSearchFilesQuery(
    client,
    {
      query: searchTerm,
    },
    {
      enabled: searchTerm.length > 2,
    }
  );

  useEffect(() => {
    const term = props.searchTerm;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setSearchTerm(term);
    }, 1000);
  }, [props.searchTerm]);

  useEffect(() => {
    if (searchTerm.length < 2) {
      setFiles([]);
      return;
    }

    if (!searchQuery.data) {
      return;
    }

    setFiles(searchQuery.data.searchFiles as DataRoomFile[]);
  }, [searchTerm, searchQuery]);

  if (searchQuery.isError) {
    return <div>Something went wrong</div>;
  }

  if (searchQuery.isLoading) {
    return (
      <div className="h-full  justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (searchTerm === "") {
    return (
      <Combobox.Options static className="max-h-80 scroll-py-2 overflow-y-auto">
        <h2 className="mb-2 mt-4 px-6 text-xs font-semibold text-gray-500">
          Recent searches
        </h2>
        {props.recentSearches.length === 0 ? (
          <p className="text-sm px-6 text-gray-400">No recent searches</p>
        ) : (
          <div className="px-2">
            {props.recentSearches.map((file) => {
              return (
                <Combobox.Option
                  key={file.id}
                  value={file}
                  className={({ active }) =>
                    classNames(
                      "flex cursor-default select-none items-center rounded-md px-3 py-2",
                      active ? "bg-gray-700 text-white" : ""
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <div className="bg-white rounded-full h-7 w-7 justify-center items-center flex p-1">
                        <FileIcon fileType={file.fileType} />
                      </div>
                      <span className="ml-3 flex-auto truncate text-sm">
                        {file.parentFolders
                          .map((pf, i) => {
                            return `${
                              pf.name === "" ? "Home" : pf.name
                            }${" / "}`;
                          })
                          .reverse()}
                        <span className="font-bold">{file.name}</span>
                      </span>
                      {active && (
                        <span className="ml-3 flex-none text-gray-100 text-sm">
                          Jump to...
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              );
            })}
          </div>
        )}
        <div className="w-full h-px bg-gray-100 mt-4"></div>
        <li className="p-2">
          <h2 className="sr-only">Quick actions</h2>
          <ul className="text-sm text-gray-700">
            {quickActions.map((action) => (
              <Combobox.Option
                key={action.shortcut}
                value={action}
                className={({ active }) =>
                  classNames(
                    "flex cursor-default select-none items-center rounded-md px-3 py-2",
                    active ? "bg-gray-200" : ""
                  )
                }
              >
                {({ active }) => (
                  <>
                    <action.icon
                      className={classNames(
                        "h-6 w-6 flex-none",
                        "text-gray-400"
                      )}
                      aria-hidden="true"
                    />
                    <span className="ml-3 flex-auto truncate">
                      {action.name}
                    </span>
                    <span
                      className={classNames(
                        "ml-3 flex-none text-xs font-semibold",
                        "text-gray-400"
                      )}
                    >
                      <kbd className="font-sans">⌘</kbd>
                      <kbd className="font-sans">{action.shortcut}</kbd>
                    </span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </ul>
        </li>
      </Combobox.Options>
    );
  }

  if (!searchQuery.data || files.length === 0) {
    return (
      <div className="flex py-8 h-full justify-center items-center flex-col">
        <FolderIconOutline className="w-6 h-6 text-gray-400" />
        <p className="text-gray-600 text-sm">
          We couldn't find any files with that search term
        </p>
      </div>
    );
  }

  return (
    <>
      <Combobox.Options
        static
        className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
      >
        <li className="p-2">
          <ul className="text-sm text-gray-700">
            {files.map((file) => (
              <Combobox.Option
                key={file.id}
                value={file}
                className={({ active }) =>
                  classNames(
                    "flex cursor-default select-none items-center rounded-md px-3 py-2",
                    active ? "bg-gray-700 text-white" : ""
                  )
                }
              >
                {({ active }) => (
                  <>
                    <div className="bg-white rounded-full h-7 w-7 justify-center items-center flex p-1">
                      <FileIcon fileType={file.fileType} />
                    </div>
                    <span className="ml-3 flex-auto truncate">
                      {file.parentFolders
                        .map((pf, i) => {
                          return `${pf.name === "" ? "Home" : pf.name}${" / "}`;
                        })
                        .reverse()}
                      <span className="font-bold">{file.name}</span>
                    </span>
                    {active && (
                      <span className="ml-3 flex-none text-gray-100">
                        Jump to...
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </ul>
        </li>
      </Combobox.Options>
      {/* {files.map((f) => {
        return (
          <Link to={`/s/dataroom/folder/${props.folderId}/file/${f.id}`}>
            <div className="py-1 cursor-pointer hover:opacity-80">
              <Flex justify="space-between">
                <Flex>
                  <FileIcon fileType={f.fileType} />
                  <Text margin="0 0 0 s" weight="bold">
                    {f.name}
                  </Text>
                </Flex>
                <Text
                  colorPreset="secondary"
                  margin="0 0 0 s"
                  size="s"
                  weight="bold"
                >
                  in{" "}
                  {f.parentFolders
                    .map((pf, i) => {
                      return `${pf.name === "" ? "Home" : pf.name}${
                        i > 0 ? "/" : ""
                      }`;
                    })
                    .reverse()}
                </Text>
              </Flex>
            </div>
          </Link>
        );
      })} */}
    </>
  );
}

const ModalWrap = styled(Card)`
  width: 100%;
  max-width: 600px;
  max-height: 500px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  cursor: default;
`;

interface Props {
  children: React.ReactNode;
  onCancel?: () => void;
  padding?: string;
  onDragEnter?: React.DragEventHandler<HTMLDivElement>;
  onDragLeave?: React.DragEventHandler<HTMLDivElement>;
  show: boolean;
}

function SearchModal(props: Props) {
  return (
    <Overlay
      onClick={props.onCancel}
      onDragEnter={props.onDragEnter}
      onDragLeave={props.onDragLeave}
    >
      <ModalWrap
        onClick={(e) => e.stopPropagation()}
        padding={props.padding ? props.padding : "xl"}
      >
        {props.children}
      </ModalWrap>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 999;
  display: flex;
  justify-content: center;
  padding: ${(p) => p.theme.spacing.xxxl} ${(p) => p.theme.spacing.m};
`;

const activity = [
  {
    id: 2,
    type: "viewed",
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    person: { name: "Henry", avatarColor: "bg-indigo-400" },
    date: "1 day ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 4,
    type: "downloaded",
    person: {
      name: "Henry",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    comment: "",
    date: "1 day ago",
    dateTime: "2023-01-23T15:56",
  },
];

function Activity2() {
  return (
    <>
      <ul role="list" className="space-y-6 mt-4 w-full">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ||
            activityItem.type === "questioned" ? (
              <>
                {activityItem.person.imageUrl ? (
                  <img
                    src={activityItem.person.imageUrl}
                    alt=""
                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  />
                ) : (
                  <div
                    className={` relative w-6 h-6 ring-2 ring-white flex-none ${activityItem.person.avatarColor} rounded-full items-center justify-center flex`}
                  >
                    <p className="text-sm text-white">
                      {activityItem.person.name[0]}
                    </p>
                  </div>
                )}

                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      {activityItem.type === "questioned"
                        ? "asked a question"
                        : activityItem.type}
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.comment}
                  </p>
                </div>
              </>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} a new version of
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : activityItem.type === "viewed" ||
              activityItem.type === "downloaded" ? (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <DocumentArrowDownIcon className="w-4 h-4 text-gray-500" />
                  )}
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type}
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            ) : null}
          </li>
        ))}
      </ul>
    </>
  );
}

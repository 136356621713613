import { H2, H3 } from "../../../components/Heading";
import useGqlClient from "../../../hooks/useGqlClient";
import {
  useQuestionsQuery,
  Question as GqlQuestion,
  useCreateQuestionMessageMutation,
  useMarkAsAnsweredMutation,
  DataRoomFile,
} from "../../../graphql/generated";
import Loading from "../../../components/Loading";
import { useState } from "react";
import { Card, Card2 } from "../../../components/Card";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import { Overlay } from "../../../components/Overlay";
import { TextInput } from "../../../components/TextInput";
import { SearchResults } from "../statements2/ProfitLoss";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "../../../utils/cn";
import { ChatBubbleLeftIcon, PlusIcon } from "@heroicons/react/24/outline";

const navigation = [
  {
    name: "Unanswered",
    href: "/s/questions",
    current: true,
  },
  {
    name: "Answered",
    href: "/s/questions/answered",
    current: false,
  },
];

export function Questions() {
  const client = useGqlClient();
  const location = useLocation();
  const questions = useQuestionsQuery(client, {
    input: {},
  });

  const account = useSelector(authSelectors.account);

  if (questions.error) {
    return <div>Something went wrong</div>;
  }

  if (questions.isLoading || !questions.data) {
    return <Loading />;
  }

  return (
    <div className="flex-1 flex flex-col ">
      <div className="bg-white px-8 pt-4 w-full shadow-md z-50">
        <div className="flex items-center justify-between">
          <div>
            <H3>Questions</H3>
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="hidden sm:-my-px sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? "border-indigo-500 text-gray-900"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="space-x-2">
            <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" />
              New
            </button>
            {/* <button
              type="button"
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <DocumentPlusIcon className="-ml-0.5 h-5 w-5" />
              File
            </button> */}
          </div>
        </div>
      </div>
      {questions.data.questions.questions.length === 0 ? (
        <div className="flex-1 flex  justify-center items-center">
          <div>
            <p className="font-semibold text-center text-gray-600">
              No questions yet
            </p>
            <p className="text-sm text-gray-500 text-center">
              You will be notified about new questions
            </p>
          </div>
        </div>
      ) : null}
      <div className="mt-2 px-8 w-1/2">
        {questions.data.questions.questions.map((question) => {
          return (
            <Question
              key={question.id}
              accountId={account ? account.id : ""}
              question={question as GqlQuestion}
              onMessageAdded={() => {
                questions.refetch();
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

interface QuestionProps {
  question: GqlQuestion;
  onMessageAdded: () => void;
  accountId: string;
}

function Question(props: QuestionProps) {
  const client = useGqlClient();
  const createQuestionMessage = useCreateQuestionMessageMutation(client);
  const markAsAnswered = useMarkAsAnsweredMutation(client);

  const [message, setMessage] = useState("");
  const [file, setFile] = useState<DataRoomFile | null>(null);

  const [showSelectFile, setShowSelectFile] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  function createMessage() {
    if (!message) {
      return;
    }

    createQuestionMessage.mutate(
      {
        questionId: props.question.id,
        body: message,
        dataRoomFileID: file ? file.id : undefined,
      },
      {
        onSuccess: () => {
          setMessage("");
          setFile(null);
          props.onMessageAdded();
        },
      }
    );
  }

  return (
    <div key={props.question.id} className="w-full 3xl:w-4/5">
      <Link to={`/s/questions/${props.question.id}`}>
        <Card2 margin="xl 0 0 0" padding="m">
          <div className="flex items-center space-x-3">
            <div className="relative w-6 h-6 ring-2 ring-white flex-none bg-indigo-400 rounded-full items-center justify-center flex">
              <p className="text-sm text-white">H</p>
            </div>
            <div className="flex-1">
              <p className="text-gray-600 text-lg font-bold">
                {props.question.title}
              </p>
              <p className="text-sm text-gray-500 text-ellipsis">
                Created 2 hours ago
              </p>
            </div>
            <div className="flex gap-x-2">
              <ChatBubbleLeftIcon className="w-6 h-6 text-gray-600" />
              <p className="text-gray-600">2</p>
            </div>
          </div>

          {/* <div className="my-4 w-full h-px bg-gray-300"></div>
        {props.question.messages.map((qm) => {
          return (
            <div
              key={qm.id}
              className="transition-all duration-500 mb-4 w-full "
            >
              <div className="flex items-center">
                <UserCircleIcon className="w-6 h-6 text-gray-400" />
                <div className="ml-2">
                  <Text size="xs" weight="semi">
                    {qm.createdBy.name}{" "}
                    {qm.createdBy.firm
                      ? `\u2022 ${qm.createdBy.firm.name}`
                      : ``}
                  </Text>
                  <div
                    className={`px-4 py-2    ${
                      props.accountId === qm.createdBy.id
                        ? `bg-blue-200`
                        : `bg-gray-200`
                    } rounded-lg`}
                  >
                    <Text size="s" weight="bold">
                      {qm.body}
                    </Text>
                  </div>
                </div>
              </div>
              {qm.file ? (
                <Link
                  to={`/s/dataroom/folder/${qm.file.parentFolders[0].id}/file/${qm.file.id}`}
                >
                  <div className="mt-2 ml-8 flex items-center w-fit border border-3 rounded-lg p-1 px-2 ">
                    <FileIcon fileType={qm.file.fileType} />
                    <Text margin="0 0 0 xs" weight="bold">
                      {qm.file.name}
                    </Text>
                  </div>
                </Link>
              ) : null}
            </div>
          );
        })}
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            createMessage();
          }}
          className="mt-6 w-full "
        >
          <div className="flex items-center">
            <UserCircleIcon className="w-6 h-6 text-gray-400" />
            <input
              value={message}
              onChange={(e) => {
                setMessage(e.currentTarget.value);
              }}
              placeholder="What do you want to say?"
              className="mr-4 ml-1 w-full px-2 py-1 border border-3 hover:border-gray-500 rounded-lg"
            />
            <PaperAirplaneIcon
              onClick={createMessage}
              className={`w-6 h-6 hover:cursor-pointer ${
                message ? `text-blue-400` : `text-gray-400`
              }`}
            />
          </div>
          {file ? (
            <div className="mt-2 ml-6 flex items-center w-fit border border-3 rounded-lg p-1 px-2 ">
              <FileIcon fileType={file.fileType} />
              <Text margin="0 0 0 xs" weight="bold">
                {file.name}
              </Text>
              <XCircleIcon
                onClick={() => {
                  setFile(null);
                }}
                className="ml-2 w-4 h-4 text-gray-500 align-super hover:cursor-pointer hover:text-gray-600"
              />
            </div>
          ) : (
            <Button
              onClick={() => {
                setShowSelectFile(true);
              }}
              margin="s 0 0 xl"
              buttonType="quaternary"
            >
              <div className="flex">
                <PlusSmallIcon className="w-4 h-4" />
                Link Data Room file
              </div>
            </Button>
          )}
        </form> */}
        </Card2>
      </Link>
      {showSelectFile ? (
        <Modal
          onCancel={() => {
            setShowSelectFile(false);
          }}
        >
          <div className="flex flex-col h-full">
            <div>
              <div className="flex justify-between items-center mb-4">
                <H2 margin="0">Search for files</H2>
                <XCircleIcon className="w-4 h-4 text-gray-700 hover:text-gray-900 hover:cursor-pointer" />
              </div>
              <TextInput
                margin="0 0 xl 0"
                placeholder="Search..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(e.currentTarget.value);
                }}
              />
            </div>
            <div className="flex-1">
              <SearchResults
                onFileSelected={(file) => {
                  setFile(file);
                  setShowSelectFile(false);
                }}
                searchTerm={searchTerm}
                folderId={""}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

const ModalWrap = styled(Card)`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  cursor: default;
`;

interface ModalProps {
  children: React.ReactNode;
  onCancel?: () => void;
  padding?: string;
  onDragEnter?: React.DragEventHandler<HTMLDivElement>;
  onDragLeave?: React.DragEventHandler<HTMLDivElement>;
}

export function Modal(props: ModalProps) {
  return (
    <Overlay
      onClick={props.onCancel}
      onDragEnter={props.onDragEnter}
      onDragLeave={props.onDragLeave}
    >
      <ModalWrap
        onClick={(e) => e.stopPropagation()}
        padding={props.padding ? props.padding : "xl"}
      >
        {props.children}
      </ModalWrap>
    </Overlay>
  );
}

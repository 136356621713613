import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { CheckCircle } from "../components/CheckCircle";
import { H1, H3 } from "../components/Heading";
import Loading from "../components/Loading";
import { Text } from "../components/Text";
import {
  CompanyAccountAccessType,
  CompanyInviteQuery,
  useAcceptCompanyInviteMutation,
  useCompanyInviteQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { authSelectors } from "../store/auth/selector";
import { actions } from "../store/auth/slice";
import styled from "../styles";

export function Invite() {
  return (
    <Wrap>
      <Content>
        <div>
          <H1>Liquid</H1>
          <Text>Liquid is the platform for buying and selling SMBs</Text>
        </div>

        <div>
          <Switch>
            <Route path="/invite/:token">
              <InviteDetails />
            </Route>
            <Route path="/invite/:token/confirm-email">
              <ConfirmEmail />
            </Route>
            <Route path="/invite/:token/nda">
              <Nda />
            </Route>
          </Switch>
        </div>
      </Content>
    </Wrap>
  );
}

function InviteDetails() {
  const { token } = useParams<{ token: string }>();

  const dispatch = useDispatch();
  const history = useHistory();

  const [ndaAccepted, setNdaAccepted] = useState(false);

  const client = useGqlClient();
  const inviteQuery = useCompanyInviteQuery(client, {
    token,
  });

  const acceptInvite = useAcceptCompanyInviteMutation(client);

  if (inviteQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (inviteQuery.isLoading || !inviteQuery.data) {
    return <Loading />;
  }

  return (
    <>
      <Card padding="s m">
        <InviteTitle
          accessType={inviteQuery.data.companyInvite.accessType}
          company={inviteQuery.data.companyInvite.company}
        />
        <InviteMessage
          accessType={inviteQuery.data.companyInvite.accessType}
          company={inviteQuery.data.companyInvite.company}
        />
        <Text margin="xl 0 s 0" weight="bold">
          To get access please agree to the below NDA:
        </Text>
        <NdaWrap>
          <div style={{ overflow: "scroll", height: 250, padding: 8 }}>
            <Text margin="0">
              THIS AGREEMENT is made and entered into as of this _____ day of
              ___, 20 (the “Effective Date”) by and between ________________, a
              _________ corporation with its principal place of business located
              at ______________________ (the “Disclosing Party”), and
              ________________, a _________ corporation with its principal place
              of business located at ______________________ (the “Receiving
              Party”). WHEREAS, the Disclosing Party possesses certain
              non-public and confidential information relating to its business;
              WHEREAS, the Receiving Party is considering acquiring the
              Disclosing Party (the “Proposed Transaction”) and in connection
              therewith is being given access to certain confidential and
              proprietary information of the Disclosing Party; NOW, THEREFORE,
              in consideration of the mutual covenants contained herein and for
              other good and valuable consideration, the receipt and sufficiency
              of which is hereby acknowledged, the parties agree as follows:
              DEFINITION OF CONFIDENTIAL INFORMATION: Any information disclosed
              by the Disclosing Party to the Receiving Party in connection with
              the consideration of the Proposed Transaction, including but not
              limited to business and financial information, customer lists,
              strategies, and other proprietary information, shall be considered
              "Confidential Information". This also includes any such
              information that is obtained by the Receiving Party from a third
              party and which the Receiving Party knows or has reason to know
              that the source of the information was bound by a duty of
              confidentiality. DUTY NOT TO DISCLOSE: The Receiving Party shall
              not disclose any Confidential Information to third parties for a
              period of ___ years from the date of this Agreement without prior
              written consent from the Disclosing Party. This clause does not
              apply to any information which is or becomes publicly available,
              is already in the Receiving Party's possession, or is
              independently developed by the Receiving Party without the use of
              the Disclosing Party's Confidential Information. RETURN OF
              CONFIDENTIAL INFORMATION: Upon the request of the Disclosing
              Party, the Receiving Party will return all Confidential
              Information received in written or tangible form, including
              copies, or reproductions or other media containing such
              Confidential Information, within ten (10) days of such request. NO
              OBLIGATION: Nothing herein shall obligate either party to proceed
              with any transaction between them, and each party reserves the
              right, in its sole discretion, to terminate the discussions
              contemplated by this Agreement concerning the business
              opportunity. JURISDICTION: This Agreement shall be governed by and
              construed in accordance with the laws of _________ without regard
              to conflicts of law principles. IN WITNESS WHEREOF, the parties
              hereto have executed this Non-Disclosure Agreement as of the
              Effective Date first above written.
            </Text>
          </div>
        </NdaWrap>

        <CheckWrap
          onClick={() => {
            setNdaAccepted(!ndaAccepted);
          }}
        >
          <CheckCircle colorPreset={ndaAccepted ? undefined : "secondary"} />
          <Text margin="0 0 0 s">
            I have read and agree to the terms of the NDA
          </Text>
        </CheckWrap>
        <Button
          isDisabled={!ndaAccepted}
          onClick={() => {
            acceptInvite.mutate(
              {
                token,
              },
              {
                onSuccess: () => {
                  dispatch(
                    actions.setActiveCompanyId({
                      id: inviteQuery.data.companyInvite.company.id,
                    })
                  );
                  history.push("/");
                },
              }
            );
          }}
        >
          Accept Invite
        </Button>
      </Card>
    </>
  );
}

interface AcceptInviteProps {
  invite: CompanyInviteQuery["companyInvite"];
}

function AcceptInvite(props: AcceptInviteProps) {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  if (!isLoggedIn) {
    return (
      <>
        <Text>Sign up first</Text>
      </>
    );
  }

  return <></>;
}

interface InviteTitleProps {
  accessType: CompanyAccountAccessType;
  company: CompanyInviteQuery["companyInvite"]["company"];
}

function InviteTitle(props: InviteTitleProps) {
  let message = "";
  switch (props.accessType) {
    case CompanyAccountAccessType.Admin:
      message = `You've been invited to join ${props.company.name} as an admin.`;
      break;
    case CompanyAccountAccessType.Staff:
      message = `You've been invited to join ${props.company.name} as a staff member.`;
      break;
    case CompanyAccountAccessType.Buyer:
      message = `You've been invited to join ${props.company.name} on the buy side.`;
      break;
  }

  return <H3>{message}</H3>;
}

function InviteMessage(props: InviteTitleProps) {
  let message = "";
  switch (props.accessType) {
    case CompanyAccountAccessType.Admin:
      message = `This is sell side access. You'll be able to view all data rooms and manage the company's account.`;
      break;
    case CompanyAccountAccessType.Staff:
      message = `This will give you sell side access. You'll be able to view data room files and activity, look at offers and negotiations.`;
      break;
    case CompanyAccountAccessType.Buyer:
      message = `This will give you access to their data room and the ability to make an offer.`;
      break;
  }

  return <Text>{message}</Text>;
}

function ConfirmEmail() {
  return null;
}

function Nda() {
  return null;
}

const Content = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  max-width: 800px;
`;

const Wrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 80px 40px;
`;

const NdaWrap = styled.div`
  overflow: hidden;
  margin-bottom: ${(p) => p.theme.spacing.xl};
  border: 1px solid ${(p) => p.theme.color.typography.secondary};
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

const CheckWrap = styled.div`
  display: flex;
  margin: ${(p) => p.theme.spacing.l} 0;
  cursor: pointer;
  user-select: none;
`;

import {
  CheckCircleIcon,
  PaperAirplaneIcon,
  PlusSmallIcon,
  UserCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { Card, Card2 } from "../../components/Card";
import { H1 } from "../../components/Heading";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { useState } from "react";
import styled from "../../styles";
import { TextInput } from "../../components/TextInput";
import { TextArea } from "../../components/TextArea";
import {
  Question as GqlQuestion,
  QuestionNodeType,
  useCreateQuestionMessageMutation,
  useCreateQuestionMutation,
  useQuestionsQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { Link } from "react-router-dom";
import { FileIcon } from "../../components/FileIcon";

export function Questions() {
  const client = useGqlClient();
  const questions = useQuestionsQuery(client, {
    input: {},
  });
  const account = useSelector(authSelectors.account);

  const [showCreateQuestion, setShowCreateQuestion] = useState(false);

  if (questions.error) {
    return <div>Something went wrong</div>;
  }

  if (questions.isLoading || !questions.data) {
    return <Loading />;
  }

  return (
    <div className="flex-1">
      <div className="w-1/2 flex items-center justify-between">
        <div>
          <H1>Questions</H1>
          <Text>Ask any questions you want</Text>
        </div>
        <div>
          <Button
            onClick={() => {
              setShowCreateQuestion(true);
            }}
            buttonType="tertiary"
          >
            New question
          </Button>
        </div>
      </div>

      <div className="w-1/2 mt-4">
        {questions.data.questions.questions.map((question) => {
          return (
            <Question
              key={question.id}
              accountId={account ? account.id : ""}
              question={question as GqlQuestion}
              onMessageAdded={() => {
                questions.refetch();
              }}
            />
          );
        })}
      </div>
      {showCreateQuestion ? (
        <CreateQuestionModal
          onCancel={() => {
            setShowCreateQuestion(false);
          }}
          onCreate={() => {
            setShowCreateQuestion(false);
            questions.refetch();
          }}
        />
      ) : null}
    </div>
  );
}

interface CreateQuestionModalProps {
  onCancel: () => void;
  onCreate: () => void;
}

function CreateQuestionModal(props: CreateQuestionModalProps) {
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [details, setDetails] = useState("");
  const [fileId, setFileId] = useState("");

  const client = useGqlClient();
  const createQuestion = useCreateQuestionMutation(client);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!title) {
      setTitleError("Title is required");
      return;
    }

    createQuestion.mutate(
      {
        title,
        body: details,
        nodeId: fileId,
        nodeType: QuestionNodeType.DataRoomFile,
      },
      {
        onSuccess: () => {
          props.onCreate();
        },
      }
    );
  }

  return (
    <QuestionModal
      onCancel={() => {
        props.onCancel();
      }}
    >
      <form onSubmit={onSubmit} className="flex flex-1 flex-col">
        <div className="flex mb-8 items-center justify-between">
          <H1>New question</H1>
          <XCircleIcon
            className="w-6 h-6 text-gray-500 hover:text-gray-600 hover:cursor-pointer"
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>

        <Text weight="bold" size="s">
          Title
        </Text>
        <TextInput
          error={titleError}
          value={title}
          placeholder="Title"
          onChange={(e) => {
            setTitleError("");
            setTitle(e.currentTarget.value);
          }}
        />

        <Text margin="xl 0 0 0" weight="bold" size="s">
          Details
        </Text>
        <TextArea
          placeholder="Details"
          value={details}
          onChange={(e) => {
            setDetails(e.currentTarget.value);
          }}
        />

        <Text margin="xl 0 0 0" weight="bold" size="s">
          File
        </Text>
        <Button
          onClick={(e) => {
            e.preventDefault();
          }}
          margin="s 0 0 0"
          buttonType="quaternary"
        >
          <div className="flex">
            <PlusSmallIcon className="w-4 h-4" />
            Link Data Room file
          </div>
        </Button>

        <div className="flex-1"></div>
        <div className="flex justify-between gap-4">
          <Button
            buttonType="secondary"
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button buttonType="primary">
            {createQuestion.isLoading ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </form>
    </QuestionModal>
  );
}

interface QuestionProps {
  question: GqlQuestion;
  onMessageAdded: () => void;
  accountId: string;
}

export function Question(props: QuestionProps) {
  const client = useGqlClient();
  const createQuestionMessage = useCreateQuestionMessageMutation(client);

  const [message, setMessage] = useState("");

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!message) {
      return;
    }

    createQuestionMessage.mutate(
      {
        questionId: props.question.id,
        body: message,
      },
      {
        onSuccess: () => {
          setMessage("");
          props.onMessageAdded();
        },
      }
    );
  }

  return (
    <div key={props.question.id} className="w-full 3xl:w-4/5">
      <Card2 margin="xl 0 0 0" padding="m">
        <div className="flex items-center justify-between">
          <div>
            <Text weight="semi">
              {props.question.createdBy.name}{" "}
              {props.question.createdBy.firm
                ? `from ${props.question.createdBy.firm.name}`
                : ``}{" "}
              {/* {props.question. asked on{" "}
                  <Text isInline colorPreset="link" weight="bold">
                    "Staff" row of Profit & Loss
                  </Text> : null} */}
            </Text>
            <Text size="l" weight="bold">
              {props.question.title}
            </Text>
          </div>
          <div>
            {props.question.answeredAt ? (
              <div className="flex items-center opacity-80">
                <p className="ml-1 text-green-500 font-bold">Answered</p>
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              </div>
            ) : null}
          </div>
        </div>
        <Text>{props.question.body}</Text>

        <div className="my-4 w-full h-px bg-gray-300"></div>
        {props.question.messages.map((qm) => {
          return (
            <div
              key={qm.id}
              className="transition-all duration-500 mb-4 w-full "
            >
              <div className="flex items-center">
                <UserCircleIcon className="w-6 h-6 text-gray-400" />
                <div className="ml-2">
                  <Text size="xs" weight="semi">
                    {qm.createdBy.name}{" "}
                    {qm.createdBy.firm
                      ? `\u2022 ${qm.createdBy.firm.name}`
                      : ``}
                  </Text>
                  <div
                    className={`px-4 py-2    ${
                      props.accountId === qm.createdBy.id
                        ? `bg-blue-200`
                        : `bg-gray-200`
                    } rounded-lg`}
                  >
                    <Text size="s" weight="bold">
                      {qm.body}
                    </Text>
                  </div>
                </div>
              </div>
              {qm.file ? (
                <Link
                  to={`/b/dataroom/folder/${qm.file.parentFolders[0].id}/file/${qm.file.id}`}
                >
                  <div className="mt-2 ml-8 flex items-center w-fit border border-3 rounded-lg p-1 px-2 ">
                    <FileIcon fileType={qm.file.fileType} />
                    <Text margin="0 0 0 xs" weight="bold">
                      {qm.file.name}
                    </Text>
                  </div>
                </Link>
              ) : null}
            </div>
          );
        })}
        <form onSubmit={onSubmit} className="mt-4 w-full flex items-center">
          <UserCircleIcon className="w-6 h-6 text-gray-400" />
          <input
            value={message}
            onChange={(e) => {
              setMessage(e.currentTarget.value);
            }}
            placeholder="What do you want to say?"
            className="mr-4 ml-1 w-full px-2 py-1 border border-3 hover:border-gray-500 rounded-lg"
          />
          <PaperAirplaneIcon
            type="submit"
            className={`w-6 h-6 ${message ? `text-blue-400` : `text-gray-400`}`}
          />
        </form>
        {/* <div className="mt-2 ml-6 flex w-fit border border-3 rounded-lg p-1 px-2 ">
                  <FileIcon fileType={answer.file.fileType} />
                  <Text weight="bold">{answer.file.name}</Text>
                </div> */}
      </Card2>
    </div>
  );
}

const ModalWrap = styled(Card)`
  width: 100%;
  max-width: 600px;
  max-height: 700px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  display: flex;
  flex: 1;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  cursor: default;
`;

interface Props {
  children: React.ReactNode;
  onCancel?: () => void;
  padding?: string;
}

function QuestionModal(props: Props) {
  return (
    <Overlay onClick={props.onCancel}>
      <ModalWrap
        onClick={(e) => e.stopPropagation()}
        padding={props.padding ? props.padding : "xl"}
      >
        {props.children}
      </ModalWrap>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 999;
  display: flex;
  justify-content: center;
  padding: ${(p) => p.theme.spacing.xxxl} ${(p) => p.theme.spacing.m};
`;

import {
  BeakerIcon,
  ChevronRightIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  EnvelopeIcon,
  EyeIcon,
} from "@heroicons/react/20/solid";
import { Card2 } from "../../../components/Card";
import { H3 } from "../../../components/Heading";
import { Link } from "react-router-dom";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { FileType } from "../../../graphql/generated";
import { FileIcon } from "../../../components/FileIcon";
import { classNames } from "../../../utils/cn";

const tasks = [
  {
    name: "Initial Outreach",
    complete: true,
    assignedto: {
      name: "Dan",
      profile:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
  },
  {
    name: "NDA signed",
    complete: true,
    ssignedto: {
      name: "Dan",
      profile:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
  },
  {
    name: "Due diligence",
    complete: false,
    childTasks: [
      {
        name: "Account rec",
        complete: true,
      },
      {
        name: "Financials",
        complete: false,
      },
    ],
  },
];

const people = [
  {
    name: "Henry Smith",
    email: "henry@lawfirm.com",
    lastSeen: "1 hour ago",
    position: "Partner",
    avatarColor: "bg-indigo-400",
  },
  {
    name: "John Ward",
    email: "john@lawfirm.com",
    lastSeen: "1 day ago",
    position: "Senior Associate",
    avatarColor: "bg-rose-400",
  },
  {
    name: "Sarah Lawther",
    position: "Junior Associate",
    lastSeen: "2 days ago",
    email: "sarah@lawfirm.com",
    avatarColor: "bg-emerald-400",
  },
];

export function Bidder() {
  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 py-2 w-full shadow-md">
        <div className="flex justify-between">
          <div>
            <div className="flex items-center">
              <div className="flex items-center">
                <p className="font-semibold text-gray-400 text-sm">Buyers</p>
                <ChevronRightIcon className="w-5 h-5 text-gray-400" />
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <div className="">
                  <H3>Mid-market Law Firm</H3>
                  <p className="text-gray-400 text-xs">Joined 3 weeks ago</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-8">
        <DealProgress />
        <div className="grid grid-cols-1 2xl:grid-cols-2 mt-8 gap-8">
          <div className="">
            <H3>Overview</H3>
            <Card2 padding="m" margin="m 0 0 0">
              <div className="grid grid-col-1 xl:grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-600 font-semibold">About</p>
                  <p className="text-gray-500 text-sm">
                    Mid-market law firm is a firm consisting of lawyers who are
                    also entrepreneurs and have founded and grown multiple
                    local, national, and international businesses.
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 font-semibold">Location</p>
                  <p className="text-gray-500 text-sm">
                    Mid-market Law Firm is HQ'd in Pennsylvania but has a
                    presence across the entire east coast of the US.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 xl:grid-cols-4 mt-4">
                <div>
                  <p className="text-gray-600 font-semibold">Offer</p>
                  <p className="text-sm text-gray-500">$1,200,000</p>
                </div>

                <div>
                  <p className="text-gray-600 font-semibold">Structure</p>
                  <p className="text-sm text-gray-500">All cash deal</p>
                </div>

                <div>
                  <p className="text-gray-600 font-semibold">Last seen</p>
                  <p className="text-sm text-gray-500">1 hour ago</p>
                </div>

                <div>
                  <p className="text-gray-600 font-semibold">Joined deal</p>
                  <p className="text-sm text-gray-500">3 weeks ago</p>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-gray-600 font-semibold">Key documents</p>
                <div className="flex mt-2 flex-wrap items-center gap-2">
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Pdf} />
                    <p className="text-xs ml-1 truncate">NDA</p>
                  </div>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Docx} />
                    <p className="text-xs ml-1 truncate">LOI</p>
                  </div>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Docx} />
                    <p className="text-xs ml-1 truncate">Heads of Terms</p>
                  </div>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={FileType.Xlsx} />
                    <p className="text-xs ml-1 truncate">
                      Mid-market law firm model
                    </p>
                  </div>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <BeakerIcon className="w-4 h-4 text-gray-500" />
                    <p className="text-xs ml-1 truncate">3 Playgrounds</p>
                  </div>
                </div>
              </div>
            </Card2>
          </div>
          <div className="">
            <H3>People</H3>
            <div className="mt-3 gap-4 grid grid-cols-1 xl:grid-cols-2">
              {people.map((person) => {
                return (
                  <Card2 key={person.email} padding="m">
                    <div className="">
                      <div className="flex items-center space-x-3">
                        <div
                          className={`relative w-6 h-6 ring-2 ring-white flex-none ${person.avatarColor} rounded-full items-center justify-center flex`}
                        >
                          <p className="text-sm text-white">{person.name[0]}</p>
                        </div>
                        <div className="flex-1">
                          <p className="text-gray-600 text-sm font-bold">
                            {person.name}
                          </p>
                          <p className="text-sm text-gray-500 text-ellipsis">
                            {person.position}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="text-gray-500 text-xs">
                            Last seen {person.lastSeen}
                          </p>
                        </div>
                      </div>
                      <div className="flex mt-2 items-center space-x-3">
                        <div className="flex items-center">
                          <EnvelopeIcon className="w-4 h-4 mt-0.5 text-gray-400" />
                          <p className="text-xs ml-1 text-gray-500">
                            {person.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card2>
                );
              })}
            </div>
          </div>

          <div className="mt-4">
            <H3>Questions</H3>
            <Link to={`/s/questions/question_2cUzXfhcvPx9mId8kjJkjMYuaJG`}>
              <Card2 margin="m 0 0 0" padding="m">
                <div className="flex items-center space-x-3">
                  <div className="relative w-6 h-6 ring-2 ring-white flex-none bg-indigo-400 rounded-full items-center justify-center flex">
                    <p className="text-sm text-white">H</p>
                  </div>
                  <div className="flex-1">
                    <p className="text-gray-600 font-bold">
                      Is there a revenue breakdown anywhere?
                    </p>
                    <p className="text-sm text-gray-500 text-ellipsis">
                      Created 2 hours ago
                    </p>
                  </div>
                  <div className="flex gap-x-2">
                    <ChatBubbleLeftIcon className="w-6 h-6 text-gray-600" />
                    <p className="text-gray-600">2</p>
                  </div>
                </div>
              </Card2>
            </Link>
          </div>
          <div className="mt-4">
            <H3>Due Diligence Checklist</H3>
            <Card2 margin="m 0 0 0">
              <Link to="/s/due-diligence">
                <div className="flex items-center space-x-3 p-3 hover:bg-gray-50 cursor-pointer rounded-t-md">
                  <div className="flex-1">
                    <p className="text-gray-600  font-bold">
                      Condition precedents
                    </p>
                    <p className="text-sm text-gray-500 text-ellipsis">
                      Deadline 30th March
                    </p>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="items-center flex">
                      <div className="w-16 xl:w-48 mt-0.5 bg-orange-400/20 rounded-md h-2">
                        <div className="w-1/2 bg-orange-400 rounded-md h-2" />
                      </div>
                      <p className="text-sm text-gray-500 ml-2 leading-none">
                        1/2
                      </p>
                      <ChevronRightIcon className="w-5 h-5 text-gray-400 ml-4" />
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center space-x-3 p-3 hover:bg-gray-50 cursor-pointer rounded-b-md">
                  <div className="flex-1">
                    <p className="text-gray-600 font-bold">
                      Condition subsequents
                    </p>
                    <p className="text-sm text-gray-500 text-ellipsis">
                      Deadline 1st June
                    </p>
                  </div>
                  <div className="flex gap-x-2">
                    <div className="items-center flex">
                      <div className="w-16 xl:w-48 mt-0.5 bg-green-400/20 rounded-md h-2">
                        <div className="w-1/5 bg-green-400 rounded-md h-2" />
                      </div>
                      <p className="text-sm text-gray-500 ml-2 leading-none">
                        1/5
                      </p>
                      <ChevronRightIcon className="w-5 h-5 text-gray-400 ml-4" />
                    </div>
                  </div>
                </div>
              </Link>
            </Card2>
          </div>
        </div>
        <h1 className="font-bold text-xl text-gray-700 mt-16">
          Mid-market Law Firm's recent activity
        </h1>
        <Activity />
      </div>
    </div>
  );
}

const steps = [
  { id: "NDA", name: "Signed 3 weeks ago", href: "#", status: "complete" },
  { id: "LOI", name: "Submitted 2 weeks ago", href: "#", status: "complete" },
  {
    id: "Heads of Terms",
    name: "Completed 2 weeks ago",
    href: "#",
    status: "complete",
  },
  {
    id: "Due Diligence",
    name: "Items overdue",
    href: "#",
    status: "blocked",
  },
  {
    id: "Deal Signed",
    name: "Waiting",
    href: "#",
    status: "upcoming",
  },
];

function DealProgress() {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === "complete" ? (
              <a
                href={step.href}
                className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm truncate font-medium text-green-600 group-hover:text-green-800">
                  {step.id}
                </span>
                <span className="text-xs font-medium text-gray-500">
                  {step.name}
                </span>
              </a>
            ) : step.status === "blocked" ? (
              <a
                href={step.href}
                className="flex flex-col border-l-4 border-orange-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-sm font-medium text-orange-600">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  {step.name}
                </span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="flex flex-col border-l-4 border-green-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                aria-current="step"
              >
                <span className="text-sm font-medium text-green-600">
                  {step.id}
                </span>
                <span className="text-xs font-medium">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-xs font-medium text-gray-500">
                  {step.name}
                </span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

const activity = [
  {
    id: 4,
    type: "questioned",
    person: {
      name: "Henry",
      avatarColor: "bg-indigo-400",
    },
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    comment: "Is there a revenue breakdown anywhere?",
    date: "2 hours ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 2,
    type: "viewed",
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    person: { name: "Henry", avatarColor: "bg-indigo-400" },
    date: "1 day ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 4,
    type: "downloaded",
    person: {
      name: "Henry",
      imageUrl:
        "https://liquid-marketing-public.s3.us-east-2.amazonaws.com/dan.jpg",
    },
    file: {
      name: "Employee Agreements",
      type: FileType.Other,
    },
    comment: "",
    date: "1 day ago",
    dateTime: "2023-01-23T15:56",
  },
];

function Activity() {
  return (
    <>
      <ul role="list" className="space-y-6 mt-4 w-1/2">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === "commented" ||
            activityItem.type === "questioned" ? (
              <>
                {activityItem.person.imageUrl ? (
                  <img
                    src={activityItem.person.imageUrl}
                    alt=""
                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  />
                ) : (
                  <div
                    className={` relative w-6 h-6 ring-2 ring-white flex-none ${activityItem.person.avatarColor} rounded-full items-center justify-center flex`}
                  >
                    <p className="text-sm text-white">
                      {activityItem.person.name[0]}
                    </p>
                  </div>
                )}

                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.person.name}
                      </span>{" "}
                      {activityItem.type === "questioned"
                        ? "asked a question"
                        : activityItem.type}
                    </div>
                    <time
                      dateTime={activityItem.dateTime}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {activityItem.date}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.comment}
                  </p>
                </div>
              </>
            ) : activityItem.type === "uploaded" ? (
              <div className="flex space-x-4  w-full">
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <DocumentArrowUpIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type} a new version of
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <div>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.date}
                  </time>
                </div>
              </div>
            ) : activityItem.type === "viewed" ||
              activityItem.type === "downloaded" ? (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "viewed" ? (
                    <EyeIcon
                      className="h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <DocumentArrowDownIcon className="w-4 h-4 text-gray-500" />
                  )}
                </div>
                <div className="flex space-x-2">
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {activityItem.person.name}
                    </span>{" "}
                    {activityItem.type}
                  </p>
                  <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1">
                    <FileIcon size="s" fileType={activityItem.file!.type} />
                    <p className="text-xs ml-1">{activityItem.file!.name}</p>
                  </div>
                </div>
                <div className="flex flex-1"></div>
                <time
                  dateTime={activityItem.dateTime}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {activityItem.date}
                </time>
              </>
            ) : null}
          </li>
        ))}
      </ul>
    </>
  );
}

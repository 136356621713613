import React from "react";
import { Link } from "react-router-dom";
import { Text } from "./Text";

interface Props {
  margin?: string;
  to: string;
  children: React.ReactNode;
}

export function TextLink(props: Props) {
  return (
    <Text weight="bold" colorPreset="link" margin={props.margin} size="s">
      <Link
        style={{ textDecoration: "none", overflow: "hidden" }}
        to={props.to}
      >
        {props.children}
      </Link>
    </Text>
  );
}

import React from "react";
import {
  CompanyAccountAccessType,
  useCompanyQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";

export function SellerOnly(props: { children: React.ReactNode }) {
  const client = useGqlClient();
  const companyQuery = useCompanyQuery(client);

  if (companyQuery.isLoading || companyQuery.error || !companyQuery.data) {
    return null;
  }

  if (
    companyQuery.data.company.activeCompanyAccount.accessType ===
    CompanyAccountAccessType.Buyer
  ) {
    return null;
  }

  return <>{props.children}</>;
}

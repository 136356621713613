export type GrowthPeriod = "quarterly" | "monthly" | "annual";

const map = {
  quarterly: 4,
  monthly: 12,
  annual: 1,
};

export const annualGrowthToPeriodGrowth = (
  annualGrowth: number,
  period: GrowthPeriod
) => {
  const decimalGrowth = annualGrowth / 100;
  const periodsPerYear = map[period];
  return Math.pow(1 + decimalGrowth, 1 / periodsPerYear) - 1;
};

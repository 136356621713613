import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "../components/Alert";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Flex } from "../components/Flex";
import { H1 } from "../components/Heading";
import { Text } from "../components/Text";
import { TextInput } from "../components/TextInput";
import { TextLink } from "../components/TextLink";
import { MOBILE_NAV_BREAKPOINT } from "../config";
import { useCreateAccountMutation } from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { actions } from "../store/auth/slice";
import styled from "../styles";

const Wrap = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentWrap = styled.div`
  transition: all 0.2s;
  overflow: hidden;

  box-sizing: border-box;
  border: 1px solid transparent;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.xxl}};

  @media (min-width: ${MOBILE_NAV_BREAKPOINT}px) {
    max-width: 400px;
    box-shadow: ${(p) => p.theme.shadow.card};
    background: ${(p) => p.theme.color.card.background};
    border-radius: ${(p) => p.theme.misc.borderRadius};
  }
`;

export function Signup() {
  const client = useGqlClient();
  const createAccount = useCreateAccountMutation(client);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  return (
    <Wrap>
      <ContentWrap>
        <Flex align="center" direction="column">
          <H1 margin="s 0 0 0">Welcome</H1>
          <Text margin="xs 0 m 0">Sign Up to Liquid to continue</Text>
        </Flex>
        <TextInput
          margin="m 0 m 0"
          placeholder="Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.currentTarget.value.toLowerCase());
          }}
        />
        <TextInput
          type="password"
          margin="0 0 xs 0"
          placeholder="Password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.currentTarget.value);
          }}
        />
        {createAccount.error ? (
          <Alert type="error" text="Something went wrong" />
        ) : null}

        <Button
          onClick={() => {
            createAccount.mutate(
              {
                email,
                password,
              },
              {
                onSuccess: (data) => {
                  dispatch(
                    actions.loginSuccess({
                      tokens: {
                        accessToken: data.createAccount.tokens.accessToken,
                        refreshToken: data.createAccount.tokens.refreshToken,
                      },
                      account: data.createAccount.account,
                    })
                  );
                },
              }
            );
          }}
          margin="m 0 0 0"
        >
          {createAccount.isLoading ? "Loading..." : "Sign Up"}
        </Button>
        <Flex margin="m 0 0 0">
          <Text weight="bold" size="s" margin="0">
            Already have an account?
          </Text>
          <TextLink margin="0 0 0 s" to="/login">
            Login
          </TextLink>
        </Flex>
      </ContentWrap>
    </Wrap>
  );
}

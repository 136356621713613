import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { H3 } from "../../../components/Heading";
import { ProfitLoss } from "./ProfitLoss";
import { Cashflow } from "./CashFlow";
import { BalanceSheet } from "./BalanceSheet";

const navigation = [
  {
    name: "Profit & Loss",
    href: "/s/finances/statements/profit-loss",
    current: true,
  },
  {
    name: "Cash Flow",
    href: "/s/finances/statements/cash-flow",
    current: false,
  },
  {
    name: "Balance Sheet",
    href: "/s/finances/statements/balance-sheet",
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function Statements() {
  const location = useLocation();

  if (location.pathname === "/s/finances/statements") {
    return <Redirect to="/s/finances/statements/profit-loss" />;
  }

  return (
    <div className="flex-1 mb-8">
      <div className="bg-white px-8 pt-4 w-full shadow-md">
        <H3>Statements</H3>
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="hidden sm:-my-px sm:flex sm:space-x-8">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname === item.href
                      ? "border-indigo-500 text-gray-900"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="p-8">
        <div className="flex-1">
          <Switch>
            <Route path="/s/finances/statements/profit-loss">
              <ProfitLoss />
            </Route>
            <Route path="/s/finances/statements/cash-flow">
              <Cashflow />
            </Route>
            <Route path="/s/finances/statements/balance-sheet">
              <BalanceSheet />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

import styled, { css } from "../styles";
import withMargin, { WithMarginProp, withMarginTw } from "../styles/withMargin";
import withPadding, {
  WithPaddingProp,
  withPaddingTw,
} from "../styles/withPadding";

interface Props {
  children: React.ReactNode;
  padding?: string;
  margin?: string;
  type?: "error" | "warning" | "success" | "normal";
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function Card2(props: Props) {
  const padding = withPaddingTw({ padding: props.padding });
  const margin = withMarginTw({ margin: props.margin });

  return (
    <div
      className={classNames(
        `${padding} ${margin} border overflow-visible rounded-lg bg-white shadow hover:shadow-lg`,
        props.type === "warning" ? "border-orange-400" : "",
        props.type === "success" ? "border-indigo-400" : ""
      )}
    >
      <div>{props.children}</div>
    </div>
  );
}

export const Card = styled.div<
  {
    hover?: boolean;
    shadow?: "normal" | "heavy" | "none";
    flex?: number;
    borderColor?: string;
  } & WithPaddingProp &
    WithMarginProp
>`
  background: ${(p) => p.theme.color.card.background};

  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};

  transition: all 0.2s;
  overflow: hidden;

  box-sizing: border-box;
  border: 1px solid ${(p) => (p.borderColor ? p.borderColor : "transparent")};

  ${(p) =>
    p.flex
      ? css`
          flex: ${p.flex};
        `
      : ``}

  ${(p) =>
    p.hover
      ? css`
          &:hover {
            transform: scale(1.01);
          }
        `
      : null}

  ${(p) =>
    p.shadow === "heavy"
      ? css`
          box-shadow: ${(p) => p.theme.shadow.cardHeavy};
        `
      : null}

      ${(p) =>
    p.shadow === "none"
      ? css`
          box-shadow: none;
        `
      : null}

  ${(p) =>
    p.onClick
      ? css`
          cursor: pointer;
        `
      : ``};

  ${withPadding};
  ${withMargin};
`;

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { classNames } from "../utils/cn";

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl" | "full";
  position?: "top" | "center" | "bottom";
  padding?: string;
}

const sizesMap = {
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-2xl",
  full: "max-w-5xl",
};

const positionsMap = {
  top: "items-start",
  center: "items-center",
  bottom: "items-end",
};

export function AnimatedModal(props: Props) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(e) => {
          props.onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-50"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div
          className={`fixed inset-0 z-10 w-screen overflow-y-auto ${
            props.size === "full" ? "p-8" : "p-0"
          }`}
        >
          <div
            className={classNames(
              props.position ? positionsMap[props.position] : "items-center",
              `flex min-h-full  justify-center text-center`,
              props.position && props.position === "top" ? "" : ""
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-50"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  `relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full`,
                  props.size ? sizesMap[props.size] : "max-w-sm",
                  props.padding ? props.padding : "px-4 pb-4 pt-5 sm:p-6"
                )}
              >
                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
